.tb-movement01 {
   table-layout: auto !important;
   .col1 {
     width: 10%;
   }
   .col2 {
     width: 20%;
   }
   .col3 {
     width: 30%;
   }
   .col4 {
     width: 20%;
   }
   .col5 {
     width: 30%;
   }
   tr td {
     text-align: left;
     &:nth-of-type(5) {
       text-align: center;
       a {
         margin: 2px 0;
       }
     }
     &.pre-line {
       white-space: pre-line;
     }
   }
 }
 .tb-movement03 {
   table-layout: auto !important;
   .col1 {
     width: 10%;
   }
   .col2 {
     width: 30%;
   }
   .col3 {
     width: 30%;
   }
   .col4 {
     width: 30%;
   }
   tr td {
     text-align: left;
     &:nth-of-type(5) {
       text-align: center;
       a {
         margin: 2px 0;
       }
     }
   }
 }
 .tb-movement02, .tb-movement04 {
   .col1 {
     width: 30%;
   }
   .col2 {
     width: 40%;
   }
   .col3 {
     width: 30%;
   }
 }
