@charset "utf-8";

/* Layout
-----------------------------------------------------------------------------*/
.barn .table-scroll     { width: 100%; overflow: auto; }
.barn .table-scroll table { min-width: 768px; }

.barn .scroll-outer .result { min-width: 1px; }
.barn .scroll-outer .fixed-tb .result { table-layout: auto; }
.barn .fixed-tb         { overflow: auto; width: 100%; height: calc(100vh - 186px); }
.barn .fixed-tb th      { padding: 8px 0; }

.barn .number-box li .section .number { padding: 15px 10px 3px; font-size: 1.6rem; font-weight: normal; text-align: center; letter-spacing: 0; }
.barn .number-box li .section .number span { padding-right: .5rem; font-size: 5rem; font-weight: bold; line-height: 1; }


.barn .number-box li .section h5 + .number { padding: 6px 10px 3px; }
.barn .number-box li:last-child .section .number span { padding-right: 0; }
.barn .number-box.number-box-inner li .section h5 { padding-top: 26px; font-size: 1.4rem; font-weight: normal; }
.barn .number-box.number-box-inner li .section .number span { font-size: 4rem; padding-left: 2rem; padding-right: .5rem; }
.barn .number-box li .section.stress .number span { padding-right: 0; }
.barn .number-box.number-box-inner li .section .number.pl0 span { padding-left: 0; }


.barn .box-history .startline { position: relative; padding-left: 56px; padding-top: 1px; padding-bottom: 20px; color: #666; font-weight: bold; }
.barn .box-history .startline:before { content: ""; display: block; position: absolute; left: 28px; top: 0; width: 20px; height: 20px; background-color: #fff; border: 3px solid #ddd; border-radius: 50%; }


/* .worklist */
.barn .worklist .section { width: 30%; min-width: 360px; height: auto!important;}
.barn .worklist .section h4 { padding-left: 3.8rem; }
.barn .worklist .section h4 .separator { padding: 0 3px; }
.barn .worklist .section h4 i { position: absolute; left: 2px; top: 4px; font-size: 3.5rem; }
.barn .worklist .section table td { border-right: none; }
.barn .worklist .section table td:nth-of-type(2) { width: 6em; text-align: center; }

/* .simplicity-box */
.barn .simplicity-box { width: 50%; min-width: 480px; margin: 79px auto 10px; padding: 10px; }
.barn .simplicity-box .section { min-height: 400px; }
.barn .simplicity-box .section h4 { margin-bottom: 0; text-align: center; }
.barn .simplicity-box .section .text-box { max-height: 290px; padding: 20px; overflow: auto; }
.barn .simplicity-box .section p { margin-bottom: 10px; font-size: 1.4rem; line-height: 1.7; }
.barn .simplicity-box .section p:nth-last-of-type(1) { margin-bottom: 0; }
.barn .simplicity-box .section p strong { font-size: 1.8rem; }
.barn .simplicity-box .section a.edit-btn { position: absolute; left: 50%; bottom: 20px; display: block; width: 9rem; margin: 0 0 0 -4.5rem; padding-left: 26px; }
.barn .simplicity-box .section a.edit-btn i { font-size: 2rem; margin-top: -1rem; }


/* .graph-box */
.barn .graph-box h4 { margin-bottom: 0; }
.barn .graph-box h4 i { top: 4px; margin-top: 0; font-size: 2.8rem; }
.barn .graph-box .switch-btn { position: absolute; right: 10px;  }
.barn .graph-box .switch-btn li { display: inline-block; }
.barn .graph-box .switch-btn li a { padding: 0 10px; }
.barn .graph-box .switch-btn li a.edit-btn { min-width: 4rem; }
.barn .graph-box .graph-title-box { padding-top: 10px; text-align: center; }
.barn .graph-box .graph-title-box li { display: inline-block; font-size: 1.5rem; font-weight: bold; }
.barn .graph-box .graph-title-box li span { font-size: 1.5rem; font-weight: bold; }

// 旧アイコンフォント調整
.barn .graph-box .graph-title-box .icon-l-arrow-A-l01,
.barn .graph-box .graph-title-box .icon-l-arrow-A-r01 {
  font-size: 14px !important;
  display: inline-block;
  width: 14px;
  height: 14px;
  color: #fff !important;
  border-radius: 50%;
  background-color: #ff9908;

  &.disabled {
    background-color: #ccc !important;
  }
}

.barn .m0-10            { margin: 0 10px; }
.barn .pb10             { padding-bottom: 10px !important; }


/* Misc
-----------------------------------------------------------------------------*/
.clear-fix:before,
.clear-fix:after  { content: ""; display: block; overflow: hidden; }
.clear-fix:after  { clear: both; }
.clear-fix        { zoom: 1; }

.barn .m0               { margin: 0 !important; }
.barn .pb0              { padding-bottom: 0 !important; }

/* Btn
-----------------------------------------------------------------------------*/
.barn .edit-btn         { position: relative; display: inline-block; min-width: 5rem; height: 22px; padding: 0 6px 0 22px; color: #fff; font-size: 1.1rem; line-height: 22px; background-color: #ff9908; border-radius: 6px; }
.barn .edit-btn:disabled { background-color: #84837F; }
.barn .edit-btn:hover   { text-decoration: none; opacity: .8; }
.barn .edit-btn.glyphic, .content .edit-btn.glyphic { padding: 0px; }
.barn .edit-btn i       { position: absolute; top: 50%; left: 4px; margin-top: -.75rem; color: #fff; font-size: 1.5rem; }
.barn .arrow-right      { padding: 0 18px 0 6px!important; }

// 旧アイコンフォント調整
.barn .arrow-right .icon-l-arrow-A-r01 {
  font-size: 18px !important;
  top: 2px;
  right: 0;
  left: auto;
  margin: 0;
}

/* CSS Sprite
-----------------------------------------------------------------------------*/
.modal-dialog.modal-m { width: 467px; padding-top: 7%; }

/* ui-datepicker
-----------------------------------------------------------------------------*/
.ui-datepicker          { display: none; width: 200px !important; padding: 1px !important; background: #fff; box-shadow: 0px 2px 2px rgba(0,0,0,0.3); -webkit-box-shadow: 0px 2px 2px rgba(0,0,0,0.3); -moz-box-shadow: 0px 2px 2px rgba(0,0,0,0.3); -ms-box-shadow: 0px 2px 2px rgba(0,0,0,0.3); }
.ui-datepicker th,
.ui-datepicker td,
.ui-datepicker td a     { font-size: 1.2rem; }
.ui-datepicker th,
.ui-datepicker td       { border-top: 1px solid #ccc; border-right: 1px solid #ccc; text-align: center; }
.ui-datepicker th       { padding: 0; color: #fff; background: #999; }
.ui-datepicker th:first-child,
.ui-datepicker td:first-child a { color: #f00; }
.ui-datepicker th:last-child,
.ui-datepicker td:last-child a { color: #00d9ff; }
.ui-datepicker td       { line-height: 20px; padding: 0; }
.ui-datepicker td a     { display: block;  color: #111; font-weight: bold; text-decoration: none; }
.ui-datepicker a:hover  { text-decoration: none; background: #8dddb0; }
.ui-widget-header       { border: 1px solid #aaa; color: #fff; font-weight: bold; background: #111;}
.ui-datepicker-calendar { width: 198px; border: 1px solid #ccc; }
.ui-datepicker-title    { font-weight: bold; }
.ui-datepicker-prev     { position: absolute; top: 3px !important; left: 2px !important; background: url(../images/sprite.png) 0 -29px !important; }
.ui-datepicker-prev:hover { top: 3px; left: 2px; background: url(../images/sprite.png) 0 -49px !important; }
.ui-datepicker-next     { position: absolute; top: 3px !important; right: 2px !important; background: url(../images/sprite.png) -20px -29px !important; }
.ui-datepicker-next:hover { top: 3px; right: 2px; background: url(../images/sprite.png) -20px -49px !important; }
.ui-datepicker-next,
.ui-datepicker-next:hover,
.ui-datepicker-prev,
.ui-datepicker-prev:hover { width: 20px !important; height: 20px !important; }
.ui-datepicker-next span,
.ui-datepicker-prev span { display: none !important; }
.ui-datepicker-title    { line-height: 24px; font-size: 1.2rem; text-align: center; }
.ui-state-highlight,
.ui-state-highlight:hover { color: #fff !important; font-weight: bold; background: #009943 !important; }


/* Media Queries
-----------------------------------------------------------------------------*/
@media all and (-ms-high-contrast:none){
  *::-ms-backdrop, .barn .content .content-box.cow-detail .tab li a { padding: 12px 12px 7px 26px !important; }
  *::-ms-backdrop, .barn .radio-custom label { padding-top: 5px !important; }
}
