.alert-box {
  max-height: 200px;
  margin: 0 0 0 150px;
  ul {
    position: relative;
    width: 100%;
    padding: 10px;
    background-color: #fff;
    overflow: auto;
    //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
    box-shadow: rgba(90, 90, 90, 0.3) 2px 2px 0 1px;
    z-index: 3;
  }
  li {
    position: relative;
    min-height: 32px;
    margin-bottom: 5px;
    padding: 0 7em 0 7px;
    color: #fff;
    line-height: 32px;
    //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
    border-radius: 6px;
    background-color: #f56464;
    &:last-child {
      margin-bottom: 0;
    }
    p {
      display: inline-block;
      &.category {
        float: left;
        font-size: 1.2rem;
        font-weight: bold;
        vertical-align: middle;
        margin-right: 8px;
      }
      &.text {
        float: left;
        font-size: 1.2rem;
        font-weight: bold;
        vertical-align: middle;
        width: 80%;
        //Instead of the line below you could use @include word-break($value)
        word-break: break-all;
      }
      &.time {
        float: left;
        margin-right: 8px;
        font-size: 1rem;
        font-weight: bold;
      }
    }
    i {
      float: left;
      margin-right: 5px;
      padding-top: 3px;
      font-size: 2.6rem;
      color: #fff;
      vertical-align: middle;
    }
    button {
      position: absolute;
      right: 10px;
      top: 0;
      display: block;
      height: 100%;
      padding-left: 6px;
      color: #fff;
      border-left: 1px dotted #fff;
      &:hover {
        text-decoration: none;
      }
      i {
        float: none;
        margin-top: -3px;
        padding-top: 0;
        font-size: 1.8rem;
      }
    }
  }
}

.alert-list {
  .content-box {
    height: auto;
  }

  .row--selected {
    .labels-td {
      span {
        border-bottom: 1px solid #fff;
        color: #fff;
      }
      .cow-labels.illness {
        border: 1px solid #fff;
        color: #fff;
        &:hover {
          border: 1px solid #dedede;
          color: #fff;
          background-color: #dedede;
        }
      }
      .cow-labels.chronic-illness {
        border-bottom: 1px solid #fff;
        color: #fff;
        &:hover {
          border: 1px solid #dedede;
          color: #fff;
          background-color: #dedede;
        }
      }
    }
  }
  .sub-sections {
    div {
      text-align: right;
      margin-right: 15px;
    }
  }
  .tab-context {
    padding-top: 10px;
  }
  .tab-content {
    padding-top: 10px;
    &-menu {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding: 0 10px;
      &-item {
        padding: 0 0 0 10px;
        display: flex;
        align-items: center;
      }
    }
  }
  .labels-td {
    text-align: left;
    .cow-labels {
      &:hover {
        background-color: #dedede;
        border-color: #dedede;
        border-radius: 2px;
      }
      text-align: center;
      font-size: 1.2rem;
      display: inline-block;
      cursor: pointer;
      font-weight: bold;
      color: #555;
      border-bottom: 1px solid #ccc;
      border-radius: 0px;
      white-space: nowrap;
      padding: 1px 8px;
      &.illness {
        border: 1px solid #c02c94;
        border-radius: 2px;
        color: #c02c94;
        margin: 0;
        padding: 1px 7px;
        &:hover {
          background-color: #ecd8e6;
          border-color: #ecd8e6;
        }
      }
      &.chronic-illness {
        border-color: #c02c94;
        color: #c02c94;
        margin: 0;
        padding: 1px 7px;
        &:hover {
          background-color: #ecd8e6;
          border-color: #ecd8e6;
        }
      }
      &.acute-illness {
        border: 1px solid #e25f00;
        border-radius: .2rem;
        color: #ea6300;
        margin: 0;
        padding: 1px 0;
        width: 62px;
        padding: 1px 0;
        &:hover {
          background-color: #fae2d0;
          border-color: #fae2d0;
        }
      }
    }
    &.labels-td-acute-illness {
      background-color: #fcf6ed;
      border-right: none;
      text-align: center;
    }
  }
  .labels-td.labels-td-illness {
    text-align: center;
  }
  &#alert-dysstasia {
    .content-box {
      padding: 0;
    }
    .tab-content {
      background: transparent;
      .result {
        .result-menu {
          display: flex;
          padding: 0 0 15px 10px;
          color: #fff;
          label[for="show-resolved"] {
            font-weight: normal;
          }

          &>div {
            display: flex;
            padding: 2px 5px;
          }

          .sort {
            display: flex;
            width: 130px;

            .sort-item {
              border: 0.1rem solid #ddd;
              border-right: none;
              color: #ccc;
              display: inline-flex;
              width: 100%;

              &-radio:checked + label {
                background: #366678;
                color: #fff;
              }

              &-label {
                align-items: center;
                background: none;
                color: #ccc;
                cursor: pointer;
                display: flex;
                font-weight: 400;
                justify-content: center;
                margin: 0;
                transition: all .3s ease;
                width: 100%;
              }

              &:last-child {
                border: 0.1rem solid #ddd;
              }
            }
          }
        }
      }
    }
  }
  // alert-list-item
  &-item {
    background: #ffffff;
    border-radius: 4px;
    display: flex;
    margin-bottom: 10px;
    overflow: auto;
    padding: 8px 24px 16px 16px;

    &-content {
      flex: 1 0 500px;

      .content-primary {
        display: flex;
        margin-bottom: 16px;
        flex-wrap: wrap;

        .content-item {
          margin: 0 32px 0 0;
          padding: 8px 0 0 0;

          .content-item-label {
            color: #2121219e;
            font-size: 13px;
            font-weight: 400;
            margin-right: 12px;
          }

          .content-item-value {
            color: #2121219e;
            font-size: 15px;
            font-weight: 700;

            &.cow-no {
              color: #2a66b7;
              font-size: 17px;
            }

            &.alert-level-high {
              color: #bc0822;
            }

            &.alert-level-low {
              color: #ff9e08;
            }

            &.confirmed-status-sign {
              color: #bc0822;
            }

            &.confirmed-status-no-sign {
              color: #bbbbbb;
            }
          }
        }
      }

      .content-secondary {
        background: #f0f0f0;
        border-radius: 4px;
        padding: 24px 20px;

        .content-item {
          margin: 0 24px 0 0;

          &-label {
            color: #2121219e;
            font-size: 13px;
            font-weight: 400;
            margin-right: 8px;
          }

          &-value {
            color: #2121219e;
            font-size: 13px;
            font-weight: 700;
          }
        }
      }
    }

    &-operation {
      &-regist {
        align-items: center;
        display: flex;
        height: 100%;
        justify-content: flex-end;
        width: 200px;

        &-button {
          align-items: center;
          background: linear-gradient(90deg,#387ac7,#0d3898);
          border: 1px solid rgba(0,0,0,.12);
          border-radius: 4px;
          box-shadow: 0 2px 3px rgba(0,0,0,.36);
          color: #fff;
          cursor: pointer;
          display: flex;
          font-size: 14px;
          font-weight: 700;
          height: 40px;
          justify-content: space-between;
          margin: 0 0 0 24px;
          opacity: 1;
          padding: 0 5px 0 19px;
          transition: all .1s ease;
          width: 156px;

          &:hover {
            border: 1px solid rgba(0, 0, 0, 0.08);
            background: linear-gradient(to right, #3e8eeb, #377adc, #1f34a8);
            box-shadow: 0 3px 6px rgba(0, 0, 0, 0.36);
          }
        }
      }

      &-edit {
        align-items: center;
        display: flex;
        height: 100%;
        justify-content: flex-end;
        width: 77px;

        &-button {
          align-items: center;
          background: #f2f2f2;
          border: 1px solid #8d8d8d15;
          border-radius: 44px;
          display: flex;
          font-size: 14px;
          justify-content: center;
          height: 40px;
          opacity: 1;
          width: 40px;

          &:hover {
            background: #eaeaea;
          }
        }
      }
    }

    .content-row {
      display: flex;
      flex-wrap: wrap;
      margin: 0 0 16px 0;

      &:last-child {
        margin: 0;
      }
    }
  }

  @media print {
    .alert-list-item {
      border-bottom: 1px solid #000000;
      padding-top: 0;
    }

    .alert-list-item-operation {
      display: none;
    }

    .content-primary {
      margin-bottom: 10px !important;
    }

    .content-secondary {
      padding: 0 !important;
    }
  }
}
