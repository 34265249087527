.milking {
  height: 623px;
  .box {
    height: auto;
  }
  .section {
    .btn-back i, .btn-next i {
       color: $orange;
       font-size: 1.8rem;
       margin-bottom: 2px;
    }
  }
  .graph-box {
    position: relative;
    background: #fff;
    box-shadow: 0 1px 2px 0 rgba(90, 90, 90, 0.3);
  }
}

