.cow-group-list {
  .content-box {
    .section {
      width: 20%;
      height: 100%!important;
      overflow-y: scroll;

      .table-wrapper {
        padding: 10px;
        table {
          tbody {
            td {
              border-right: 1px solid #ddd;
            }
          }
        }
      }
    }
  }
}