/**
 *  Main Navigation component
 *  $1. Default styles
 *  $2. Custom styling for nav items
 *  $3. Menu Toggler
 */

/**
  $1. Default styles
 */
.main-nav {
  font-size: $nav-font-size;
  position: fixed;
  z-index: 10000000;
  top: $header-height;
  left: -$nav-width-minimised;
  width: $nav-width-minimised;
  height: 100vh;
  transition: $default-transition;
}

.nav-item {
  position: relative;
  display: flex;
  overflow: visible;
  flex-direction: column;
  max-width: $nav-width-maximised;
  transition: $default-transition;
  white-space: nowrap;
  color: #fff;
  background-color: #444;

  align-items: center;
  flex: 0 0 auto;

  &:hover {
    background-color: $midGrey;
  }

  &::after {
    position: absolute;
    top: 0;
    left: 100%;
    width: 1px;
    height: 100%;
    content: "";
    background-color: #000;
  }
}

/**
  $2. Custom styling for nav items
  - logo
 */
.menu--greeting,
.menu--logout {
  display: inline-block;
  width: 100%;
  padding: 15px 20px;
}

// U-motion logo
.logo {
  position: fixed;
  z-index: 100;
  display: block;
  width: 100px;
  height: $logo-height;
  border-bottom: none;
  background-color: $lighterGrey;
  background-image: url(../images/1.5/header_logo-horizontal.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 70px 19px;

  justify-content: center;

  &:hover {
    background-color: $lighterGrey;
  }
}

/**
  $3. Menu Toggler
 */
.menu-toggler {
  left: $nav-width-minimised;
  flex-direction: row;
  height: $nav-toggler-height;
  margin-bottom: 0; // Force override old defaults
  cursor: pointer;
  border-bottom: none;

  &.isOpen {
    background-color: $midGrey;
  }
}

// Menu Toggler hamburger container
.menu-toggler-bars {
  display: flex;
  flex-direction: column;
  width: $nav-width-minimised;
  height: 14px;
  margin-left: auto;

  align-items: center;
  justify-content: space-between;
}

// Menu Toggler hamburger line
.menu-toggler-bar {
  width: 20px;
  height: 2px;
  transition: $default-transition;
  background-color: #fff;

  flex: 0 0 auto;
}

// Menu Toggler label
.menu-toggler-label {
  font-size: 1.3rem;
  display: inline-block;
  width: 0;
  transition: $default-transition;
  opacity: 0;
}