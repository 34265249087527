.carcass-report {
  $left-table-width: 180px;
  $right-table-width: 1500px;
  .print {
    display: none;
  }
  .content-box {
    height: auto;
  }
  form.tools-sort {
    height: 90px;
    .tools-sort-item {
      float: left;
      display: flex;
      margin: 10px;
      height: 25px;
      &>* {
        padding: 0 5px;
        margin: auto;
        display: flex;
        align-items: center;
      }
      select {
        margin: 2.5px auto;
        min-width: 100px;
        position: relative;
        &:focus {
          margin: auto;
        }
      }
      button {
        display: flex;
        justify-content: center;
      }
    }
  }
  .table-info {
    max-width: calc(#{$left-table-width} + #{$right-table-width});
    padding: 5px 0px;
  }
  carcass-report-table {
    .carcass-report-table {
      width: 100%;
      height: 100%;
      overflow: auto;
      tr.border-bottom-thick {
        border-bottom: 2px solid #ddd;
      }
      th {
        height: 60px;
      }
      td {
        height: 30px;
        text-align: center;
        white-space: nowrap;
      }
      .left-table-container {
        width: $left-table-width;
        float: left;
        overflow: auto;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        .left-top-table {
          border-right: none;
          border-left: none;
          tr:first-child {
            th {
              height: 40px;
              background-color: white;
              border-right: none;
              border-top: none;
              border-left: none;
            }
          }
        }
        .left-bottom-table {
          border-bottom: 1px solid #ddd;
          td {
            border-top: none;
            border-bottom: none;
            text-align: left;
          }
          tr td:last-child {
            border-top: 1px solid #ddd;
          }
        }
      }
      .right-table-container {
        width: calc(100% - #{$left-table-width});
        float: left;
        overflow: auto;
        .right-top-table {
          width: $right-table-width;
          border-left: none;
          tr:first-child {
            th {
              height: 40px;
              border: none;
              border-left: 1px solid #ddd;
            }
            th:first-child {
              border-left-color: white;
              background-color: white;
            }
          }
        }
        .right-bottom-table {
          width: $right-table-width;
          border-bottom: 1px solid #ddd;
          tr:last-child {
            td {
              border-bottom: none;
            }
          }
        }
      }
    }
  }
}
