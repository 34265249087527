.barn {
  &--temporary-group-view-activity {
    .uMain {
      overflow-x: auto;
      overflow-y: hidden;
    }

    .content .content-box {
      display: flex;
      height: calc(100vh - 128px);

      @media (pointer: coarse) {
        height: -webkit-fill-available;
        min-height: calc(100vh - 224px);

        @media(min-height: 700px) {
          min-height: calc(100vh - 300px);
        }
      }
    }
  }

  &--temporary-group-view-cow-detail {
    min-width: 1260px !important;

    &~.ui-datepicker {
      z-index: 10005001 !important;
    }
  }
}

.temporary-group-view {
  display: flex;

  &-list {
    width: 300px;
    height: 100%;
    margin-right: 10px !important;

    .section {
      padding: 10px;
    }

    .row-clickable-list-outer {
      @media (pointer: coarse) {
        height: 100%;
      }
    }

    .row-clickable-list-heading {
      white-space: nowrap;
    }

    &--pen {
      position: absolute;
      top: 36px;
      width: 300px;
      height: calc(100% - 36px);
      margin-right: 10px !important;

      .section {
        padding: 10px;
      }

      .row-clickable-list-outer {
        @media (pointer: coarse) {
          height: 100%;
        }
      }

      .row-clickable-list-heading {
        position: sticky;
        top: 0;
      }
    }
  }

  &-body {
    position: relative;
    display: flex;
  }

  .row-clickable-list-row {
    &.active {
      background-color: #f0f0f0 !important;
    }
  }

  .contents__tab {
    position: absolute;
    z-index: 9;
    top: 0;
    left: 0;
    width: 100%;
    min-width: 300px;
    margin: 0 !important;
    padding: 0 0 10px;
    background: #1f1f1f;
  }

  &-toolbar {
    position: absolute;
    display: flex;
    z-index: 9;
    top: 0;
    left: 0;
    width: 600px;
    background: #1f1f1f;
    gap: 10px;
    align-items: center;

    .bulk-event-button {
      margin: 0 0 0 8px !important;
    }

    &-text {
      color: white(primary);
      font-size: 13px !important;
    }
  }

  &-chart {
    position: absolute;
    top: 36px;
    width: 600px;
    height: calc(100% - 36px);
    margin-right: 10px !important;
    background: #fff;

    &-inner {
      overflow-x: hidden;
      overflow-y: auto;
      height: 100%;
      padding: 40px 0 20px;
    }

    &-body {
      &:last-child {
        min-height: 250px;
      }
    }

    &-legends {
      position: absolute;
      z-index: 9;
      top: 0;
      left: 0;
      display: flex;
      width: 600px;
      background: rgba(255, 255, 255, .8);

      justify-content: center;
    }

    &-legend {
      display: flex;
      margin: 10px !important;

      align-items: center;

      &:hover {
        text-decoration: none !important;
      }

      &-line {
        display: block;
        width: 10px;
        height: 10px;
        margin: 0 5px 0 0 !important;
      }

      &-text {
        color: #333 !important;
      }
    }

    &-pen {
      display: block;
      font-size: 12px !important;
      font-weight: 700;
      margin: 5px 15px !important;
      padding: 5px 10px;
      color: #333;
      background: #f2f2f2;
      cursor: pointer;

      &-checkbox {
        margin: 0 5px 0 0 !important;
      }
    }

    .c3-tooltip-container {
      z-index: 8;
    }
  }

  &-cow-detail {
    .uHeader {
      position: absolute;

      &__trigger {
        position: absolute;
      }
    }

    .uHeading {
      position: absolute;
    }

    .cow-detail-summary {
      display: flex;
      width: 100%;
      height: 800px;
      padding: 0 10px 10px;

      &-col1 {
        width: 300px;
        padding: 0 10px 0 0;

        flex-shrink: 0;

        .uList--secondary {
          margin: 0 0 10px !important;
          border-radius: 0;
          background: #fff;
        }

        .uList__row {
          &:not(:first-child) {
            border-top: none;
          }
        }

        .activityCharts {
          height: 398px;

          justify-content: flex-start;
        }

        .activity-prot-control-panel {
          margin: 0 0 10px;
        }

        .u-loader {
          display: flex;
          height: 299px;
          padding: 0;

          align-items: center;
          justify-content: center;
        }

        .activity-scatter-plot-chart-message {
          line-height: 1;
          display: flex;
          height: 299px;

          align-items: center;
          justify-content: center;
        }

        .scatter-plot-chart {
          height: 299px;
          min-height: auto;
          max-height: none !important;
        }
      }

      &-col2 {
        position: relative;
        display: flex;
        flex-direction: column;
        width: calc(100% - 700px);

        &-body {
          display: flex;
          flex-direction: column;
          width: 100%;
          height: 100%;
          background: #fff;

          &:not(:last-child) {
            margin: 0 0 10px;
          }
        }

        .chart-datepicker {
          width: 120px;
          text-align: center;
          border: none;
        }

        .chart-header {
          .chart-header-item {
            &:first-child {
              display: none;
            }
          }

          .chart-nav {
            position: absolute;
            top: 0;
            right: 5px;
            display: flex;
            width: 135px;
            height: 36px;

            align-items: center;
            justify-content: space-between;
          }
        }

        .activityCharts .u-loader {
          display: flex;
          height: 160px;
          padding: 0;

          align-items: center;
          justify-content: center;
        }

        .activity-stacked-bar-chart-message {
          display: flex;
          height: 160px;
          padding: 0;

          align-items: center;
          justify-content: center;
        }

        .activity--chart-wrapper {
          height: 160px;
          min-height: auto;
          max-height: none !important;
        }

        .time-budget-title {
          position: relative;

          .right-contents {
            position: absolute;
            top: 0;
            right: 10px;
            display: flex;
            height: 36px;

            align-items: center;
          }
        }

        .graph-title-box {
          padding: 0;
        }

        .right-shoulder {
          .kindColor {
            margin: 0;
          }
        }

        #activityTimeBudget-contents .u-loader {
          position: absolute;
          z-index: 10;
          display: flex;
          width: 100%;
          height: 175px;
          padding: 0;
          background: #fff;

          align-items: center;
          justify-content: center;
        }

        .activity-time-budget-message {
          position: absolute;
          z-index: 10;
          display: flex;
          width: 100%;
          height: 175px;
          padding: 0;
          background: #fff;

          align-items: center;
          justify-content: center;
        }

        #activity_summary_chart {
          height: 190px;
          min-height: auto;
          max-height: none !important;
        }
      }

      &-col3 {
        z-index: 10;
        width: 400px;

        flex-shrink: 0;

        .box-history.individual-history {
          position: relative;
          width: 400px !important;
          height: 100%;
          padding: 0 0 0 10px !important;
        }

        .box-history.individual-history .list-unstyled {
          height: 100%;
          background: #fff;
        }

        .timeline-year-wrapper {
          left: 10px !important;
          width: calc(100% - 10px) !important;
        }

        .on-event-input .timeline-year-wrapper {
          display: none;
        }

        .box-history-innner {
          height: 704px;
        }

        .timeline--cowIdLabel {
          display: none;
        }

        .filter-box {
          position: absolute !important;
        }
      }
    }

    .cow-detail-data {
      display: flex;
      width: 100%;
      padding: 0 10px 10px;

      &-col1 {
        width: calc(100% - 400px);

        .section {
          background: #fff;
        }
      }

      &-col2 {
        width: 400px;
        padding: 0 0 0 10px;

        flex-shrink: 0;

        &.fixed {
          position: fixed;
          top: 10px;
          right: 10px;

          .box-history-innner {
            height: calc(100vh - 106px);
          }

          .on-event-input .box-history-innner {
            height: calc(100vh - 55px);
          }
        }

        .box-history {
          position: relative;
          width: 390px;
          padding: 0 !important;
        }

        .section {
          background: #fff;
        }

        .on-event-input .timeline-year-wrapper {
          display: none;
        }

        .timeline--cowIdLabel {
          display: none;
        }

        .filter-box {
          position: absolute !important;
        }
      }
    }
  }
}
