#activityTimeBudget, #summaryTimeBudget {
  span.selectInfo {
    font-size:2.0rem;
    font-weight:normal;
  }

  .axis {
    line {
      fill: none;
      stroke: #ddd;
      shape-rendering: crispEdges;
      stroke-width: 1px;
      transform: translate(0px, -15px);
    }
    path {
      display: none;
    }
    text {
      font-size: 11px;
      fill: #666;
    }
  }
  .ouga {
    fill: #9467bd;
    opacity: "0.6";
  }
  .hokou {
    fill: #fba40d;
    opacity: "0.6";
  }
  .soukou {
    fill: #d62728;
    opacity: "0.6";
  }
  .saishoku {
    fill: #2ca02c;
    opacity: "0.6";
  }
  .insui {
    fill: #aec7e8;
    opacity: "0.6";
  }
  .seishi {
    fill: #666666;
    opacity: "0.6";
  }
  .legend {
    text {
      fill: #545454;
      &.legend-title {
        font-size: 11px;
      }
      &.legend-body {
        font-size: 11px;
      }
    }
  }
  .detail-frame {
    fill: #ffffff !important;
  }
}
