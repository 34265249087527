/* Unique Style of 'GenomLinkage'
----------------------------------------*/
.genom-linkage {
  font-size: 1.4rem;
  margin: 0 auto;
  padding-top: 8rem;
  position: absolute;
  height: 100vh;
  width: 100vw;
  overflow: auto;
  top: 0;
  background-color: #fff; }
@media (min-width: 451px) {
  .genom-linkage {
    display: table-cell;
    padding-top: 6rem; } }

.genom-linkage .l-form {
  background-color: #f0f0f0;
  margin: 0 -1.5rem 3.2rem;
  padding: 4rem 1.6rem; }

.genom-linkage .l-title {
  font-weight: 400;
  font-size: 1.6rem;
  margin-bottom: 4rem;
  margin-top: 0;
  text-align: center; }
.genom-linkage .l-title_object {
  display: block;
  margin: 0 auto 0.5rem;
  width: 7.7rem; }

.genom-linkage .l-form {
  padding-right: 2.8rem;
  padding-left: 2.8rem; }

.genom-linkage .confirm-message {
  text-align: center;
  margin-bottom: 20px;
  span {
    font-size: 1.6rem;
  } }

.genom-linkage .confirm-content {
  background-color: #fff;
  text-align: left;
  margin: 1rem;
  padding: 1rem;
  .auth-message {
    margin-bottom: 20px;
    span {
      font-size: 1.5rem;
    } }
  .auth-list {
    margin-bottom: 20px;
    ol {
      list-style-type: disc;
      li {
        font-size: 1.3rem;
      } } } }

.genom-linkage .linkage-action .linkage-action-button {
    background-color: #fff;
    width: 35%;
    font-weight: 600;
    border-color: transparent;
    height: 4.6rem;
    font-size: calc(1em + .1rem);
    margin-right: 1rem;
    margin-left: 1rem;
}

.genom-linkage .linkage-action .linkage-action-button[type="submit"] {
  background-color: #5d7836;
  width: 50%;
  color: #fff;
  font-weight: 700;
}

@media (min-width: 451px) {
  .genom-linkage .l-container {
    margin-bottom: 10rem;
    padding-top: 4rem; }
  .genom-linkage .l-form {
    box-shadow: 0 0.2rem 0.8rem rgba(62, 62, 62, 0.5);
    margin-right: auto;
    margin-left: auto;
    padding-right: 2.8rem;
    padding-left: 2.8rem;
    width: 40rem; } }
