/*---------------------------------
　タイムライン
---------------------------------*/

/*
　タイムライン
---------------------------------*/
.uTimeline {
  &__item {
    display: flex;
    border-radius: 4px;
    background: linear-gradient(to right, white(primary), #ebebeb);

    &:not(:first-child) {
      margin: 8px 0 0;
    }

    &Head {
      display: flex;
      min-height: 52px;

      &Date {
        font-size: 14px !important;
        line-height: 1.2;
        display: flex;
        width: 123px;
        padding: 0 0 0 24px;
        text-align: center;
        color: black(primary);

        align-items: center;
      }

      &Body {
        display: flex;

        align-items: center;
        justify-content: center;
      }

      &Text {
        font-size: 14px !important;
        font-weight: 700;
        margin: 0 0 0 15px !important;
        white-space: nowrap;
        color: black(primary);
      }
    }

    &Body {
      margin: 13px 0 13px 20px !important;

      &Items {
        line-height: 2.1;
        display: flex;
        padding: 0 0 0 13px;
        border-left: 1px solid #ccc;

        flex-wrap: wrap;
      }

      &Item {
        display: flex;

        flex-wrap: wrap;
      }

      &Heading {
        font-size: 13px !important;
        margin: 0 10px 0 0 !important;
        white-space: nowrap;
        color: black(secondary);
      }

      &Text {
        font-size: 13px !important;
        font-weight: 700;
        margin: 0 24px 0 0 !important;
        word-break: break-word;
        color: black(secondary);
      }
    }
  }

  &__noData {
    font-size: 14px !important;
    display: flex;
    padding: 20px;
    color: black(primary);
    border-radius: 4px;
    background: linear-gradient(to right, white(primary), #ebebeb);
  }
}