/**
 *  Bulk event registration button
 *  component
 */

// TODO 1: remove !important flags
// TODO 2: remove 'button' styles to button component
.bulk-event-label {
  font-weight: 700;
  font-size: 1.2rem;
}

.bulk-event-button {
  background-color: $desamisGreen;
  border: 0;
  border-radius: 0.3rem;
  box-shadow: 0 0.1rem 0.25rem rgba(18, 18, 18, 0.4);
  color: #fff;
  cursor: pointer;
  font-size: 1.4rem !important;
  margin-left: 14px !important;
  padding: 3px 15px;
  &:hover {
    background-color: $desamisGreenDark;
  }
}

.bulk-entry-history {
  .bulk-event-delete-button {
    background-color: #f4f4f4;
    color: #555;
  }

  .icon-css-plus {
    position: relative;
    display: inline-block;
    width: 12px;
    height: 12px;

    &::before {
      position: absolute;
      top: 7px;
      left: 0;
      display: block;
      width: 9px;
      height: 1px;
      content: '';
      background-color: #333;
    }

    &::after {
      position: absolute;
      top: 7px;
      left: 0;
      display: block;
      width: 9px;
      height: 1px;
      content: '';
      transform: rotate(90deg);
      background-color: #333;
    }
  }

  .icon-css-cancel {
    position: relative;
    display: inline-block;
    width: 12px;
    height: 12px;

    &::before {
      position: absolute;
      top: 7px;
      left: 0;
      display: block;
      width: 9px;
      height: 1px;
      content: '';
      transform: rotate(45deg);
      background-color: #333;
    }

    &::after {
      position: absolute;
      top: 7px;
      left: 0;
      display: block;
      width: 9px;
      height: 1px;
      content: '';
      transform: rotate(-45deg);
      background-color: #333;
    }
  }
}

.event-ui-select-label {
  margin-right: 10px;
  margin-bottom: 0;
  vertical-align: middle;
}

.event-ui-select {
  display: inline-block;
  width: 80px;
  vertical-align: middle;
  .form-control {
    height: 24px;
  }
}

.quick-action {
  button {
    color: #ee9210;
    border: 1px solid #ff9908;
    border-radius: 4px;
    padding: 4px 10px;

    &[disabled] {
      cursor: default;
      color: #ddd !important;
      border: 1px solid #ddd !important;
      background: none !important;
    }
  }
  button:hover {
    background-color: #ff9908;
    color: #fff;
  }
}

#bulk-regist-table {
  th.line-no,
  td.line-no {
    text-align: center;
    width: 30px;
    border-color: #fff;
    background-color: #fff;
    color: #d5d5d5;
    font-weight: normal;
    padding: 0 6px 0 0;
    text-align: right;
  }
  .time-container {
    display: flex;
    align-items: center;
    justify-content: center;
    .target-date {
      margin-right: 1.5rem;
    }
  }
  .time-selectors {
    height: 24px;
    margin: 0 3px !important;
    width: 50px !important;
    &:first-of-type {
      margin-left: 0 !important;
    }
  }
  td {
    text-align: center;
    .error-message,
    .warning-message {
      white-space: normal;
    }
  }
  .lame-diagnosis {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    margin: 5px;
    div {
      padding: 0px 5px;
    }

    &-label {
      display: flex;
      width: 30px;
    }

    input[type=checkbox] {
      min-width: 1em;
    }
  }
  .treatment-occurred-date-continuation-radio-group {
    display: flex;
    justify-content: space-around;
    align-items: center;
    .radio-item {
      display: flex;
      justify-content: center;
      align-items: center;
      width: auto;
      input {
        margin: 0;
        width: initial !important;
      }
      label {
        margin-left: 5px;
        white-space: nowrap;
      }
    }
  }
  div.text {
    > span {
      border: 1px dotted gray;
      padding: 3px;
    }

    button.remove {
      margin: 0px 5px 0px 2px;
    }
  }
}
