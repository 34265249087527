.timeline-input-label {
  display: inline-block;
  min-width: 105px;
  text-align: right;
}

.box-history.on-event-input .event-input-step02 .table-wrapper td .timeline-input-select {
  width: 90px;
}

.box-history #timeline-cow {
  .startline {
    padding-left: 46px;
  }
  .startline::before {
    left: 18px;
  }
}

#box-history.on-event-input .timeline-filter-menu {
  display: none;
}

#box-history.on-event-input .timeline-year-wrapper .timeline-year {
  display: none;
}

.box-history #timeline-cow .timeline-year-wrapper {
  padding: 0;
  position: absolute;
  display: grid;
  width: 100%;
  height: 48px;
  z-index: 1;
  left: 0;
  background: #fff;
  border: none;
  border-radius: 0;
  top: 36px;
  border-bottom: 1px solid #ddd;
  padding-left: 10px;
  padding-top: 15px;
  padding-bottom: 15px;
  color: #666;
  font-size: 14px;
  align-items: center;
  .timeline-year {
    width: 95%;
    height: 30px;
    padding: 5px 0;
    text-align: center;
    .timeline-year-span {
      background: $desamisGreen;
      color: #fff;
      padding: 3px 10px;
      border-radius: 1rem;
    }
  }
}

.box-history #timeline-cow .timeline {
  border-left: 3px solid #ddd;
  clear: both;
  margin-left: 26px;
  padding-bottom: 25px;
  padding-top: none;
  position: relative;
  li {
    margin-bottom: 15px;
    margin-left: -3px;
    position: relative;
    width: 95%;
    border-bottom: 1px solid #ddd;
    padding-bottom: 0px;
    div.data-box {
      background-color: #fff;
      display: table-cell;
      padding-top: 5px;
      position: relative;
      vertical-align: super;
      width: 15%;
      border-right: 1px solid #eee;
    }
    .data-box::after,
    .data-box::before {
      display: none;
    }
    .last-event-of-year .timeline-content-wrapper {
      margin-top: 70px;

      .year-mark {
        position: absolute;
        width: calc(100% + 29px);
        height: 40px;
        padding: 10px 0px 10px 10px;
        transform: translateX(-29px);
        top: -55px;
        left: 0;
        display: block;
        background: #fff;
        border-top: 1px solid #ddd;
        border-bottom: 1px solid #ddd;
        font-size: 14px;
        color: #666;
      }
    }
    .timeline-content-wrapper {
      border: none;
      display: table;
      position: relative;
      width: 100%;
      border-bottom: none;
      border-left: 3px solid;

      .year-mark {
        display: none;
      }
    }
    .category01 {
      border-left-color: #008c99;
    }
    .category02 {
      border-left-color: #f56e9d;
    }
    .category03 {
      border-left-color: #df6feb;
    }
    .category04 {
      border-left-color: #826ef5;
    }
    .category05 {
      border-left-color: #6ebff5;
    }
    .category06 {
      border-left-color: #4dab8a;
    }
    .category07 {
      border-left-color: #bca446;
    }
    .category08 {
      border-left-color: #2775be;
    }
    .category09 {
      border-left-color: #1C90FD;
    }
    .category10 {
      border-left-color: #666;
    }
    .category11 {
      border-left-color: #666;
    }
    .category12 {
      border-left-color: #826ef5;
    }
    .category13 {
      border-left-color: #f56e9d;
    }
    .category-all {
      border-left-color: #7b9915;
    }
    .data {
      font-size: 1.3rem;
      font-weight: 700;
      line-height: 1.4;
      text-align: center;
    }
  }
}

.box-history #timeline-cow {
  .timeline-balloon {
    background: #fff;
    display: table-cell;
    padding: 8px 20px 8px 8px;
    position: relative;
    width: 85%;
    z-index: 0;
    .pulldown-btn {
      background-color: #fff;
      border-radius: 3px;
      display: block;
      height: 1.6rem;
      line-height: 0;
      position: absolute;
      right: 3px;
      text-align: center;
      top: 10px;
      text-decoration: none;
      width: 1.6rem;
      color: #333;
      border: 1px solid #ccc;
      i {
        font-size: 1.4rem;
      }
    }
    .medicines .medicines-label {
      white-space: nowrap;
    }
  }
  .category-title {
    color: #fff;
    display: inline-block;
    font-size: 1.1rem;
    margin-bottom: 5px;
    padding: 1px 3px;
    border-bottom: none;
    font-weight: none;
    margin-top: none;
  }
  .timeline li::after,
  .timeline li::before {
    display: none;
  }
}

.input-label {
  display: inline-block;
  min-width: 85px;
  text-align: right;
}

.input-label-short {
  display: inline-block;
  min-width: 6.5rem;
  margin: 0 0.5em 0 0;
  text-align: right;
}

input.short {
  width: 6.5rem;
}

.timeline-filter-menu {
  position: relative;
  z-index: 3;
  margin-bottom: 1px;
  .button-bar {
    text-align: right;
    margin-left: 26px;
    width: 90%;
    padding: 5px 0px 5px 0px;
    .toggle-filter-button {
      border-radius: 6px; //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
      box-shadow: 0 1px 2px 0 rgba(90, 90, 90, 0.3);
      margin: 0.5em 0.5em;
    }
  }
  .filter-box {
    margin: 0 1em 1em 1em;
    position: fixed;
    width: 50em;
    right: 0.7em;
    padding: 1em;
    color: #fff;
    background-color: rgba(0, 0, 0, .75); //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
    border-radius: 2px; //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
    box-shadow: 0 3px 4px 0 rgba(120, 120, 120, 0.5);
    z-index: 9999;
    display: flex;
    flex-flow: column nowrap;
    text-align: left;
    .filter-tool-box {
      padding: 0.2em 0.5em;
      text-align: right;
      a {
        color: white;
      }
      button {
        border: 1px solid #ccc;
        border-radius: 4px;
        padding: 2px 5px;
      }
      button:hover {
        background-color: white;
        color: black;
      }
    }
    .filter-group-box {
      display: flex;
      flex-flow: row nowrap;
      margin: .5em .5em 0em;
      padding: .5em 0 0 0;
      border-top: 1px solid rgba(255, 255, 255, .3);
    }
    .filter-title-box {
      flex-grow: 1;
      flex-basis: 15%;
      border-right: 1px solid rgba(255, 255, 255, 0.3);
      margin-right: 20px;
      font-size: 14px;

      &-input {
        margin: 0.2em 0.2em 0.3em 0;
      }
    }
    .filter-form-box {
      flex-grow: 4;
      flex-basis: 85%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      .filter-form-item {
        flex-basis: 20%;
        margin: 0.25em 0;
        vertical-align: middle;
        label {
          input {
            margin: 0.2em 0.2em 0.3em 0;
          }
        }
      }
      .filter-form-item-2col {
        flex-basis: 40%;
        margin: 0.25em 0;
        vertical-align: middle;
        label {
          input {
            margin: 0.2em 0.2em 0.3em 0;
          }
        }
      }
      .filter-form-item-3col {
        flex-basis: 80%;
        margin: 0.25em 0;
        vertical-align: middle;
        label {
          input {
            margin: 0.2em 0.2em 0.3em 0;
          }
        }
      }
    }
    select {
      vertical-align: middle;
    }
    .days,
    .bcs,
    .count {
      width: 8rem;
    }
    &:before {
      content: "";
      position: absolute;
      top: -16px;
      right: 2.3em;
      width: 0;
      height: 0;
      border-right: 8px solid transparent;
      border-bottom: 20px solid #4a4a4a;
      border-left: 8px solid transparent;
    }
  }

  // 旧アイコンフォント調整
  .icon-s-check-box-outlined {
    font-size: 10px !important;
    margin: 0 1px 0 0;
  }
}
