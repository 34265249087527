/**
 *  Vendor overrides
 *  Vendorなどのデフォルトスタイルを上書きする
 */

/*
  Bootstrapの.btnのpaddingが大きいすぎる為、
  uiSelectの選択のspanが枠からはみ出て、位置が下方にずれる。
*/
.ui-select-bootstrap>.ui-select-match>.btn {
  // フォーカスを当ててる時、中になるspanのbox-shadowがはみ出ないように
  overflow: hidden;
  // これはその位置修正
  padding-top: 3px;
}
