.print {
  display: none;
}

.shipment-history-filter {
  background: #fff;
  padding: 10px;
  overflow: auto;
}

.shipment-history-header {
  border-bottom: 2px solid #aaa;
  padding: 10px 0px 5px 0px;
  font-weight: 800;
  span {
    display: inline-block;
    font-size: 1.3rem;
    margin: 0px 10px;
  }
}

.shipment-history {
  border-bottom: 1px solid #ccc;
  padding: 2px 0px 10px;
  span {
    display: inline-block;
    font-size: 1.3rem;
    margin: 0px 10px;
  }
  button {
    visibility: hidden;
    &.visible {
      visibility: visible;
    }
  }
  &:hover {
    cursor: pointer;
    background: #eee;
    button {
      visibility: visible;
    }
  }
}

.shipment-history li {
  height: auto;
  margin: 1px 20px;
  background: #FFF;

  border-bottom: 1px dashed #ccc;
  span {
    display: inline-block;
    font-size: 1.2rem;
    margin: 0px 10px;
    input[type=checkbox] {
      margin-top: 0px;
    }
  }
}

.shipments {
  margin-top: 10px;
  cursor: default;
  .shipment-cow-header {
    margin: 1px 20px;
    background: #FFF;
    border-bottom: 1px solid #aaa;
    font-weight: 600;
    span {
      display: inline-block;
      font-size: 1.3rem;
      margin: 0px 10px;
    }
  }
}
