.report-summary-box {
  flex-basis: 200px;
  margin: 0px 10px;
  background-color: #fff;

  table {
    border-collapse: collapse;
    thead {
      border: none;
      tr {
        border-bottom: 1px solid #ddd;
        border-left: none;
        th {
          text-align: left;
          padding: 10px;
        }
        th:nth-of-type(2) {
          border-left: 1px solid #ddd;
          border-right: 1px solid #ddd;
        }
      }
    }
    tbody {
      td {
        border: none;
        width: 150px;
      }
      td:nth-of-type(2) {
        border-left: 1px solid #ddd;
        border-right: 1px solid #ddd;
      }
    }
  }
}
