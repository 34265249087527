/*---------------------------------
　リスト
---------------------------------*/

/*
　リスト
---------------------------------*/
.uList {
  overflow: hidden;
  border-radius: 4px;

  &+.uList {
    margin: 16px 0 0;
  }

  &--secondary {
    padding: 24px 16px 24px 16px;
    border-radius: 4px;
    background: #f0f0f0;

    .uList__row {
      &:not(:last-child) {
        margin: 0 0 14px;
      }
    }

    .uList__heading,
    .uList__bodyText,
    [class^="uList__heading--"],
    [class^="uList__bodyText--"] {
      font-size: 13px !important;
      color: rgba(33, 33, 33, .62);
    }
  }

  &--tertiary {
    [class^="uContent__heading"]+& {
      margin: 30px 0 0 !important;
    }
  }

  &__row {
    display: flex;

    &:not(:first-child) {
      border-top: 1px solid #eee;
    }

    &--height36 {
      display: flex;

      &:not(:first-child) {
        border-top: 1px solid #eee;
      }

      .uList__columnInner {
        height: 36px;
      }
    }
  }

  &__column {
    display: flex;
    width: 100%;

    &:not(:first-child) {
      border-left: 1px solid #eee;
    }

    &--width188 {
      display: flex;
      width: 188px;

      &:not(:first-child) {
        border-left: 1px solid #eee;
      }
    }

    &--width302 {
      display: flex;
      width: 302px;

      &:not(:first-child) {
        border-left: 1px solid #eee;
      }
    }

    &--width430 {
      display: flex;
      width: 430px;

      &:not(:first-child) {
        border-left: 1px solid #eee;
      }
    }

    &--outside {
      display: flex;
      padding: 0 16px;

      flex-grow: 1;
      align-items: center;

      .uList {
        &__heading {
          color: black(secondary);

          &--width95 {
            color: black(secondary);

            &Nowrap {
              color: black(secondary);
            }
          }
        }

        &__body {
          &Num {
            color: black(secondary);
          }

          &Text {
            color: black(secondary);
          }
        }
      }
    }

    &--right {
      display: flex;
      width: 100%;

      justify-content: flex-end;
    }

    &--directionColumn {
      flex-direction: column;
      width: 100%;
    }

    &--wrapRight {
      display: flex;
      width: 100%;

      flex-wrap: wrap;
      justify-content: flex-end;
    }

    &Inner {
      display: flex;
      width: 100%;
      height: 47px;
      padding: 0 16px;
      background: white(primary);

      align-items: center;
    }
  }

  &__button {
    display: flex;
    width: 46px;
    height: 36px;
    background: white(primary);

    flex-shrink: 0;
    align-items: center;
    justify-content: center;

    .uList__columnInner+& {
      border-radius: 0 4px 4px 0;
    }

    &Link {
      display: flex;
      width: 34px;
      height: 34px;
      transition: all .1s linear;
      color: black(secondary);
      border-radius: 50%;

      align-items: center;
      justify-content: center;

      &:hover {
        text-decoration: none;
        color: black(secondary);
        background: #e6e6e6;
      }
    }
  }

  &__heading {
    font-size: 14px !important;
    margin: 0 10px 0 0 !important;
    color: black(primary);

    flex-shrink: 0;

    &--secondary {
      font-size: 13px !important;

      color: white(secondary);

      &:not(:first-child) {
        margin: 20px 0 0!important;
      }
    }

    &--width80 {
      font-size: 14px !important;
      width: 80px;
      color: black(primary);

      flex-shrink: 0;
    }

    &--width95 {
      font-size: 14px !important;
      width: 95px;
      color: black(primary);

      flex-shrink: 0;

      &Nowrap {
        font-size: 14px !important;
        width: 95px;
        white-space: nowrap;
        color: black(primary);
      }
    }

    &--width125 {
      font-size: 14px !important;
      width: 125px;
      color: black(primary);

      flex-shrink: 0;
    }

    &--width100per {
      font-size: 14px !important;
      width: 100%;
      color: black(primary);

      flex-shrink: 0;
    }
  }

  &__body {
    display: flex;
    color: black(primary);

    &--grow {
      display: flex;
      color: black(primary);

      flex-grow: 1;
    }

    &Num {
      font-size: 14px !important;
      font-weight: 700;
      text-align: right;

      &+.uList__bodyText {
        margin: 0 0 0 8px;
      }

      &--width50 {
        font-size: 14px !important;
        font-weight: 700;
        width: 50px;
        text-align: right;

        &+.uList__bodyText {
          margin: 0 0 0 8px;
        }
      }

      &--width64 {
        font-size: 14px !important;
        font-weight: 700;
        width: 64px;
        text-align: right;

        &+.uList__bodyText {
          margin: 0 0 0 8px;
        }
      }
    }

    &Text {
      font-size: 14px !important;
      word-break: break-all;

      &--secondary {
        font-size: 14px !important;
        font-weight: 700;
        word-break: break-all;
      }
    }
  }

  &__value {
    font-size: 13px !important;
    font-weight: 700;
    color: white(secondary);
  }

  &__rank {
    font-size: 14px !important;
    font-weight: 700;
    display: flex;
    width: 34px;
    height: 29px;
    margin: 0 0 0 10px !important;
    color: #043d67;
    border-radius: 4px;
    background: #f2f2f2;

    align-items: center;
    justify-content: center;
  }

  &__label {
    font-size: 12px;
    margin: 2px !important;
    padding: 0 4px;
    border: 1px dotted #ccc;
  }
}