/*---------------------------------
　関数定義
---------------------------------*/

/*
　色
---------------------------------*/

/*
　色 - 黒
---------------------------------*/
$black: (
  primary: #212121,
  secondary: #666,
  tertiary: #757575,
  quaternary:#bdbdbd
);

@function black($key) {
  @return map-get($black, $key);
}

/*
　色 - 白
---------------------------------*/
$white: (
  primary: #fff,
  secondary: #b2b2b2,
  tertiary: #777
);

@function white($key) {
  @return map-get($white, $key);
}

/*
　色 - 赤
---------------------------------*/
$red: (
  primary: #bc0822
);

@function red($key) {
  @return map-get($red, $key);
}

/*
　色 - ノーティフィケーションのカテゴリー
---------------------------------*/
$notificationCategory: (
  background:#f2f2f2,
  maintenance:#305694,
  update:#305694,
  incident:#db44ae,
  information:#718623,
);

@function notificationCategory($key) {
  @return map-get($notificationCategory, $key);
}