.embryo-recovery-report {
  .toolbar {
    background: #fff;
    overflow: auto;
    height: 45px;

    span {
      font-size: 1.5rem !important;
    }
  }

  h4 {
    display: flex;
    justify-content: space-between;
  }

  .left-area {
    padding: 1px 10px;
  }

  .right-area {
    padding: 1px 10px;
  }

  .content-box .section {
    margin-bottom: 0px;
  }

  .main-detail {
    align-items: center;
    display: flex;
    width: 100%;

    .cow-no {
      align-items: center;
      display: flex;
      max-width: 240px;
      word-wrap: break-word;

      span {
        display: inline-block;
        font-size: 1.3rem;
        margin-right: 4px;
      }

      a {
        font-size: 1.7rem;
      }
    }

    .breeding-value {
      align-items: center;
      display: flex;
      font-size: 1.4rem;
      margin-left: 24px;
      min-width: 110px;

      span {
        display: inline-block;
        font-size: 1.3rem;
        margin-right: 4px;
      }
    }

    .cow-info {
      display: flex;
      margin-left: 88px;

      li {
        align-items: center;
        color: #444;
        display: flex;
        margin-right: 20px;

        label {
          font-size: 1.2rem;
          font-weight: normal;
          margin-right: 6px;

          span {
            color: #212121;
          }
        }
        &:nth-last-of-type(1) {
          span {
            display: inline-block;
            word-wrap: break-word;
          }
        }
      }
    }
  }

  // pc/page/cow-detail.scss の .section-breeding からコピー
  .sub-detail {
    margin-bottom: 24px;
    padding-left: 24px;

    table, th, td {
      border: none !important;
    }
    tr {
      background-color: transparent !important;
    }
    th, td {
      min-height: auto;
      padding: 8px;
      text-align: left !important;
      background-color: transparent;
      word-wrap: break-word;
    }
    th {
      color: inherit;
      font-weight: bold;
      position: relative;
      white-space: nowrap;
      width: 1%;
    }

    td {
      font-size: 1.3rem;

      .cowUid-4digits {
        display: inline-block;
        font-size: 1.3rem;
        font-weight: bold;
        padding: 0 4px;
      }
    }

    tr:not(:last-child) {
      border-bottom: 1px solid #ddd;
    }
  }

  .history {
    &-title {
      font-weight: bold;
      margin-bottom: 8px;
      padding-left: 6px;
    }

    .smart {
      tr {
        &:nth-last-of-type(2) {
          td {
            padding-bottom: 16px;
          }
        }
      }
    }

    .average {
      border-bottom: none;

      td {
        border-bottom: none;
        border-top: 4px double #ccc;
        font-size: 1.3rem;
        font-weight: bold;
        padding-bottom: 8px;
        padding-top: 8px;
      }
    }
  }
}
