#hidden-print-area {
  max-width: 890px;

  * {
    background-color: #1F1F1F;
    border-color: #1F1F1F;
    color: #1F1F1F;
  }

  h2 {
    margin-top: 4px;
  }

  table {
    margin-top: 8px;
    margin-left: 16px;
  }  

  td {
    font-size: 10px;
  }

  th {
    &:nth-child(1) { width: 150px; } // 牛群
    &:nth-child(2) { width: 200px; } // 検知時刻
    &:nth-child(3) { width: 150px; } // センサー番号
    &:nth-child(4) { width: 120px; } // 個体識別番号
    &:nth-child(5) { width: 120px; } // 牛番号
    &:nth-child(6) { width: 150px; } // 月齢
  }
}
