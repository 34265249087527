/**
 *  Timeline component
 *  $1. Filter
 */

/**
 *  $1. Filter
 */

 // 旧アイコンフォント調整
 .timeline--filter-button {
  font-size: 11px !important;
  border: 1px solid $lighterGreyOld;
  border-radius: 50%;
  transition: all 100ms ease;
  width: 20px;
  height: 20px;
  display: block;
  padding: 4px 0 0;

  &:hover {
    background-color: $greyOld;
    border-color: $greyOld;
    color: #fff;
  }
  &:focus {
    outline: 0;
  }
}

.timeline--datepicker {
  text-align: center;
}

// UNDO THIS OR CHANGE HTML!
// remove timeline bars from tag
.filter-list,
.timeline--datepicker {
  &:after,
  &:before {
    content: none !important;
  }
}

// UNDO THIS OR CHANGE HTML!
// remove timeline bars from tag
.top--filter-list li {
  margin-left: 0 !important;
  &:before,
  &:after {
    content: none !important;
  }
}

/**
 *  $2. Datepicker
 */
.datepicker--date {
  border: 0;
  cursor: pointer;
  font-size: 1.5rem !important;
  font-weight: bold;
  width: 100px;
  vertical-align: top !important;
  text-align: center;

  &:hover {
    color: #4682b4;
  }
}

// 旧アイコンフォント調整
.datepicker--selector {
  cursor: pointer;

  &:before {
    font-size: 14px;
    display: block;
    margin: 4px 0 0;
    vertical-align: top;
    color: #fff;
    border-radius: 50%;
    background-color: $orange;
  }
}

#box-history {
  .flex-container {
    div.half-width {
      width: 50%;
      padding: 0px 5px;
      select, input, label {
        vertical-align: middle;
      }
    }
  }
}
