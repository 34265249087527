/*---------------------------------
　フッター（タブレット用）
---------------------------------*/

/*
　フッター
---------------------------------*/

body {
  @media (pointer: coarse) and (min-height: 700px) {
    padding-bottom: 56px;
  }
}

.uFooter {
  display: none;

  @media (pointer: coarse) and (min-height: 700px) {
    position: fixed;
    z-index: 1001;
    bottom: 0;
    left: 0;
    display: flex;
    width: 100%;
    padding: 0 72px env(safe-area-inset-bottom) 0;
    background-color: #e9e9e9;

    &__menu {
      margin-right: auto !important;
      padding: 0 12px;

      .is-sideBoard & {
        padding: 0 12px 0 0;
      }

      &Items {
        display: flex;
        height: 56px;
      }

      &Item {
        position: relative;
        display: flex;

        align-items: center;
        justify-content: center;

        @media (min-width: 700px) {
          margin: 0 16px 0 0 !important;
        }

        &--sideBoard,
        &--back {
          position: relative;
          display: flex;
          border-right: 1px solid #d9d9d9;

          align-items: center;
          justify-content: center;

          @media (min-width: 700px) {
            margin: 0 10px 0 0 !important;

            .uFooter {
              &__menuIcon {
                width: 56px;
                padding: 0;
                pointer-events: auto;

                &--black {
                  width: 56px;
                  padding: 0;
                  pointer-events: auto;
                }
              }
            }
          }
        }

        &--back {
          margin-left: -12px !important;
        }
      }

      &Icon {
        display: flex;
        width: 56px;
        height: 56px;
        text-decoration: none !important;
        color: black(secondary) !important;

        align-items: center;
        justify-content: center;

        @media (min-width: 700px) {
          width: auto;
          padding: 0 0 0 16px;
          pointer-events: none;
        }

        &--black {
          display: flex;
          width: 56px;
          height: 56px;
          text-decoration: none !important;
          color: white(primary) !important;
          background: #3b3b3b;

          align-items: center;
          justify-content: center;

          @media (min-width: 700px) {
            width: auto;
            padding: 0 0 0 16px;
            pointer-events: none;
          }
        }
      }

      &Links {
        visibility: hidden;
        width: 0;
        transition: opacity .2s linear;
        opacity: 0;

        @media (min-width: 700px) {
          display: flex;
          visibility: visible;
          width: auto;
          opacity: 1;
        }

        .uFooter__menuLink {
          @media (min-width: 700px) {
            &:not(:last-child) {
              padding: 0 10px;
            }

            &:last-child {
              padding: 0 16px 0 10px;
            }
          }
        }

        &--open {
          position: absolute;
          bottom: calc(100% + 8px);
          left: 0;
          display: block;
          visibility: visible;
          overflow: hidden;
          width: 180px;
          opacity: 1;
          border-radius: 4px;
          background-color: #eee;
          box-shadow: 0 3px 30px rgba(0, 0, 0, 0.5);

          .uFooter__menuLink {
            font-size: 14px !important;
            height: 53px;
            color: black(primary) !important;
            background-color: white(primary);

            &:not(:first-child) {
              margin: 1px 0 0 !important;
            }

            &::after {
              position: absolute;
              right: 18px;
              display: block;
              width: 12px;
              height: 12px;
              content: '';
              transform: rotate(45deg);
              border-top: 2px solid black(primary);
              border-right: 2px solid black(primary);
            }
          }
        }
      }

      &Link {
        font-size: 13px !important;
        position: relative;
        display: flex;
        height: 56px;
        padding: 0 16px;
        white-space: nowrap;
        text-decoration: none !important;
        color: black(secondary) !important;

        align-items: center;

        [class^="icon-"] {
          @media (min-width: 700px) {
            padding: 0 10px 0 0;
          }
        }
      }

      &Text {
        font-size: 13px !important;
        display: none;

        @media (min-width: 700px) {
          display: block;
        }
      }

      &Separate {
        display: none;
        height: 56px;

        align-items: center;

        @media (min-width: 700px) {
          display: flex;
        }
      }
    }

    &__pager {
      display: flex;
      margin: 0 0 0 -12px !important;
      padding: 1px 0;
      text-align: center;
      border: 1px solid #fff;
      border-radius: 50vw;
      background: #f2f2f2;

      align-items: center;
      align-self: center;

      @media (max-width: 1023px) {
        position: absolute;
        right: 18px;
        bottom: calc(100% + 18px);
        box-shadow: 0px 3px 6px rgba(0, 0, 0, .32);
      }

      &Current,
      &Heading {
        font-size: 13px !important;
      }

      &Current {
        line-height: 1.2;
      }

      &Heading {
        font-weight: 700;
        overflow: hidden;
        width: 12em;
        white-space: nowrap;
        text-overflow: ellipsis;

        @media (max-width: 1023px) {
          width: 13.7em;
        }
      }

      &Link {
        position: relative;
        width: 40px;
        height: 40px;

        &::before {
          position: absolute;
          top: 50%;
          right: 50%;
          display: block;
          width: 11px;
          height: 11px;
          content: '';
          transform: translate(75%, -50%) rotate(-45deg);
          border-top: 2px solid #212121;
          border-left: 2px solid #212121;
        }

        &:last-child {
          &::before {
            transform: translate(25%, -50%) rotate(135deg);
          }
        }

        &--disabled {
          position: relative;
          width: 40px;
          height: 40px;
          pointer-events: none;

          &::before {
            position: absolute;
            top: 50%;
            right: 50%;
            display: block;
            width: 11px;
            height: 11px;
            content: '';
            transform: translate(75%, -50%) rotate(-45deg);
            opacity: .12;
            border-top: 2px solid #212121;
            border-left: 2px solid #212121;
          }

          &:last-child {
            &::before {
              transform: translate(25%, -50%) rotate(135deg);
            }
          }
        }
      }
    }
  }

  @media print {
    display: none;
  }
}