/*---------------------------------
　サイドボード
---------------------------------*/

/*
　@extend用のプレースホルダーセレクタ
---------------------------------*/
%uSideBoard {
  font-size: 14px;
  position: fixed;
  z-index: 10;
  top: 56px;
  left: 0;
  overflow: hidden;
  min-width: 24px;
  height: 100%;
  max-height: calc(100% - 56px);
  color: white(primary);
  border-right: 1px solid #2f2f2f;
  background: linear-gradient(to right, #2e2e2e, #000);

  flex-shrink: 0;
}

%uSideBoard__category {
  font-size: 13px;
  margin: 40px 0 10px !important;
  padding: 0 12px;
}

%uSideBoard__item {
  font-size: 13px;
  display: flex;
  margin: 1px 0 !important;

  align-items: center;
}

%uSideBoard__link {
  font-size: 13px;
  position: relative;
  display: flex;
  width: 100%;
  height: 36px;
  padding: 0 12px;
  text-decoration: none !important;
  border: 1px solid transparent;
  border-radius: 4px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, .16);

  align-items: center;
  justify-content: space-between;
}

/*
　サイドボード
---------------------------------*/
.uSideBoard {
  @extend %uSideBoard;

  /*
  　サイドボード - 横幅144px
  ---------------------------------*/
  .uWrapper--sideBoard144 & {
    width: 144px;

    @extend %uSideBoard;

    @media (pointer: coarse), (max-width: 959px) {
      width: 96px;
    }

    @media (pointer: coarse) and (orientation:portrait) {
      display: none;
    }

    &__inner {
      width: 144px;

      @media (pointer: coarse), (max-width: 959px) {
        width: 96px;
      }
    }

    &__body {
      padding: 0 9px;

      @media (pointer: coarse), (max-width: 959px) {
        width: 100%;
        margin: 0 !important;
        padding: 0;
      }
    }

    &__link {
      @media (pointer: coarse), (max-width: 959px) {
        padding: 12px 8px;
        border-color: transparent;
        border-radius: 0;
      }

      &--changeUiVersion {
        @media (pointer: coarse), (max-width: 959px) {
          height: 51px;
          margin: 48px 0 0 !important;
          border-radius: 0;
        }
      }
    }
  }

  /*
  　サイドボード - 横幅200px
  ---------------------------------*/
  .uWrapper--sideBoard200 & {
    width: 200px;

    @extend %uSideBoard;

    &__inner {
      width: 200px;
    }
  }

  /*
  　サイドボード - 横幅240px
  ---------------------------------*/
  .uWrapper--sideBoard240 & {
    width: 240px;

    @extend %uSideBoard;

    &__inner {
      width: 240px;
    }
  }

  /*
  　サイドボード - 閉じている
  ---------------------------------*/
  &--closed {
    pointer-events: none;
    border-right: none;
    background: linear-gradient(to right, #2e2e2e 0px, #000 16px, #2f2f2f 16px, rgba(0, 0, 0, 0) 16px);

    @extend %uSideBoard;

    .uSideBoard {
      &__body {
        visibility: hidden;
        transition: opacity .1s linear;
        opacity: 0;
      }

      &__footer {
        padding: 0;
        border-top: none;

        justify-content: flex-start;
      }

      &__toggle {
        z-index: 10;
        left: 0;
        width: 40px;
        padding: 0;
        border-color: rgba(56, 56, 56, .6);
        border-radius: 0 20px 20px 0;
        background: linear-gradient(to right, #2e2e2e, #333);
        box-shadow: 0 3px 6px rgba(0, 0, 0, .16);

        justify-content: flex-end;

        &Text {
          position: absolute;
          z-index: 10;
          top: 50%;
          left: calc(100% + 6px);
          visibility: hidden;
          height: 28px;
          padding: 4px 8px;
          transform: translateY(-50%);
          pointer-events: none;
          opacity: 0;
          border-radius: 2px;
          background: rgba(95, 100, 105, .96);
          box-shadow: 0 3px 6px rgba(0, 0, 0, .16);
        }

        &Icon {
          top: 14px;
          right: 14px;
          transform: rotate(180deg);
        }

        &:hover {
          width: 52px;
          background: linear-gradient(to right, #333, #4d4d4d);

          .uSideBoard {
            &__toggle {
              &Text {
                visibility: visible;
                transition: opacity .2s linear;
                opacity: 1;

                &::before {
                  opacity: 1;
                }
              }
            }
          }
        }
      }
    }
  }

  /*
  　サイドボード - 非表示
  ---------------------------------*/
  &--hidden {
    display: none;
  }

  /*
  　サイドボード - 印刷対応
  ---------------------------------*/
  .print & {
    display: none;
    width: 0;

    &--closed {
      display: none;
      width: 0;
    }
  }

  @media print {
    display: none;
    width: 0;

    &--closed {
      display: none;
      width: 0;
    }
  }

  /*
  　inner
  ---------------------------------*/
  &__inner {
    display: flex;
    flex-direction: column;
    height: 100%;

    justify-content: space-between;
  }

  /*
  　本体
  ---------------------------------*/
  &__body {
    overflow-y: auto;
    width: calc(100% - 6px);
    height: 100%;
    margin: 0 3px !important;
    padding: 0 12px;
    transition: opacity .1s linear;

    scrollbar-width: thin;
    scrollbar-color: #000 #777;

    @media (pointer: coarse) and (min-height: 700px) {
      padding-bottom: 60px;
    }

    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-track {
      background-color: #111;
    }

    &::-webkit-scrollbar-thumb {
      border: 1px solid #444;
      border-radius: 4px;
      background-color: #333;

      &:hover {
        cursor: pointer;
        background-color: #444;
      }
    }
  }

  /*
  　フッター
  ---------------------------------*/
  &__footer {
    display: flex;
    width: 100%;
    height: 64px;
    padding: 0 10px;
    border-top: 1px solid rgba(54, 54, 54, .56);

    align-items: center;
    justify-content: center;

    @media (pointer: coarse) and (min-height: 700px) {
      display: none;
    }
  }

  /*
  　見出し
  ---------------------------------*/
  &__heading {
    font-size: 15px;
    margin: 56px 0 40px !important;
    padding: 0 12px;
  }

  /*
  　日付
  ---------------------------------*/
  &__date {
    font-weight: 700;
    display: flex;
    width: 100%;
    margin: 0 0 32px !important;
    padding: 2px 4px;
    color: white(primary);
    border: 2px solid rgba(255, 255, 255, 0.16);
    border-radius: 20px;

    &Prev,
    &Next {
      position: relative;
      display: flex;
      width: 32px;
      height: 32px;
      color: rgba(255, 255, 255, 0.7);
      border-radius: 50%;

      align-items: center;
      justify-content: center;
      flex-shrink: 0;

      &:hover {
        background: #444;
      }
    }

    &Prev {
      padding: 0 0 0 5px;

      &::before {
        display: block;
        width: 12px;
        height: 12px;
        content: '';
        transform: rotate(45deg);
        border-bottom: 2px solid rgba(255, 255, 255, 0.7);
        border-left: 2px solid rgba(255, 255, 255, 0.7);
      }
    }

    &Next {
      padding: 0 5px 0 0;

      &::before {
        display: block;
        width: 12px;
        height: 12px;
        content: '';
        transform: rotate(45deg);
        border-top: 2px solid rgba(255, 255, 255, 0.7);
        border-right: 2px solid rgba(255, 255, 255, 0.7);
      }
    }

    &Input {
      font-size: 15px !important;
      width: 100%;
      padding: 0 8px !important;
      cursor: pointer;
      text-align: center;
      color: inherit !important;
      border: 0 !important;
      border-radius: 8px !important;
      outline: none;
      background: none !important;
      box-shadow: none !important;

      &:hover {
        background: #444 !important;
      }
    }
  }

  /*
  　カテゴリー
  ---------------------------------*/
  &__category {
    @extend %uSideBoard__category;

    &--disabled {
      color: rgba(255, 255, 255, .2);

      @extend %uSideBoard__category;
    }
  }

  /*
  　items
  ---------------------------------*/
  &__items {
    margin: 0 0 32px !important;

    .uSideBoard__back+& {
      margin-top: 144px !important;

      @media (pointer: coarse), (max-width: 959px) {
        margin-top: 175px !important;
      }
    }
  }

  /*
  　item
  ---------------------------------*/
  &__item {
    @extend %uSideBoard__item;

    &--active {
      @extend %uSideBoard__item;

      .uSideBoard {
        &__link {
          font-size: 14px;
          font-weight: 700;
          color: white(primary) !important;
          background: linear-gradient(to right, rgba(150, 150, 150, .4), rgba(114, 114, 114, .4)) !important;

          &--history {
            font-weight: 700;
            color: white(primary) !important;
            background: linear-gradient(to right, rgba(150, 150, 150, .4), rgba(114, 114, 114, .4)) !important;

            &::after {
              border-left: 7px solid white(primary);
            }
          }

          &--dysstasia {
            font-weight: 700;
            border: 1px solid rgba(255, 39, 39, .72);
          }

          &--alertSensor {
            border: 1px solid #ffd600;
          }

          &--alertCalving,
          &--heat {
            font-weight: 700;
            border: 1px solid rgba(253, 68, 168, .72);
          }

          &--badConditionCow,
          &--illness {
            font-weight: 700;
            border: 1px solid rgba(231, 60, 253, .72);
          }
        }
      }
    }

    &--disabled {
      @extend %uSideBoard__item;

      .uSideBoard {
        &__link {
          pointer-events: none;
          color: rgba(255, 255, 255, .12);
          background: linear-gradient(to right, rgba(87, 87, 87, .04), rgba(51, 51, 51, .04));

          &--alertCalving,
          &--heat {
            pointer-events: none;
            color: rgba(253, 68, 168, .2);
            border: 1px solid transparent;
            background: linear-gradient(to right, rgba(52, 34, 44, .4), rgba(41, 12, 28, .4));
          }

          &--badConditionCow,
          &--illness {
            pointer-events: none;
            color: rgba(231, 60, 253, .2);
            border: 1px solid transparent;
            background: linear-gradient(to right, rgba(46, 23, 46, .4), rgba(43, 11, 43, .4));
          }
        }
      }
    }
  }

  /*
  　リンク
  ---------------------------------*/
  &__link {
    color: rgba(255, 255, 255, .7);
    background: linear-gradient(to right, rgba(87, 87, 87, .12), rgba(51, 51, 51, .12));

    @extend %uSideBoard__link;

    &:hover {
      color: white(primary);
      border: 1px solid rgba(137, 137, 137, .1);
      background: linear-gradient(to right, rgba(119, 119, 119, .4), rgba(85, 85, 85, .4));
    }

    &--history {
      margin: 22px 0 0 !important;
      color: rgba(255, 255, 255, .7);
      background: linear-gradient(to right, rgba(87, 87, 87, .12), rgba(51, 51, 51, .12));

      @extend %uSideBoard__link;

      &::after {
        display: block;
        width: 9px;
        height: 9px;
        content: '';
        pointer-events: none;
        border-top: 4px solid transparent;
        border-bottom: 4px solid transparent;
        border-left: 7px solid rgba(255, 255, 255, .7);
      }

      &:hover {
        color: white(primary);
        border: 1px solid rgba(137, 137, 137, .1);
        background: linear-gradient(to right, rgba(119, 119, 119, .4), rgba(85, 85, 85, .4));

        &::after {
          border-left: 7px solid white(primary);
        }
      }

      .uSideBoard {
        &__count {
          display: none;
        }
      }
    }

    &--dysstasia {
      padding: 13px 12px;
      color: #ff2222;
      border: 1px solid rgba(255, 39, 39, .08);
      background: linear-gradient(to right, rgba(87, 15, 15, .72) 0%, rgba(75, 25, 25, .72) 24.63%, rgba(74, 6, 6, .72) 100%);

      @extend %uSideBoard__link;

      &:hover {
        padding: 13px 12px;
        color: #ff2222;
        border: 1px solid rgba(255, 39, 39, .72);
        background: linear-gradient(to right, rgba(87, 15, 15, .72) 0%, rgba(75, 25, 25, .72) 24.63%, rgba(74, 6, 6, .72) 100%);
      }

      .uSideBoard {
        &__name {
          font-size: 14px !important;
        }

        &__count {
          font-size: 14px !important;
        }
      }
    }

    &--alertSensor {
      color: #ffe170;
      background: rgba(171, 143, 0, 0.16);

      @extend %uSideBoard__link;

      &:hover {
        color: #ffe170;
        border: 1px solid rgba(255, 214, 0, 0.72);
        background: rgba(171, 143, 0, 0.16);
      }
    }

    &--alertCalving,
    &--heat {
      color: #fd44a8;
      border: 1px solid rgba(253, 68, 168, .08);
      background: linear-gradient(to right, #34222c, #290c1c);
      box-shadow: 0 1px 2px rgba(0, 0, 0, .16);

      @extend %uSideBoard__link;

      &:hover {
        color: #fd44a8;
        border: 1px solid rgba(253, 68, 168, .72);
        background: linear-gradient(to right, #34222c, #290c1c);
        box-shadow: 0 1px 2px rgba(0, 0, 0, .16);
      }
    }

    &--badConditionCow,
    &--illness {
      color: #e73cfd;
      border: 1px solid rgba(231, 60, 253, .08);
      background: linear-gradient(to right, #2e172e, #2b0b2b);
      box-shadow: 0 1px 2px rgba(0, 0, 0, .16);

      @extend %uSideBoard__link;

      &:hover {
        color: #e73cfd;
        border: 1px solid rgba(231, 60, 253, .72);
        background: linear-gradient(to right, #2e172e, #2b0b2b);
        box-shadow: 0 1px 2px rgba(0, 0, 0, .16);
      }
    }

    &--changeUiVersion {
      height: 40px;
      margin: 80px 0 0 !important;
      padding: 0 7px;
      color: white(primary);
      border: 1px solid #768424;
      background: rgba(67, 78, 6, .4);

      @extend %uSideBoard__link;

      &:hover {
        color: white(primary);
        background: #434e06;
      }
    }
  }

  /*
  　リンク名称
  ---------------------------------*/
  &__name {
    font-size: 13px !important;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  /*
  　件数
  ---------------------------------*/
  &__count {
    font-size: 13px !important;
    width: 45px;
    text-align: right;

    flex-shrink: 0;
  }

  /*
  　開閉ボタン
  ---------------------------------*/
  &__toggle {
    position: relative;
    display: flex;
    height: 40px;
    padding: 3px 32px 3px 12px;
    cursor: pointer;
    pointer-events: auto;
    color: rgba(255, 255, 255, .7);
    border: 1px solid transparent;
    border-radius: 20px;

    align-items: center;

    &Text {
      font-size: 13px !important;
      white-space: nowrap;
    }

    &Icon {
      position: absolute;
      top: 50%;
      right: 12px;
      transform: translate3d(0, -50%, 0);

      &::before {
        display: block;
        width: 9px;
        height: 9px;
        content: '';
        transform: rotate(-45deg);
        border-top: 2px solid rgba(255, 255, 255, .7);
        border-left: 2px solid rgba(255, 255, 255, .7);
      }
    }

    &:hover {
      color: white(primary);
      border-color: rgba(137, 137, 137, .1);
      background: linear-gradient(to right, rgba(119, 119, 119, .4), rgba(85, 85, 85, .4));
      box-shadow: 0 1px 2px rgba(0, 0, 0, .16);

      .uSideBoard {
        &__toggle {

          &Icon {
            &::before {
              border-top: 2px solid white(primary);
              border-left: 2px solid white(primary);
            }
          }
        }
      }
    }
  }

  /*
  　戻るボタン
  ---------------------------------*/
  &__back {
    position: absolute;
    top: 26px;
    left: 0;
    width: 100%;
    padding-right: 10px;
    padding-left: 10px;

    @media (pointer: coarse), (max-width: 959px) {
      top: 72px;
    }

    @media (pointer: coarse) and (min-height: 700px) {
      display: none;
    }

    &Link {
      font-size: 13px;
      display: flex;
      padding: 8px 10px;
      color: white(primary);
      border: 1px solid transparent;

      align-items: baseline;
      justify-content: center;

      &:hover {
        text-decoration: none;
        color: white(primary);
        border-color: rgba(137, 137, 137, 0.1);
        border-radius: 24px;
        background: linear-gradient(to right, rgba(119, 119, 119, 0.4), rgba(85, 85, 85, 0.4));
      }

      &Text {
        font-size: 13px !important;

        @media (pointer: coarse), (max-width: 959px) {
          display: none;
        }
      }
    }

    .icon-s-back {
      padding-right: 16px;

      @media (pointer: coarse), (max-width: 959px) {
        padding-right: 10px;
      }
    }
  }
}

/*
　.barn min-widthの調整
---------------------------------*/
.barn {
  .is-sideBoard & {
    min-width: auto !important;

    @media print {
      min-width: 1024px !important;
    }
  }

  .print & {
    min-width: 1024px !important;
  }
}

/*
　調整
---------------------------------*/
.is-sideBoard {

  /*
　　ui-gridの横幅
　---------------------------------*/
  .ui-grid-header-viewport,
  .ui-grid-viewport {
    width: 100% !important;
  }

  /*
　　テーブルのヘッダー横幅
　---------------------------------*/
  .fixed-tb:not(#tab-1) {
    width: calc(100% - 20px) !important;

    .cowList {
      width: 100% !important;
    }
  }
}
