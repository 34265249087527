/*
  main.cssをimport記述のみにするため一旦分離
  .barn整理後に下記記述は各SCSSファイルに移行してこのファイルは削除する予定
*/
.barn {
  font-size: 1.3rem;
  line-height: 1.5;
  min-width: 1024px;
  height: 100%;
  min-height: 100%;
  margin: 0;
  padding: 0;
  text-align: left;
  color: #333;

  &--cowDetail {
    min-width: auto;
  }

  &--home {
    @media (max-width: 1023px) {
      min-width: auto;
    }

    .content {
      padding-top: 56px !important;
    }
  }

  @media (pointer: coarse) {
    min-width: auto;
  }

  * {
    margin: 0;
  }

  table {
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse;
    border: 1px solid #ddd;
    border-top: none;
    border-bottom: none;

    &.tb-row4 {
      border-left: none;

      th {
        border-right: none;
        border-left: none;
      }

      td {
        border-right: none;
      }

      tr {
        &:first-child th {
          border-top: 1px solid #979694;
        }

        &:last-child th {
          border-bottom: 1px solid #979694;
        }
      }
    }

    &.clone th {
      border-bottom: 1px solid #ddd;
    }

    &.tb-col {
      th {
        border-top: none;
      }

      tr {
        &:first-child th {
          &:first-child {
            border-left: 1px solid #979694;
          }

          &:last-child {
            border-right: 1px solid #979694;
          }
        }

        &:nth-of-type(1) td {
          border-top: none;
        }
      }
    }

    tr:nth-child(odd) {
      background-color: #f7f7f7;
    }

    tr th:first-child,
    tr td:first-child {
      border-left: 1px solid #ddd;
    }

    tr.separate-cell td {
      height: 4px;
      background-color: transparent;
    }

    tr.separate-cell+tr.separate-cell td {
      border-top: none;
    }

    tr.separate-cell.group-ttl td {
      font-weight: bold;
      height: auto;
      padding-bottom: 5px;
      padding-left: 7px;
      text-align: left;
      border-top: none;
    }

    tr:nth-child(2n+1).separate-cell {
      background-color: #e7e7e7;
    }

    tr:nth-child(2n+2).separate-cell {
      background-color: #e7e7e7;
    }
  }

  table.smart {
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse;
    border: none;

    tr {
      border-bottom: 1px solid #eee;

      &:first-child th {
        &:first-child {
          border-left: none;
        }

        &:last-child {
          border-right: none;
        }

        &:nth-of-type(1) td {
          border-top: none;
        }
      }
    }

    th {
      font-size: 1.2rem;
      min-height: 25px;
      text-align: left;
      text-align: left;
      vertical-align: middle;
      color: #000;
      border-right: none;
      border-bottom: 1px solid #303030;
      background: #eee;

      span {
        font-weight: bold;
      }

      .unit {
        margin-left: 0;
        color: #dfdfdf;
        color: #fff;
      }
    }

    &.table-fix th {
      border-bottom: none;
    }

    &.clone th {
      border-bottom: 1px solid #aaa;
    }

    tr:nth-child(odd) {
      background-color: #fff;
    }

    td {
      font-size: 1.2rem;
      min-height: 20px;
      text-align: left;
      vertical-align: middle;
      border: none;
    }

    tr th:first-child,
    tr td:first-child {
      border-left: none;
    }
  }

  .table-fix {
    // 旧アイコンフォント調整
    .icon-s-check-box-outlined {
      font-size: 12px!important;
    }
  }

  img {
    width: 100%;
    border: 0;
  }

  p {
    font-size: 1.2rem;
    margin: 0;
  }

  b {
    font-weight: bold;
  }

  strong {
    font-weight: bold;
  }

  abbr[title] {
    border-bottom: 1px dotted;
  }

  textarea {
    font-size: 1.2rem;
    line-height: 18px;
    display: inline-block;
    overflow: auto;
    height: 5em;
    margin: 0;
    padding: 2px 6px;
    transition: border linear 0.2s, box-shadow linear 0.2s;
    vertical-align: baseline;
    color: #555;
    border: 1px solid #ccc;
    border-radius: 3px;
    background: #fff;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);

    &:focus {
      outline: 0;
    }

    &:disabled {
      background-color: #f6f4f4;
    }
  }

  button {
    font-size: 100%;
    line-height: normal;
    margin: 0;
    cursor: pointer;
    vertical-align: baseline;
  }

  select {
    font-size: 1.2rem;
    line-height: 18px;
    display: inline-block;
    margin: 0;
    padding: 2px 6px;
    transition: border linear 0.2s, box-shadow linear 0.2s;
    vertical-align: baseline;
    color: #555;
    border: 1px solid #ccc;
    border-radius: 3px;
    background: #fff;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  }

  input[type="button"],
  input[type="reset"],
  input[type="submit"] {
    cursor: pointer;
  }

  input[type="checkbox"] {
    margin-right: 5px;
    vertical-align: middle;
  }

  h4 {
    font-size: 1.5rem;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 15px;
    padding: 10px;
    vertical-align: middle;
    background-color: #f6f6f6;

    i {
      font-size: 3rem;
      margin-top: -3px;
      color: #333;
    }

    &.add-left-icon {
      position: relative;
      padding-left: 3.6rem;

      i {
        position: absolute;
        top: 6px;
        left: 5px;
      }
    }
  }

  h5 {
    font-size: 1.6rem;
    font-weight: bold;
    padding: 18px 10px 10px;
    text-align: center;

    &.tag {
      font-size: 1.2rem;
      display: inline-block;
      min-width: 110px;
      margin-top: -15px;
      padding: 3px 10px;
      text-align: center;
      color: #fff;
      border: none;
      border-radius: 0 0 5px 5px;
      background: #009943;
    }
  }

  span {
    font-size: 1.2rem;
  }

  li {
    font-size: 1.2rem;
  }

  pre {
    white-space: pre-wrap;

    &.pre-style-none {
      font-family: “Original Yu Gothic”, “Yu Gothic”, YuGothic, Meiryo, “MS PGothic”, sans-serif;
      padding: 0;
      border: medium none;
      background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
    }
  }

  dt {
    font-size: 1.2rem;
    float: left;
    clear: both;
    width: 120px;
    height: auto;
    padding: 7px 10px;
  }

  dd {
    font-size: 1.2rem;
    width: auto;
    height: auto;
    margin-left: 120px;
    padding: 7px 10px;
    border-top: 1px dotted #ccc;
    background: #fff;
  }

  th {
    font-size: 1.2rem;
    min-height: 33px;
    padding: 5px 0;
    text-align: center;
    vertical-align: middle;
    color: #fff;
    border-top: 1px solid #dbdbdb;
    border-right: 1px solid #eee;
    background: #777;

    span {
      font-weight: normal;
    }

    .unit {
      margin-left: 0;
      color: #dfdfdf;
      color: #fff;
    }
  }

  td {
    font-size: 1.2rem;
    min-height: 1px;
    padding: 5px;
    vertical-align: middle;
    border-top: 1px solid #ddd;
    border-right: 1px solid #ddd;

    &.stylish {
      text-align: left;
    }

    .cow-no {
      font-weight: bold;
      text-align: center;
    }
  }

  dl {
    zoom: 1;
    width: 100%;

    &:before {
      display: block;
      overflow: hidden;
      content: "";
    }

    &:after {
      display: block;
      clear: both;
      overflow: hidden;
      content: "";
    }
  }

  .scroll-outer {
    overflow: auto;
  }

  .display-none {
    display: none !important;
  }

  .display-block {
    display: block;
  }

  .overflow-hidden {
    overflow: hidden;
  }

  .no-arrow {
    padding: 0 6px;
  }

  .btn-box {
    margin-top: 15px;
    padding: 10px;
    text-align: center;
    background-color: #f6f6f6;

    .btn {
      font-size: 1.3rem;
      font-weight: bold;
      line-height: 1;
      height: auto;
      padding: 12px 20px;
      border: none;
    }
  }

  .table {
    display: table;
    width: 100%;

    li {
      display: table-cell;
      padding: 0;
    }

    &.fixed {
      table-layout: fixed;
    }

    &.table-first50 {
      li:nth-of-type(1) {
        width: 55px;
        padding-right: 3px;
        text-align: right;
      }
    }

    &.table-first60 {
      li:nth-of-type(1) {
        width: 65px;
        padding-right: 3px;
        text-align: right;
      }
    }
  }

  .input-block {
    display: block;
    width: 100% !important;
  }

  .width-block {
    display: block;
    width: 100%;
  }

  .p10 {
    padding: 10px;
  }

  .m0 {
    margin: 0 !important;
  }

  .mb10 {
    margin-bottom: 10px;
  }

  .align-right {
    text-align: right !important;
  }

  .align-left {
    text-align: left !important;
  }

  .align-center {
    text-align: center !important;
  }

  .add-line {
    margin-top: 15px;

    button {
      display: flex;
      width: 100%;
      padding: 10px;
      color: #333;
      border: 1px dashed #ccc;
      border-radius: 4px;

      align-items: center;
      justify-content: center;

      &:hover {
        text-decoration: none;
        background-color: #eee;
      }

      .input {
        margin: 0 5px;
      }

      .icon-css-plus {
        position: relative;
        display: inline-block;
        width: 18px;
        height: 18px;
        margin: 0 4px 0 0;

        &::before {
          position: absolute;
          top: 7px;
          left: 3px;
          display: block;
          width: 12px;
          height: 2px;
          content: '';
          background-color: #333;
        }

        &::after {
          position: absolute;
          top: 7px;
          left: 3px;
          display: block;
          width: 12px;
          height: 2px;
          content: '';
          transform: rotate(90deg);
          background-color: #333;
        }
      }
    }
  }

  input[type="text"],
  select {
    &:disabled {
      background-color: #f6f4f4;
    }
  }

  input[type="password"] {
    font-size: 1.2rem;
    line-height: 18px;
    display: inline-block;
    padding: 2px 6px;
    transition: border linear 0.2s, box-shadow linear 0.2s;
    color: #555;
    border: 1px solid #ccc;
    border-radius: 3px;
    background: #fff;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);

    &:focus {
      border-color: rgba(82, 168, 236, 0.8);
      outline: 0;
      outline: thin dotted #111111;
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(82, 168, 236, 0.6);
    }
  }

  .sprite {
    display: inline-block;
    vertical-align: middle;
    background: url(../images/sprite.png) 0 0 no-repeat;
  }

  .section h4:first-child {
    margin-top: 0;
  }

  .section-inner01 {
    padding: 10px 10px 0;

    .section {
      padding-bottom: 10px;
      box-shadow: none !important;
    }
  }

  .add-border01 {
    border: 1px solid #dadada !important;
  }

  .right-shoulder {
    position: relative;
    width: 100%;

    .right-contents,
    .edit-btn {
      position: absolute;
      top: 8px;
      right: 6px;
    }
  }

  .edit-regist-list01 {
    padding: 5px;

    dl {
      font-size: 1.4rem;
      display: inline-block;
      float: none;
      width: auto;
      margin-top: 10px;
      margin-bottom: 15px;
      margin-left: 0;
      padding: 0;

      dt {
        font-size: 1.4rem;
        display: inline-block;
        float: none;
        width: auto;
        margin-right: 3px;
        margin-left: 0;
      }

      dd {
        font-size: 1.4rem;
        line-height: 1;
        position: relative;
        display: inline-block;
        float: none;
        width: auto;
        margin-right: 12px;
        margin-left: 0;
        padding: 0;
        border: 1px dashed #ccc;
        border-radius: 5px;

        &:nth-last-of-type(1) {
          margin-right: 0;
        }

        a {
          font-size: 1.4rem;
          display: inline-block;
          padding: 6px 28px 6px 7px;
          color: #333;

          &:hover {
            text-decoration: none;
            background-color: #eee;
          }
        }

        input.date-ver {
          font-size: 1.4rem;
          position: relative;
          width: 120px;
          padding-top: 3px;
          padding-bottom: 3px;
          cursor: pointer;
          border: none;
          background: transparent url("../images/icon-edit.svg") no-repeat 100px 4px;
          background-size: 15px 15px;
          box-shadow: none;

          &:hover {
            background-color: #eee;
          }
        }
      }
    }

    // 旧アイコンフォント調整
    .icon-s-edit {
      font-size: 12px !important;
      margin: 0 0 0 5px;
    }
  }

  tr:last-child td {
    border-bottom: 1px solid #ddd;
  }

  .number-box {
    li {
      .section {
        min-height: 150px;

        .number {
          font-size: 1.6rem;
          font-weight: normal;
          padding: 15px 10px 3px;
          text-align: center;
          letter-spacing: 0;

          span {
            font-size: 5rem;
            font-weight: bold;
            line-height: 1;
            padding-right: .5rem;
          }

          &.attension span {
            color: #f65757;
          }
        }

        .supplement {
          font-size: 1.3rem;
          font-weight: bold;
          text-align: center;
        }

        i {
          font-size: 3rem;
          position: absolute;
          top: 3px;
          right: 4px;
          display: block;
        }
      }

      &:last-child .section .number span {
        padding-right: 0;
      }
    }

    &.number-box-inner {
      padding: 0 10px;

      .section {
        position: relative;
        min-height: 130px;
        border: 3px solid #ebebeb;
        background-color: #fafafa;
        box-shadow: none;
      }

      li .section .number {
        span {
          font-size: 4rem;
          padding-right: .5rem;
          padding-left: 2rem;
        }

        &.pl0 span {
          padding-left: 0;
        }
      }
    }
  }

  &.search-manu .content {
    overflow: hidden;
  }

  .inline li {
    display: inline-block;

    a {
      display: inline-block;
    }
  }

  li.disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  .caution-message {
    font-size: 1.3rem;
    margin-bottom: 8px;
    margin-left: 5px;
    color: #e03333;
  }

  td .caution-message {
    margin-top: 1px;
    margin-bottom: 0;
  }

  .caution-message i {
    font-size: 1.8rem;
    margin-top: -3px;
    margin-right: 1px;
    color: #e03333;
  }

  .required-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
  }

  th .required {
    font-size: 1rem;
    position: relative;
    right: 0;
    display: inline-block;
    margin-top: 2px;
    margin-left: 5px;
    padding: 0 3px;
    color: #fff;
    border-radius: 3px;
    background-color: #d22323;
  }

  .add-checkbox {
    font-weight: normal;
    display: block;
    margin-top: 4px;
    margin-left: 2px;
  }

  .table.table-first50 li:nth-of-type(1) {
    width: 55px;
    padding-right: 3px;
    text-align: right;
  }

  .table.table-first60 li:nth-of-type(1) {
    width: 65px;
    padding-right: 3px;
    text-align: right;
  }

  .table.table-first90 li:nth-of-type(1) {
    width: 90px;
    padding-right: 3px;
    text-align: right;
  }

  .table.table-first100 li:nth-of-type(1) {
    width: 100px;
    padding-right: 3px;
    text-align: right;
  }

  .table.table-first150 li:nth-of-type(1) {
    width: 150px;
    padding-right: 3px;
    text-align: right;
  }

  .note {
    background-color: #f2f2f2;
  }

  .note-radio {
    width: 100%;
    margin-top: 3px;
  }

  .note-tx {
    padding: 4px 5px;
    border-left: 1px solid #fff;
  }

  .note-tx li {
    font-size: 1rem;
    margin-bottom: 2px;
  }

  .note-tx li:nth-last-of-type(1) {
    margin: 0;
  }

  .cow-detail {
    &:before {
      background-color: #1f1f1f;
    }

    @media screen and (max-width: 1200px) {
      &.has-label {
        padding-top: 90px;

        @media (pointer: coarse) {
          padding-top: 70px;
        }

        .detail-header {
          flex-direction: column-reverse;
          height: 90px;
          padding: 10px;

          .tab-selector--container {
            align-self: flex-start;
          }

          .cow-label--container {
            align-self: flex-end;
          }
        }
      }
    }
  }

  .detail-header {
    height: 4.8rem;
    background-color: rgba(30, 30, 30, .96);

    @media screen and (max-width: 1200px) {
      z-index: 19;
    }
  }

  .tab-selector--container {
    .tab-selector {
      font-size: 1.3rem;

      &.active {
        border-color: transparent;
        border-radius: 4px;
        background-color: #666;
      }

      &:hover {
        border-color: transparent;
        border-radius: 4px;
        background-color: #666;
      }
    }
  }

  @media (max-width: 959px) {
    .expand {
      .cow-detail {
        padding-top: 96px;

        &.has-label {
          padding-top: 138px;
        }
      }

      .detail-header {
        top: 172px;
      }
    }
  }

  @media print {
    &::before,
    &::after {
      display: none;
    }
  }
}

th {
  background: #777;
}

.beef-history {
  .content-box {
    height: auto!important;
  }
}

.cow-board-modal-dismiss {
  display: none;
}

.cow-board-modal-close {
  position: absolute;
  right: 0;
  z-index: 1;
  display: block;
  width: 40px;
  height: 40px;
}
