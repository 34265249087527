/*---------------------------------
　ボタン
---------------------------------*/

/*
　@extend用のプレースホルダーセレクタ
---------------------------------*/
%uButton {
  font-size: 14px !important;
  font-weight: 700;
  display: inline-block;
  width: 136px;
  height: 40px;
  margin: 0 0 0 24px !important;
  cursor: pointer !important;
  transition: all .1s ease;
  opacity: 1;
  color: white(primary);
  border: none !important;

  border-radius: 20px;
  box-shadow: none !important;
}

%uButton--disabled {
  opacity: .32;
  box-shadow: none !important;
}

/*
　ボタン
　@deprecated .uButton--primaryを利用してください
　※この実装の修正および、新規の利用は禁止です
　※文字間隔はスペースで対応してください
---------------------------------*/
.uButton {
  text-indent: .5em;
  letter-spacing: .5em;
  border: 1px solid rgba(0, 0, 0, .12) !important;
  background: linear-gradient(to right, #387ac7, #0d3898);
  box-shadow: 0 2px 3px rgba(0, 0, 0, .36) !important;

  @extend %uButton;

  &:hover,
  &:focus {
    border: 1px solid rgba(0, 0, 0, .08) !important;
    background: linear-gradient(to right, #3e8eeb, #377adc, #1f34a8);
    box-shadow: 0 3px 6px rgba(0, 0, 0, .36) !important;

    @media (pointer: coarse) {
      border: 1px solid rgba(0, 0, 0, .12) !important;
      background: linear-gradient(to right, #387ac7, #0d3898);
      box-shadow: 0 2px 3px rgba(0, 0, 0, .36) !important;
    }
  }

  &:disabled {
    border: 1px solid rgba(0, 0, 0, .12) !important;
    background: linear-gradient(to right, #387AC7, #0D3898);

    @extend %uButton--disabled;

    &:hover {
      box-shadow: none !important;
    }
  }

  /*
  　ボタン - 削除ボタン
  　@deprecated .uButton--secondaryを利用してください
  　※この実装の修正および、新規の利用は禁止です
  　※文字間隔はスペースで対応してください
  ---------------------------------*/
  &--delete {
    text-indent: .5em;
    letter-spacing: .5em;
    border: 1px solid rgba(0, 0, 0, .24) !important;
    background: linear-gradient(to right, #d00c28, #ac0119);
    box-shadow: 0 2px 3px rgba(0, 0, 0, .36) !important;

    @extend %uButton;

    &:hover,
    &:focus {
      border: 1px solid rgba(0, 0, 0, .12) !important;
      background: linear-gradient(to right, #d00c28, #d00c28);
      box-shadow: 0 3px 6px rgba(0, 0, 0, .48) !important;

      @media (pointer: coarse) {
        border: 1px solid rgba(0, 0, 0, .24) !important;
        background: linear-gradient(to right, #d00c28, #ac0119);
        box-shadow: 0 2px 3px rgba(0, 0, 0, .36) !important;
      }
    }

    &:disabled {
      border: 1px solid rgba(0, 0, 0, .24) !important;
      background: linear-gradient(to right, #d00c28, #a20017);

      @extend %uButton--disabled;

      &:hover {
        box-shadow: none !important;
      }
    }
  }

  /*
  　ボタン - キャンセルボタン
    @deprecated .uButton--tertiaryを利用してください
  　※この実装の修正および、新規の利用は禁止です
  　※文字間隔はスペースで対応してください
  ---------------------------------*/
  &--cancel {
    color: black(primary);
    background: linear-gradient(to right, #fff, #fff);

    @extend %uButton;

    &:hover,
    &:focus {
      background: linear-gradient(to right, #fff, #f2f2f2);
      box-shadow: 0 3px 6px rgba(0, 0, 0, .24) !important;

      @media (pointer: coarse) {
        background: linear-gradient(to right, #fff, #fff);
        box-shadow: none !important;
      }
    }

    &:disabled {
      opacity: 1;
      color: black(quaternary);
      background: linear-gradient(to right, #f2f2f2, #f2f2f2) !important;

      @extend %uButton--disabled;

      &:hover {
        box-shadow: none !important;
      }
    }
  }

  /*
　  ボタン - primary
  ---------------------------------*/
  &--primary {
    border: 1px solid rgba(0, 0, 0, .12) !important;
    background: linear-gradient(to right, #387ac7, #0d3898);
    box-shadow: 0 2px 3px rgba(0, 0, 0, .36) !important;

    @extend %uButton;

    &:hover,
    &:focus {
      border: 1px solid rgba(0, 0, 0, .08) !important;
      background: linear-gradient(to right, #3e8eeb, #377adc, #1f34a8);
      box-shadow: 0 3px 6px rgba(0, 0, 0, .36) !important;

      @media (pointer: coarse) {
        border: 1px solid rgba(0, 0, 0, .12) !important;
        background: linear-gradient(to right, #387ac7, #0d3898);
        box-shadow: 0 2px 3px rgba(0, 0, 0, .36) !important;
      }
    }

    &:disabled {
      border: 1px solid rgba(0, 0, 0, .12) !important;
      background: linear-gradient(to right, #387ac7, #0d3898);

      @extend %uButton--disabled;

      &:hover {
        box-shadow: none !important;
      }
    }
  }

  /*
　  ボタン - secondary（削除ボタン）
  ---------------------------------*/
  &--secondary {
    border: 1px solid rgba(0, 0, 0, .24) !important;
    background: linear-gradient(to right, #d00c28, #ac0119);
    box-shadow: 0 2px 3px rgba(0, 0, 0, .36) !important;

    @extend %uButton;

    &:hover,
    &:focus {
      border: 1px solid rgba(0, 0, 0, .12) !important;
      background: linear-gradient(to right, #d00c28, #d00c28);
      box-shadow: 0 3px 6px rgba(0, 0, 0, .48) !important;

      @media (pointer: coarse) {
        border: 1px solid rgba(0, 0, 0, .24) !important;
        background: linear-gradient(to right, #d00c28, #ac0119);
        box-shadow: 0 2px 3px rgba(0, 0, 0, .36) !important;
      }
    }

    &:disabled {
      border: 1px solid rgba(0, 0, 0, .24) !important;
      background: linear-gradient(to right, #d00c28, #a20017);

      @extend %uButton--disabled;

      &:hover {
        box-shadow: none !important;
      }
    }
  }

  /*
　  ボタン - tertiary（キャンセルボタン）
  ---------------------------------*/
  &--tertiary {
    color: black(primary);
    background: linear-gradient(to right, #fff, #fff);

    @extend %uButton;

    &:hover,
    &:focus {
      background: linear-gradient(to right, #fff, #f2f2f2);
      box-shadow: 0 3px 6px rgba(0, 0, 0, .24) !important;

      @media (pointer: coarse) {
        background: linear-gradient(to right, #fff, #fff);
        box-shadow: none !important;
      }
    }

    &:disabled {
      opacity: 1;
      color: black(quaternary);
      background: linear-gradient(to right, #f2f2f2, #f2f2f2);

      @extend %uButton--disabled;

      &:hover {
        box-shadow: none !important;
      }
    }
  }

  /*
　  ボタン - quaternary（枠線あり、背景色なし）
  ---------------------------------*/
  &--quaternary {
    color: white(primary);
    border: 2px solid #707070 !important;

    @extend %uButton;

    &:hover,
    &:focus {
      background: #444;
      box-shadow: none !important;
      border: 2px solid #444 !important;
    }

    &:disabled {
      opacity: 1;
      color: black(quaternary);
      background: linear-gradient(to right, #f2f2f2, #f2f2f2);

      @extend %uButton--disabled;

      &:hover {
        box-shadow: none !important;
      }
    }
  }

  /*
  　ボタン - 丸みのあるボタン
  ---------------------------------*/
  &--rounded {
    font-size: 14px;
    font-weight: 700;
    display: flex;
    height: 36px;
    padding: 0 14px;
    transition: all .1s ease;
    color: black(primary);
    border-radius: 18px;
    background: #f0f0f0;

    align-items: center;

    [class^="icon"] {
      margin: 0 8px 0 0;
    }

    &:hover {
      background: #ddd;
    }
  }

  /*
  　ボタン - 丸みのある枠線ありボタン
  ---------------------------------*/
  &--roundedBorder {
    font-size: 14px;
    font-weight: 700;
    display: flex;
    width: 112px;
    height: 36px;
    transition: all .1s ease;
    color: #444;
    border: 2px solid #666;
    border-radius: 18px;
    background: #fff;

    align-items: center;
    justify-content: center;

    &:hover {
      border-color: #e6e6e6;
      background: #e6e6e6;
    }
  }

  /*
  　ボタン - 矢印付きボタン
  ---------------------------------*/
  &--arrow {
    font-size: 14px;
    position: relative;
    display: flex;
    height: 40px;
    padding: 0 40px 0 14px;
    transition: all .1s ease;
    color: black(primary);
    border: 1px solid white(primary);
    border-radius: 20px;
    background: white(primary);
    box-shadow: 0 1px 2px rgba(0, 0, 0, .16);

    align-items: center;

    &:hover {
      border: 1px solid #e6f7ff;
      background: linear-gradient(to right, #fafdff, #f7fbff);
    }

    &::after {
      position: absolute;
      top: 15px;
      right: 18px;
      display: block;
      width: 8px;
      height: 8px;
      content: '';
      transform: rotate(45deg);
      border-top: 2px solid black(primary);
      border-right: 2px solid black(primary);
    }
  }

  /*
  　ボタン - 左矢印付きボタン
  ---------------------------------*/
  &--arrowLeft {
    position: relative;
    width: 100%;
    padding: 0 14px 0 40px;
    color: black(primary);
    background: linear-gradient(to right, #fff, #fff);

    @extend %uButton;

    &:hover,
    &:focus {
      background: linear-gradient(to right, #fff, #f2f2f2);
      box-shadow: 0 3px 6px rgba(0, 0, 0, .24) !important;

      @media (pointer: coarse) {
        background: linear-gradient(to right, #fff, #fff);
        box-shadow: none !important;
      }
    }

    &:disabled {
      opacity: 1;
      color: black(quaternary);
      background: linear-gradient(to right, #f2f2f2, #f2f2f2);

      @extend %uButton--disabled;

      &:hover {
        box-shadow: none;
      }
    }

    &::after {
      position: absolute;
      top: 15px;
      left: 18px;
      display: block;
      width: 8px;
      height: 8px;
      content: '';
      transform: rotate(45deg);
      border-bottom: 2px solid black(primary);
      border-left: 2px solid black(primary);
    }
  }

  &__heading {
    font-size: 14px;
    font-weight: 700;
    margin: 0 16px 0 0;
    color: black(primary);
  }

  &__emphasis {
    &--primary {
      font-size: 14px;
      font-weight: 700;
      color: #95084e;
    }

    &--secondary {
      font-size: 14px;
      font-weight: 700;
      color: #6c5b00;
    }

    &--tertiary {
      font-size: 14px;
      font-weight: 700;
      color: #0d52a2;
    }
  }
}