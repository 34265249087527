@media print {
  // 乳房炎リスト
  .mastitis-list {
    .working-day {
      position: absolute;
      top: -45px;
      left: 5px;
      display: block !important;
      span {
        font-weight: bold;
        font-size: 1.5rem !important;
      }
    }
    .content-box {
      .tabs {
        padding: 0 !important;
      }
      .result {
        padding: 0 !important;
      }
    }
  }
}