.activity-chart-range-selector {
  #activity-date {
    width: 7em;
    font-size: 14px;
    border: none;
    text-align: center;
  }
  #activity-date:focus {
    outline: none;
  }
}