#senser-list {
  .content-box {
    .left-adjust {
      .latest-data-nothing {
        text-align: center;
        line-height: 124px;
        font-weight: bold;
      }
      .stress {
        .number {
          span {
            color: #000;
            &.score-color1 { color : #418632; }
            &.score-color2 { color : #BABA00; }
            &.score-color3 { color : #ff3f3f; }
          }
        }
      }
    }
    .right-adjust {
      .p10 {
        .data-nothing {
          text-align: center;
          width: 100%;
          height: 200px;
          line-height: 200px;
          display: none;
          font-size: 1.2rem;
        }
      }
      #stress {
        svg {
          g.c3-regions {
            g.c3-region {
              &.score-color1 { fill : #418632; }
              &.score-color2 { fill : #ffff00; }
              &.score-color3 { fill : #ff0000; }
            }
          }
        }
      }
    }
  }
}
