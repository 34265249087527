.management {
  .box {
    height: auto;
  }
  label {
    font-size: 1.4rem;
  }
  select {
    min-width: 110px;
  }
  table {
    margin-top: 10px;
  }
  tr {
    th {
      &:first-child {
        width: 10%;
      }
      &:nth-child(n+2) {
        padding-left: 20px;
      }
    }
    td:first-child {
      text-align: center;
    }
  }
  th {
    width: 9%;
    padding-right: 0;
    &:nth-child(n+2) {
      font-weight: normal;
    }
    .unit {
      float: right;
      margin-top: 3px;
      margin-left: 0;
    }
  }
  td {
    text-align: right;
  }
}
