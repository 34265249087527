.row-clickable-list {
  width: 480px !important;
  height: 100%;

  .section {
    overflow: hidden;
    height: 100%;
    padding: 10px;
  }

  &-outer {
    overflow: auto;
    width: 100%;
    height: calc(100vh - 186px);
  }

  &-main {
    border: none !important;
  }

  &-row {
    background: none !important;
  }

  &-heading {
    font-size: 1.3rem !important;
    font-weight: 700;
    padding: 6px 10px !important;
    text-align: left !important;
    color: #333 !important;
    border: none !important;
    background-color: #f6f6f6 !important;
  }

  &-body {
    .row-clickable-list {
      &-row {
        cursor: pointer;
        border-top: 1px solid #d4d4d4 !important;

        &:last-child {
          border-bottom: 1px solid #d4d4d4 !important;
        }

        &:hover {
          background-color: #f0f0f0 !important;

          .row-clickable-list {
            &-cell {
              &-hover {
                text-decoration: underline;
                color: #23527c;
              }
            }
          }
        }
      }

      &-cell {
        font-size: 1.4rem !important;
        padding: 10px 6px;
        border: none !important;

        &-hover {
          color: #449dff;
        }
      }
    }
  }
}