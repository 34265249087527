.tool-bar {
  text-align: right;
  padding: 10px 10px 0px 10px;
}

.reproduction-performance-report-menu {
  padding-top: 5px;
}
.reproduction-performance-content {
  background-color: #fff;
  margin-bottom: 5px !important;
  margin-left: 5px !important;
}

.reproduction-performance-report.non-print {
  display: block;
}

.reproduction-performance-report.print {
  display: none;
}

.reproduction-performance-report {
  padding: 0px;
  margin: 0px;
  .unsettled {
    color: #999;
  }
  .note {
    text-align: left;
    color: #fff;
    padding: 10px 20px;
    background-color: #606060;
  }
  .data-nothing-box {
    padding: 10px;
    .data-nothing-font {
      color: #fff;
      font-weight: 700;
    }
  }
  .summary {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0px 5px;
    div.table-container {
      flex-basis: 200px;
      margin: 0px 10px;
      background-color: #fff;
    }
    table {
      border-collapse: collapse;
      thead {
        border: none;
        tr {
          border-bottom: 1px solid #ddd;
          border-left: none;
          th {
            text-align: left;
            padding: 10px;
          }
          th:nth-of-type(2) {
            border-left: 1px solid #ddd;
            border-right: 1px solid #ddd;
          }
        }
      }
      tbody {
        td {
          border: none;
        }
        td:nth-of-type(2) {
          border-left: 1px solid #ddd;
          border-right: 1px solid #ddd;
        }
      }
    }
  }
  .result {
    table {
      border-collapse: collapse;
      .yoy-data {
        color: #999;
        font-size: 1.1rem;
        font-style: italic;
        min-height: 17px;
        padding-right: 3px;
        text-align: right;
      }
      .all-data {
        min-height: 20px;
      }
      td {
        background-color: white;
        text-align: right;
        border: none;
        white-space: nowrap;
        padding: 0.5em 0.5em;
      }
      th {
        white-space: nowrap;
        padding: 0.5em 0.5em;
        min-width: 190px;
        height: 3em; // Firefoxの謎挙動でheightを設定しないと表が1pixelずれる
      }
      td.data-all {
        border-top: none;
        border-right: none;
        border-left: 1px solid #eee;
      }
      td.data {
        border-left: 1px solid #eee;
        border-top: 1px solid #eee;
      }
      td.category {
        text-align: left;
        vertical-align: top;
        border-right: none;
      }
      tr.category {
        border-bottom: none;
        border-top: 1px solid #777;
      }
      td.sub-category {
        text-align: left;
        vertical-align: top;
        border-left: 1px solid #ddd;
        border-top: 1px solid #ddd;
      }
      td.sub-category-yoy {
        text-align: left;
        vertical-align: bottom;
      }
    }
    table#header-table {
      border-collapse: collapse;
      margin: 0 0;
      position: absolute;
      width: 170px;
      z-index: 1;
    }
    .scroll-outer {
      overflow-x: scroll;
      padding-left: 169px;
      #body-table-wrapper {
        margin: 0 0;
        width: 100em;
      }
    }
  }
}
.reproduction-performance-container {
  overflow: auto;
  .tab-selector--container {
    padding: 5px;
  }
}
.reproduction-performance-contents {
  display: grid;
  width: 950px;
  background-color: white;
  overflow: auto;
  margin: 10px;
  .heat-detection-rate {
    width: 100%;
    .heat-detection-rate-chart-wrapper {
      padding: 15px 10px 10px 10px;
      background-color: white;
      width: 670px;
      height: 350px;
      float: left;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      .y-unit {
        position: absolute;
        top: 13px;
        left: 16px;
        span {
          font-size: 0.8rem;
        }
      }
      #heat-detection-rate-chart {
        transition: translateY(-100px);
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: visible;
        text, line, path {
          pointer-events: none;
        }
        svg {
          overflow: visible !important;
        }
        &:hover {
          cursor: pointer;
        }
        .c3-tooltip-container.move-up{
          transform: translateY(-130px);
        }
        .heat-detection-rate-chart-tooltip {
          display: none;
          height: 10rem;
          min-width: 150px;
          table {
            tbody {
              tr {
                td {
                  background-color: white;
                  white-space: nowrap;
                }
              }
            }
          }
          &.show-tooltip {
            display: block;
          }
        }
      }
    }
    .heat-detection-rate-chart-menu-wrapper {
      background-color: white;
      width: 280px;
      height: 350px;
      float: right;
      .heat-detection-rate-chart-menu {
        padding: 15px 10px 15px 15px;
        flex: 1 1 auto;
        input, select {
          height: 2.5rem;
        }
        label {
          white-space: nowrap;
        }
        .heat-detection-rate-chart-menu-timespan {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          .base-date {
            width: 8rem;
          }
          span {
            white-space: nowrap;
          }
        }
        .mf-radiogroup_label {
          font-size: 1.2rem;
        }
      }
    }
  }
  .first-ai-dim {
    width: 100%;
    path {
      fill: none;
    }
    .first-ai-dim-chart-wrapper {
      padding: 15px 10px 10px 10px;
      width: 670px;
      height: 350px;
      float: left;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      #first-ai-dim-chart {
        overflow: visible;
        text, line, path {
          pointer-events: none;
        }
        circle.transparent {
          fill: none !important;
        }
        svg {
          overflow: visible !important;
        }
        .c3-event-rect:hover {
          cursor: crosshair !important;
        }
        .c3-legend-item.pale {
          opacity: 0.1 !important;
        }
      }
      .first-ai-dim-chart-tooltip {
        display: none;
        height: 10rem;
        table tbody tr td {
          background-color: white;
          white-space: nowrap;
        }
        &.show-tooltip {
          display: block;
        }
      }
    }
    .first-ai-dim-chart-menu-wrapper {
      width: 280px;
      height: 350px;
      float: right;
      .first-ai-dim-chart-menu {
        padding: 15px 5px;
        .first-ai-dim-chart-menu-timespan {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          .base-date {
            width: 8rem;
          }
          span {
            white-space: nowrap;
          }
        }
      }
    }
  }
  .bred-analysis {
    width: 100%;
    .u-loader-wrapper {
      height: 532px;
      position: relative;

      .u-loader {
        position: absolute;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        margin: auto;
        padding: 0px;
        width: 30px;
        height: 30px;
      }
    }
    .bred-analysis-data-wrapper {
      padding: 20px 10px 20px 20px;
      background-color: white;
      width: 650px;
      height: 100%;
      float: left;
      position: relative;

      .start-end-date {
        color: gray;
        margin: 0.5em 0em 0em 2em;
        text-align: right;
      } 

      .y-unit {
        position: absolute;
        top: 13px;
        left: 16px;
        span {
          font-size: 0.8rem;
        }
      }
      ng-include {
        width: 100%;
      }
      #bred-analysis-table {
        td {
          text-align: right;
        }
      }
      .bred-analysis-chart-wrapper {
        position: relative;
      }
      #bred-analysis-chart {
        margin-bottom: 2.5em;
        transition: translateY(-100px);
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: visible;
        text, line, path {
          pointer-events: none;
        }
        svg {
          overflow: visible !important;
        }
        &:hover {
          cursor: pointer;
        }
        .c3-tooltip-container.move-up{
          transform: translateY(-100px);
        }
        .bred-analysis-chart-tooltip {
          display: none;
          height: 10rem;
          table {
            tbody {
              tr {
                td {
                  background-color: white;
                  white-space: nowrap;
                }
              }
            }
          }
          &.show-tooltip {
            display: block;
          }
        }
      }
    }
    .bred-analysis-menu-wrapper {
      background-color: white;
      width: 280px;
      height: 350px;
      float: right;

      .bred-analysis-chart-menu {
        margin-top: 1em;
        flex: 1 1 auto;
        .form-container {
          margin: 1.5em 0em;
            .form-line {
              display: flex;
              align-items: center;
              margin-top: 1em;
              label {
                padding-left: 0.5em;
                white-space: nowrap;  
              }
              input {
                margin: 0;
              }
            }
          select {
            margin: 0em 0.5em 0em 0em;
          }
        }
        .form-line+section {
          padding-left: 1.5em;
        }
        .base-date {
          width: 8rem;
        }
        .bred-analysis-chart-menu-timespan {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          span {
            white-space: nowrap;
          }
        }
        .mf-radiogroup_label {
          font-size: 1.2rem;
        }
      }
      .bred-analysis-data-wrapper.module-form {
        padding: 0px !important;
      }
      .bred-analysis-chart-menu.module-form {
        padding: 10px !important;
        input, select {
          vertical-align: middle;
        }
        .mf-field.mf-select select {
          font-size: 1.4rem;
        }
        .mf-label {
          font-size: 1.3rem;
        }
      }
    }
  }
}
