$cool-pescon-height: 36px;
$cool-pescon-margin-bottom: 10px;

.top--container {
  display: flex;
  padding: 0 16px 24px;
  height: auto;
}

.kpi--container {
  width: 66.66%;

  @media screen and (max-width: 1200px) {
    width: auto !important;

    flex: 1;
  }
}

.right--container {
  position: fixed;
  right: 0px;
  overflow: hidden;
  width: calc(33.33% - 50px);
  margin-right: 10px;

  @for $i from 0 through 3 {
    &.notification-visible-count-#{$i} {
      .timeline-top .box-history-innner {
        height: calc(100vh - 167px - #{$i} * 53px - 5px);
      }

      &.contains-cool-pescon .timeline-top .box-history-innner {
        height: calc(100vh - 167px - #{$i} * 53px - 5px - #{$cool-pescon-height} - #{$cool-pescon-margin-bottom});
      }
    }
  }

  &.notification-visible-count-0 {
    .timeline-top .box-history-innner {
      height: calc(100vh - 167px);
    }

    &.contains-cool-pescon .timeline-top .box-history-innner {
      height: calc(100vh - 167px - #{$cool-pescon-height} - #{$cool-pescon-margin-bottom});
    }
  }

  @media screen and (max-width: 1200px) {
    position: relative;
    width: 340px;
    margin: 0 0 0 34px;
  }

  @media #{$tablet-portrait} {
    margin: 0 0 0 24px;
  }

  @media (pointer: coarse) and (min-height: 700px) {
    margin-bottom: 56px;

    .timeline-top {
      @media (orientation: landscape) {
        height: 700px;
        overflow-y: scroll;
        background: #fff;

        .section {
          box-shadow: none;
        }
      }
    }
  }
}

.top--alerts,
.top--counters {
  display: flex;
}

.top--alerts {
  .alerts-header {
    margin: 0;
    color: $primaryColorOld;
  }
}

.alerts--sickness {
  margin-left: 24px;
}

.top--counters {
  margin-top: 24px;
}

.flex-box {
  flex: 1;
}

.flex-vertical {
  flex-direction: column;
}

.alerts--info,
.alerts--sickness,
.top--schedule,
.top--temporary-group-view {
  padding: 10px;
  background-color: #fff;

  flex: 1;
}

.alerts--info,
.top--schedule,
.top--temporary-group-view {
  padding: 0;

  ul {
    padding: 10px;

    li {
      border-bottom: 1px solid #ccc;

      &:nth-last-of-type(1) {
        border-bottom: none;
      }
    }

    li.link {
      text-align: right;

      a {
        color: #449dff;
        background-color: #fff;
      }
    }
  }

  .child--list {
    padding-top: 6px;
    padding-right: 0;
    padding-bottom: 0;
    padding-left: 0;

    a,
    dl {
      padding: 0px;
      padding-right: 0;
      background-color: transparent;
    }

    dl {
      padding: 8px 10px;
    }

    dt span {
      font-weight: normal;
    }

    dd span.count {
      font-weight: normal;
    }

    // 旧アイコンフォント調整
    .icon-s-notice {
      font-size: 14px !important;
      position: absolute;
      top: calc(50% - 7px);
      left: 0;
      margin: 0;
    }
  }

  a {
    display: block;
    padding: 8px;
    color: #555;
    background-color: #f2f2f2;
    border-radius: 4px;

    &:hover {
      text-decoration: none;
      background-color: #f2f2f2;
    }

    dl {
      padding: 0;
      color: #555;
    }

    dt i {
      color: #555;
    }

    dd i {
      color: #477d17;
    }
  }

  dl {
    display: flex;
    padding: 8px;
    color: #ddd;

    align-items: center;
  }

  dt,
  dd {
    font-size: 1.3rem;
    float: none;
    width: auto;
    margin: 0;
    padding: 0;
    border: none;
    background-color: transparent;
  }

  dt {
    position: relative;
    padding-left: 25px;
    text-indent: 0;

    flex-basis: 60%;

    i {
      font-size: 1.6rem;
      font-weight: normal;
      position: absolute;
      top: 50%;
      left: 1px;
      margin-top: -1rem;
      margin-right: 5px;
      color: #ddd;
    }

    span {
      font-size: 1.3rem;
      font-weight: bold;
      padding: 0 2px;
    }
  }

  dd {
    text-align: right;

    flex-basis: 40%;

    display: flex;
    align-items: center;
    justify-content: flex-end;

    span {
      font-weight: normal;
      padding-left: 7px;

      &.count {
        font-size: 1.6rem;
        font-weight: bold;
      }
    }

    // 旧アイコンフォント調整
    .icon-l-arrow-A-r01 {
      width: 35px;
    }
  }

  .top--alerts-heat {
    border-bottom: none;

    a {
      padding: 12px 10px 12px 8px;
      background-color: #f2f2f2;
    }

    &:hover {
      background: #eaeaea;
    }

    dt {
      font-weight: bold;
      padding-left: 25px;
      text-indent: 0;

      i {
        font-size: 2rem;
        position: absolute;
        top: 50%;
        left: -3px;
        margin-top: -1.2rem;
        margin-right: 5px;
        margin-left: 3px;
        color: #c02c94 !important;
      }

      span {
        color: #c02c94;
      }
    }

    dd {
      span {
        color: #555;

        &.count {
          font-size: 1.8rem;
          font-weight: bold;
          color: #c02c94;
        }
      }
    }

  // 旧アイコンフォント調整
  .icon-s-heat,
  .icon-s-notice {
    font-size: 16px!important;
    position: absolute;
    top: calc(50% - 10px);
    margin: 0 0 0 5px;
    }
  }

  .top--alerts-false-heat {
    margin-top: 7px;
    margin-bottom: 12px;
    border-bottom: none;

    a {
      padding: 12px 10px 12px 8px;
      background-color: #f2f2f2;

      &:hover {
        background: #eaeaea;
      }
    }

    dt {
      font-weight: bold;
      padding-left: 25px;
      text-indent: 0;

      i {
        font-size: 2rem;
        position: absolute;
        top: 50%;
        left: -3px;
        margin-top: -1.2rem;
        margin-right: 5px;
        margin-left: 3px;
      }
    }

    dd {
      span {
        &.count {
          font-size: 1.8rem;
          font-weight: bold;
        }
      }
    }
  }

  .top--alerts-dysstasia {
    margin-top: 7px;
    margin-bottom: 12px;
    border-bottom: none;

    a {
      padding: 12px 7px 12px 5px;
      border: 3px solid rgba(255, 255, 255, .1);
      background-color: #c52424;

      &:hover {
        background-color: #b71c1c;
      }
    }

    dt {
      font-weight: bold;
      padding-left: 25px;
      text-indent: 0;
      color: #ffffff;

      i {
        font-size: 2rem;
        position: absolute;
        top: 50%;
        left: -3px;
        margin-top: -1.2rem;
        margin-right: 5px;
        margin-left: 3px;
        color: #ffffff;
      }

      span {
        color: #ffffff;
      }
    }

    dd {
      span {
        color: #ffffff;

        &.count {
          font-size: 1.8rem;
          font-weight: bold;
          color: #ffffff;
        }
      }
    }

    // 旧アイコンフォント調整
    .icon-s-caution {
      font-size: 16px !important;
      position: absolute;
      top: calc(50% - 10px);
      margin: 0 0 0 5px;
    }
  }

  .heat-icon {
    color: #c02c94;
  }

  span.beta-box {
    font-size: 1.1rem;
    font-weight: normal;
    line-height: 1;
    display: inline-block;
    margin-left: 10px;
    padding: 2px 5px;
    color: #488eb4 !important;
    border: 1px solid #488eb4;
    border-radius: 2px;
  }
}

.alerts--sickness {
  margin-left: 10px;
  padding: 0;

  h4 {
    margin-bottom: 7px;
  }

  dt {
    width: 80px;
    padding: 7px 15px;

    div {
      font-size: 1rem;
    }
  }

  dd {
    margin-left: 80px;
    border-top: none;

    >div {
      margin-bottom: 10px;
      padding-left: 4.5em;
      text-indent: -4.5em;

      &:nth-last-of-type(1) {
        margin-bottom: 0;
      }
    }
  }
}

/**
 *  Cow counters
 */
.counter--sum,
.counter--milk {
  flex: none;
}

.counter--horizontal {
  display: flex;
}

.cows-count-div {
  padding: 10px;
  background-color: #fff;
}

.each-cows-count {
  display: flex;
  border-bottom: 1px solid rgba(90, 90, 90, 0.2);

  &>div {
    position: relative;
    min-width: 145px;
    padding: 28px 15px 5px;
    text-align: right;

    flex: 1;

    &:first-of-type {
      border-right: 1px solid rgba(90, 90, 90, 0.2);
    }

    &:nth-of-type(2) {
      margin-left: 10px;
    }
  }
}

.total-cows-count {
  padding: 6px 10px 0 0;
  text-align: right;
}

.count-title {
  position: absolute;
  top: 0;
  left: 0;
}

.cow-count,
.total-count {
  font-weight: bold;
}

.cow-count {
  font-size: 3rem;
}

.total-count {
  font-size: 2.5rem;
}

.top--timeline {
  position: static;
  width: 100%;
}

.top--counters-meat {
  display: flex;
  margin-top: 10px;
}

.meatcows-count-div,
.fattening-stage-count-div {
  table {
    margin-top: 16px;

    thead {
      tr th {
        padding-bottom: 4px;
      }
    }

    tbody {
      span {
        font-size: 2.1rem;
        font-weight: normal;
      }

      th,
      td {
        border-bottom: none;
      }

      th {
        padding-left: 10px;
      }

      td {
        padding-top: 4px;
        padding-bottom: 4px;
      }

      .sub-total-row,
      .grand-total-row {
        th {
          font-weight: 700;
        }
      }

      .sub-total-row {
        span {
          font-size: 2.4rem;
          font-weight: 700;
        }

        th,
        td {
          padding-top: 8px;
          padding-bottom: 8px;
          border-top: 1px solid #ddd;
        }
      }

      .grand-total-row {
        background-color: #f2f2f2;

        span {
          font-size: 2.7rem;
          font-weight: 700;
          padding-left: 40px;
        }

        th {
          padding: 20px 0;
        }

        td {
          text-align: center;
        }
      }
    }
  }
}

.meatcows-count-div {
  max-width: 350px;
  min-height: 334px;
  background-color: #fff;

  flex: 1;
  flex-basis: 350px;

  tbody {
    td:last-child {
      padding-right: 14px;
    }

    .grand-total-row {
      td:last-child {
        padding-right: 0;
      }
    }
  }
}

.meatcows-count-div:nth-of-type(2) {
  margin-left: 10px;

  flex: none;
}

.fattening-stage-count-div {
  max-width: 400px;
  background-color: #fff;

  flex: 1;
  flex-basis: 400px;

  thead,
  tbody {

    th,
    td {
      &:nth-child(1) {
        width: 160px;
      }
      &:nth-child(2),
      &:nth-child(3) {
        width: 100px;
      }
      &:last-child {
        width: 40px;
        padding-left: 0;
      }
    }
  }

  .clickable {
    cursor: default;
  }
}

.reproduction-summary-box {
  max-width: 220px;
  background-color: #fff;

  table tbody {
    span {
      font-size: 2.1rem;
      font-weight: normal;
    }

    th,
    td {
      border-bottom: none;
    }

    .sub-total-row {
      span {
        font-size: 2.4rem;
        font-weight: 700;
      }

      th,
      td {
        border-top: 1px solid #ddd;
      }

      th {
        font-weight: 700;
      }
    }
  }
}

.table-simple,
.table-simple tr:nth-child(2n+1),
.table-simple th,
.table-simple tr th:first-child,
.table-simple td,
.table-simple tr:last-child td {
  font-size: 1.3rem;
  font-weight: normal;
  color: #333;
  border-top: none;
  border-right: none;
  border-left: none;
  background-color: transparent;
}

.table-simple tbody th,
.table-simple tbody td {
  border-bottom: 1px solid #ddd;
}

.table-simple tbody tr:last-child th,
.table-simple tbody tr:last-child td {
  border-bottom: none;
}

.table-simple tbody.clickable tr {
  th {
    width: 160px;
  }

  td.arrow {
    width: inherit;
    padding-top: 8px;
  }

  i {
    font-size: 1.3rem;
    font-weight: 400;
    display: none;
    display: inline-block;
    width: 35px;
    padding-right: 3px;
    color: #ddd;
  }

  &.clickable {
    &:hover {
      cursor: pointer;
      background-color: #f2f2f2;
    }

    th {
      color: #449dff;
    }

    i {
      color: #477d17;
    }
  }
}

.top--table-simple thead {
  text-align: center;

  tr th {
    padding: 0;
    padding-top: 5px;
  }
}

.top--table-simple tbody th,
.top--table-simple td {
  text-align: right;
}

.top--table-simple tbody td {
  width: 120px;
}

.top--table-simple td span {
  font-size: 2.7rem;
  font-weight: bold;
  padding-right: 7px;
}

.top--schedule-wrapper,
.top--temporary-group-view-wrapper {
  background-color: #fff;
  flex: 1;
  margin-left: 10px !important;
  padding: 0;

  @media #{$tablet-portrait} {
    margin-left: 0 !important;
  }
}

.top--schedule,
.top--temporary-group-view {
  background-color: #ffff;
  height: 100%;
  h4 {
    height: 36px;

    div.date {
      margin: 0px 3px;
    }

    button {
      &:hover {
        visibility: visible;
      }

      i {
        font-size: 1.6rem;
        color: #477d17;
      }
    }
  }

  dl {
    min-height: 24px;
  }

  dt {
    padding-left: 10px;

    flex-basis: 60%;
  }

  ul.child--list {
    dl {
      dt {
        width: 80px !important;
        margin: 0px;
        white-space: nowrap;
      }
      dd {
        margin: 0px;
      }
    }
  }

  .child--list {
    padding-left: 15px;
  }

  .link-disabled {
    cursor: default;
  }
}

.top--cool-pescon {
  background: #fff;
  cursor: pointer;
  display: flex;
  height: $cool-pescon-height;
  margin-bottom: $cool-pescon-margin-bottom;

  &:hover {
    background: #f6f6f6;
  }

  .cool-pescon--header {
    background: transparent;
    color: #8aa633;
    font-size: 15px;
    font-weight: bold;
    margin: 0;
    padding: 10px;
    vertical-align: middle;
  }

  .cool-pescon--content {
    display: flex;
    flex: 1;
    padding: 10px 0;
  }

  .cool-pescon--item {
    align-items: center;
    display: flex;
    flex: 1;
    justify-content: space-between;
    padding: 0 10px 0 15px;

    &:first-of-type {
      border-right: 1px solid rgba(90, 90, 90, 0.2);
    }
  }

  .cool-pescon--item-title {
    cursor: pointer;
  }

  .cool-pescon--item-count {
    font-size: 16px;
    font-weight: bold;
  }
}

.top--reproduction-probable-event {
  dt {
    padding-left: 10px;
  }
}

// 分娩アラート件数BOX

.top--alert-calving-box {
  margin-bottom: 16px;

  %__alert-calving-box-base {
    background: #fff;
    border: 1px solid #c02c94;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    padding: 4px 8px;
    min-height: 40px;
  }

  .alert-calving-box-both {
    @extend %__alert-calving-box-base;
  }

  .alert-calving-box-unconfirmed {
    @extend %__alert-calving-box-base;

    .calving-box-content-unconfirmed {
      background: none;
      margin-right: 0;

      &:hover {
        background: none;
      }

      &-icon {
        text-align: right;
        width: 35px;
      }
    }
  }

  .alert-calving-box-confirmed {
    @extend %__alert-calving-box-base;

    .calving-box-content-confirmed {
      background: none;
      margin-right: 0;

      &:hover {
        background: none;
      }

      &-icon {
        text-align: right;
        width: 35px;
      }
    }
  }

  .alert-calving-box-nothing {
    @extend %__alert-calving-box-base;

    border: none;
    border-radius: none;
    cursor: default;

    &:hover {
      background: none;
    }

    .calving-box-title {
      color: #ddd;
    }

    .calving-box-content-unconfirmed {
      background: none;
      cursor: default;
      margin-right: 0px;

      &:hover {
        background: none;
      }

      &-label {
        color: #ddd;
      }

      &-count {
        color: #ddd;
      }

      &-unit {
        color: #ddd;
      }

      &-icon {
        color: #ddd;
        text-align: right;
        width: 35px;
      }
    }
  }

  .calving-box-title {
    align-items: center;
    color: #c02c94;
    display: flex;
    width: auto;

    &-icon {
      padding-right: 10px;
    }

    &-label {
      font-size: 13px;
      font-weight: 700;
    }
  }

  %__calving-box-content-label-base {
    color: #555;
    display: inline;
    font-size: 12px;
    font-weight: 400;
    line-height: 1;
    text-align: center;
    padding: .2em .6em .3em;
    vertical-align: baseline;
    white-space: nowrap;
  }

  %__calving-box-content-count-base {
    color: #555;
    font-size: 18px;
    font-weight: 700;
  }

  %__calving-box-content-unit-base {
    color: #555;
    font-size: 12px;
    padding-left: 7px;
  }

  .calving-box-content {
    display: flex;

    @media screen and (max-width: 1200px) {
      flex-direction: column;

      &-unconfirmed {
        margin: 0 0 4px 0 !important;
      }
    }

    &-unconfirmed {
      align-items: center;
      background: #fdeff7;
      border-radius: 3px;
      cursor: pointer;
      display: flex;
      margin-right: 5px;
      padding: 0;

      &:hover {
        background: #f8e5f0;
      }

      &-label {
        @extend %__calving-box-content-label-base;
        font-weight: 400;
        color: #c02c94;
      }

      &-count {
        @extend %__calving-box-content-count-base;
        color: #c02c94;
      }

      &-unit {
        @extend %__calving-box-content-unit-base;
      }

      &-icon {
        color: #c02c94;
      }
    }

    &-confirmed {
      align-items: center;
      background: #f2f2f2;
      border-radius: 3px;
      cursor: pointer;
      display: flex;

      &:hover {
        background: #eaeaea;
      }

      &-label {
        @extend %__calving-box-content-label-base;
      }

      &-count {
        @extend %__calving-box-content-count-base;

        font-size: 17px;
        font-weight: normal;
      }

      &-unit {
        @extend %__calving-box-content-unit-base;
      }

      &-icon {
        color: #348000;
      }
    }
  }
}

// 分娩センサー脱落アラート件数BOX

.top--alert-calving-sensor-dropped-box {
  margin-bottom: 20px;
  margin-top: -8px;
}

@media #{$tablet-portrait} {

  .top--alerts,
  .counter--horizontal {
    flex-direction: column;
  }

  .counter--milk,
  .alerts--sickness {
    margin-top: 10px;
    margin-left: 0;
  }
}

.top--deposit {
  .fixed-tb {
    height: auto!important;
  }
}

.timeline-top {
  .timeline--head {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
  }

  .timeline--externalLabel {
    display: none;
  }

  .timeline--daysAgo {
    display: none;
  }
}
