/**
 *  Site wide defaults
 *  $1. Defaults
 */

/**
 *  $1. Defaults
 */

* {
  margin: 0;
  padding: 0;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

html {
  font-size: 10px;
  box-sizing: border-box;
}

body {
  font-family: "Segoe UI", "Yu Gothic UI", "Meiryo UI", YuGothic, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, sans-serif;
  position: relative;
  color: $textGrey;
  background-color: $body-bg;

  @media (pointer: coarse) {
    font-family: "Helvetica Neue", "Hiragino Kaku Gothic ProN", "Hiragino Sans", sans-serif;
  }
}