.u-loader {
  display: block;
  padding-top: 10px;
  text-align: center;

  &--backdrop {
    position: fixed;
    z-index: 999999999999;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, .9);

    align-items: center;
    justify-content: center;
  }

  svg {
    display: inline;
  }
}
