.calving-sensor-number {
  display: inline;
  margin-right: 10px !important;

  .first-number {
    width: 40px;
  }

  .last-number {
    width: 45px;
  }

  .value-changed {
    color: #ff4500;
  }
}
