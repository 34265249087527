.cow-shed {
  .content-box {
    height: auto;
    .left-adjust {
      .latest-data-nothing {
        text-align: center;
        line-height: 124px;
        font-weight: bold;
      }
      .stress {
        .number {
          span {
            color: #000;
            &.score-color1 { color : #418632; }
            &.score-color2 { color : #BABA00; }
            &.score-color3 { color : #ff3f3f; }
          }
        }
      }
      .number {
        .operation-status {
          color: #ff3f3f;
          font-size: 1.6rem;

          &--active {
            color: #0000ff;
          }
        }
      }
      .work-hours {
        color: #000;

        &--warning {
          color: #ff3f3f;
        }
      }
    }
    .right-adjust {
      .p10 {
        .data-nothing {
          text-align: center;
          width: 100%;
          height: 200px;
          line-height: 200px;
          display: none;
          font-size: 1.2rem;
        }
      }
      #stress {
        svg {
          g.c3-regions {
            g.c3-region {
              &.score-color1 { fill : #418632; }
              &.score-color2 { fill : #ffff00; }
              &.score-color3 { fill : #ff0000; }
            }
          }
        }
      }
    }
  }
}

.cowshed-cool-pescon {
  position: relative;

  &-loading {
    align-items: center;
    background: rgba(1,1,1,0.1);
    bottom: 0;
    cursor: wait;
    display: flex;
    justify-content: center;
    left: 0;
    right: 0;
    position: fixed;
    top: 0;
    z-index: 1;
  }
}

.cool-pescon-chart-box {
  position: relative;

  .chart-box-loading {
    align-items: center;
    background: rgba(1,1,1,0.1);
    bottom: 0;
    cursor: wait;
    display: flex;
    justify-content: center;
    left: 0;
    right: 0;
    position: absolute;
    top: 0;
    z-index: 1;
  }
}

.cool-pescon-chart-operation {
  background: #fff;
  height: 27px;
  margin: 0 0 10px 0;
  position: relative;

  .chart-date-operation {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;

    .current-date {
      margin: 0 5px;

      &-text {
        font-size: 15px;
        font-weight: 700;
      }
    }

    .prev-date {
      &-text {
        font-size: 15px;
        font-weight: 700;
      }

      &-icon {
        background-color: #ff9908;
        border-radius: 50%;
        color: #fff !important;
        display: inline-block;
        font-size: 14px !important;
        height: 14px;
        width: 14px;
      }
    }

    .next-date {
      &-text {
        font-size: 15px;
        font-weight: 700;
      }

      &-icon {
        background-color: #ff9908;
        border-radius: 50%;
        color: #fff !important;
        display: inline-block;
        font-size: 14px !important;
        height: 14px;
        width: 14px;
      }
    }
  }

  .chart-unit-operation {
    font-size: 0;
    display: inline-block;
    position: absolute;
    right: 5px;
    top: 5px;

    &-label {
      background: white;
      border: 1px solid rgba(1, 1, 1, 0.3);
      color: black;
      cursor: pointer;
      font-size: 1.2rem;
      text-align: center;
      width: 50px;

      &--right {
        border-radius: 0 5px 5px 0;
      }

      &--left {
        border-radius: 5px 0 0 5px;
      }

      &--active {
        background: $orange;
        border: 1px solid $orange;
        color: #fff;
      }
    }

    &-input {
      display: none;
    }
  }
}

.environment-chart-content {
  .chart-content-nothing {
    align-items: center;
    display: flex;
    height: 200px;
    justify-content: center;
  }
}
