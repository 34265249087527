.group-activity-scatter-plot {
  .group-activity-scatter-plot-menu {
    padding-top: 5px;
    margin-bottom: 10px;
  }
  .group-activity-scatter-plot-wrapper {
    display: flex;
    height: calc(100vh - 184px);
    overflow: auto;

    .clickable-list-area {
      width: 25%;
      height: calc(100vh - 184px);
      margin-right: 10px;

      .section {
        overflow: hidden;
        height: 100%;
        padding: 10px;
      }

      .row-clickable-list-outer {
        height: calc(100vh - 236px);
      }

      .row-clickable-list-body {
        .active {
          background-color: #f0f0f0 !important;;
        }
      }
    }

    .scatter-plot-area {
      width: 30%;
      min-width: 360px;
      height: calc(100vh - 184px);
      margin-right: 10px;

      .control-panel {
        flex: 1;
        .disabled {
          background-color: #ccc !important;
        }
      }
      .activity-charts {
        height: 100%;
        background-color: #fff;
        padding: 10px 10px 0px 10px;
        display: flex;
        flex-flow: column nowrap;
        min-width: 0;
        justify-content: center;
      }
      .chart-panel-wrapper {
        overflow: auto;

        .chart-panel {
          height: 390px;
        }
        .u-loader {
          padding-top: 0px;
        }
      }
      .c3-target-normal {
        circle {
          opacity: 0.4 !important;
        }
      }
    }

    .cow-board-wrapper {
      width: 0px;
      height: calc(100vh - 184px);
      float: left;

      &.cow-board-opened {
        width: 460px;
      }
      .cow-board {
        height: 100%;
        width: 100%;
        top: 0px;
        right: 0px;
        transform: translateX(460px);
        &.open {
          transform: none;
        }
      }
    }
  }
}
