/*---------------------------------
　テーブル
---------------------------------*/

/*
　テーブル
---------------------------------*/
.uTable {
  position: relative;

  .uContent & {
    @media (max-width: 1324px) {
      overflow: hidden;
      overflow-x: scroll;
      margin: 0 -24px;

      &::-webkit-scrollbar {
        width: 18px;
      }

      &::-webkit-scrollbar-button {
        display: none;
      }

      &::-webkit-scrollbar-track {
        background: #272727;
      }

      &::-webkit-scrollbar-thumb {
        height: 17px;
        border: 2px solid #272727;
        border-radius: 10px;
        background: #c8c9c9;

        &:hover {
          background: #858585;
        }
      }
    }

    @media (pointer: coarse) and (max-width: 1250px) {
      overflow-y: scroll;
      margin: 0 -24px;
    }
  }

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: calc(100% - 18px);
    height: 51px;
    content: '';
    border-bottom: 1px solid #ddd;
    border-radius: 4px 4px 0 0;
    background: linear-gradient(to right, #f2f2f2, #eee) !important;

    .uContent & {
      @media (max-width: 1324px) {
        left: 24px;
        width: 1087px;
      }

      @media (pointer: coarse) and (max-width: 1250px) {
        left: 24px;
        width: 1105px;
      }
    }
  }

  &__outer {
    overflow: hidden;
    border-radius: 4px;

    .uContent & {
      @media (max-width: 1324px), (pointer: coarse) and (max-width: 1250px) {
        width: 1153px;
        padding: 0 24px;
      }
    }

    &--height500 {
      overflow: hidden;
      height: 500px;
      border-radius: 4px;

      .uContent & {
        @media (max-width: 1324px), (pointer: coarse) and (max-width: 1250px) {
          width: 1153px;
          padding: 0 24px;
        }
      }

      .uTable__inner {
        max-height: 500px;
      }
    }
  }

  .uTable__inner {
    overflow: scroll;
    border-radius: 4px;
    background: white(primary);

    &::-webkit-scrollbar {
      z-index: 1000;
      width: 18px;background: #272727;
    }

    &::-webkit-scrollbar-button {
      display: none;
    }

    &::-webkit-scrollbar-track {
      background: #272727;
    }

    &::-webkit-scrollbar-thumb {
      height: 17px;
      border: 2px solid #272727;
      border-radius: 10px;
      background: #c8c9c9;

      &:hover {
        background: #858585;
      }
    }

    &::-webkit-scrollbar-corner {
      background: #272727;
    }
  }

  &__main {
    width: 100% !important;
    border-spacing: 0 !important;
    border-collapse: collapse !important;
    border: none !important;
  }

  &__row {
    background: none !important;
  }

  &__heading {
    position: sticky;
    top: 0;
    height: 51px;
    min-height: auto !important;
    padding: 22px 10px 0 !important;
    text-align: left !important;
    vertical-align: top !important;
    border: none !important;
    background: none !important;

    &:first-child {
      padding-left: 24px !important;
    }

    &:last-child {
      padding-right: 24px !important;
    }

    &Text {
      font-size: 13px !important;
      font-weight: 700 !important;
      white-space: nowrap;
      color: black(secondary) !important;
    }
  }

  &__body {

    .uTable__row,
    .uTable__row--grouping,
    .uTable__row--doublet {
      border-top: 1px solid #eee;
      background: none !important;

      &:first-child {
        border-top: none;

        .uTable__cell {
          height: 42px !important;
          padding-top: 6px !important;
        }
      }

      &:last-child {
        .uTable__cell {
          height: 42px !important;
          padding-bottom: 6px !important;
        }
      }
    }

    .uTable__row {
      &--grouping {
        border-top: none;
      }

      &--doublet {
        border-top: 3px double #eee;
      }
    }
  }

  &__cell {
    height: 36px;
    min-height: auto !important;
    padding: 0 10px !important;
    vertical-align: middle !important;
    border: none !important;

    &:first-child {
      padding-left: 24px !important;
    }

    &:last-child {
      padding-right: 24px !important;
    }

    &--noData {
      font-size: 14px !important;
      height: 52px !important;
      min-height: auto !important;
      padding: 0 0 0 24px !important;
      vertical-align: middle !important;
      color: black(secondary);
      border: none !important;
    }

    &Inner {
      display: flex;

      align-items: center;

      .uTable__cellLabel {

        &--primary,
        &--secondary {
          margin: 0 0 0 8px;
        }
      }
    }

    &Text {
      font-size: 14px !important;
      color: black(primary);
    }

    &Link {
      font-size: 14px !important;
    }

    &Label {

      &--primary {
        font-size: 13px !important;
        font-weight: 700;
        line-height: 1.2;
        display: inline-block;
        padding: 3px 6px;
        white-space: nowrap;
        color: #ce2587;
        border: 1px solid #e557d5;
        border-radius: 4px;
      }

      &--secondary {
        font-size: 13px !important;
        line-height: 1.2;
        display: inline-block;
        padding: 3px 6px;
        white-space: nowrap;
        color: black(primary);
        border: 1px solid #f0f0f0;
        border-radius: 4px;
        background: #f0f0f0;
      }
    }
  }
}
