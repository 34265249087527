@media print {
  .cow-list {
    .tabs {
      padding: 0 !important;
    }
    .tab-content {
      margin-top: 3px !important;
    }
    .scroll-outer {
      overflow: visible !important;
      section.result {
        padding: 0 !important;
      }
    }
    .content-box {
      padding-top: 0 !important;
      .p10 { padding: 0 !important; }
    }
    table {
      width: auto!important;
      max-width: 100%;
      margin: 0 auto !important;
      border-color: #333 !important;
      overflow: visible !important;
      &.clone {
        display: none !important;
      }
    }
    thead {
      visibility: visible !important;
    }
    .fixed-tb {
      overflow: visible !important;
      height: 100% !important;
    }
    th {
      font-weight: normal;
      padding: 3px !important;
      color: #000 !important;
      border-color: #333 !important;
      border-right: 1px dotted #888 !important;
      background-color: #bbb !important;
      width: initial !important;
      &:last-child {
        border-right: 1px solid #333 !important;
      }
    }

    td {
      width: initial !important;
      border-color: #333 !important;
      border-right: 1px dotted #888 !important;
      &:last-child {
        border-right: 1px solid #333 !important;
      }
    }

    select {
      border: none !important;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
    }

    select::-ms-expand {
        display: none;
    }
  }
}
