.u-alerts-settings {
  background-color: $grey;
  display: flex;
  position: relative;
  width: 100%;
}

.u-alert-form {
  background-color: #fff;
  flex: 1 0 auto;
  padding: 10px;

  &--alert {
    &:not(:last-of-type) {
      margin-bottom: 30px !important;
    }
  }

  &--header {
    background-color: #f6f6f6;
    display: flex;
    align-items: center;

    &-label {
      background-color: transparent;
    }

    .btn {
      margin-left: auto;
      margin-right: 10px;
    }
  }

  &--field {
    display: flex;
    padding: 10px 0;
    border-bottom: 1px solid #ddd;

    &:last-of-type {
      border-bottom: none;
    }

    &-label {
      width: 160px;
      text-align: right;

      &.moon-age {
        line-height: 33px;
        margin: 0;
      }
    }
    &-content {
      margin-left: 30px !important; // .barnのせい
      p + p {
        margin-top: 10px;
      }
      .checkbox-groups {
        display: flex;
      }
      .third {
        width: 150px;
      }
      textarea.u-alert-longtext { // .barn消せたらtextarea指定不要
        background-color: #f6f4f4;
        width: 350px;
        height: 50px;
      }
      input {
        &:disabled {
          border: none;
          box-shadow: none;
        }
      }
      &-label {
        width: 65px;
        display: inline-block;
      }
      &.moon-age {
        height: 30px;
        line-height: 30px;

        .use-moon-age {
          vertical-align: middle;
          margin: 0;
        }
      }
      &.heat-index-rate {
        .cowshed-names {
          border: dashed 1px;
          border-radius: 5px;
          margin-bottom: 5px;
          padding: 5px;
          width: 350px;
        }

        .range-caption {
          .right-caption {
            margin-left: 180px;
          }
        }

        .input-range[type="range"] {
          width: 205px;

          &:focus,
          &:active {
            outline: none;
          }

          &::-ms-tooltip {
            display: none;
          }
        }

        #intensity-list {
          display: flex;
          width: 205px;

          justify-content: space-between;
        }

        .note-tx {
          margin-left: 2px;
          width: 300px;
          color: #999;
        }
      }
      .heat-detection-event {
        .event-detection-type {
          width: 185px;
        }
      }

      &.calving-detection-threshold {
        .note-tx {
          margin-left: 2px;
          width: 320px;
          color: #999;
        }
      }

      &.show-acute-illness {
        .precautions {
          background: #f0f0f0;
          margin: 12px 0 10px 0;
          padding: 12px 12px 10px 12px;
          width: 350px;

          &-label {
            color: #bc0822;
            margin: 0;

            &:last-of-type {
              margin: 0 0 10px 0;
            }
          }

          &-text {
            margin: 0;
          }
        }
      }
    }
  }
}

.u-alert--spaced-text {
  display: inline-block;
  width: 65px;
}

// 起立困難アラート設定

.alert-dysstasia-config-view {
  .alert-dysstasia-config-threshold {
    &-default {
      margin-bottom: 20px;
      width: 350px;
    }

    &-option {
      .note-tx {
        padding: 4px 0;
        margin: 0 0 5px 0;
      }

      .threshold-label {
        font-size: 13px;
        border-bottom: 1px solid #000;
        margin-bottom: 10px;
      }

      .threshold-content {
        margin-bottom: 20px;

        .breed-selection {
          border: 1px dashed;
          border-radius: 5px;
          margin-bottom: 5px;
          padding: 5px;
          width: 350px;
        }
      }
    }
  }
}

.alert-dysstasia-config-edit-form {
  .alert-dysstasia-config-threshold {
    &-default {
      margin-bottom: 20px;
      width: 350px;
    }

    &-option {
      .threshold-header {
        align-items: baseline;
        display: flex;
        font-weight: 700;
        justify-content: flex-end;
        margin-bottom: 8px;
      }

      .threshold-label {
        font-size: 13px;
      }

      .threshold-content {
        border-bottom: 1px solid #ddd;
        padding: 5px 0 10px 0;
        margin-bottom: 10px;

        .breed-selection {
          margin-bottom: 8px;

          &-header {
            align-items: baseline;
            display: flex;
            justify-content: space-between;
            margin-bottom: 4px;
          }

          &-content {
            align-items: center;
            display: flex;
            justify-content: space-between;

            .breed-selection-text {
              flex: 1;
              margin-right: 3px;

              &:disabled {
                background: #f6f4f4;
                border: 1px solid #ccc;
              }
            }
          }
        }

        &:last-child {
          border-bottom: none;
        }
      }
    }
  }
}

.alert-dysstasia-config-edit-threshold {
  .month-age-to {
    &:disabled {
      background: #f6f4f4;
      border: 1px solid #ccc;
    }
  }

  .errors {
    .error-message {
      color: #ff573f;
      display: block;
      padding: 2px;
    }
  }

  .note-tx {
    margin-left: 2px;
    width: 300px;
    color: #999;
  }
}
