/*---------------------------------
　ノーティフィケーション
---------------------------------*/
.uNotice {
  color: white(primary);

  &__list {
    margin: 0 !important;
  }

  &__item {
    display: flex;

    &Button {
      position: relative;
      padding: 10px 20px 6px;

      flex: 0 0 auto;

      &:hover {
        &::before {
          position: absolute;
          top: calc(50% - 16px);
          left: calc(50% - 16px);
          display: block;
          width: 32px;
          height: 32px;
          content: '';
          opacity: 0.24;
          border-radius: 50%;
          background: #eee;
        }
      }

      .uNotice__itemIcon {
        position: relative;
        width: 14px;
        height: 14px;
        margin: 0 !important;
        border: 1px solid transparent;

        &::before,
        &::after {
          position: absolute;
          top: 40%;
          right: -25%;
          display: block;
          width: 18px;
          height: 2px;
          content: "";
          background: white(primary);
        }

        &::before {
          transform: rotate(45deg);
          transform-origin: 50%;
        }

        &::after {
          transform: rotate(-45deg);
          transform-origin: 50%;
        }
      }
    }

    &Icon {
      display: inline-block;
      width: 24px;
      margin: 0 13px 0 0 !important;
      text-align: center;

      flex: 0 0 auto;
    }

    &Label {
      font-weight: 700;
      margin: 0 16px 0 0 !important;

      flex: 0 0 auto;
    }

    &Lead {
      font-size: inherit !important;
    }

    &Link {
      font-size: 15px !important;
      line-height: 1.6;
      display: flex;
      width: 100%;
      padding: 0 17px 0 21px;
      color: white(primary);

      align-items: center;

      &:hover {
        text-decoration: none;
        color: white(primary);
      }
    }

    &:hover {
      background: linear-gradient(90deg, #455200 0%, #254012 100%);
    }

    &--incident {
      display: flex;
      background: linear-gradient(90deg, #3f027b 0%, #280069 100%);

      &:hover {
        background: linear-gradient(90deg, #20055d 0%, #290048 100%);

        .uNotice__itemButton {
          &::before {
            position: absolute;
            top: calc(50% - 16px);
            left: calc(50% - 16px);
            display: block;
            width: 32px;
            height: 32px;
            content: '';
            opacity: 0.24;
            border-radius: 50%;
            background: #eee;
          }
        }
      }

      .uNotice__itemLink {
        min-height: 95px;
      }
    }

    &--maintenance {
      display: flex;
      background: linear-gradient(90deg, #324b7b 0%, #074081 100%);
      box-shadow: inset 0px 3px 6px rgba(0, 0, 0, 0.16);

      &:hover {
        background: linear-gradient(90deg, #223c6e 0%, #043872 100%);
      }

      .uNotice__itemLink {
        min-height: 40px;
      }
    }

    &--update {
      display: flex;
      background: linear-gradient(90deg, #0d83a0 0%, #2d70be 100%);
      box-shadow: inset 0px 3px 6px rgba(0, 0, 0, 0.16);

      &:hover {
        background: linear-gradient(90deg, #036e88 0%, #1055a5 100%);
      }

      .uNotice__itemLink {
        min-height: 40px;
      }
    }

    &--information {
      display: flex;
      background: linear-gradient(90deg, #587c06 0%, #216a00 100%);
      box-shadow: inset 0px 3px 6px rgba(0, 0, 0, 0.16);

      &:hover {
        background: linear-gradient(90deg, #3e5800 0%, #216000 100%);
      }

      .uNotice__itemLink {
        min-height: 40px;
      }
    }
  }
}