
.history {
  .table {
    ul {
      margin-bottom: 10px;
    }
    li.category {
      width: 150px;
      font-weight: bold;
      font-size: 16px;
      padding-top: 7px;
      vertical-align: top;
    }
    .selection {
      display: table;
      text-align: left;
      width: 100%;
      .selection-item {
        position: relative;
        list-style: none;
        display: inline-block;
        text-align: center;
        margin: 10px;
        &.breeding {
          background-color: #56b1bc;
        }
        &.medical {
          background-color: #bc7e9c;
        }
        &.other {
          background-color: #ace8ba;
        }
      }
    }
  }
  a.circle-btn {
    width: 130px;
    height: 90px;
    display: inline-block;
    text-decoration: none;
    padding-top: 20px;
    border: solid #333 3px;
    border-radius: 10%;
    color: #333;
    font-size: 26px;
    font-weight: bold;
    &:hover {
      background-color: #ace8ba;
    }
  }
}
.cow-detail .box-history.individual-history .box-history-innner { height: calc(100vh - 183px); }

.box-history {
  position: fixed;
  right: 0;
  width: calc(33.33% - 50px);
  .mb-0 {margin-bottom: 0px;}
  .box-history-innner { height: calc(100vh - 392px); overflow: auto; }
  &.individual-history .box-history-innner { height: calc(100vh - 168px); }
  .individual-history h4 { margin-bottom: 0; }
  h4 {
    position: relative;
    padding-left: 3.6rem;
    i {
      position: absolute;
      left: 5px;
      top: 6px;
    }
  }
  > div {
    overflow-y: auto;
  }
  th .required { position: absolute; }
  .filtering-wrapper {
    position: relative;
    margin-left: 36px;
    padding-top: 10px;
    text-align: right;
    border-left: 3px solid #ddd;
  }
  .filtering {
    position: relative;
    right: 10px;
    top: 0px;
    display: inline-block;
    margin-right: 10px;
    padding: 2px 18px 2px 6px;
    color: #333;
    font-size: 1rem;
    border: 1px solid #eaeaea;
    //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
    border-radius: 6px;
    background-color: #fff;
    &:hover {
      text-decoration: none;
    }
    i {
      position: absolute;
      right: 2px;
      color: $orange;
      padding-left: 3px;
      font-size: 1.4rem;
    }
  }
  .filtering-top {
    position: absolute;
    right: 0px;
    top: 9px;
    display: inline-block;
    margin-right: 10px;
    padding: 2px 18px 2px 6px;
    color: #333;
    font-size: 1rem;
    border: 1px solid #eaeaea;
    //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
    border-radius: 6px;
    background-color: #fff;
    &:hover {
      text-decoration: none;
    }
    i {
      position: absolute;
      right: 2px;
      color: $orange;
      padding-left: 3px;
      font-size: 1.4rem;
    }
  }
  .filter-list {
    display: none;
    position: relative;
    margin: 10px 10px 0;
    padding: 10px;
    border: 1px solid #ccc;
    background-color: #f7f7f7;
    &:before {
      display: block;
      content: "";
      position: absolute;
      top: -12px;
      right: 41px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 8px 12px;
      border-color: transparent transparent #ccc transparent;
    }
    &:after {
      display: block;
      content: "";
      position: absolute;
      top: -10px;
      right: 41px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0px 8px 12px;
      border-color: transparent transparent #f7f7f7 transparent;
    }
    p {
      position: absolute;
      left: 8px;
      top: 8px;
      display: inline-block;
      width: auto;
      margin-bottom: 5px;
      border: 1px solid #eaeaea;
      background: #fff;
      border-radius: 6px;
      a {
        padding: 0 7px 0 22px;
        font-size: 1rem;
        color: #333;
        &:hover {
          text-decoration: none;
        }
      }
    }
    ul {
      text-align: left;
      &.mt25 { margin-top: 25px; }
      li {
        display: inline-block;
        width: 50%;
        margin-left: -3px;
        margin-bottom: 3px;
        text-align: left;
        input {
          vertical-align: middle;
        }
      }
    }
    // 旧アイコンフォント調整
    .icon-s-check-box-outlined {
      font-size: 10px !important;
      position: absolute;
      top: 4px;
      left: 6px;
    }
  }
  .timeline {
    clear: both;
    position: relative;
    margin-left: 36px;
    padding-top: 10px;
    padding-bottom: 25px;
    border-left: 3px solid #ddd;
    li {
      position: relative;
      width: 87%;
      margin-left: 26px;
      margin-bottom: 16px;
      &:last-child {
        margin-bottom: 0;
      }
      &:before, &:after {
        content: "";
        position: absolute;
        left: -27px;
        top: 50%;
        display: block;
        background-color: #ddd;
      }
      &:before {
         width: 27px;
         height: 3px;
       }
       &:after {
         width: 8px;
         height: 8px;
         margin-top: -2px;
         margin-left: -4px;
         border-radius: 50%;
         background-color: #fff;
         border: 2px solid #ddd;
       }
       .timeline-content-wrapper {
         position: relative;
         display: table;
         width: 100%;
         border: 2px solid #ddd;
         .comment-item dt, .comment-item dd {
           min-height: 31px;
           .horizontal-item + .horizontal-item {
             margin-left: 5px;
           }
         }
         .comment-item dl section:first-child dd{
           border-top: none;
         }
         .comma-seperation-group .comma-seperation-group-item:last-of-type .comma-seperation {
           display: none;
         }
       }
       .data-box {
         position: relative;
         display: table-cell;
         /*  float: left; */
         width: 15%;
         padding-top: 5px;
         vertical-align: middle;
         background-color: #f7f7f7;
       }
       .data {
         font-size: 1.2rem;
         font-weight: bold;
         text-align: center;
         line-height: 1.1;
       }
       .data-box {
         &:before, &:after {
           content: "";
           position: absolute;
           left: -2px;
           display: block;
           height: 2px;
         }
         &:before {
           top: -2px;
         }
         &:after {
           bottom: -2px;
         }
       }
       .category01 .data-box {
         &:before, &:after {
           background-color: #008c99;
         }
       }
       .category02 .data-box {
         &:before, &:after {
           background-color: #f56e9d;
         }
       }
       .category03 .data-box {
         &:before, &:after {
           background-color: #df6feb;
         }
       }
       .category04 .data-box {
         &:before, &:after {
           background-color: #826ef5;
         }
       }
       .category05 .data-box {
         &:before, &:after {
           background-color: #6ebff5;
         }
       }
       .category06 .data-box {
         &:before, &:after {
           background-color: #4dab8a;
         }
       }
       .category07 .data-box {
         &:before, &:after {
           background-color: #bca446;
         }
       }
       .category08 .data-box {
         &:before, &:after {
           background-color: #2775be;
         }
       }
       .category09 .data-box {
         &:before, &:after {
           background-color: #1C90FD;
         }
       }
       .category10 .data-box {
         &:before, &:after {
           background-color: #666;
         }
       }
       .category11 .data-box {
         &:before, &:after {
           background-color: #666;
         }
       }
       .category12 .data-box {
         &:before, &:after {
           background-color: #826ef5;
         }
       }
       .category-all .data-box {
         &:before, &:after {
           background-color: #7b9915;
         }
       }
       .dropdown-menu {
        z-index: 1;
       }
    }
  }
  .post-wrapper {
    width: 92%;
    height: 50px;
    margin: 0 auto;
    text-align: center;
    border: 3px dotted #ccc;
    //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
    border-radius: 5px;
    i {
      padding-bottom: 2px;
      font-size: 2rem;
      color: #333;
    }
    a.box-post {
      display: block;
      height: 100%;
      color: #333;
      line-height: 44px;
      &:hover {
        text-decoration: none;
        background-color: #eee;
      }
    }
  }
  .right-shoulder h4 {transition: all 0.5s ease 0s;}
  .right-shoulder h4 .tag-inputting {
    display: none;
    margin-left: 9px;
    padding: 5px;
    color: #8aa633;
    background-color: #fff;
    animation: flash 3.5s infinite linear;
  }
  @keyframes flash {
    0% { opacity: 0.5; }
    50% { opacity: 1; }
    100% { opacity: 0.5; }
  }
  .normal-type, .right-shoulder .edit-btn {display: block; }
  .right-shoulder .white-btn, .box-event-input { display: none; }
  &.on-event-input .right-shoulder .edit-btn,
  &.on-event-input .normal-type,
  &.on-event-input .event-input-step02 { display: none; }
  &.on-event-input .right-shoulder .white-btn,
  &.on-event-input .box-event-input { display: block; }

  // 旧アイコンフォント調整
  .right-shoulder .edit-btn i {
    font-size: 15px !important;
  }
  &.on-event-input {
    .right-shoulder {
      h4 {
        margin-bottom: 0px;
        color: #fff !important;
        background-color: #8aa633;
        .tag-inputting { display: inline; }
      }
      .white-btn {
        position: absolute;
        top: 5px;
        right: 6px;
        &:hover { opacity: 1; }
      }
    }
    .box-history-innner {
      border: 4px solid #8aa633;
      border-top: none;
    }
    .box-event-input {
      position: relative;
      z-index: 2;
      height: 100%;
      background: #fff;
      padding-top: 20px;
      #event-input-step
      .box-event-btn, .box-event-btn a { color: #1298ba; }
      .box-event-btn.breeding-event,.box-event-btn.breeding-event a { color: #e3659a; }
      .box-event-btn.other-event, .box-event-btn.other-event a { color: #999636; }
      td div.input-item {
        span {
          display: inline-block;
          width: 80px;
          text-align: right;
          margin: 0 0.5em;
        }
        input, select {
          width: 140px;
        }
      }
      .et-input-type-container label {
        font-weight: normal;
      }
    }
    .read-tx {
      margin: 0 10px 20px 20px;
      font-size: 1.4rem;
      font-weight: bold;
    }
    .box-event-btn {
      margin: 10px 10px 25px;
      &:nth-last-of-type(1) { margin-bottom: 0; }
      p {
        position: relative;
        margin: 0 0 10px 8px;
        font-size: 1.5rem;
        font-weight: bold;
      }
      &.breeding-event p i { color: #e3659a; font-size: 2.8rem; }
      .other-event p i { color: #999636; font-size: 2.6rem; }
      ul { padding-bottom: 12px; background-color: #ededed; }
      ul li {
        float: left;
        width: calc(100% / 4 - 20px);
        height: auto;
        margin: 10px 10px 0;
        font-weight: bold;
        line-height: 1;
        text-align: center;
        border-radius: 3px;
        background-color: #fff;
        box-shadow: 0 1px 2px 0 rgba(90, 90, 90, 0.3);
        a {
          display: block;
          width: 100%;
          height: 50px;
          padding-top: 19px;
          font-size: 1.3rem;
          box-sizing: border-box;
          &:hover {
            color: #fff;
            text-decoration: none;
            border-radius: 3px;
            background-color: #1298ba;
          }
        }
      }
      ul li.multi-lines a { padding-top: 9px; line-height: 1.3; }
      &.breeding-event ul li a:hover { color: #fff; background-color: #e3659a; }
      &.other-event ul li a:hover { color: #fff; background-color: #999636; }
    }
    .event-input-step01, .event-input-step02 { display: none; height: 100%; position: relative;}
    .edit-regist-list01 dl { margin-top: 0; }
    .event-input-step02 {
      .edit-regist-list01 { padding-left: 7px; }
      .table-wrapper {
        height: calc(100% - 146px);
        margin: 0 10px;
        padding-left: 0;
        padding-right: 0;
        overflow: auto;
        th { width: 135px; }
        td select,td textarea { width: 100%; }
        td select.min-size { width: 5em; }
        td select.middle-size { width: 10em; }
        td textarea { height: 8em; }
        td .w-date {width:115px;}
        td select.w-time {width: 50px;}
        td .m-right8 {margin-right: 8px;}
        td .m-left8 {margin-left: 8px;}
        td .m-right10 {margin-right: 10px;}
        td .va-middle {vertical-align:middle;}
        td .workerName {width: 185px;}
        td .mastitis-cow-group-id {width: 185px;}
        td .mastitis-bacteria {width: 150px;}
        td .mastitis-score {width: 150px;}
        td .breast-group {float: left;}
        td .breast-mr {margin-right: 20px;}
        td .lameness-group {float: left;}
        td .lameness-mr {margin-right: 20px}
        td .lameness-mr {margin-right: 20px}
        td select.lame-affected-limb {width: 60px;}
        td .lame-affected-part select {width: 70px;}
        td .lame-affected-part label { font-weight: inherit; }
        td .lame-affected-part input[type="checkbox"] { margin: 0px 2px; }
        td .lame-diagnosis-result { margin-bottom: 14px; }
        td select.claw-disease-name {width: 130px;}
        td select.lame-diagnosis-result-of-hoof {width: 90px;}
      }
      .btn-box {
        position: absolute;
        bottom: 0px;
        width: 100%;
        padding: 15px 10px;
        background-color: #ededed;
        li {
          display: inline-block;
          margin-right: 18px;
          :nth-last-of-type(1) { margin-right: 0; }
          button {
            min-width: 100px;
            height: auto;
            padding: 14px 10px;
            font-size: 1.4rem;
            font-weight: bold;
            line-height: 1;
            &.white-btn { color: #555; }
            &:disabled {
              background-color: #8d8d8d;
              pointer-events: none;
            }
          }
        }
      }
    }
    .horizontal-radio {
      display:inline;
    }
  }
  .timeline-balloon {
     position: relative;
     display: table-cell;
     /*  float: right;  */
     width: 85%;
     padding: 8px;
     background: #fff;
     z-index: 0;
     .timeline-balloon-toggle-contents {
       display: none;
     }
     &.togglable {
       &:hover {
         cursor: pointer;
       }
       .timeline-balloon-toggle-contents {
         display: block;
         text-align: right;

         a {
           color: #449dff;

           &:hover,
           &:focus {
             color: #23527c;
           }
         }
       }
     }
     .timeline-balloon-top {
       position: relative;
     }
     .category-title {
       display: inline-block;
       margin-bottom: 3px;
       padding: 2px 4px;
       font-size: 1rem;
       color: #fff;
     }
     .medicines {
       display: flex;
     }
     .comment {
       word-break: break-all;
       overflow-wrap: break-word;
       max-height: 0px;
       overflow: hidden;
       transition: max-height 0.5s ease;
       p {
         margin-top: 5px;
       }
       .add-tx {
         display: none;
       }
       .expand-open {
         display: block;
         margin-top: 5px;
         &.add-close:before {
           content: "\e903";
           font-family: 'icomoon';
           font-size: 1.4rem;
           vertical-align: middle;
           line-height: 1;
         }
       }
     }
     .comment-item-comment {
       border: 1px dotted #ccc;
       border-radius: 3px;
       padding: 3px 5px;
       margin: 5px 0px;
     }
     .pulldown-btn {
       position: absolute;
       right: 3px;
       top: 3px;
       display: block;
       width: 1.6rem;
       height: 1.6rem;
       line-height: 0;
       text-align: center;
       border-radius: 3px;
       background-color: #dfdfdf;

       // 旧アイコンフォント調整
       .icon-l-arrow-A-d01 {
         font-size: 15px !important;
       }

       .icon-css-arrow {
         position: absolute;
         top: 3px;
         left: 4px;
         display: block;
         width: 6px;
         height: 6px;
         transform: rotate(135deg);
         border-top: 1px solid #333;
         border-right: 1px solid #333;
       }
     }
   }
   .pulldown-menu {
     position: absolute;
     float:right;
     right: 2px;
     left:auto;
     min-width:5.5em;
     top: 19px;
     width: 5.5em;
     height: auto;
     padding: 5px;
     text-align: center;
     background-color: #fff;
     border: 2px solid #b2b2b2;
     //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
     box-shadow: 2px 2px 0 0 rgba(150, 150, 150, 0.2);
     a {
       display: block;
       margin-bottom: 5px;
       padding-bottom: 5px;
       color: #333;
       border-bottom: 1px solid #ddd;
       &:last-child {
         margin-bottom: 0;
         padding-bottom: 0;
         border-bottom: none;
       }
       &:hover {
         text-decoration: none;
       }
       i {
         padding-right: 5px;
       }
     }

    // 旧アイコンフォント調整
    .icon-s-edit {
      font-size: 11px!important;
    }

    // 旧アイコンフォント調整
    .icon-s-delete {
      font-size: 11px!important;
    }
   }
   .more {
     position: relative;
     display: block;
     width: 100%;
     height: 37px;
     margin-top: -37px;
     color: #333;
     font-size: 1.1rem;
     text-align: center;
     line-height: 37px;
     background-color: rgba(215, 215, 215, 0.5);
     &:hover {
       text-decoration: none;
     }
     i {
       color: $orange;
       font-size: 1.6rem;
     }
   }

  .timeline li {
    .category01 {
      border-left-color: #008c99;

      .timeline--eventNameWrapper {
        border-color: #008c99;
      }

      .category-title {
        background-color: #008c99;
      }

      .timeline--externalLabel {
        color: #008c99;
      }
    }

    .category02 {
      border-left-color: #f56e9d;

      .timeline--eventNameWrapper {
        border-color: #f56e9d;
      }

      .category-title {
        background-color: #f56e9d;
      }

      .timeline--externalLabel {
        color: #f56e9d;
      }
    }

    .category03 {
      border-left-color: #df6feb;

      .timeline--eventNameWrapper {
        border-color: #df6feb;
      }

      .category-title {
        background-color: #df6feb;
      }

      .timeline--externalLabel {
        color: #df6feb;
      }
    }

    .category04 {
      border-left-color: #826ef5;

      .timeline--eventNameWrapper {
        border-color: #826ef5;
      }

      .category-title {
        background-color: #826ef5;
      }

      .timeline--externalLabel {
        color: #826ef5;
      }
    }

    .category05 {
      border-left-color: #6ebff5;

      .timeline--eventNameWrapper {
        border-color: #6ebff5;
      }

      .category-title {
        background-color: #6ebff5;
      }

      .timeline--externalLabel {
        color: #6ebff5;
      }
    }

    .category06 {
      border-left-color: #4dab8a;

      .timeline--eventNameWrapper {
        border-color: #4dab8a;
      }

      .category-title {
        background-color: #4dab8a;
      }

      .timeline--externalLabel {
        color: #4dab8a;
      }
    }

    .category07 {
      border-left-color: #bca446;

      .timeline--eventNameWrapper {
        border-color: #bca446;
      }

      .category-title {
        background-color: #bca446;
      }

      .timeline--externalLabel {
        color: #bca446;
      }
    }

    .category08 {
      border-left-color: #2775be;

      .timeline--eventNameWrapper {
        border-color: #2775be;
      }

      .category-title {
        background-color: #2775be;
      }

      .timeline--externalLabel {
        color: #2775be;
      }
    }

    .category09 {
      border-left-color: #1c90fd;

      .timeline--eventNameWrapper {
        border-color: #1c90fd;
      }

      .category-title {
        background-color: #1c90fd;
      }

      .timeline--externalLabel {
        color: #1c90fd;
      }
    }

    .category10 {
      border-left-color: #666666;

      .timeline--eventNameWrapper {
        border-color: #666666;
      }

      .category-title {
        background-color: #666666;
      }

      .timeline--externalLabel {
        color: #666666;
      }
    }

    .category11 {
      border-left-color: #666666;

      .timeline--eventNameWrapper {
        border-color: #666666;
      }

      .category-title {
        background-color: #666666;
      }

      .timeline--externalLabel {
        color: #666666;
      }
    }

    .category12 {
      border-left-color: #826ef5;

      .timeline--eventNameWrapper {
        border-color: #826ef5;
      }

      .category-title {
        background-color: #826ef5;
      }

      .timeline--externalLabel {
        color: #826ef5;
      }
    }

    .category13 {
      border-left-color: #f56e9d;

      .category-title {
        font-size: 1.2rem !important;
        font-weight: 700;
        padding-left: 0 !important;
        color: #df4278 !important;
      }
    }

    // 旧アイコンフォント調整
    .category-facility-box {
      line-height: 1;
      position: absolute;
      top: 50%;
      left: -29px;
      width: 33px;
      height: 33px;
      margin-top: -1.5rem;
      margin-left: -1.5rem;
      padding: 5px;
      border-radius: 50%;
      background-color: #7b9915;

      .icon-m-facility {
        color: #fff;
      }
    }
  }

  div.row {
    padding: 4px;
    margin-top: 4px;
    span.text {
      border: 1px dotted gray;
      padding: 0.5rem;
    }
    button.remove {
      margin-left: 4px;

      .icon-css-cancel {
        position: relative;
        display: inline-block;
        width: 12px;
        height: 12px;
        margin: 0 2px 0 0;
        vertical-align: top;

        &::before {
          position: absolute;
          top: 9px;
          left: 0;
          display: block;
          width: 10px;
          height: 1px;
          content: '';
          transform: rotate(45deg);
          background-color: #333;
        }

        &::after {
          position: absolute;
          top: 9px;
          left: 0;
          display: block;
          width: 10px;
          height: 1px;
          content: '';
          transform: rotate(-45deg);
          background-color: #333;
        }
      }
    }
  }
}
