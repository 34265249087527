/**
 *  Content component
 *  $1. Content box
 *  $2. Module
 *  $3. Global nav
 *  $4. Event
 *  $5. Overwrite style
 *  $6. Content
 */

/**
   $1. Content box
 */
@use "sass:math";

.content-box .cb- {
  &aside {
    position: fixed;
    z-index: 1;
    width: 240px;
    height: 100vh;
    max-height: calc(100vh - 130px);
    margin-left: -12px;
    padding: 12px 15px;
    transition: all .3s ease;
    color: #fff;
    border-right: 1px solid #666;
    background-color: #1F1F1F;

    flex: 0 0 auto;

    &::after {
      position: absolute;
      top: 100%;
      left: 0;
      width: calc(100% + 1px);
      height: 10px;
      content: "";
      border-right: 1px solid #1F1F1F;
      background-color: #1F1F1F;
    }

    hr {
      margin-top: 48px;
      margin-bottom: 32px;
      border-top-color: #000;
    }

    p {
      font-size: 14px;
    }

    table {
      word-break: break-all;
      border: none;

      tr {
        td,
        th {
          font-size: 14px;
          padding: 12px;
          border: none;

          &:first-child {
            border-left: none;
            background: none;
          }
        }

        td {
          text-align: right;
        }

        th {
          text-align: left;
          background-color: transparent;
        }

        &:nth-child(odd) {
          background-color: transparent;
        }
      }

      tbody {
        td,
        th {
          border-bottom: 1px solid #000;
        }

        td {
          max-width: 48px;
          white-space: nowrap;
        }

        th {
          max-width: 112px;
        }
      }

      thead {
        td,
        th {
          font-weight: 400;
          padding-right: 8px;
          padding-left: 8px;
          color: rgba(#fff, .67);
          border-bottom: 1px solid #666;

          &:last-child {
            text-align: right;
          }
        }
      }
    }

    &-accordion {
      margin-top: 32px;
      border-bottom: 1px solid #000;

      &-main {
        overflow: hidden;
      }

      &-toggle {
        font-size: 15px;
        font-weight: 700;
        position: relative;
        width: 192px;
        height: 56px;
        padding: 12px 24px 12px 12px;
        text-align: left;

        [class*="icon-"] {
          position: absolute;
          top: 50%;
          right: 12px;
          width: 20px;
          height: 20px;
          transform: translateY(-50%);
          border: 1px solid #4D4D4D;
          border-radius: 4px;
          background-color: #444;

          &::before {
            position: absolute;
            top: -2px;
            left: -3px;
          }
        }

        &:hover {
          background-color: #2E2E2E;
        }

        &[aria-expanded="true"] {
          border-top: 1px solid #000;
          background-color: #2E2E2E;

          [class*="icon-"] {
            transform: translateY(-50%) rotate(180deg);
            border-color: transparent;
            background-color: transparent;
          }
        }
      }

      &:first-child,
      &+.cb-aside-accordion {
        margin-top: 0;
      }
    }

    &-button {
      font-size: 14px;
      font-weight: 700;
      display: flex;
      width: 100%;
      margin-bottom: 15px;
      padding: 7px 0 7px 10px;
      border-radius: 4px;
      background-color: #0C7B82;
      box-shadow: 0 2px 3px rgba(#000, .36);

      align-items: center;

      [class*="icon-"] {
        margin-left: auto;
      }

      &:hover {
        background-color: darken(#0C7B82, 5%);
      }

      &.is-secondary {
        background-color: #A7570F;

        &:hover {
          background-color: darken(#A7570F, 5%);
        }
      }
    }

    &-column {
      width: 100%;
      padding-right: 8px;
      padding-left: 8px;

      &-title {
        font-size: 13px;
        margin: 0 4px 8px;
        color: rgba(#fff, .67);
      }
    }

    &-header {
      margin: 0 -15px;
      padding-right: 14px;
      padding-left: 6px;
      border-bottom: 1px solid #666;

      .cb-aside-list {
        display: flex;

        &-item {
          position: relative;
          width: 100%;
          margin-right: 6px;
          margin-left: 6px;
          text-align: center;

          a {
            font-size: 13px;
            position: relative;
            display: inherit;
            padding: 12px 0;
            color: rgba(#fff, .67);

            &:hover {
              text-decoration: none;

              &::after {
                position: absolute;
                bottom: 0;
                left: 50%;
                width: 100%;
                height: 3px;
                content: "";
                transform: translateX(-50%);
                background-color: #fff;
              }
            }
          }

          &:first-child:last-child {
            margin-right: 18px;
            margin-left: 18px;
            text-align: left;

            a {
              color: #fff;
            }
          }

          &.is-active {
            a {
              color: #fff;

              &::after {
                position: absolute;
                bottom: 0;
                left: 50%;
                width: 94px;
                height: 3px;
                content: "";
                transform: translateX(-50%);
                background-color: #fff;
              }
            }
          }
        }
      }
    }

    &-main {
      overflow-y: auto;
      max-height: calc(100% - 22px);
      margin: 0 -15px;
      padding: 32px 20px 48px;

      -ms-overflow-style: -ms-autohiding-scrollbar;
      -ms-scrollbar-face-color: #000;
      -ms-scrollbar-highlight-color: #000;
      scrollbar-color: #1F1F1F #000;

      &::-webkit-scrollbar {
        width: 8px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #000;
      }

      &:hover {
        &::-webkit-scrollbar-thumb {
          background-color: #666;
        }
      }
    }

    &-row {
      display: flex;
      margin-top: 24px;
      margin-bottom: 24px;

      align-items: flex-start;
    }

    &-toggle {
      position: absolute;
      top: 50%;
      right: -17px;
      width: 32px;
      height: 32px;
      transform: translateY(-50%);
      color: #fff;
      border: 1px solid #707070;
      border-radius: 50%;
      background-color: #1F1F1F;

      [class*="icon-"] {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -.05em;
        transform: translate(-50%, -50%);
      }

      &:hover {
        background-color: #444;
      }
    }

    &.is-collapse {
      width: 0;

      .cb-aside-header,
      .cb-aside-main {
        visibility: hidden;
      }

      .cb-aside-toggle {
        background-color: #444;

        [class*="icon-"] {
          transform: translate(-45%, -55%) rotate(180deg);
        }
      }

      &~.cb-main {
        padding-left: 48px;
      }
    }
  }

  &container {
    position: relative;
    display: flex;
    padding: 12px;

    &.is-fluid {
      display: block;

      .cb-aside,
      .cb-main {
        position: relative;
        width: 100%;
        max-height: 100%;
        margin: 0;
      }

      .cb-aside {
        height: auto;
        min-height: 0;
        max-height: none;
        padding-bottom: 18px;
        border-right: none;
        border-bottom: 1px solid #666;

        &::after {
          display: none;
        }

        &-column {
          width: math.div(1, 3) * 100%;
          margin-top: 0;
          margin-bottom: 24px;
        }

        &-main {
          padding: 0;
        }

        &-row {
          margin-top: 0;
          margin-bottom: 0;

          flex-wrap: wrap;
        }

        &-toggle {
          top: 100%;
          right: 50%;
          transform: translate(50%, -50%);

          [class*="icon-"] {
            transform: translate(-50%, -50%) rotate(90deg);
          }
        }
      }

      &:not(.is-collapse) {
        .cb-aside {
          min-height: 50vh;
        }
      }

      .cb-main {
        padding: 40px 0;
      }
    }
  }

  &main {
    overflow-x: auto;
    width: 100%;
    padding: 12px 32px 12px 260px;
    transition: all .3s ease;
  }

  &tab {
    &-list {
      display: flex;
      padding-right: 20px;
      padding-left: 20px;
      border-bottom: 1px solid #666;

      &-item {
        padding-right: 4px;
        padding-left: 4px;

        a {
          font-size: 14px;
          position: relative;
          display: inherit;
          min-width: 88px;
          padding: 11px 16px 10px;
          transition: all .3s ease;
          text-align: center;
          color: rgba(#fff, .67);
          border: 1px solid transparent;
          border-bottom: none;

          &:hover {
            text-decoration: none;
            color: #fff;
            border-color: #666;

            &::after {
              position: absolute;
              bottom: -2px;
              left: 0;
              width: 100%;
              height: 3px;
              content: "";
              background-color: #1F1F1F;
            }
          }
        }

        &.is-active {
          a {
            color: #fff;
            border-color: #666;

            &::after {
              position: absolute;
              bottom: -2px;
              left: 0;
              width: 100%;
              height: 3px;
              content: "";
              background-color: #1F1F1F;
            }
          }
        }

        &.is-disabled {
          a {
            pointer-events: none;
            color: #666;

            &::before {
              position: absolute;
              top: 50%;
              left: 50%;
              width: math.div(65, 88) * 100%;
              height: 1px;
              content: "";
              transform: translate(-50%, -50%);
              background-color: #666;
            }
          }
        }
      }
    }

    &-panel {
      padding-top: 20px;
      padding-bottom: 20px;

      .top--container {
        padding: 0;
      }
    }
  }
}

/**
   $2. Module
 */
.module-field {
  position: relative;
  width: 100%;
}

.module-field .mf- {
  &buttons {
    display: flex;

    .mf-button {
      font-weight: 400;
      overflow: hidden;
      width: 100%;
      cursor: pointer;
      text-align: center;
      color: rgba(#fff, .47);
      border-left: 1px solid #1F1F1F;

      input[type="radio"] {
        &:checked+.mf-label {
          color: #fff;
          border-color: #4D4D4D;
          background-color: #444;
        }
      }

      .mf-label {
        font-weight: 400;
        display: block;
        padding: 9px 0;
        border: 1px solid #000;
        background-color: #000;

        &:hover {
          color: #fff;
          border-color: #4D4D4D;
          background-color: #444;
        }
      }

      &:first-child {
        border-left: none;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;

        .mf-label {
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
        }
      }

      &:last-child {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;

        .mf-label {
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
        }
      }
    }
  }

  &checkbox,
  &radio {
    display: flex;
    margin: 14px 4px 16px;

    align-items: center;

    input[type="checkbox"],
    input[type="radio"] {
      &~.mf-pseudo {
        position: relative;
        display: inline-block;

        &~.mf-label {
          padding-left: 12px;
        }
      }

      &:not(:checked) {
        &~.mf-label {
          color: rgba(#fff, .47);
        }
      }
    }
  }

  &checkbox {
    input[type="checkbox"] {
      &+.mf-pseudo {
        &::before {
          display: block;
          width: 18px;
          height: 18px;
          content: "";
          border: 1px solid #404040;
          border-radius: 2px;
          background-color: #000;
        }

        [class*="icon-"] {
          position: absolute;
          top: 50%;
          left: 50%;
          display: none;
          transform: translate(-50%, -50%);
          color: #1D79B2;
        }
      }

      &:checked {
        &+.mf-pseudo {
          &::before {
            border-color: transparent;
            background-color: #fff;
          }

          [class*="icon-"] {
            display: block;
          }
        }
      }
    }
  }

  &execution {
    margin-top: 48px;
    margin-bottom: 48px;
    padding: 32px 8px;
    text-align: center;
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;

    &-button {
      font-size: 14px;
      display: block;
      width: 100%;
      max-width: 176px;
      margin: 0 auto;
      padding: 10px;
      text-indent: .5em;
      letter-spacing: .5em;
      border-radius: 4px;
      background-color: #1D79B2;
      box-shadow: 0 2px 3px rgba(#000, .36);

      &:hover {
        background-color: darken(#1D79B2, 5%);
      }
    }

    &-reset {
      margin-top: 24px;
      padding: 1px 9px;
      color: rgba(#fff, .67);
      border: 1px solid #666;
      background-color: #1F1F1F;

      &:hover {
        background-color: darken(#1F1F1F, 5%);
      }
    }

    &-status {
      font-size: 14px;
      display: block;
      margin-bottom: 16px;
    }
  }

  &label {
    width: 100%;
    cursor: pointer;
  }

  &period {
    display: flex;

    &-end,
    &-start {
      display: flex;
      width: 100%;

      align-items: center;
    }

    &-end {
      justify-content: flex-end;

      .mf-select {
        width: 144px;
        margin-left: 8px;
      }
    }

    &-start {
      .mf-select {
        width: 144px;
        margin-right: 8px;
      }
    }

    &~.mf-period {
      margin-top: 12px;
    }
  }

  &radio {
    input[type="radio"] {
      &+.mf-pseudo {
        &::before {
          display: block;
          width: 18px;
          height: 18px;
          content: "";
          border: 1px solid #404040;
          border-radius: 50%;
          background-color: #000;
        }
      }

      &:checked {
        &+.mf-pseudo {
          &::before {
            border: 4px solid #fff;
            background-color: #1D79B2;
          }
        }
      }
    }
  }

  &select {
    position: relative;
    display: block;
    width: 100%;
    height: 36px;
    border-radius: 4px;
    background-color: #444;

    select {
      font-size: inherit;
      width: 100%;
      height: 100%;
      padding: 0 32px 0 10px;
      cursor: pointer;
      color: rgba(#fff, .47);
      border: none;
      background: none;

      appearance: none;

      &::-ms-expand {
        display: none;
      }

      option {
        background-color: #1F1F1F;
      }
    }

    [class*="icon-"] {
      position: absolute;
      top: 50%;
      right: 5px;
      transform: translateY(-50%);
      pointer-events: none;
    }
  }

  &text {
    input[type="text"] {
      font-size: 13px;
      width: 100%;
      padding: 5px 10px;
      color: rgba(#fff, .47);
      border: none;
      border-bottom: 1px solid #666;
      border-radius: 0;
      background: none;
    }

    &-alert {
      font-size: 13px;
      position: absolute;
      display: block;
      margin-top: 5px;
      padding-right: 10px;
      padding-left: 10px;
      color: #FC1F38;
    }
  }

  &title {
    font-size: 13px;
    margin: 0 4px 8px;
    color: rgba(#fff, .67);
  }
}

.module-mode {
  font-size: 14px;
  position: fixed;
  right: 24px;
  bottom: 24px;
  width: 296px;
  height: 128px;
  padding: 20px 16px 16px 20px;
  color: rgba(255, 255, 255, .70);
  background: rgba(#000, .8);
  box-shadow: rgba(0, 0, 0, 0.5) 0 3px 30px;
  cursor: move;

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 4px;
    height: 100%;
    content: "";
    background-color: #000;
  }

  &.is-dev,
  &.is-local,
  &.is-trial {
    &::before {
      background-color: #48BFF4;
    }
  }

  &.is-sand {
    &::before {
      background-color: #FFC119;
    }
  }

  &.is-prod {
    &::before {
      background-color: #E604AA;
    }
  }
}

.module-mode .mm- {
  &account {
    display: flex;

    align-items: center;

    &-button {
      font-size: 13px;
      margin-left: auto;
      padding: 5px 12px;
      border-radius: 4px;
      background: #444;

      flex: 0 0 auto;
    }

    &-title {
      font-size: 13px;
      line-height: 1.6;
      max-width: 200px;
      max-height: 3.2em;
      margin: 0 24px 0 0;
      padding: 0;
      background: none;
      overflow: hidden;
    }
  }

  &button {
    position: absolute;
    top: 16px;
    right: 16px;
    width: 14px;
    height: 14px;

    &::before,
    &::after {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 1px;
      height: 18px;
      content: "";
      background-color: #FFF;
    }

    &::before {
      transform: translate(-50%, -50%) rotate(45deg);
    }

    &::after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }

  &heading {
    font-size: 13px;
    font-weight: 400;
    margin: 0 auto 8px;
    padding: 0;
    color: rgba(#fff, .47);
    background: none;
  }

  &title {
    font-size: 15px;
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 24px;
  }
}

/**
   $3. Global nav
 */
.global-nav {
  font-size: 14px;
  position: fixed;
  z-index: 1003;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: all .3s ease;
  color: #fff;
  background-color: rgba(0, 0, 0, .72);

  a {
    font-size: inherit;
    color: inherit;
    &:hover {
      color: inherit;
    }
  }
}

.global-nav .gn- {
  &column {
    width: math.div(344, 914) * 100%;
    height: 100%;
    padding-right: 24px;
    padding-left: 24px;
    border-right: 1px solid #444;

    &:last-child {
      border-right: none;
    }
  }

  &container {
    width: 88%;
    height: 100%;
    margin: 0 auto;
    padding: 24px 24px 0;
    border: 1px solid #292929;
    border-radius: 16px;
    background-color: rgba(31, 31, 31, .96);
    box-shadow: 0px 0px 16px rgba(0, 0, 0, .8);
  }

  &frame {
    overflow-x: auto;
    width: 100%;
  }

  &header {
    display: grid;
    gap: 32px;
    align-items: flex-end;
    grid-template-columns: 1fr 240px 40px;
    position: relative;
    padding: 0 0 18px 16px;

    &-button {
      position: relative;
      display: grid;
      width: 40px;
      height: 48px;
      font-size: 13px;

      justify-content: center;

      .icon-css-cancel {
        width: 40px;
        height: 23px;

        &::before,
        &::after {
          position: absolute;
          top: 13px;
          left: 19px;
          display: block;
          width: 2px;
          height: 28px;
          content: "";
          background-color: #fff;
        }

        &::before {
          transform: translateY(-50%) rotate(45deg);
        }

        &::after {
          transform: translateY(-50%) rotate(-45deg);
        }
      }
    }

    &-home {
      display: inline-flex;
      padding: 5px 15px 5px 5px;
      transition: all .3s ease;
      text-decoration: none;
      border-radius: 4px;
      background-color: #2e2e2e;

      align-items: center;

      [class*="icon-"] {
        display: flex;
        width: 24px;
        height: 24px;
        margin-right: 5px;

        align-items: center;
        justify-content: center;
      }

      &:hover {
        background-color: #3e3e3e;
        box-shadow: 0 3px 6px rgba(0, 0, 0, .16);
      }
    }

    &-banner {
      border-radius: 16px;
      overflow: hidden;
    }
  }

  &list {
    margin-bottom: 0;

    &-item {
      font-size: inherit;
      line-height: 1.8;
      margin-bottom: 8px;
      background-color: #2a2a2a;

      >a {
        display: flex;
        padding: 20px 8px 20px 16px;
        transition: all .3s ease;
        text-decoration: none;

        align-items: center;

        [class*="icon-"] {
          display: flex;
          width: 36px;
          height: 24px;
          padding-right: 12px;

          align-items: center;
          justify-content: center;
        }

        &:hover {
          background-color: #3e3e3e;
          box-shadow: 0 3px 6px rgba(0, 0, 0, .16);
        }
      }

      &:last-child {
        border-bottom: none;
      }
    }

    &-heading {
      font-size: 13px;
      display: flex;
      padding: 24px 6px 16px 16px;
      color: rgba(#fff, .47);

      align-items: center;

      [class*="icon-"] {
        display: flex;
        width: 36px;
        height: 24px;
        padding-right: 12px;
        color: #fff;

        align-items: center;
        justify-content: center;
      }
    }

    &-nest {
      padding-bottom: 12px;
      padding-left: 40px;

      .gn-list-item {
        margin-bottom: 0;
        padding-top: 0;
        padding-bottom: 0;
        border-bottom: none;

        >a {
          padding-left: 12px;
        }
      }
    }
  }

  &main {
    display: flex;
    height: calc(100% - 85px);
    margin-right: -8px;
    margin-left: -8px;
    padding-top: 12px;
    padding-bottom: 12px;
  }

  &tab {
    overflow: hidden;
    height: 100%;

    &-content {
      overflow-y: auto;
      height: calc(100% - 56px);
      padding-top: 20px;
    }

    &-header {
      padding-right: 14px;
      padding-left: 6px;
      border-bottom: 1px solid #444;

      .gn-tab-list {
        display: flex;
        margin-bottom: 0;

        &-item {
          position: relative;
          width: 100%;
          margin-right: 6px;
          margin-left: 6px;
          text-align: center;

          a {
            font-size: 14px;
            position: relative;
            display: inherit;
            padding: 16px 0 20px;
            transition: all .3s ease;
            color: rgba(255, 255, 255, 0.47);

            &::after {
              position: absolute;
              bottom: 0;
              left: 10%;
              width: 80%;
              height: 2px;
              content: "";
              transition: transform .3s;
              transform: scale(0, 1);
              transform-origin: center top;
              background-color: #fff;
            }

            &:hover {
              text-decoration: none;
              color: rgba(255, 255, 255, 1);

              &::after {
                transform: scale(1, 1);
              }
            }
          }

          &:first-child:last-child {
            margin-right: 18px;
            margin-left: 18px;
            text-align: left;

            a {
              color: #fff;
            }
          }

          &.is-active {
            a {
              color: #fff;

              &::before {
                position: absolute;
                bottom: 0;
                left: 50%;
                width: 80%;
                height: 2px;
                content: "";
                transform: translateX(-50%);
                background-color: #fff;
              }

              &:hover {
                &::after {
                  display: none;
                }
              }
            }
          }
        }
      }
    }

    &-panel {
      .gn-list {
        font-size: 14px;
        border-bottom: 1px solid #000;

        &-item {
          >a {
            padding-right: 12px;
            padding-left: 12px;
          }

          &-data,
          &-head {
            font-size: 14px;
          }

          &-data {
            width: 64px;
            margin-left: auto;
            text-align: right;
            color: rgba(#fff, .47);
          }

          &-indicate {
            font-size: 15px;
          }
        }
      }
    }
  }

  &wrap {
    display: flex;
    width: math.div(570, 914) * 100%;
  }
}

@media screen and (max-width: 1279px) {
  .global-nav .gn- {
    &container {
      width: 90%;
    }
  }
}

@media screen and (max-width: 959px) {
  .global-nav .gn- {
    &column {
      width: 100%;
    }

    &wrap {
      overflow-x: auto;
      width: 100%;

      flex-wrap: wrap;

      .gn-column {
        overflow-x: visible;
        height: auto;

        &:not(:last-child) {
          >.gn-list {
            >.gn-list-item {
              &:last-child {
                border-bottom: 1px solid #444;
              }
            }
          }
        }
      }
    }
  }
}

/**
   $4. Event
 */
.event-condition {
  display: flex;
  padding-right: 8px;
  padding-left: 8px;

  align-items: baseline;
}

.event-condition .ec- {
  &button {
    font-size: 14px;
    margin-left: auto;
    padding: 8px 23px;
    border-radius: 4px;
    background-color: #1D79B2;
    box-shadow: 0 2px 3px rgba(#000, .36);

    flex: 0 0 auto;

    &:hover {
      background-color: darken(#1D79B2, 5%);
    }
  }

  &list {
    display: flex;

    flex-wrap: wrap;

    &-item {
      font-size: 15px;
      margin-right: 12px;
      margin-bottom: 12px;
      padding: 2px 10px;
      border: 1px dashed #898989;
    }
  }

  &title {
    font-size: 13px;
    margin-right: 20px;

    flex: 0 0 auto;
  }
}

.event-search {
  font-size: 15px;
  position: fixed;
  z-index: 1000;
  top: 108px;
  left: 0;
  display: flex;
  display: none;
  width: 100vw;
  height: calc(100vh - 108px);
  padding-top: 32px;
  transition: all .3s ease;
  color: #fff;
  background-color: #1F1F1F;

  align-items: center;

  a {
    font-size: inherit;
    color: inherit;
  }

  .mf-execution {
    &:last-child {
      margin-bottom: 0;
      padding-bottom: 0;
      border-bottom: none;
    }
  }
}

.event-search .es- {
  &column {
    padding-right: 16px;
    padding-left: 16px;

    &-3 {
      width: math.div(3, 12) * 100%;
    }

    &-4 {
      width: math.div(4, 12) * 100%;
    }

    &-7 {
      width: math.div(7, 12) * 100%;
    }
  }

  &container {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
  }

  &row {
    display: flex;
    margin-right: -16px;
    margin-bottom: 32px;
    margin-left: -16px;
  }

  &title {
    font-size: 17px;
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 72px;
    text-align: center;
  }
}


/**
   $5. Overwrite style
 */
.barn {
  .alerts--info {
    margin-right: 24px;
    margin-bottom: 24px;

    @media #{$tablet-portrait} {
      margin-right: 0;
    }
  }

  .alerts--sickness {
    margin-right: 24px;
    margin-left: 0 !important;
  }

  .box-history {
    position: static;
    width: auto;
    margin-right: 0 !important;

    &.individual-history {
      position: fixed;
      right: 0;
      width: calc(33.33% - 50px);
      padding: 0 10px 10px 0!important;

      @media screen and (max-width: 1200px) {
        position: relative;
        width: 33.33%;
        padding-right: 0!important;
      }
    }
  }

  .counter--horizontal {
    margin-right: 24px;

    flex-wrap: wrap;
  }

  .top--alerts,
  .top--counters {
    margin-top: 0 !important;

    flex-wrap: wrap;

    >* {
      margin-bottom: 24px;
    }
  }

  .counter--sum,
  .counter--milk {
    margin-top: 0;
    margin-bottom: 24px;
  }
}

/**
   $6. Content
 */
.content {
  height: 100%;

  .content-box {
    width: 100%;
    height: calc(100vh - 124px);
    padding: 0 10px 10px;

    .section {
      position: relative;
      background: #fff;
      box-shadow: 0 1px 2px 0 rgba(90, 90, 90, 0.3);
      height: 100%;
    }

    .active-box {
      ul.active-list {
        width: 95%;
        margin: 10px auto;

        h5 {
          font-size: 1.5rem;
          font-weight: normal;
          padding: 8px 4px 8px 10px;
          text-align: center;
          color: #fff;
          border: none;
          background-color: #739315;
        }

        li {
          i {
            color: #fff;
          }
        }
      }
    }
  }

  .row-2 {
    .left-adjust {
      padding: 0 10px 10px 0;
    }

    .right-adjust {
      padding: 0 0 10px 0;
    }
  }

  .row-6 {
    padding: 10px 10px 0;

    li {
      padding: 0;

      .section {
        margin: 0 10px 0 0;
      }

      &:last-child .section {
        margin-right: 0;
      }
    }
  }

  .add-searcbox {
    float: left;
    width: 100%;
    margin: 0 -300px 20px 0;

    .tabs {
      position: relative;
      margin: 0 300px 0 0;
    }
  }

  .title-box {
    position: fixed;
    z-index: 11000;
    top: 26px;
    width: calc(100% - 150px);
    height: 52px;
    padding: 5px;
    text-align: center;
    background: #fff;
    background: linear-gradient(#ededed 0, #ffffff 100%);
    box-shadow: 0 1px 2px 1px rgba(90, 90, 90, 0.3);

    filter: progid:dximagetransform.microsoft.gradient(startcolorstr='#ededed', endcolorstr='#ffffff', gradienttype=0);

    h2 {
      font-size: 2rem;
      font-weight: bold;
      line-height: 41px;
      letter-spacing: .08em;
    }

    button {
      position: absolute;
      top: 0;
      display: block;

      &.btn-back {
        top: 15px;
        left: 10px;
        color: #333;

        display: flex;
        align-items: center;

        // 旧アイコンフォント調整
        .icon-l-arrow-A-l01 {
          font-size: 16px !important;
          display: flex;
          width: 16px;
          height: 16px;
          margin-right: 8px;
          color: #fff;
          border-radius: 50%;
          background-color: $orange;

          align-items: center;
          justify-content: center;
        }
      }

      &.btn-pencil {
        top: 14px;
        right: 70px;
      }

      &.btn-delete {
        top: 14px;
        right: 10px;
      }

      &.btn-apply {
        top: 14px;
        right: 20px;
      }
    }
  }

  .row-3 li {
    padding: 0;

    .section {
      margin: 0 10px 10px 0;
    }

    &:last-child .section {
      margin-right: 0;
    }
  }

  .unit {
    font-size: 1.1rem;
    font-weight: normal;
    margin-left: 5px;
    color: #666;
  }

  .clear-btn {
    font-size: 1.1rem;
    font-weight: normal;
    left: -25px;
    color: #666;
  }

  .edit-btn {
    font-size: 1.1rem;
    line-height: 22px;
    position: relative;
    display: inline-block;
    min-width: 5rem;
    height: 22px;
    padding: 0 6px 0 22px;
    color: #fff;
    border-radius: 4px;
    background-color: $orange;

    &:hover {
      text-decoration: none;
      opacity: .8;
    }

    &:disabled {
      cursor: default;
      opacity: 1;
      color: #afafaf;
      background-color: #ececec;
    }

    i {
      font-size: 1.5rem;
      position: absolute;
      top: 50%;
      left: 4px;
      margin-top: -.75rem;
      color: #fff;
    }

    // 旧アイコンフォント調整
    .icon-s-print {
      font-size: 12px !important;
      left: 6px;
      margin-top: -6px;
    }

    // 旧アイコンフォント調整
    .icon-s-setting {
      font-size: 12px !important;
      left: 6px;
      margin-top: -6px;
    }

    // 旧アイコンフォント調整
    .icon-m-search {
      font-size: 12px !important;
      left: 6px;
      margin-top: -6px;
    }

    // 旧アイコンフォント調整
    .icon-s-edit {
      font-size: 12px !important;
      left: 6px;
      margin-top: -6px;
    }

    // 旧アイコンフォント調整
    .icon-s-delete {
      font-size: 12px !important;
      left: 6px;
      margin-top: -6px;
    }

    .icon-css-plus {
      display: inline-block;
      width: 12px;
      height: 12px;
      margin: -8px 0 0 4px;

      &::before {
        position: absolute;
        top: 7px;
        left: 0;
        display: block;
        width: 9px;
        height: 1px;
        content: '';
        background-color: #fff;
      }

      &::after {
        position: absolute;
        top: 7px;
        left: 0;
        display: block;
        width: 9px;
        height: 1px;
        content: '';
        transform: rotate(90deg);
        background-color: #fff;
      }
    }

    .icon-css-remove {
      position: relative;
      display: inline-block;
      width: 12px;
      height: 12px;
      margin: 1px 2px 0 0;
      left: 0 !important;
      vertical-align: top;

      &::before {
        position: absolute;
        top: 9px;
        left: 0;
        display: block;
        width: 10px;
        height: 2px;
        content: '';
        transform: rotate(45deg);
        background-color: #fff;
      }

      &::after {
        position: absolute;
        top: 9px;
        left: 0;
        display: block;
        width: 10px;
        height: 2px;
        content: '';
        transform: rotate(-45deg);
        background-color: #fff;
      }
    }

    .icon-css-cancel {
      width: 12px;
      height: 12px;
      margin: -10px 0 0 4px;

      &::before {
        position: absolute;
        top: 9px;
        left: 0;
        display: block;
        width: 10px;
        height: 2px;
        content: '';
        transform: rotate(45deg);
        background-color: #fff;
      }

      &::after {
        position: absolute;
        top: 9px;
        left: 0;
        display: block;
        width: 10px;
        height: 2px;
        content: '';
        transform: rotate(-45deg);
        background-color: #fff;
      }
    }

    &.no-icon {
      padding-left: 6px;
    }
  }

  .delete-btn {
    font-size: 1.5rem;
    line-height: 22px;
    position: relative;
    display: inline-block;
    min-width: 5rem;
    height: 26px;
    padding: 0 10px 0 10px;
    color: #fff;
    border-radius: 4px;
    background-color: #ee664e;

    &:hover {
      text-decoration: none;
      opacity: .8;
    }

    &:disabled {
      background-color: #84837F;
    }

    i {
      font-size: 1.5rem;
      position: absolute;
      top: 50%;
      left: 4px;
      margin-top: -.75rem;
      color: #fff;
    }
  }

  .white-btn {
    font-size: 1.1rem;
    line-height: 28px;
    position: relative;
    display: inline-block;
    height: 28px;
    padding: 0 10px 0 25px;
    color: #333;
    //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
    border-radius: 6px;
    background-color: #fff;

    &:hover {
      text-decoration: none;
      color: $orange;

      i {
        text-decoration: none;
        color: $orange;
      }

      // 旧アイコンフォント調整
      .icon-css-close {
        border: 1px solid $orange;
        &::before,
        &::after{
          background-color: $orange;
        }
      }
    }

    // 旧アイコンフォント調整
    .icon-css-close {
      position: absolute;
      top: calc(50% - 8px);
      left: 6px;
      display: inline-block;
      width: 16px;
      height: 16px;
      border: 1px solid #333;
      border-radius: 50%;

      &::before {
        position: absolute;
        top: 6px;
        left: 3px;
        display: block;
        width: 8px;
        height: 2px;
        content: '';
        transform: rotate(45deg);
        background-color: #333;
      }

      &::after {
        position: absolute;
        top: 6px;
        left: 3px;
        display: block;
        width: 8px;
        height: 2px;
        content: '';
        transform: rotate(-45deg);
        background-color: #333;
      }
    }

    // 旧アイコンフォント調整
    .icon-s-filter,
    .icon-s-search {
      font-size: 12px !important;
      position: absolute;
      top: calc(50% - 6px);
      left: 7px;
      margin: 0;
      color: #333;
    }
  }

  .required {
    font-size: 1rem;
    font-weight: bold;
    line-height: 18px;
    position: absolute;
    height: 18px;
    margin-left: 5px;
    padding: 1px 5px 1px 5px;
    color: #fff;
    border-radius: 6px;
    background-color: #d33a3d;
  }

  .new {
    display: inline-block;
    font-size: 10px;
    font-weight: 700;
    line-height: 18px;
    height: 18px;
    margin: 0 0 0 5px;
    padding: 0 5px;
    color: #fff;
    border-radius: 6px;
    background-color: #d33a3d;
  }
}
