/*---------------------------------
　メインボード
---------------------------------*/
.uMainBoard {
  padding: 0 304px 0 0;

  &--rightBoardClosed {
    padding: 0 30px 0 0;

    @extend .uMainBoard;
  }

  &__container {
    display: flex;
    width: 100%;
    height: auto;
    padding: 0 22px 0 14px;

    align-items: flex-start;
    flex-wrap: wrap;
  }

  .uHeading {
    font-size: 14px;
    position: static;
    height: 76px;
    padding: 0 32px 0 0;
  }

  .uHeading__button {
    font-size: inherit;
    margin: 0 0 0 16px;
    color: inherit;
    border: 1px solid #3d7b89;
    border-radius: 10vw;
    background: none;
    box-shadow: none;

    &-icon {
      margin: 0 0 0 16px;
    }

    &-label {
      font-size: 12px;
      font-weight: 700;
      margin: 0 8px 0 -6px;
      padding: 2px 6px;
      text-transform: uppercase;
      border-radius: 10vw;
      background-color: #AD1414;
    }

    &:hover {
      text-decoration: inherit;
      background-color: #203f4e;
    }

    &--rollback {
      font-size: inherit;
      margin: 0 0 0 16px;
      color: inherit;
      border: 1px solid #3d7b89;
      border-color: #768424;
      border-radius: 10vw;
      background-color: rgba(67, 78, 6, 0.4);
      box-shadow: none;

      &:hover {
        text-decoration: inherit;
        border-color: #768424;
        background-color: rgba(67, 78, 6, 0.4);
      }
    }
  }

  .uHeading__title {
    margin: 0 auto 0 0 !important;
  }

  &__masonry {
    display: inherit;
    width: 100%;

    align-items: inherit;
    flex-wrap: inherit;
    justify-content: inherit;
  }

  @media (pointer: coarse),
  (max-width: 1023px) {
    padding-right: 0;

    &__container {
      padding-right: 0 !important;
      padding-left: 0 !important;

      justify-content: space-between;
    }
  }
}