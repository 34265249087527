/*---------------------------------
　ツールチップ
---------------------------------*/

.uTooltip {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  display: block;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  background: rgba(255, 255, 255, 0);

  &::before {
    font-size: 13px;
    font-weight: 400;
    position: absolute;
    top: 100%;
    left: 50%;
    display: block;
    padding: 4px 8px;
    content: attr(data-text);
    transition: opacity .2s ease;
    transform: translate3d(-50%, 0, 0);
    white-space: nowrap;
    pointer-events: none;
    opacity: 0;
    color: white(primary);
    border-radius: 2px;
    background: rgba(95, 100, 105, .96);
    box-shadow: 0 3px 6px rgba(0, 0, 0, .16);
  }

  &:hover {
    &::before {
      opacity: 1;

      @media (pointer: coarse) {
        opacity: 0;
      }
    }
  }
}