.bulk-entry-history {
  min-height: 500px;

  .edit-regist-list01 {
    .event-dialog-link.disabled {
      pointer-events: none;
      background-color: #f6f4f4;
    }
    .target-date {
      margin: 0px;
    }
    .target-farm {
      padding: 5px 6px;
      background-color: #f6f4f4;
    }
    .in-valid-target-date {
      border: none;
      margin: 0px;
      width: 88px;
    }
  }
  #bulk-regist-table {
    input, select {
      width: 100%;
    }

    .other-difficulty input {
      width: 130px;
    }

    .other-difficulty select {
      width: 70px;
    }

    .checkbox--label input {
      width: auto;
    }

    .mastitis-cow-group {
      width: 130px;
    }
    .mastitis-score {
      width: 130px;
    }
    .diagnosis-of-mastitis {
      width: 130px;
    }
    .bacteria-of-mastitis {
      width: 170px;
    }
    .cowNo {
      width: 140px;
    }
    // 発生日
    .occurred-date {
      width: 160px;
    }
    // 症状
    .condition {
      width: 350px;
    }
    // 重症度
    .severity {
      width: 110px;
    }
    // 担当者
    .worker-name {
      width: 150px;
    }
    // 投薬薬品
    .medicine-names {
      width: 350px;
      .medicine-names-content {
        width: 80%;
        float: left;
        text-align: left;
      }
      .medicine-names-btn {
        width: 20%;
        float: left;
      }
    }
    // 体温
    .body-temperature {
      width: 100px;
    }
    // 個体識別番号
    .cow-identification-number {
      width: 140px;
    }
    // 移動前牛群名
    .before-cow-group-name {
      width: 140px;
    }

    // 移動前牛房
    .before-pen {
      width: 100px;
    }

    td {
      // 体温
      .body-temperature {
        width: 60px;
      }
    }

    // 時刻
    td.top {
      vertical-align: top;
    }
    td.middle {
      vertical-align: middle;
    }

    span.no-wrap {
      white-space: nowrap;
    }

    p.fault-date-error {
      padding-top:0.5rem;
      width:100%;
      text-align: center;
    }
  }

  .input-field-container {
    vertical-align: middle;

    label {
      font-weight: normal;
      vertical-align: middle;

      input[type=checkbox] {
        width: 13px !important;
        vertical-align: middle;
        position: relatve;
        margin-bottom: 6px;
      }
    }
  }
}
@for $i from 12 through 450 {
  .col-w#{$i * 5} {
    width: #{$i * 5}px;
  }
}
.carcass input[type="text"],
.carcass select {
  padding: 2px;
}

.in-valid-introduction-date {
  border: none !important;
}

//ホルモンプログラム > 時刻入力枠
.hormoneProgram--time-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/**********************************************
  下記4つのせいで !importantが必要。
  特に#を使ったところ。
  selector,
  .barn selector,
  .barn .bulk-entry-history #bulk-regist-table input,
  .barn .bulk-entry-history #bulk-regist-table select
**********************************************/
.hormoneProgram--selectors {
  height: 24px;
  margin: 0 3px !important;
  width: 50px !important;
  &:first-of-type {
    margin-left: 0 !important;
  }
}

/**********************************************
  一時しのぎなCSS
**********************************************/
// Override default bootstrap styles
.bulk-ui-select {
  height: 24px;

  .btn {
    padding: 3px 6px;
  }
  .form-control {
    height: 24px;
  }

  .ui-select-match {
    font-size: auto;
  }

  // 複数選択のみ
  &.ui-select-multiple {
    height: auto;
    padding: 0;

    // 選択されたものの枠
    .ui-select-match-item {
      padding: 1px 2px;
      margin: 0 1px 1px 0;

      // Xボタン
      .close {
        font-size: 1.6rem;
        line-height: 1;
        padding-top: 2px;
      }
    }
    // 選択消すとき、1番目の選択がなぜかこのクラスが追加されて青くなる
    .btn-primary {
      color: $textGrey;
      background-color: inherit;
      border-color: $lightGreyBorder;
    }
  }
}
