@page {
  margin: 10mm;
}

@media print {
  body {

    font-size: 10pt;
    background-color: #fff;
    -webkit-print-color-adjust: exact;
    .print {
      display: block !important;
    }
    .no-print {
      display: none !important;
    }
    .barn {
      font-size: 10pt;
    }
    .content-box * {
      min-width: 1px!important;
      font-size: 7pt!important;
    }
    .print-dspnone { display: none!important; }
    .content .content-box .section {
      width: 100%!important;
      background-color: #fff!important;
      box-shadow: none!important;
    }
    .cow-labels {
      margin: 0!important;
      padding: 0!important;
      color: #000!important;
      background-color: transparent!important;
      &::after {
        content: "、";
        display: inline;
      }
      &:last-child {
        &::after {
          content: none !important;
        }
      }
    }
    .cow-labels-wrapper > span > span:nth-last-of-type(1) .cow-labels::after { display: none; }
    .text-join {
      &:not(:last-child) {
        &::after {
          content: "、";
          display: inline;
        }
      }
    }
    table, th, td {
      border-collapse: collapse;
      border-spacing: 0;
    }
    td {
      padding: 2px 3px!important;
      text-align: left!important;
      span { text-align: left!important; }
    }
    .barn table.tb-col tr:first-child th:first-child { border-left-color: #333; }
    .barn table.tb-col tr:first-child th:last-child { border-right-color: #333; }
    th, td {
      border-top: 1px solid #333 !important;
    }
    th, table.tb-col tbody tr:nth-of-type(1) td{ border-top: 1px solid #333!important; }
    tbody tr td:nth-of-type(1) { border-left: 1px solid #333!important; }

    tr td:last-child { border-right: 1px solid #333; }
    tr:last-child td { border-bottom: 1px solid #333; }
    .w-maxwidth200 { max-width: 200px!important; }

    header {
      display: none !important;
    }
    #header-band {
      display: none !important;
    }
    .box-bulk-regist {
      display: none !important;
    }
    .wrapper {
      overflow: visible !important;
      .content {
        margin: 0 !important;
        overflow: visible !important;
      }
    }
    .negative-left-margin-on-print {
      margin-left: -100px;
    }
  }
}
