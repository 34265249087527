/*---------------------------------
　メインエリア
---------------------------------*/

/*
　@extend用のプレースホルダーセレクタ
---------------------------------*/
%uMain {
  position: relative;

  flex-grow: 1;
}

/*
　メインエリア
---------------------------------*/
.uMain {
  @extend %uMain;

  /*
  　メインエリア - サイドボード横幅144px
  ---------------------------------*/
  .uWrapper--sideBoard144 .uSideBoard+& {
    padding-left: 144px;

    @extend %uMain;

    @media (pointer: coarse), (max-width: 959px) {
      padding-left: 96px;
    }

    @media (pointer: coarse) and (orientation:portrait) {
      padding-left: 0;
    }

    @media (max-width: 1023px), (pointer: coarse) and (max-height: 699px) {
      padding-bottom: 70px;
    }

    @media print {
      padding-left: 0;
    }

    .uHeading {
      width: calc(100% - 144px);

      @media (pointer: coarse), (max-width: 959px) {
        left: 0;
        width: 100%;
        background: #2A2A2A;
        box-shadow: 0 2px 4px rgba(0, 0, 0, .48);
      }

      @media print {
        width: 100%;
      }
    }
  }

  /*
  　メインエリア - サイドボード横幅200px
  ---------------------------------*/
  .uWrapper--sideBoard200 .uSideBoard+& {
    padding-left: 200px;

    @extend %uMain;

    @media print {
      padding-left: 0;
    }

    .uHeading {
      width: calc(100% - 200px);

      @media print {
        width: 100%;
      }
    }
  }

  /*
  　メインエリア - サイドボード横幅240px
  ---------------------------------*/
  .uWrapper--sideBoard240 .uSideBoard+& {
    padding-left: 240px;

    @extend %uMain;

    @media print {
      padding-left: 0;
    }

    .uHeading {
      width: calc(100% - 240px);
    }
  }

  /*
  　メインエリア - サイドボード閉じている
  ---------------------------------*/
  .uSideBoard--closed+& {
    padding-left: 16px;

    @extend %uMain;

    .uHeading {
      width: calc(100% - 16px);
    }
  }

  /*
  　メインエリア - 印刷対応
  ---------------------------------*/
  .print & {
    padding: 0 !important;
  }

  @media print {
    padding: 0 !important;
  }

  /*
  　メインエリア - ページ見出し調整
  ---------------------------------*/
  .uHeading {
    left: auto;
  }

  /*
  　メインエリア - タブ
  ---------------------------------*/
  &__tabs {
    display: none;

    @media (orientation: portrait) and (pointer: coarse) {
      display: flex;
      padding: 12px 16px 0;

      flex-wrap: wrap;
    }
  }

  &__tab {
    @media (orientation: portrait) and (pointer: coarse) {
      display: inline-flex;

      &:first-child {
        margin: 0 0 16px 0 !important;
      }

      &:not(:first-child) {
        margin: 0 0 16px 8px !important;
      }

      &--active {
        display: inline-flex;

        &:first-child {
          margin: 0 0 16px 0 !important;
        }

        &:not(:first-child) {
          margin: 0 0 16px 8px !important;
        }

        .uMain {
          &__tabLink {
            font-weight: 700;
            color: #fff !important;
            border-color: rgba(#898989, .4);
            border-radius: 10vw;
            background: linear-gradient(to right, rgba(#969696, .4), rgba(#727272, .4));
            box-shadow: 0 1px 2px rgba(0, 0, 0, .16);
          }
        }
      }

      &--disabled {
        display: inline-flex;

        &:first-child {
          margin: 0 0 16px 0 !important;
        }

        &:not(:first-child) {
          margin: 0 0 16px 16px !important;
        }

        .uMain {
          &__tabLink {
            pointer-events: none;
            color: rgba(255, 255, 255, .12) !important;
            background: linear-gradient(to right, rgba(87, 87, 87, .04), rgba(51, 51, 51, .04));
          }
        }
      }

      &Link {
        font-size: 13px;
        display: flex;
        height: 32px;
        padding: 0 20px;
        text-decoration: none !important;
        color: rgba(#fff, .7) !important;
        border: 1px solid rgba(#707070, .12);
        border-radius: 10vw;
        background: linear-gradient(to right, rgba(#575757, .12), rgba(#333, .12));
        box-shadow: 0 1px 2px rgba(0, 0, 0, .16);

        align-items: center;

        &--changeUiVersion {
          font-size: 13px;
          display: flex;
          width: 143px;
          height: 32px;
          margin: 0 0 0 5px !important;
          color: white(primary);
          border: 1px solid #768424;
          border-radius: 4px;
          background: rgba(67, 78, 6, .4);

          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}