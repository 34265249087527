cow-board {
  .cow-board {
    // height, width, top, right, transformは各画面のscssで個別に指定
    position: relative;
    overflow: visible;
    background-color: #555;
    transition: transform 0.5s ease;
    .cow-board-header {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .cow-board-close {
        width: 40px;
        height: 40px;
      }
    }
    .cow-board-tabs {
      padding-bottom: 0.5rem;
    }
    loading {
      height: calc(100% - 69px);
      display: flex;
      justify-content: center;
      align-items: center;
      background: #fff;
    }
    .cow-board-body {
      height: calc(100% - 74px);
      overflow: auto;
      position: relative;
      margin: 0 5px;
      &.timeline-opened {
        overflow: visible;
      }
      .tb-row4 {
        th, td {
          width: 25%;
        }
      }
      .section {
        margin-bottom: 5px;
        position: relative;
        background: #fff;
        box-shadow: 0 1px 2px 0 rgba(90, 90, 90, 0.3);
        height: 100%;
        &:last-child {
          margin-bottom: 0;
        }
      }
      #box-history {
        height: 100%;
        width: 100%;
        overflow: visible;
        position: absolute;
        padding: 0;
        background-color: white;
        #timeline-cow {
          height: 100%;
          li.section {
            height: 100%;
            margin-bottom: 0;
            box-shadow: none;
          }
          .timeline-filter-menu {
            #menu-body {
              position: absolute;
            }
          }
          .box-history-innner {
            height: calc(100% - 85px);
            transition: none;

            .box-event-btn:last-of-type {
              margin-bottom: 10px;
            }

            .timeline--head {
              display: grid;
              margin-bottom: 5px;

              grid-template-columns: auto 1fr auto;
              grid-template-rows: auto;
            }

            .timeline--eventNameWrapper {
              min-height: 19px;

              grid-column: 1/2;
              grid-row: 1/2;
            }

            .timeline--eventName {
              font-size: 11px !important;
              display: inline-grid !important;
              height: 100%;
              margin: 0 !important;
              padding: 0 4px !important;

              align-items: center;
              justify-content: center;
            }

            .timeline--note {
              padding: 0 0 0 5px;

              grid-column: 2/3;
              grid-row: 1/2;
            }

            .timeline--daysAgo {
              font-size: 12px;
              padding: 0 12px 0 16px;
              text-align: right;
              white-space: nowrap;

              grid-column: 3/4;
              grid-row: 1/2;
            }

            // タイムラインボディから牛番号を非表示にする
            .timeline--cowIdLabel {
              display: none;
            }

            .isInputPathExternal {
              grid-template-columns: auto 1fr;
              grid-template-rows: repeat(2, auto);

              .timeline--eventNameWrapper {
                display: flex;
                border-width: 1px;
                border-style: solid;
              }

              .timeline--note {
                padding: 5px 0 0;

                grid-column: 1/3;
                grid-row: 2/3;
              }

              .timeline--externalLabel {
                font-size: 11px;
                display: inline-grid !important;
                padding: 0 4px !important;

                align-items: center;
                justify-content: center;
              }

              .timeline--daysAgo {
                grid-column: 2/3;
                grid-row: 1/2;
              }
            }
          }
        }
        &.on-event-input {
          #timeline-cow {
            .box-history-innner {
              position: relative;
              z-index: 1;
              height: calc(100% - 36px);
              background: #fff;
            }
          }
        }
       }
      #activity-rate-chart-wrapper {
        height: 330px;
      }
      #activity-stacked-bar-chart-wrapper {
        height: 330px;
      }
      div.has-no-data {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
      }

      .c3-circles {
        display: none;
      }
      .chart-nav {
        .edit-btn {
          background-color: #ececec;
          color: #666;
          &:hover {
            opacity: 1;
            background-color: #ff9908;
            color: #fff;
          }
          &:disabled {
            background-color: #ff9908;
            color: #fff;
          }
        }
      }
      .cow-board-dhi {
        background: #fff;
        padding: 5px;

        .dhi-content {

          &--nothing {
            align-items: center;
            display: flex;
            height: 331px;
            justify-content: center;
          }
        }

        .dhi-birth-number {
          display: flex;
          margin: 0 0 5px;

          &-label {
            line-height: 23.6px;
          }

          &-select {
            margin: 0 0 0 10px;
          }
        }

        .dhi-table {
          display: flex;

          &-header {
            min-width: 90px;
            width: 90px;

            &-cell {
              background: #f3f3f3;
              border-bottom: solid 1px #ddd;
              font-size: 12px;
              font-weight: 700;
              height: 25px;
              line-height: 24px;
              min-height: 25px;
              padding: 0 5px;
            }
          }

          &-body {
            flex: 1;
            overflow-x: scroll;

            &-row {
              display: flex;
            }

            &-cell {
              border-bottom: solid 1px #ddd;
              height: 25px;
              line-height: 24px;
              min-height: 25px;
              min-width: 40px;
              text-align: right;
              width: 40px;

              &--date {
                background: #f3f3f3;
                font-weight: 700;
              }
            }
          }
        }
      }
    }
  }
}
