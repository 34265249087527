.tb-setting01 {
  table-layout: auto !important;
  th {
    &:nth-of-type(1) {
      width: 28%;
    }
    &:nth-of-type(3) {
      width: 18%;
    }
    &:nth-of-type(4) {
      width: 15%;
    }
  }
  tr td {
    word-break: break-word;
    &:nth-of-type(1), &:nth-of-type(2), &:nth-of-type(3) {
      text-align: left;
    }
    &:nth-of-type(4) {
      text-align: center;
    }
    &:nth-of-type(5) {
      text-align: center;
      a {
        margin: 2px 0;
      }
    }
  }
}

.tb-setting02 {
  th {
    width: 21%;
  }
  td {
    text-align: left;
  }
}
.tb-setting03 {
  .col1 {
    width: 21%;
  }
  .col2, .col3 {
    width: 18%;
  }
  .col5 {
    width: 8%;
  }
  tr td {
    text-align: left;
    &:nth-of-type(5) {
      text-align: center;
    }
  }
}
.selectedItemArea {
  width: 100%;
}
.selectedItemText {
  width: 100%;
  height: 200px;
  &:disabled { background-color: #f6f4f4; }
}
.setting-list-header {
  border-bottom: 2px solid silver;
  padding: 4px 0px;
  margin-right: 5px;
  label {
    display: inline-block;
    font-size: 1.3rem;
    margin-right: 5px;
  }
}
.setting-list {
  border-bottom: 1px solid silver;
  padding: 4px 0px;
  span {
    display: inline-block;
    font-size: 1.3rem;
    margin-right: 5px;
  }
}
