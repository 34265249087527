/**
 *  Table components
 *  standard styles for
 *  - tables
 *  - thead
 *  - tbody
 *  - tr
 *  - td
 *  - th
 *  $1. Default styles
 *  $2. Rows count
 */

/**
 *  $1. Default styles
 */
.u-table {
  width: 100%;
  table-layout: fixed;

  // 旧アイコンフォント調整
  .icon-s-check-box-outlined {
    font-size: 12px!important;
  }

  &-hover {
    cursor: pointer;

    &:hover {
      background: #ececec!important;
    }
  }

  th {
    font-size: 1.3rem;
  }

  td {
    font-size: 1.4rem !important;

    a,
    p,
    span {
      font-size: 1.4rem !important;
    }
  }
}

.u-table-scroll {
  overflow: auto;
}

.scrollable-table-container { // replaces with .table-scroll
  max-height: calc(100vh - 453px); // not a good idea. should be replaced.
  overflow: auto;
  width: 100%;
}

.table-layout-fixed { // replaces .table-fixed
  table-layout: fixed;
}

/**
 *  $2. Row count
 */
.rows-count {
  font-size: 1.4rem;
  font-weight: bold;
  margin-top: 15px !important; // override .barn *
  margin-right: 10px !important; // override .barn *
}

.row--selected {
  background-color: #758890 !important;
  a, td{
    color: #fff;
  }
}
