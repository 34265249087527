.tool-bar {
  text-align: right;
  padding: 10px 10px 0px 10px;
}

.accordion-table-menu {
  padding-top: 5px;
}

.accordion-table-content {
  background-color: #fff;
  margin-bottom: 5px !important;
  margin-left: 5px !important;
}

.accordion-table.non-print {
  display: block;
}

.accordion-table.print {
  display: none;
}

.accordion-table {
  padding: 0px;
  margin: 0px;
  .unsettled {
    color: #999;
  }
  .note {
    text-align: left;
    color: #fff;
    padding: 10px 20px;
    background-color: #606060;
  }
  .data-nothing-box {
    padding: 10px;
    .data-nothing-font {
      color: #fff;
      font-weight: 700;
    }
  }
  .summary {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0px 5px;
    div.table-container {
      flex-basis: 200px;
      margin: 0px 10px;
      background-color: #fff;
    }
    table {
      border-collapse: collapse;
      thead {
        border: none;
        tr {
          border-bottom: 1px solid #ddd;
          border-left: none;
          th {
            text-align: left;
            padding: 10px;
          }
          th:nth-of-type(2) {
            border-left: 1px solid #ddd;
            border-right: 1px solid #ddd;
          }
        }
      }
      tbody {
        td {
          border: none;
        }
        td:nth-of-type(2) {
          border-left: 1px solid #ddd;
          border-right: 1px solid #ddd;
        }
      }
    }
  }
  .result {
    table {
      border-collapse: collapse;
      .yoy-data {
        span {
          color: #999;
          font-size: 1.1rem;
          font-style: italic;
          min-height: 17px;
          padding-right: 3px;
          text-align: right;
        }
      }
      .all-data {
        min-height: 20px;
      }
      td {
        background-color: white;
        text-align: right;
        border: none;
        white-space: nowrap;
        padding: 0.5em 0.5em;
      }
      th {
        white-space: nowrap;
        padding: 0.5em 0.5em;
        min-width: 190px;
        height: 3em; // Firefoxの謎挙動でheightを設定しないと表が1pixelずれる
      }
      td.data-all {
        border-top: none;
        border-right: none;
        border-left: 1px solid #eee;
      }
      td.data {
        border-left: 1px solid #eee;
        border-top: 1px solid #eee;
      }
      td.category {
        text-align: left;
        vertical-align: top;
        border-right: none;
      }
      tr.category {
        border-bottom: none;
        border-top: 1px solid #777;
        .yoy-data {
          padding-left: 1.5em;
        }
      }
      td.sub-category {
        text-align: left;
        vertical-align: top;
        border-left: 1px solid #ddd;
        border-top: 1px solid #ddd;
        padding-left: 2em;
        .yoy-data {
          padding-left: 2em;
        }
      }
      td.sub-category-yoy {
        text-align: left;
        vertical-align: bottom;
        div.pulldown {
          text-align: right;
        }

        // 旧アイコンフォント調整
        .icon-l-arrow-A-d01,
        .icon-l-arrow-A-u01 {
          font-size: 16px !important;
          width: 16px;
          height: 16px;
          cursor: pointer;
          color: blue;
          border-radius: 50%;
          background-color: #eee;
        }
      }
    }
    table#header-table {
      border-collapse: collapse;
      margin: 0 0;
      position: absolute;
      width: 170px;
      z-index: 1;
    }
    .scroll-outer {
      overflow-x: scroll;
      padding-left: 169px;
      #body-table-wrapper {
        margin: 0 0;
        width: 100em;
      }
    }
  }
}
.accordion-table-container {
  overflow: auto;
  .tab-selector--container {
    padding: 5px;
  }
}

