/*---------------------------------
　授精証明書　印刷画面
---------------------------------*/
.certificateAi {
  &--print {
    display: none;

    @media print {
      font-family: serif;
      display: flex;
      width: 752pt;
      margin: auto !important;

      justify-content: space-between;
      page-break-before: always;

      .certificateAi {
        &__main {
          width: 452pt;
        }

        &__head {
          margin: 0 0 0 125pt;
        }

        &__heading {
          font-size: 22pt;
          line-height: 1.05;
          width: 226pt;
          padding: 0 11pt;
          text-align: center;
          text-align-last: justify;
          text-justify: inter-ideograph;
          border-bottom: double 3pt #000;

          @media (-ms-high-contrast: none) {
            text-align: justify;
          }
        }

        &__body {
          margin: 20pt 0 0;
        }

        &__no {
          font-size: 10pt;
          line-height: 1.2;
          width: 144pt;
          padding: 0 0 0 4pt;
          text-align: center;
          text-align-last: justify;
          text-justify: inter-ideograph;

          @media (-ms-high-contrast: none) {
            text-align: justify;
          }
        }

        &__table {
          line-height: 1.2;
          width: 452pt;
          margin: 4pt 0 0;
          border-spacing: 0;
          border-collapse: collapse;
          border: 1pt solid #000;
        }

        &__row {
          height: 36pt;
        }

        &__cell {
          border: 1pt solid #000 !important;

          &:nth-child(1) {
            width: 40pt;
            padding: 0 6pt !important;
          }

          &:nth-child(2) {
            width: 111pt;
            padding: 0 6pt !important;
          }

          &:nth-child(3) {
            width: 120pt;
            padding: 0 6pt !important;
          }

          &:nth-child(4) {
            width: 39pt;
            padding: 0 3pt !important;
          }
        }

        &__text {
          .certificateAi {
            &__equalSpacing {
              &:not(:first-child) {
                margin: 5pt 0 0;
              }
            }
          }
        }

        &__equalSpacing {
          font-size: 11pt;
          display: block;
          text-align: center;
          text-align-last: justify;
          white-space: nowrap;
          text-justify: inter-ideograph;

          @media (-ms-high-contrast: none) {
            text-align: justify;
          }
        }

        &__verticalWriting {
          font-size: 11pt;
          line-height: 1.1;
          width: 1em;
          margin: auto !important;
        }

        &__fontSize7 {
          font-size: 7pt;
        }

        &__value {
          font-size: 11pt;
          text-align: center;
          letter-spacing: 1pt;
          word-break: break-all;
        }

        &__foot {
          margin: 3pt 0 0 10pt;

          &Text {
            font-size: 11pt;
            letter-spacing: -0.7pt;
          }

          &Date {
            font-size: 11pt;
            width: 200pt;
            margin: 15pt 0 0 13pt;
            text-align: center;
            text-align-last: justify;
            text-justify: inter-ideograph;

            @media (-ms-high-contrast: none) {
              text-align: justify;
            }
          }

          &No {
            display: flex;
            margin: 2pt 0 0 13pt;

            &Label {
              display: flex;

              &Text {
                font-size: 11pt;

                &:nth-child(2) {
                  margin: 0 0 0 14pt;
                }
              }
            }

            &Value {
              display: flex;
              margin: 0 0 0 20pt;

              &Text {
                font-size: 11pt;

                &:nth-child(1) {
                  min-width: 40pt;
                }

                &:nth-child(2) {
                  margin: 0 0 0 10pt;
                }

                &:nth-child(3) {
                  min-width: 40pt;
                  margin: 0 0 0 5pt;
                  text-align: center;
                }

                &:nth-child(4) {
                  margin: 0 0 0 5pt;
                }
              }
            }
          }

          &Items {
            margin: 16pt 0 0 13pt;
          }

          &Item {
            display: flex;
            margin: 10pt 0 0;
          }

          &Label {
            width: 61pt;

            flex-shrink: 0;
          }

          &Value {
            font-size: 11pt;
            min-width: 172pt;
            margin: 0 0 0 20pt;
          }

          &Seal {
            font-size: 11pt;
            width: 30pt;
            margin: 0 0 0 20pt;

            flex-shrink: 0;
          }
        }

        &__side {
          width: 282pt;
          padding: 96pt 0 0 0;
        }

        &__label {
          height: 186pt;
          padding: 7pt;
          border: 1pt dotted #000;

          &Heading {
            font-size: 8pt;
            width: 110pt;
            margin: auto;
            text-align: center;
            text-align-last: justify;
            text-justify: inter-ideograph;

            @media (-ms-high-contrast: none) {
              text-align: justify;
            }
          }
        }

        &__notes {
          margin: 35pt 0 0;
        }

        &__note {
          font-size: 9pt;
          line-height: 2.1;
          position: relative;
          margin: 0 0 17pt;
          padding: 0 0 0 6em;

          &::before {
            position: absolute;
            top: 0;
            left: 0;
            width: 6em;
            text-align: right;
            white-space: nowrap;
          }

          &:nth-child(1) {
            &::before {
              content: '備考 1）';
            }
          }

          &:nth-child(2) {
            &::before {
              content: '2）';
            }
          }
        }
      }
    }
  }
}