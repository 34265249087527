#fattening-report {
  .content-box {
    height: auto;
  }

  .fattening-report-table-menu {
    margin-bottom: 10px;
    width: 100%;
    .year-diff {
      color: #999;
      font-style: italic;
      padding-right: 14px;
    }
    input[type="radio"] {
      margin: 0;
    }
  }
  .fattening-report-table-wrapper {
    height: 80vh;
    overflow: auto;
  }
  .fattening-report-switcher {
    margin: 0 0 10px 0;
  }
  #fattening-report-table {
    overflow: hidden;

    &.clone th {
      border-bottom: 1px solid #ccc;
      border-top: none;
    }
    tr {
      border: none;
      &:first-child {
        td {
          border-top: none;
        }
      }
      td {
        padding: 8px 6px;
      }
    }
    th {
      border-bottom: none;
      padding: 8px 6px;
    }
    td {
      min-width: 80px;
    }


    thead {
      tr {
        th:not(:first-child) {
          width: auto;
          text-align: right;
        }
        th:first-child {
          width: auto;
          min-width: 124px;
          border-right: 1px solid #fff;
        }
        th:last-child {
          padding-right: 12px;
          border-right: 1px solid #fff;
        }
      }
    }
    tbody {
      &:hover {
        cursor: pointer;
      }
      tr {
        border-bottom: 1px solid #d4d4d4;
        &:hover {
          background: #fff;
          .accordion-toggle-button {
            color: #fff;
            background-color: transparent;
            border-color: transparent;
            border: none;
          }
          td:first-child {
            background-color: #fff;
            transition: 0.6s;
            .td-inner .summary-cell span span {
              background-color: #1d79b2;
              border-color: #1d79b2;
            }
          }
        }
        td {
          height: 48px;
          .td-inner {
            .diff-cell {
              color: #999;
              font-style: italic;
              span {
                font-size: 1.2rem;
              }
            }
          }
          &:first-child {
            width: auto;
            min-width: 150px;
            border-right: 1px solid #f0f0f0;
            .td-inner {
              .summary-cell {
                display: flex;
                justify-content: space-between;
                b + span {
                  padding-left: 6px;
                }
                span span {
                  border: 1px solid #d4d4d4;
                  border-radius: 4px;
                  display: inline-block;
                  height: 16px;
                  position: relative;
                  text-align: center;
                  width: 16px;
                }
              }
            }
          }
          &:not(:first-child) {
            .td-inner {
              height: 100%;
              display: flex;
              flex-direction: column;
              .data-cell {
                flex-basis: 50%;
                text-align: right;
                b {
                  font-weight: normal;
                }
              }
            }
          }
          &:last-child {
            padding-right: 12px;
            border-right: 1px solid #f0f0f0;
          }
        }
        &.summary-row {
          border-top: 1px solid #fff;
          &.open {
            border-bottom: 1px solid #fff;
          }
          td:first-child {
            color: #1d79b2;
          }
          &.no-detail {
            pointer-events: none;
            td:first-child {
              color: #212121;
            }
            .td-inner .summary-cell .accordion-toggle-button-wrapper {
              display: none;
            }
          }
        }
        &.detail-row {
          display: none;
          opacity: 0;
          &:hover {
            td:first-child {
              background-color: #f2f2f2;
            }
          }
          &.open {
            display: table-row;
            border-top: 1px solid #fff;
            border-bottom: 1px solid #fff;
            opacity: 1;
            transition: background-color 0.3s;
            td {
              padding: 6px 8px;
              b {
                font-weight: normal;
              }
              &:first-child {
                border-right: 1px solid #f0f0f0;
              }
              &:last-child {
                padding-right: 12px;
              }
            }
          }
          &.breed-row {
            td {
              background-color: #f2f2f2;
              b {
                font-weight: normal;
              }
              &:first-child {
                padding-left: 50px;
                background-color: #fff;
                padding: 0;
                border-right: 1px solid #fff;
                .td-inner {
                  align-items: center;
                  display: flex;
                  height: 100%;
                  padding: 8px 6px;
                  margin-left: 20px;
                  border-bottom: 1px solid #f2f2f2;
                  background-color: #f2f2f2;
                }
              }
              &:last-child {
                padding-right: 0;
                .td-inner {
                  padding-right: 12px;
                }
              }
              .td-inner {
                display: flex;
                height: 100%;
                min-height: 54px;
                padding: 4px 8px;
                margin-left: 0;
                border-bottom: 1px solid #f2f2f2;
              }
            }
            &.open {
              td {
                padding: 0;
              }
              &.last-detail-row {
                td .td-inner {
                  border-bottom: 6px solid #fff;
                }
              }
            }
          }
          &.sex-row {
            td {
              &:first-child {
                padding-left: 80px;
                background-color: #fff;
                padding: 0;
                .td-inner {
                  align-items: center;
                  display: flex;
                  height: 100%;
                  padding-left: 12px;
                  margin-left: 36px;
                }
              }
              &:last-child {
                .td-inner .data-cell b {
                  font-weight: normal;
                }
              }
            }
            &.open td:last-child {
              background-color: #fff;
            }
          }
        }
        &.summary-row + .detail-row.open .td-inner {
          border-top: 6px solid #fff;
        }
        &.last-detail-row {
          &.open {
            border-bottom: 1px solid #d4d4d4;
            td .td-inner {
              border-bottom: 6px solid #fff;
            }
          }
        }
      }
    }

    // 旧アイコンフォント調整
    .icon-l-arrow-A-d01 {
      font-size: 21px !important;
      position: absolute;
      top: -4px;
      left: -4px;
      width: auto;
      height: auto;
      padding: 0;
      transition: 0.3s;
      color: #1d79b2;
      border: none;
      border-radius: 0;
      background-color: transparent;
    }

    span {
      font-size: 13px;
      span {
        font-size: 1.2rem;
      }
    }
    &.monthly {
      tbody tr {
        td:last-child {
          border-left: 1px solid #f2f2f2;
          .td-inner {
            .data-cell b {
              font-weight: bold;
            }
          }
        }
        &.detail-row.open td:last-child {
          border-left: 1px solid #fff;
        }
      }
    }
  }

  // 牧場別レポート用
  .fattening-farm-report-container {
    .fattening-report-table-wrapper {
      #fattening-report-table {
        td {
          min-width: 130px;
        }
        &.monthly {
          tbody tr {
            td:last-child {
              .td-inner {
                .data-cell b {
                  font-weight: normal;
                }
              }
            }
          }
        }
      }
    }
  }

  // 項目別レポート用
  .fattening-item-report-container {
    .fattening-report-table-menu {
      margin: 0 0 10px 0;

      .flex-container {
        margin: 0 0 10px 0;
      }
    }

    .fattening-item-report-chart {
      margin: 0 0 15px 0;
    }
  }
}
