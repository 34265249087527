@media print {
  .schedule-list {

    button.btn-pencil.edit-btn.hover {
      display: none;

      i.icon-s-print {
        display: none;
      }
    }

    table.table-fix.smart {
      margin: 0px !important;
    }

    table {
      border: 1px solid black;

      tr {
        border: 1px solid black;

        th {
          border: 1px solid black !important;

        }
        td {
          border: 1px solid black !important;
        }
      }
    }

    label.checkbox--label {
      display: none;
    }

    th:first-child, td:first-child, col:first-child {
      display: none;
    }
  }
}

