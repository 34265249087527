// 乳房炎リスト
.mastitis-list {
  .working-day {
    display: none;
  }
  .start-cow-group {
    border-top: red solid 3px !important;
  }
  th {
    box-sizing: content-box;
  }
  .caution-cow {
    width: 25px;
  }
  .pl {
    width: 40px;
  }
  .cow-no {
    width: 33px;
  }
  .birth-number {
    width: 25px;
  }
  .mastitisCowGroupName {
    width: 25px;
  }
  .after-calvied-days {
    width: 33px;
  }
  .pregnancy {
    width: 80px;
  }
  .after-latest-fertilization-days {
    width: 33px;
  }
  .band {
    width: 50px;
  }
  .occurred-mastitis-count {
    width: 20px;
    padding: 0;
  }
  .endDate {
    width: 90px;
  }
  .remarks {
    width: 60%;
  }
  .no-end-date-milk-washout-period {
    background-color: yellow !important;
  }
  .end-date-2-days-ago {
    background-color: yellow !important;
  }
  .end-date-3-days-ago {
    background-color: rgba(0,191,255,0.5) !important;
  }
  td {
    a {
      color: black;
    }
  }
}

// 周産期リスト
.perinatal-list {
  .working-day {
    display: none;
  }
  .start-cow-group {
    border-top: red solid 3px !important;
  }
  th {
    box-sizing: content-box;
  }
  .caution-cow {
    width: 50px;
  }
  .pump-count {
    width: 15px;
  }
  .uterus-cleaning-count {
    width: 30px;
  }
  .cow-group-name {
    width: 30px;
  }
  .cow-no {
    width: 40px;
  }
  .birth-number, .pregnancy, .pregnancy-days, .bcs,
  .dry-previous-period-days, .dry-late-period-days {
    width: 30px;
  }
  .after-calvied-days, .calving-interval {
    width: 40px;
  }
  .calving-state {
    width: 150px;
  }
  .remarks {
    width: 50%;
  }
  .latestTreatmentYesterday {
    background-color: rgba(0,255,0,0.5) !important;
  }
  .cautionCow {
    background-color: yellow !important;
  }
  .birth-number-over-5 {
    background-color: rgba(255,0,0,0.5) !important;
  }
  .birthToTwins {
    background-color: rgba(255,0,0,0.5) !important;
  }
  .after-calvied-6days {
    background-color: rgba(255,0,0,0.5) !important;
  }
  .dry-days-over-80 {
    background-color: rgba(255,0,0,0.5) !important;
  }
  .dry-previous-days-under-10 {
    background-color: rgba(255,0,0,0.5) !important;
  }
  .dry-late-days-under-10 {
    background-color: rgba(255,0,0,0.5) !important;
  }
  td {
    a {
      color: black;
    }
  }
}
