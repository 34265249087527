$ui-grid-border: 1px solid #eee;
.ui-grid {
  height: 100%;
  border: none;
  .ui-grid-header {
    border-bottom: none;
    .ui-grid-top-panel {
      width: 100%;
      overflow: visible;
      background-color: transparent;
      .ui-grid-header-canvas .ui-grid-header-cell-row {
        background-color: #f3f3f3;
        .ui-grid-header-cell {
          border: none;
          &:first-child {
            display: flex;
            justify-content: center;
          }
          .customlist-th-inner {
            display: flex;

            span {
              font-size: 1.3rem;
            }
          }
          > div {
            padding: 8px 6px;
          }
        }
      }
    }
  }
  .ui-grid-viewport {
    &:hover {
      cursor: pointer;
    }
    .ui-grid-canvas {
      padding-top: 0;
      border-top: $ui-grid-border;
      .ui-grid-row {
        &:hover {
          background-color: #eee;

          .ui-grid-cell {
            .cow-labels-wrapper {
              .cow-labels {
                background: #fff !important;
              }
            }
          }

          .ui-grid-alertLabels-body--isAcuteIllness {
            &::before {
              display: none;
            }
          }
        }
        .ui-grid-cell {
          background-color: transparent;
          border: none;
          border-bottom: $ui-grid-border;
          label.cow-select input.ui-grid-checkbox {
            margin: 0;
          }
          .cow-labels-wrapper {
            padding: 0 4px;
            .cow-labels {
              display: inline-block;
              padding: 1px 5px;
              margin-top: 2px;
              font-size: 1.2rem;
              margin-left: 3px;
              border-radius: 2px;
              font-weight: bold;
              color: #666;
              background-color: #eee !important;
            }
          }

          &.ui-grid-row-selected,
          &.ui-grid-row-cow-board-opened {
            .cow-labels {
              background: #fff !important;
            }
          }
        }
      }
    }
  }
}

.ui-grid-row, .ui-grid-cell {
  height: auto !important;
}

.ui-grid-cell {
  overflow: visible;
  position: relative;
  float: none;
  display: table-cell;
  vertical-align: middle;
}

.ui-grid-header-cell, .ui-grid-cell-contents {
  white-space: normal;
  word-break: break-word;
}

.ui-grid-cell-contents {
  padding: 6px;
}

.ui-grid-checkbox {
  cursor: pointer;
  vertical-align: middle;
}

.ui-grid-row-selected {
  background-color: #eef0ff !important;
  border-color: #87a9ff !important;

  &::before {
    position: absolute;
    top: -1px;
    left: 0;
    width: 100%;
    height: 1px;
    content: '';
    background: #87a9ff;
  }

  .ui-grid-alertLabels-body--isAcuteIllness {
    &::before {
      display: none;
    }
  }
}

.ui-grid-row-cow-board-opened {
  background: #b1c5ff !important;
  border-color: #87a9ff !important;

  &::before {
    position: absolute;
    top: -1px;
    left: 0;
    width: 100%;
    height: 1px;
    content: '';
    background: #87a9ff;
  }

  .ui-grid-alertLabels-body--isAcuteIllness {
    &::before {
      display: none;
    }
  }
}

.ui-grid-sorted-none {
  &:hover {
    cursor: pointer;
    color: #999;
  }
  .customlist-th-inner {
    align-items: center;
    div.sort-icon {
      flex: 0 0 auto;
      background-image: url("../images/sorted-normal.svg");
      background-repeat: no-repeat;
      width: 10px;
      height: 16px;
      margin-left: 8px;
    }
  }
}

.ui-grid-sorted-asc {
  color: #2f5b6d !important;
  background-color: #dfdfdf;
  &:hover {
    cursor: pointer;
  }
  .customlist-th-inner {
    justify-content: flex-start;
    align-items: center;
    div.sort-icon {
      flex: 0 0 auto;
      background-image: url('../images/sorted-asc.svg');
      background-repeat: no-repeat;
      width: 10px;
      height: 18px;
      margin-left: 8px;
    }
  }
}

.ui-grid-sorted-desc {
  color: #2f5b6d !important;
  background-color: #dfdfdf;
  &:hover {
    cursor: pointer;
  }
  .customlist-th-inner {
    justify-content: flex-start;
    align-items: center;
    div.sort-icon {
      flex: 0 0 auto;
      background-image: url('../images/sorted-desc.svg');
      background-repeat: no-repeat;
      width: 10px;
      height: 18px;
      margin-left: 8px;
    }
  }
}
.ui-grid-alertLabels {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  height: 100%;

  &-body {
    position: relative;
    display: flex;
    width: 265px;
    height: 100%;
    padding: 0 0 0 96px;

    align-items: center;

    &--isAcuteIllness {
      position: relative;
      display: flex;
      width: 340px;
      height: 100%;
      padding: 0 0 0 172px;

      align-items: center;

      &::before {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 74px;
        height: 100%;
        content: '';
        background: #fcf6ed;
      }
    }
  }
}
