@media print {
  #alert-history {
    span.cow-labels {
      border: none;
      font-weight: normal;
      margin: none;
    }
    .content-box {
      margin-top: 1em;
    }
  }
}