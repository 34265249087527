.tabs {
  position: relative;
  min-height: 500px;
  padding: 10px;
  li {
    position: relative;
    border-bottom: 0;
    font-weight: bold;
    a {
      position: relative;
      top: 4px;
      display: block;
      width: 100%;
      height: 100%;
      padding: 12px 17px;
      color: #444;
      font-size: 1.3rem;
      line-height: 1;
      background-color: rgba(210,204,150,.7);
      //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
      border-radius: 3px 3px 0 0;
      &:hover {
        top: 0;
        //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
        border-radius: 3px 3px 0 0;
        color: #333;
        background-color: #fff;
        text-decoration: none;
        //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
        box-shadow: 0 1px 2px 0 rgba(90, 90, 90, 0.3);
      }
    }
  }
  .search-wrapper {
    position: relative;
    .search-box {
      label {
        display: block;
        margin-bottom: 2px;
        font-weight: normal;
      }
      div {
        float: left;
        display: inline-block;
        width: 33.3%;
        &:nth-of-type(1) {
          width: 25%;
        }
        &:nth-of-type(2) {
          width: 75%;
          font-weight: normal;
          display: flex;
          flex-wrap: wrap;
        }
      }
      ul li {
        padding: 5px 0;
        border-bottom: 1px solid rgba(230, 230, 230, 0.3);
        &:last-child {
          border-bottom: none;
        }
      }
    }
    .disuse {
      div {
        &:nth-of-type(2) {
          width: 74%;
          text-align: left;
        }
        p:first-child {
          margin-bottom: 7px;
        }
      }
      span {
        margin: 0 2px !important;
        font-size: 1.1rem;
      }
    }
    .text-search {
      input {
        display: inline-block;
        width: 100%;
        padding: 5px 6px;
        font-weight: normal;
        border: none;
      }
      div:nth-of-type(2) {
        width: 74%;
      }
    }
    button {
      display: block;
      width: 100%;
      padding: 8px 4px!important;
      color: #fff;
      //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
      border-radius: 6px;
      border: none;
      background: $orange;
      //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
      //Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
      transition: border linear 0.2s, box-shadow linear 0.2s;
      &:hover {
        opacity: .8;
      }
    }
  }
  .search-btn {
    float: right;
    //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
    border-radius: 6px;
    //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
    box-shadow: 0 1px 2px 0 rgba(90, 90, 90, 0.3);
  }
  .search-box {
    position: absolute;
    right: 0;
    top: 45px;
    width: 480px;
    padding: 0 15px;
    color: #fff;
    background-color: #4a4a4a;
    //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
    border-radius: 2px;
    //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
    box-shadow: 0 3px 4px 0 rgba(120, 120, 120, 0.5);
    z-index: 9999;

    label {
      &.search-item-label {
        height: 24px;
        line-height: 24px;
        margin-bottom: 0px;
      }
    }

    .state-items {
      .state {
        width: 33%;
      }
    }

    .individual-label-items {
      .individual-label {
        width: 33%;
      }
    }

    .days,
    .bcs,
    .count {
      width: 8rem;
    }

    &:before {
      content: "";
      position: absolute;
      top: -16px;
      right: 40px;
      width: 0;
      height: 0;
      border-right: 8px solid transparent;
      border-bottom: 20px solid #4a4a4a;
      border-left: 8px solid transparent;
    }
  }
  .tab.active a {
    top: 0;
    color: #333;
    background-color: #fff;
    //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
    box-shadow: 0 1px 2px 0 rgba(90, 90, 90, 0.3);
  }
}
