// Legacy CSS
@import "legacy";

// Defaults
@import "variables";
@import "defaults";
@import "override";
@import "icons-l";
@import "icons-m";
@import "icons-s";
@import "icons-ss";
@import "function";

// Commonly used helper classes
@import "common";

// Older components
@import "components/modal";
@import "components/datapicker";
@import "components/history";
@import "components/tab";
@import "components/content";
@import "components/print";
@import "components/pager";
@import "components/prints/import";

// Standard layout styles
@import "components/button";
@import "components/form";
@import "components/island";
@import "components/table";

// Components
@import "components/bulk-event-registration";
@import "components/time-budget";
@import "components/timeline";
@import "components/main-nav";
@import "components/chart";
@import "components/toast";
@import "components/alerts-settings";
@import "components/loader";
@import "components/help-dialog";
@import "components/toolbar";
@import "components/events-list";
@import "components/report-summary";
@import "components/cow-board";
@import "components/schedule-list";
@import "components/embryo-recovery-report";
@import "components/angular-ui-grid";
@import "components/notification";
@import "components/env-toast";
@import "components/row-clickable-list";
@import "components/side-menu";
@import "components/block-ui";
@import "components/alert-label";
@import "components/contents";
@import "components/group-activity-scatter-plot";
@import "components/reproduction-config";
@import "components/calving-sensor-number";
@import "components/temporary-group-view";

// Components (modern)
@import "components/u-header";
@import "components/u-heading";
@import "components/u-sub-heading";
@import "components/u-modal";
@import "components/u-button";
@import "components/u-sort-order-switcher";
@import "components/u-tooltip";
@import "components/u-footer";
@import "components/u-wrapper";
@import "components/u-main";
@import "components/u-side-board";
@import "components/u-menu-list";
@import "components/u-grid";
@import "components/u-timeline";
@import "components/u-content";
@import "components/u-table";
@import "components/u-label";
@import "components/u-card";
@import "components/u-list";
@import "components/u-alert";
@import "components/u-board";
@import "components/u-main-board";
@import "components/u-right-board";
@import "components/u-footer-board";
@import "components/u-notice";
@import "components/u-widget";
@import "components/u-form";

// SHAME
@import "shame";

// login page
@import "page/login";

// external genom linkage
@import "page/genom-linkage";

.barn {
  @import "components/unit-chart";
  @import "components/activity-scatter-plot-chart";
  @import "components/activity-rate-chart";
  @import "components/activity-stacked-bar-chart";
  @import "components/active-milk-chart";
  @import "components/alert";
  @import "components/ordered-list";
  @import "components/master";
  @import "components/accordion-table";
  @import "page/top";
  @import "page/calendar";
  @import "page/timebudget";
  @import "page/cow-list";
  @import "page/cow-detail";
  @import "page/cow-group-list";
  @import "page/individual";
  @import "page/milking";
  @import "page/machinery";
  @import "page/entry";
  @import "page/management";
  @import "page/workList";
  @import "page/setting";
  @import "page/movement";
  @import "page/relationGraph";
  @import "page/bulk-registration";
  @import "page/cow-shed";
  @import "page/senser-list";
  @import "page/bulk-entry-history";
  @import "page/timeline";
  @import "page/alert-location";
  @import "page/reproduction-performance-report";
  @import "page/reproduction-summary";
  @import "page/carcass-report";
  @import "page/beef-history";
  @import "page/fattening-report";
  @import "components/activity-chart-range-selector";
  @import "components/help";
  @import "page/alert-calving";
  @import "page/alert-calving-sensor-dropped";
  @import "page/alert-eartag-sensor-dropped";
}

@import "components/prints/certificate-ai";
@import "components/prints/certificate-birth";

// main.cssはimport記述のみにするため一旦分離、.barn整理後に下記ファイルの記述は各SCSSファイルに移行する予定
@import "other";

@import "components/prints/reproduction-performance-report";
@import "components/prints/carcass-report";
@import "components/prints/alert-history";
@import "components/prints/beef-history";
@import "components/prints/customlist";
@import "components/prints/schedule-list";
@import "components/prints/accordion-table";

@page {
  margin: 10mm;
}
