/*---------------------------------
　アラート
---------------------------------*/
.uAlert {
  border-bottom: 4px solid #000;

  &__body {
    padding: 32px 0 8px 24px;
  }

  &__container {
    display: flex;
    margin: 0 0 16px !important;
  }

  &__outer {
    display: flex;
  }

  &__inner {
    display: flex;
  }

  &__dysstasia {
    margin: 24px 0 0 !important;
    padding: 3px 0;
    border-top: 2px solid #693400;
    border-bottom: 2px solid #693400;
    background: #1f0203;

    &Body {
      display: flex;
      height: 78px;
      border-top: 1px solid #693400;
      border-bottom: 1px solid #693400;

      align-items: center;
      justify-content: center;
    }

    &Main {
      margin: 0 0 0 19px !important;
      color: #ff7207;
    }

    &Heading {
      font-size: 15px !important;
    }

    &Text {
      font-size: 17px !important;
      font-weight: 700;
    }

    &Button {
      position: relative;
      display: flex;
      height: 44px;
      margin: 0 0 0 20px !important;
      padding: 0 14px 0 24px;
      opacity: 1;
      color: white(primary);
      border: 1px solid rgba(204, 73, 3, .48);
      border-radius: 22px;
      background: linear-gradient(90deg, #bc0101 0%, #910000 54%, #7e3f00 100%);
      box-shadow: 0px 0px 24px #910000;

      align-items: center;

      &:hover {
        text-decoration: none;
        color: white(primary);
        background: linear-gradient(90deg, #960909 0%, #740000 54%, #773B00 100%);
      }

      &::before,
      &::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: block;
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        margin: auto;
        content: '';
        animation: animationDysstasiaButton 2s linear infinite;
        pointer-events: none;
        opacity: 1;
        border: 1px solid rgba(204, 73, 3, .48);
        border-radius: 33px;
      }

      &::after {
        animation-delay: 1s;
      }

      @keyframes animationDysstasiaButton {
        0% {
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 1;
          border: 1px solid rgba(204, 73, 3, .48);
          border-radius: 33px;
        }

        100% {
          left: -16px;
          width: calc(100% + 32px);
          height: 76px;
          opacity: 0;
          border: 2px solid rgba(204, 73, 3, .48);
          border-radius: 38px;
        }
      }
    }

    &Value {
      font-size: 24px !important;
      font-weight: 700;
      padding-top: 2px;

      align-self: baseline;
    }

    &Unit {
      font-size: 14px !important;
      margin: 4px 0 0 7px !important;
    }

    .icon-l-caution {
      font-size: 31px !important;
      color: #ff7207;
    }

    .icon-ss-chevron-r01 {
      margin: 0 0 0 13px;
    }
  }

  &__content {

    &--primary,
    &--secondary,
    &--tertiary {
      margin: 0 10px 0 0 !important;
      padding: 10px 0 0;
      color: white(primary);
      border-radius: 8px;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, .16);

      .uAlert__items {
        padding: 0;
      }

      .uAlert__item {
        padding: 0 3px;

        &:last-child {
          .uAlert__link {
            padding: 0 5px;
          }
        }
      }

      .uAlert__box {
        padding: 0 3px;

        &::before {
          background-color: rgba(#fff, .16);
        }
      }

      .uAlert__heading,
      .uAlert__heading--disabled {
        text-align: left;
      }

      .uAlert__link {
        text-decoration: inherit;
      }
    }

    &--primary,
    &--secondary {

      .uAlert__heading {
        padding: 0 10px;
      }

      .uAlert__link {
        min-width: 40px;
      }

      .uAlert__item {
        &:not(:first-child) {
          padding: 0 7px 0 4px;
        }
      }
    }

    &--primary {
      border: 1px solid rgba(165, 77, 65, .56);
      background: linear-gradient(90deg, #a54c00, #840048);

      .uAlert__box {
        &::before {
          left: -3px;
        }
      }

      .uAlert__link:hover {
        background: rgba(180, 98, 45, .88);
      }

      .uAlert__item {
        &:last-child {
          .uAlert__link:hover {
            background: rgba(167, 21, 88, .88);
          }
        }
      }
    }

    &--secondary {
      border: 1px solid rgba(141, 57, 102, .56);
      background: linear-gradient(90deg, #a5025b, #6f0a7c);

      .uAlert__link:hover {
        background: rgba(255, 222, 240, .32);
      }

      .uAlert__item {
        &:last-child {
          .uAlert__link:hover {
            background: rgba(174, 20, 181, .8);
          }
        }
      }

      .uAlert__box {
        &::before {
          display: none;
        }

        &:last-child {
          .uAlert__value {
            font-size: 17px !important;
          }
        }
      }
    }

    &--tertiary {
      border: 1px solid rgba(105, 63, 136, .49);
      background: linear-gradient(90deg, #6c008e 0%, #36168d 50%, #1a4a8b 100%);

      .uAlert__heading {
        padding: 0 8px;
      }

      .uAlert__item {
        .uAlert__link:hover {
          background: rgba(206, 109, 237, .32);
        }

        .uAlert__item {
          &:last-child {
            .uAlert__link:hover {
              background: rgba(92, 150, 229, .32);
            }
          }
        }
      }

      .uAlert__box {
        &::before {
          left: -3px;
        }
      }
    }

    &--quaternary {
      padding: 10px 0 0;
      color: white(primary);
      border: 1px solid #333;
      border-radius: 8px;
      background: #2e2e2e;
      box-shadow: 0 1px 6px rgba(0, 0, 0, .16);

      .uAlert__items {
        padding: 0 4px;
      }

      .uAlert__item {
        min-width: 72px;
        padding: 0 5px 0 4px;
      }

      .uAlert__link {
        &:hover {
          background: rgba(144, 144, 144, .88);
        }
      }
    }
  }

  &__box {
    position: relative;
    display: inline-flex;
    height: 39px;

    align-items: center;
    justify-content: flex-end;

    &::before {
      position: absolute;
      top: 50%;
      left: -5px;
      width: 1px;
      height: 32px;
      content: "";
      transform: translateY(-50%);
      background: #adadad;
    }

    &--disabled {
      pointer-events: none;
      opacity: .35;

      @extend .uAlert__box;
    }
  }

  &__value {
    font-size: 21px !important;
    font-weight: 700;
    margin: -1px 0 0 4px !important;

    &:first-child {
      margin-left: 0 !important;
    }
  }

  &__heading {
    font-size: 14px !important;
    font-weight: 700 !important;
    line-height: 1.2;
    display: block;
    text-align: center;
    white-space: nowrap;

    &--disabled {
      opacity: .35;

      @extend .uAlert__heading;
    }
  }

  &__icon {
    margin: 1px 0 0 4px !important;

    _:lang(x)+_:-webkit-full-screen-document,
    & {
      margin-top: 0 !important;
    }
  }

  &__items {
    display: flex;
    margin: 0 !important;
    padding: 0 8px;

    align-items: center;
    justify-content: flex-start;
  }

  &__item {
    display: flex;
    flex-direction: column;
    padding: 0 5px;

    justify-content: flex-start;

    &:first-child {
      .uAlert__box::before {
        display: none;
      }
    }

    &--secondary {
      display: flex;
      flex-direction: row;
      padding: 0 3px;

      justify-content: flex-start;
    }
  }

  &__label {
    font-size: 13px !important;
    margin: 0 4px 0 0 !important;
  }

  &__link {
    display: inline-flex;
    height: 30px;
    padding: 0 5px;
    color: inherit;
    border-radius: 10vw;

    align-items: center;
    justify-content: flex-end;

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      content: "";
    }

    &:hover {
      text-decoration: none;
      color: inherit;
      background: rgba(144, 144, 144, .32);
      box-shadow: 0 3px 6px rgba(0, 0, 0, .16);
    }

    &.uAlert__button {
      margin: 0 !important;
      padding-right: 8px;
      padding-left: 8px;
      background: rgba(#de84e2, .32);

      flex: 0 0 auto;
    }
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    width: 148px;
    margin: 0 0 0 30px !important;

    align-items: flex-start;
    justify-content: center;
  }

  &__button {
    font-size: 14px !important;
    font-weight: 700;
    display: inline-flex;
    margin: 0 12px 0 0 !important;
    padding: 6px 12px 6px 16px;
    color: inherit;
    border: 1px solid rgba(238, 238, 238, .16);
    border-radius: 10vw;
    background: linear-gradient(90deg, #eee 0%, #ddd 100%);
    box-shadow: 0 1px 2px rgba(0, 0, 0, .16);

    align-items: center;

    &:hover {
      text-decoration: inherit;
      color: inherit;
      background: linear-gradient(#ccc 0%, #ccc 100%);
    }

    &Value {
      font-size: 15px !important;
      margin: 0 0 0 16px !important;
    }

    &Icon {
      margin: 0 0 0 10px !important;
    }

    &--important {
      font-size: 17px;
      display: flex;
      height: 50px;
      margin: 0 !important;
      color: inherit;
      border-color: rgba(157, 18, 0, .48);
      border-radius: 25px;
      background: linear-gradient(90deg, #a80007 0%, #bc6500 100%);
      box-shadow: 0 2px 4px rgba(0, 0, 0, .16);

      align-items: center;

      &:hover {
        text-decoration: inherit;
        color: inherit;
        border-color: rgba(245, 50, 24, .8);
        background: linear-gradient(90deg, #cc0009 0%, #ff8900 100%);
      }

      .uAlert__buttonValue {
        font-size: 21px !important;
        margin-left: 44px !important;
      }

      .icon-l-caution {
        margin: 0 17px 0 20px;
      }

      .icon-ss-chevron-r01 {
        margin: 0 14px 0 11px;
      }
    }

    &--action {
      display: inline-flex;
      height: 30px;
      margin: 0 !important;
      padding: 0 11px 0 9px;
      color: white(primary);
      border: 1px solid #5f5f5f;
      border-radius: 15px;
      background: none;

      align-items: center;

      &:last-child {
        margin-top: 7px !important;
      }

      &:hover {
        text-decoration: inherit;
        color: white(primary);
        background: #3b3b3b;
      }

      .uAlert__buttonLabel {
        font-size: 14px;
        margin: 0 0 0 8px !important;
        white-space: nowrap;

        &Omit {
          font-size: inherit;
        }
      }
    }
  }

  @media (max-width: 1023px) {
    &__button {
      &--action {

        .uAlert__buttonLabel {
          &Omit {
            display: none;
          }
        }
      }
    }
  }

  @media (pointer: coarse),
  (max-width: 1023px) {
    &__container {
      +.uAlert__container {
        .uAlert__button {
          margin-top: 8px !important;
        }
      }
    }

    &__outer {
      display: block;
    }

    &__buttons {
      display: flex;
      flex-direction: column;

      justify-content: center;
    }

    &__grow {
      margin: 14px 10px 0 0 !important;

      flex-grow: 1;
    }

    &__content {
      &--quaternary {
        padding: 0;
        border: none;
        background: none;

        .uAlert {
          &__items {
            padding: 0;
          }

          &__item {
            position: relative;
            flex-direction: row;
            width: 100%;
            min-width: auto;
            padding: 0 3px 0 9px;
            border: 1px solid #333;
            background: #2e2e2e;

            align-items: center;
            justify-content: space-between;

            &:first-child {
              border-radius: 8px 0 0 8px;
            }

            &:last-child {
              border-radius: 0 8px 8px 0;
            }

            &:not(:first-child) {
              margin: 0 0 0 1px !important;
            }
          }

          &__box {
            position: static;
            height: 40px;

            &::before {
              display: none;
            }
          }

          &__link {
            height: 40px;

            &:hover {
              background: none;
              box-shadow: none;
            }
          }
        }
      }
    }
  }
}