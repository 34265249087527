/*---------------------------------
　ライトボード
---------------------------------*/
.uRightBoard {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  width: 304px;
  padding: 0 8px 0 0;
  transition: right .3s ease;
  background: linear-gradient(90deg, #000 0%, #1f1f1f 100%);

  &::before,
  &:after {
    position: absolute;
    z-index: 1;
    left: 0;
    width: calc(100% - 20px);
    height: 48px;
    content: "";
    pointer-events: none;
  }

  &::before {
    top: 0;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
  }

  &::after {
    bottom: 0;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
  }

  &__button {
    position: absolute;
    top: 50%;
    right: 100%;
    display: inline-flex;
    width: 44px;
    height: 44px;
    transform: translate(50%, -50%);
    color: white(primary);
    border: 2px solid #000;
    border-radius: 50%;
    background: #2e2e2e;

    align-items: center;
    justify-content: center;

    &-icon {
      transition: all .3s ease;
    }

    &:hover {
      background-color: #444;
    }
  }

  &__container {
    overflow-y: scroll;
    width: 100%;
    height: 100%;
    padding: 48px 14px 24px 26px;

    scrollbar-width: thin;

    &::-webkit-scrollbar {
      position: relative;
      width: 8px;
    }

    &::-webkit-scrollbar-button {
      display: none;
    }

    &::-webkit-scrollbar-track {
      border-radius: 4px;
      background: #1f1f1f;

      &:hover {
        background: #444;

        &::-webkit-scrollbar-thumb {
          background: none;
        }
      }
    }

    &::-webkit-scrollbar-thumb {
      height: 17px;
      border-radius: 4px;
      background: #b2b2b2;

      &:hover {
        background: #ccc;
      }
    }
  }

  &--closed {
    right: -280px;

    @extend .uRightBoard;

    .uRightBoard__button {
      &Icon {
        transform: rotate(180deg);
      }
    }
  }

  &__card {
    overflow: hidden;
    margin: 0 0 24px !important;
    padding: 0 11px;
    -webkit-transform: translate3d(0, 0, 0);
    color: white(primary);
    border: 1px solid #3b3b3b;
    border-radius: 8px;

    &Button {
      display: inline-flex;
      width: 24px;
      height: 24px;
      border: 1px solid transparent;
      border-radius: 50%;
      background: #020303;

      align-items: center;
      justify-content: center;

      &Icon {
        transition: all .3s ease;
        transform: rotate(-90deg);
      }

      &:hover {
        border-color: #575757;
        background-color: #444;
      }
    }

    &Container {
      padding: 6px 0 0;
    }

    &Header {
      position: relative;
      display: flex;
      margin: 0 -11px !important;
      padding: 6px 16px 6px 14px;
      background: #2e2e2e;

      align-items: center;

      &--outer {
        position: relative;
        display: flex;
        margin: 0 !important;
        padding: 6px 16px 10px 14px;
        background: none;

        align-items: center;

        &.uRightBoard__cardTitle {
          font-size: 17px !important;
        }
      }

      &--closed {
        @extend .uRightBoard__cardHeader;

        .uRightBoard__cardButton {
          background-color: #444;

          &Icon {
            transform: rotate(90deg);
          }

          &:hover {
            border-color: #575757;
            background-color: #1f1f1f;
          }
        }
      }

      &Button {
        &::before {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          content: "";
        }
      }

      &Title {
        padding: 2px 0;
      }
    }

    &Title {
      font-size: 14px !important;
      margin: 0 auto 0 0 !important;
    }

    &Table {
      width: 100% !important;
      border-spacing: 0 !important;
      border-collapse: collapse !important;
      border: 0 !important;

      &Head {
        .uRightBoard__cardTableValue {
          width: auto;
          margin: 0 auto;
          padding-right: 0;
          padding-left: 0;
        }

        &~.uRightBoard__cardTableBody .uRightBoard__cardTableHeader:not([class]) {
          min-width: 6em;
        }
      }

      &Body {
        .uRightBoard__cardTableRow:not(:first-child) {

          [class^="uRightBoard__cardTableHeader"]:not(:empty),
          [class^="uRightBoard__cardTableHeader"]~.uRightBoard__cardTableData,
          [class^="uRightBoard__cardTableHeader"]~.uRightBoard__cardTableSeparate,
          .uRightBoard__cardTableData:first-child:last-child[colspan],
          .uRightBoard__cardTableSeparate:first-child:last-child[colspan] {
            border-top: 1px solid #707070 !important;
          }
        }

        .uRightBoard__cardTableHeaderData {
          &.uRightBoard__cardTableSeparate {
            border-left: 1px solid #707070 !important;
          }
        }
      }

      &Foot {
        .uRightBoard__cardTableRow {
          border-top: 4px double #707070;
        }

        .uRightBoard__cardTableHeader,
        .uRightBoard__cardTableData {
          font-weight: 700;
          padding-top: 14px !important;
          padding-bottom: 14px !important;
        }
      }

      &Row {
        background: none !important;
      }

      &Header,
      &Data {
        overflow: hidden;
      }

      &Header {
        font-size: 13px !important;
        font-weight: 400;
        min-height: auto !important;
        padding: 8px 0 7px 8px !important;
        text-align: left !important;
        vertical-align: top !important;
        color: white(primary) !important;
        border: 0 !important;
        background: none !important;

        &:not([class]) {
          min-width: 4.5em;
        }

        &:empty {
          min-width: auto;
        }

        &--middle {
          width: 40px;
          min-width: 40px;
          min-height: auto !important;
          padding: 0 5px !important;
          text-align: center !important;
          vertical-align: middle !important;
          color: white(primary) !important;
          border: 0 !important;
          background: none !important;
        }
      }

      &Data {
        min-height: auto !important;
        text-align: left !important;
        text-align: right !important;
        vertical-align: middle !important;
        border: 0 !important;
        background: none !important;
      }

      &Separate {
        padding: 5px !important;
        border-top: none !important;
        border-right: none !important;
        border-left: 1px solid #707070 !important;
      }

      &Calculation {
        display: flex;

        align-items: flex-end;
        justify-content: center;
      }

      &Column {
        font-size: 14px !important;
        font-weight: 400;
        padding: 0 6px;
        text-align: center;

        .uRightBoard__cardTableLabel {
          font-size: 13px;
          display: block;
          color: rgba(255, 255, 255, 0.67);
        }
      }

      &Value {
        font-size: 14px !important;
        padding: 0 12px 0 12px;
        white-space: nowrap;

        &:first-child {
          margin: 0 0 0 auto;
          padding-left: 0;
        }

        &+& {
          width: 60px;
        }
      }

      &Head {
        font-size: 13px !important;

        &--secondary {
          margin-left: -5px !important;
        }
      }

      &Icon {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
      }

      &Item {
        position: relative;
        display: flex;
        padding: 0 10px 0 8px;

        align-items: center;
        justify-content: space-between;

        .uRightBoard__cardTableLink {
          font-size: 12px;
          margin-right: -13px !important;
          color: white(primary);

          &:hover {
            text-decoration: none;
          }

          &:first-child:last-child {
            width: calc(100% + 13px);
          }
        }

        &+& {
          margin: 1px 0 0;
        }
      }

      &Link {
        display: flex;
        margin: 0 0 0 auto !important;
        padding: 3px 12px 3px 10px !important;
        border-radius: 10vw;

        align-items: center;
        justify-content: space-between;

        &::before {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          content: "";
        }

        &:hover {
          background: #444;
        }

        &--disabled {
          pointer-events: none;
          opacity: 0.35;

          @extend .uRightBoard__cardTableLink;
        }
      }

      &Relation {
        position: relative;
        margin: 0 7px 0 1.85em !important;
        padding: 5px 0;
        border-radius: 8px;
        background: #1f1f1f;

        &::before {
          position: absolute;
          right: 100%;
          bottom: 50%;
          width: 9px;
          height: 75%;
          content: "";
          border-bottom: 1px solid #525252;
          border-left: 1px solid #525252;
        }

        .uRightBoard__cardTableData {
          padding-right: 20px;
        }

        .uRightBoard__cardTableIcon {
          right: 6px;
        }

        .uRightBoard__cardTableLink {
          min-width: 56px;
          margin-right: -6px !important;
          padding: 2px 4px 2px 8px !important;
        }
      }
    }
  }

  @media (pointer: coarse),
  (max-width: 1023px) {
    display: none;
  }
}