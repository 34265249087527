/*---------------------------------
　ウィジェット
---------------------------------*/
.uWidget {
  width: calc(1 / 2 * 100% - 20px);
  max-width: 450px;
  margin: 0 10px 20px !important;
  padding: 24px 39px 32px;
  color: white(primary);
  border: 1px solid #333;
  border-radius: 8px;
  background: #2e2e2e;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);

  .uMainBoard--rightBoardClosed & {
    width: calc(1 / 3 * 100% - 20px);
  }

  &__button {
    font-size: inherit;
    display: inline-flex;
    height: 36px;
    margin: 0 0 0 8px !important;
    padding: 0 16px;
    color: inherit;
    border-radius: 10vw;
    background: #242424;

    align-items: center;

    &:hover {
      text-decoration: inherit;
      color: inherit;
      background-color: #444;
    }

    &Icon {
      &--desktop {
        display: inline-flex;
        margin: 0 10px 0 0 !important;
      }

      &--tablet {
        display: none !important;
      }
    }

    &Label {
      font-size: 14px !important;
    }
  }

  &__date {
    font-size: 15px;
    font-weight: 700;
    display: inline-flex;
    margin: 10px 0 32px !important;
    padding: 2px 4px;
    color: white(primary);
    border: 2px solid rgba(#fff, .16);
    border-radius: 10vw;

    &Current,
    &Next,
    &Prev {
      font-size: inherit !important;
      display: inline-flex;

      align-items: center;

      &:hover {
        text-decoration: inherit;
        background: #444 !important;
      }

      &:active {
        background-color: #707070 !important;
      }
    }

    &Current {
      width: 117px;
      padding: 0 8px !important;
      cursor: pointer;
      text-align: center;
      color: inherit !important;
      border: 0 !important;
      border-radius: 8px !important;
      outline: none;
      background: none !important;
      box-shadow: none !important;
    }

    &Next,
    &Prev {
      width: 32px;
      height: 32px;
      color: rgba(255, 255, 255, 0.7) !important;
      border-radius: 50%;

      justify-content: center;
    }

    &Prev {
      transform: rotate(180deg);
    }
  }

  &__header {
    display: flex;
    min-height: 36px;
    margin: 0 -16px 10px -8px !important;

    align-items: center;

    .uWidget__title {
      margin: 0 auto 0 0;
    }
  }

  &__heading {
    font-size: 14px !important;
    margin: 0 0 13px !important;
    padding: 0 0 0 8px;
  }

  &__title {
    font-size: 17px !important;
    font-weight: 700;
    transition: all .3s ease;
  }

  /*
　　作業予定
　---------------------------------*/
  &--schedule {
    padding-bottom: 8px;

    @extend .uWidget;

    &__table {
      font-size: 14px;
      width: 100% !important;
      margin: 0 0 24px !important;
      border-spacing: 0 !important;
      border-collapse: collapse !important;
      color: $textGrey;
      border: none !important;
      border-radius: 8px;
      background: white(primary);

      &Row {
        background: none !important;
      }

      &Header {
        font-size: inherit !important;
        font-weight: 700;
        width: 50%;
        min-height: auto !important;
        padding: 4px 16px !important;
        text-align: left !important;
        word-break: break-all;
        color: inherit !important;
        border: 0 !important;
        background: none !important;
      }

      &Data {
        font-size: inherit !important;
        font-weight: 700;
        width: 50%;
        min-height: auto !important;
        padding: 4px 16px 4px 0 !important;
        text-align: left !important;
        color: inherit !important;
        border: 0 !important;
        background: none !important;
      }

      &Body {
        .uWidget--schedule__tableRow {
          &:first-child {

            .uWidget--schedule__tableHeader,
            .uWidget--schedule__tableData {
              padding-top: 16px !important;
            }
          }

          &:last-child {

            .uWidget--schedule__tableHeader,
            .uWidget--schedule__tableData {
              padding-bottom: 16px !important;
            }
          }
        }
      }

      &Foot {
        border-top: 1px solid #ccc;

        .uWidget--schedule__tableHeader,
        .uWidget--schedule__tableData {
          padding-top: 12px !important;
          padding-bottom: 12px !important;
        }

        .uWidget--schedule__tableLink {
          height: 36px;
          border-radius: 18px;
        }
      }

      &Value {
        font-size: 17px !important;
        word-break: break-all;
      }

      &Icon {
        margin: 0 0 0 12px !important;
      }

      &Link {
        font-size: inherit;
        display: flex;
        padding: 2px 10px 3px;
        color: inherit;
        border-radius: 15px;
        background: #eee;

        align-items: center;
        justify-content: flex-end;

        &:hover {
          text-decoration: none;
          color: inherit;
          background-color: #ddd;
        }
      }

      @media (min-width: 1024px) and (max-width: 1279px) {
        .uWidget--schedule__tableHeader {
          width: auto;
        }

        .uWidget--schedule__tableData {
          width: 104px;
        }
      }
    }
  }

  /*
　　作業日報
　---------------------------------*/
  &--dailyReport {
    @extend .uWidget;

    &__button {
      font-size: inherit;
      display: inline-block;
      padding: 8px 28px;
      color: inherit;
      border: 1px solid rgba(112, 112, 112, 0.08);
      border-radius: 10vw;
      background: linear-gradient(90deg, #3b3b3b 0%, #414243 100%);
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.16);

      &:hover {
        text-decoration: inherit;
        color: inherit;
        background: linear-gradient(90deg, #666 0%, #555 100%);
      }
    }

    &__list {
      margin: 0 0 20px !important;
      padding: 18px 17px 20px;
      border-radius: 8px;
      background-color: #1f1f1f;

      &:last-child {
        margin-bottom: 0 !important;
      }

      .uWidget__heading {
        display: flex;
        margin: 0 0 12px !important;
        padding: 0;

        align-items: center;

        &::before {
          display: block;
          width: 4px;
          height: 16px;
          margin: 0 9px 0 3px;
          content: '';
          border-radius: 8px;
        }

        &--group {
          &1 {
            &::before {
              background-color: #176fe3;
            }
          }

          &2 {
            &::before {
              background-color: #c19124;
            }
          }

          &3 {
            &::before {
              background-color: #ab19b1;
            }
          }

          &4 {
            &::before {
              background-color: #32a0b3;
            }
          }

          &5 {
            &::before {
              background-color: #9b9ea0;
            }
          }
        }
      }
    }

    &__items {
      font-size: 14px;
      display: flex;
      margin: -4px !important;
      color: $textGrey;

      flex-wrap: wrap;
    }

    &__item {
      display: inline-flex;
      width: 78px;
      height: 72px;
      margin: 4px !important;
      text-align: right;

      &Link {
        font-size: inherit;
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 10px 0 5px 8px;
        color: inherit;
        border-radius: 8px;
        background: white(primary);

        justify-content: space-between;

        &:hover {
          text-decoration: inherit;
          color: inherit;
          background-color: #eee;
        }
      }

      &Label {
        font-size: 14px;
        font-weight: 700 !important;
        line-height: 1.2;
        text-align: left;
        white-space: nowrap;

        &.perinatal_and_metabolic_diseases,
        &.fresh_check,
        &.timed_ai {
          font-size: 13px;
        }
      }

      &Data {
        font-size: 14px !important;
        margin: 0 8px 0 0 !important;
        white-space: nowrap;
      }

      &Num {
        font-size: 17px;
        font-weight: 700;
        line-height: 1.4;
      }
    }

    &__timeline {
      font-size: 14px;
      width: 100%;
      text-align: center;

      &EditButton {
        position: absolute;
        top: 0;
        right: 0;
        display: inline-flex;
        width: 36px;
        height: 36px;
        transition: all .3s ease;
        color: white(primary);
        border-radius: 50%;
        background: #666;

        align-items: center;
        justify-content: center;

        &:hover {
          background: #666;
        }

        &--closed {
          position: absolute;
          top: 0;
          right: 0;
          display: inline-flex;
          width: 36px;
          height: 36px;
          transition: all .3s ease;
          color: #333;
          border-radius: 50%;
          background: #f2f2f2;

          align-items: center;
          justify-content: center;

          &:hover {
            background-color: #ddd;
          }
        }
      }

      &ToggleButton {
        position: absolute;
        top: 0;
        right: 0;
        display: inline-flex;
        width: 36px;
        height: 36px;
        transition: all .3s ease;
        color: $textGrey;
        border-radius: 50%;
        background: #e6e6e6;

        align-items: center;
        justify-content: center;

        &:hover {
          background-color: #ddd;
        }

        .icon-s-chevron-r01 {
          transition: all .3s ease;
          transform: rotate(-90deg);
        }

        &--closed {
          position: absolute;
          top: 0;
          right: 0;
          display: inline-flex;
          width: 36px;
          height: 36px;
          transition: all .3s ease;
          color: $textGrey;
          border-radius: 50%;
          background: #f2f2f2;

          align-items: center;
          justify-content: center;

          &:hover {
            background-color: #ddd;
          }

          .icon-s-chevron-r01 {
            transition: all .3s ease;
            transform: rotate(90deg);
          }
        }
      }

      &Menu {
        position: absolute;
        z-index: 1;
        top: 36px;
        right: 0;
        overflow: hidden;
        width: 96px;
        padding: 8px 0;
        border-radius: 8px;
        background: white(primary);
        box-shadow: 0px 3px 16px rgba(0, 0, 0, 0.32);

        &Link {
          font-size: 14px;
          display: block;
          display: flex;
          height: 40px;
          padding: 0 20px;
          color: black(primary);

          align-items: center;

          &:hover {
            text-decoration: none;
            color: black(primary);
            background-color: #f2f2f2;
          }
        }
      }

      &Container {
        position: relative;
        overflow: hidden;
        margin: 6px 0 0 !important;
        transition: height .3s ease;
      }

      &Mask {
        font-size: inherit;
        position: absolute;
        bottom: 0;
        display: flex;
        width: 100%;
        height: 30px;
        content: '';
        color: inherit;
        background: linear-gradient(180deg, #eee 0px, #eee 20px, #ccc 30px);

        align-items: center;
        justify-content: center;

        &:hover {
          text-decoration: inherit;
          color: inherit;
        }

        .icon-m-dots {
          font-size: 11px !important;
          transform: rotate(90deg);
        }
      }

      &Date {
        font-size: 14px;
        position: absolute;
        top: 18px;
        right: calc(100% + 26px);
        white-space: nowrap;
        color: rgba(255, 255, 255, 0.67);
      }

      &Head {
        font-size: 14px;
        position: relative;
        display: flex;
        padding: 0 52px 0 0;
        color: #0d52a2;

        align-items: center;
        flex-wrap: wrap;
      }

      &Item {
        margin: 0 0 8px 49px !important;
        padding: 10px;
        text-align: left;
        color: $textGrey;
        border-radius: 8px;
        background-color: white(primary);

        &~.uWidget--dailyReport__button {
          margin: 16px auto 0;
        }
      }

      &Label {
        font-size: 13px !important;
        font-weight: 700;
        margin: 6px 8px 6px 0 !important;
        padding: 2px 6px;
        color: black(primary);
        border: 1px solid #c9c9c9;
        border-radius: 4px;
        background: #c9c9c9;

        &.category-2 {
          color: white(primary);
          border-color: #b11970;
          background-color: #b11970;
        }

        &.category-3 {
          color: white(primary);
          border-color: #a800a2;
          background-color: #ab19b1;
        }

        &.category-4,
        &.category-12 {
          color: white(primary);
          border-color: #522df0;
          background-color: #4c27e8;
        }

        &.category-7 {
          color: white(primary);
          border-color: #8e7d4d;
          background-color: #8d7126;
        }

        &.category-8 {
          color: white(primary);
          border-color: #0062b4;
          background-color: #0062b4;
        }

        &.category-9 {
          color: white(primary);
          border-color: #0062b4;
          background-color: #0062b4;
        }

        &.category-10 {
          color: white(primary);
          border-color: #6b4577;
          background-color: #6b4577;
        }
      }

      &Link {
        font-size: inherit;
        margin: 6px 0 6px 8px !important;
        text-decoration: underline !important;
        word-break: break-all;
        color: inherit;

        &:hover {
          text-decoration: inherit;
        }
      }

      &Table {
        font-size: 14px;
        width: 100% !important;
        border-spacing: 0 !important;
        border-collapse: collapse !important;
        color: #212121;
        border: 0 !important;
        border-radius: 8px;
        background: #eee;

        &Row {
          background: none !important;

          &:first-child {

            .uWidget--dailyReport__timelineTableHeader,
            .uWidget--dailyReport__timelineTableData {
              padding-top: 20px !important;
            }
          }

          &:last-child {

            .uWidget--dailyReport__timelineTableHeader,
            .uWidget--dailyReport__timelineTableData {
              padding-bottom: 20px !important;
            }
          }
        }

        &Header {
          font-size: inherit !important;
          line-height: 1.2;
          width: 10%;
          min-height: auto !important;
          padding: 4px 24px 4px 16px !important;
          text-align: left !important;
          vertical-align: top !important;
          white-space: nowrap;
          word-break: break-all;
          color: #666 !important;
          border: 0 !important;
          background: none !important;
        }

        &Data {
          font-size: inherit !important;
          line-height: 1.2;
          min-height: auto !important;
          padding: 4px 16px !important;
          text-align: left !important;
          vertical-align: top !important;
          word-break: break-all;
          color: inherit !important;
          border: 0 !important;
          background: none !important;
        }

        &Link {
          font-size: inherit;
          text-decoration: underline !important;
          color: #337ab7;
        }
      }

      @media (max-width: 1279px) {
        &-item {
          margin-left: 51px !important;
        }
      }

      @media (min-width: 1024px) and (max-width: 1279px) {
        &Table {

          &Header,
          &Data {
            padding-right: 12px !important;
            padding-left: 12px !important;
          }
        }
      }
    }
  }

  /*
　　噴霧装置
　---------------------------------*/
  &--coolPescon {
    position: relative;
    padding-top: 14px;
    padding-bottom: 14px;

    @extend .uWidget;

    .uWidget__header {
      margin-bottom: 0 !important;
    }

    &__item {
      position: relative;
      min-width: 88px;
      margin: 8px 16px 0 0 !important;
      padding: 10px 10px 6px 6px;
      text-align: right;
      border-radius: 8px;
      background-color: #1f1f1f;
    }

    &__label {
      font-size: 14px;
      position: absolute;
      top: 0;
      left: 12px;
      transform: translateY(-50%);
    }

    &__value {
      font-size: 30px;
      font-weight: 700;
      line-height: 1.2;
      padding: 0 6px 0 0;
    }

    &__button {
      display: inline-flex;
      width: 40px;
      height: 40px;
      margin: 0 0 0 16px !important;
      color: inherit;
      border-radius: 50%;
      background: #444;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.16);

      align-items: center;
      justify-content: center;

      &:hover {
        text-decoration: inherit;
        color: inherit;
        background-color: #666;
      }

      &::before {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        content: "";
      }
    }

    .uMainBoard--rightBoardClosed & {
      .uWidget__header {
        margin-right: -8px !important;

        flex-wrap: wrap;
      }

      &__button {
        margin-left: auto !important;
      }

      .uWidget__title {
        width: 100%;
        margin-bottom: 14px;
        padding-top: 2px;
      }
    }

    @media (min-width: 1280px) {
      padding-top: 14px;
      padding-left: 32px;

      &__item {
        .uMainBoard--rightBoardClosed & {
          min-width: 74px;
          padding-right: 8px;

          &__label {
            left: 8px;
          }
        }
      }

      &__button {
        .uMainBoard--rightBoardClosed & {
          margin-left: 4px !important;
        }
      }
    }

    @media (min-width: 1024px) and (max-width: 1279px) {
      padding-top: 18px;

      .uWidget__header {
        margin-right: -8px !important;

        flex-wrap: wrap;
      }

      &__button {
        margin-left: auto !important;
      }

      .uWidget__title {
        width: 100%;
        margin-bottom: 14px;
        padding-top: 2px;
      }
    }

    @media (max-width: 1023px) {
      &__item {
        min-width: 74px;
        margin-right: 8px !important;
        padding-right: 8px;

        &__label {
          left: 8px;
        }

        &__button {
          margin-left: 20px !important;
        }
      }
    }
  }

  /*
　　肥育頭数
　---------------------------------*/
  &--fattening {
    @extend .uWidget;

    &__table {
      font-size: 13px;
      width: 100% !important;
      border-spacing: 0 !important;
      border-collapse: collapse !important;
      color: white(primary);
      border: 0 !important;

      &Row {
        background: none !important;
      }

      &Header {
        font-size: inherit !important;
        width: 100%;
        min-height: auto !important;
        padding: 1px 0 1px 24px !important;
        transition: all .3s ease;
        text-align: left !important;
        color: inherit !important;
        border: 0 !important;
        background: none !important;
      }

      &Data {
        font-size: inherit !important;
        min-height: auto !important;
        padding: 1px 16px !important;
        transition: all .3s ease;
        text-align: center !important;
        color: inherit !important;
        border: 0 !important;
        background: none !important;
      }

      &Head {
        .uWidget--fattening__tableData {
          font-size: 13px !important;
          font-weight: 400;
        }
      }

      &Body {

        .uWidget--fattening__tableHeader,
        .uWidget--fattening__tableData {
          padding-top: 2px !important;
          padding-bottom: 2px !important;
        }

        .uWidget--fattening__tableHeader {
          color: rgba(255, 255, 255, 0.67) !important;
        }

        .uWidget--fattening__tableRow:last-child {

          .uWidget--fattening__tableHeader,
          .uWidget--fattening__tableData {
            padding-bottom: 14px !important;
          }
        }

        .uWidget--fattening__tableItem {
          position: relative;
          overflow: hidden;
          border-radius: 18px;
          background: #3b3b3b;
        }
      }

      &Foot {
        .uWidget--fattening__tableRow {
          border-top: 1px solid #575757;

          &:not(:first-child) {
            .uWidget--fattening__tableData {
              padding-top: 11px;
              padding-bottom: 11px;
            }
          }
        }
      }

      &Value {
        font-size: 15px !important;
        font-weight: 700;
        display: inline-block;
        min-width: 80px;
        padding: 7px 0;
        transition: all .3s ease;
        word-break: break-all;
      }

      &Heading {
        line-height: 1.4;
        display: -webkit-box;
        overflow: hidden;
        word-break: break-all;

        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
      }

      &Item {
        display: flex;
        padding: 0 28px 0 16px;
      }

      &Link {
        display: flex;
        width: 100%;

        align-items: center;

        &:hover {
          text-decoration: inherit;
        }

        &::before {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          content: "";
          border: 1px solid rgba(238, 238, 238, 0.92);
          background-color: white(primary);
          box-shadow: 0 1px 2px rgba(0, 0, 0, 0.16);
        }

        .uWidget--fattening__tableValue,
        .uWidget--fattening__tableIcon {
          position: relative;
          color: #212121;
        }

        .uWidget--fattening__tableIcon {
          position: absolute;
          top: 50%;
          right: 10px;
          transform: translateY(-50%);
        }

        &:hover::before {
          background-color: #ddd;
        }
      }

      @media (max-width: 1399px) {
        .uMainBoard--rightBoardClosed & {
          .uWidget--fattening__tableHeader {
            padding-left: 8px !important;
          }

          .uWidget--fattening__tableData {
            padding-right: 8px !important;
          }

          .uWidget--fattening__tableValue {
            min-width: 72px;
          }
        }
      }

      @media (max-width: 1279px) {
        .uWidget--fattening__tableHeader {
          padding-left: 4px !important;
        }

        .uWidget--fattening__tableData {
          padding-right: 0 !important;
        }

        .uWidget--fattening__tableValue {
          min-width: 68px;
        }
      }

      @media (max-width: 1023px) {
        .uWidget--fattening__tableHeader {
          padding-left: 8px !important;
        }

        .uWidget--fattening__tableData {
          padding-right: 8px !important;
        }

        .uWidget--fattening__tableValue {
          min-width: 71px;
        }
      }

      @media (pointer: coarse) {
        .uWidget--fattening__tableHeader {
          padding-left: 8px !important;
        }

        .uWidget--fattening__tableData {
          padding-right: 8px !important;
        }

        .uWidget--fattening__tableValue {
          min-width: 72px;
        }
      }
    }
  }

  @media (max-width: 1279px) {
    max-width: none;
    margin: 0 8px 16px !important;
    padding-top: 18px;
    padding-right: 19px;
    padding-left: 19px;

    &__header {
      min-height: 40px;
      margin-right: -4px !important;
      margin-left: 5px !important;
    }

    &__button {
      width: 40px;
      height: 40px;
      margin-left: 4px !important;
      border-radius: 50%;

      justify-content: center;

      &Icon {
        &--desktop {
          display: none !important;
          margin-right: 0 !important;
        }

        &--tablet {
          display: inline !important;
          margin-right: 0 !important;
        }
      }

      &Label {
        display: none !important;
      }
    }
  }

  @media (min-width: 1024px) and (max-width: 1279px) {
    padding-bottom: 26px;
  }

  @media (max-width: 1279px) {
    &--dailyReport {
      &__list {
        padding: 18px 12px 20px
      }

      &__item {
        width: calc(1 / 3 * 100% - 8px);
      }
    }
  }

  @media (max-width: 1023px) {
    width: calc(50% - 16px) !important;
  }

  @media (pointer: coarse) and (min-width: 1024px) {
    width: calc(1 / 3 * 100% - 20px);
  }
}