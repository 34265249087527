// HelpContentDirective
.help-list {
  padding: 10px;
  background: white;
  overflow: hidden;
  width: 600px;

  .content-title {
    h4 {
      margin-top: 0;
    }
    .add-btn {
      position: absolute;
      top: 7px;
      right: 7px;
      padding: 0;
      text-align: center;
      min-width: 6rem;
    }
    .edit-order-btn {
      position: absolute;
      top: 7px;
      right: 7rem;
      padding: 0px 8px 0px 0px;
      text-align: center;
      min-width: 5rem;
      i {
        position: relative;
        top: 0.2rem;
        color: #fff;
        font-size: 1.5rem;
      }
    }
  }

  .content {
    margin: 0;
    padding-top: 0;
    // テーブル
    @import "content/table";
    @import "content/cow-label";
  }
}
