.alert-location-toolbar {
  @extend .toolbar;
  height: 42px;
}
.alert-location-datepicker {
  width: 100%;
  text-align: center;
}
.alert-location-caption {
  font-size: 1.5rem;
}
.alert-location-description {
  font-size: 1.2rem;
}
.alert-location-history {
  &:hover{
    color: #23527c;
  }
}
.alert-location-left-cell {
  text-align: right;
  display: inline-block;
  width:40%;
}
.alert-location-arrow {
  font-size: 1.6rem;
  text-align: center;
  display: inline-block;
  width:30px;
}
.alert-location-right-cell {
  text-align: left;
  display: inline-block;
  width:40%;
}