.content .content-box.cow-detail {
  .box-summary-info {
    max-width: 350px;
    width: 33.333333%;
    width: calc(100% / 3);
    .section{
      &:not(:last-child) { margin-bottom: 10px; }
      h4:first-child { margin-bottom: 0; }
      h5 {
        background-color: $primaryColorOld;
        border: none;
        color: #fff;
        font-size: 12px;
        font-weight: normal;
        padding: 8px 4px;
        text-align: center;
      }
    }
    table {
      tr:nth-child(odd), tr:nth-child(even) { background-color: transparent; }
      a { text-decoration: underline; }
    }
    .section-information {
      .information-status {
        border: 5px solid #ccc;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        float: left;
        height: 130px;
        margin: 0 10px 0 0;
        padding: 0 10px;
        text-align: center;
        width: 110px;
        strong {
          display: block;
          font-size: 15px;
          width: 100%;
        }
        img {
          margin-bottom: auto;
          max-width: 80px;
        }
      }
      .information-detail { overflow: hidden; }
      .detail-list {
        border-bottom: 1px solid #ccc;
        font-size: 0;
        margin-bottom: 5px;
        padding-bottom: 5px;
        li {
          display: inline-block;
          font-size: calc(1rem + 2px);
          font-weight: bold;
          width: 50%;
        }
        // 旧アイコンフォント調整
        .icon-s-tag {
          font-size: 12px !important;
          color: #ddd0a8;
        }
      }
      .tag-number {
        display: inline-block;
        line-height: 1.3;
      }
      .tag-number-id {
        font-size: calc(1rem + 1px);
        &:nth-of-type(1) {
          display: block;
          text-align: center;
        }
        &:nth-of-type(2), &.large {
          font-size: calc(1rem + 5px);
          margin-right: 3px;
        }
      }
      table, th, td { border: none; }
      th, td {
        min-height: auto;
        padding-top: 1px;
        padding-bottom: 1px;
      }
      th {
        background-color: transparent;
        color: inherit;
        font-weight: normal;
        padding-left: 0;
        position: relative;
        text-align: left;
        white-space: nowrap;
        width: 1%;
      }
      td::before {
        content: '：';
        margin-left: -0.5em;
      }
    }
    .section-breeding {
      table, th, td { border: none; }
      th, td {
        min-height: auto;
        padding-top: 4px;
        padding-bottom: 4px;
      }
      th {
        background-color: transparent;
        color: inherit;
        font-weight: normal;
        position: relative;
        text-align: left;
        white-space: nowrap;
        width: 1%;
      }
      tr:not(:last-child) { border-bottom: 1px solid #ddd; }
      td::before {
        content: '：';
        margin-left: -0.5em;
      }
    }
    .section-ancestors {
      th,
      td {
        width: 25%;
        a { text-decoration: none; }
        a:hover { text-decoration: underline; }
      }
    }
    .section-therapy {
      th, td {
        border-color: #dbdbdb;
        min-height: auto;
      }
      th { background: #777; }
      td  {
        padding: 7px;
        b {width: calc(1em + 2px); }
      }
    }
    .section-disease {
      th, td {
        min-height: auto;
        padding: 7px;
      }
    }
  }
  .flex {
    display: flex;
    .section {
      position: relative;
      margin-bottom: 0;
      background: #fff;
      box-shadow: none;
    }
    .left-adjust.p0, .right-adjust.p0 {
      position: relative;
      margin-bottom: 10px;
      padding: 0;
      background: #fff;
      box-shadow: 0 1px 2px 0 rgba(90, 90, 90, 0.3);

    }
    .box-summary-chart {
      background-color: transparent;
      box-shadow: none;
      .section { margin-bottom: 10px; }
    }
  }
  .box-summary-chart {
    flex: 1;
    .section-report-today {
      margin-right: 1%;
      width: 24%;
      .number-box {
        .number {
          font-size: 4rem;
          line-height: 1;
          &.attension { color: #f65757; }
          span {
            padding-right: .5rem;
          }
        }
      }
      .supplement {
        &:first-of-type {
          border-bottom: 2px solid #ccc;
          font-size: 16px;
          font-weight: normal;
        }
      }
    }
  }
  .radio-custom #tbj-ruminate + label:before,
  .radio-custom #s-tbj-ruminate + label:before, {
    background-color: #ba8800;
  }

  .number-box.number-box-inner .section {
    min-height: 140px;
    &.large {
      min-height: 165px;
    }
  }

  .box-shadow {
    box-shadow: 0 1px 2px 0 rgba(90, 90, 90, 0.3) !important;
  }
  .tab-result {
    padding: 0 10px 10px;
    display: none;
    &.active { display: block; }

  }
  // Timeline height
  .box-history.individual-history .box-history-innner {
    height: calc(100vh - 245px);
    position: relative;
    z-index: 2;

    @media (pointer: coarse) and (min-height: 700px) {
      height: calc(100vh - 330px);
    }
  }

  .milkingTab {
    .section {
      height: 488px;
    }
  }

  .graph-box {
    .count-tx {
      display: inline-block;
      width: auto;
      padding: 3px 7px;
      font-size: 1.1rem;
      font-weight: bold;
      text-align: right;
      background-color: $beige;
    }
    .switch-btn li a {
      background-color: $beige;
      border-radius: 4px;
      color: #333;
      font-size: 1.1rem;
      height: 22px;
      line-height: 22px;
      min-width: 4rem;
      padding: 5px 10px;
      &:hover, &.active {
        background-color: $orange;
        color: #fff;
        text-decoration: none;
      }
      &.edit-btn {
        padding: 0px 10px;
        background-color: $orange;
        color: #fff;
      }
    }
  }
  .activity-chart-contents-container {
    display: flex;
    height: 820px;
    flex-direction: column;
    .graph-title-box {
      padding-top: 0px;
    }
    .graph-box {
      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: 0%;
      &:first-child {
        margin-bottom: 10px;
      }
    }
  }
  .chart-loading {
    background: url('../images/select2-spinner.gif') no-repeat 100%;
    background-position: center center;
    background-size: 22px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
  .chart-datepicker {
    border: none;
    text-align: center;
    width: 120px;
    &:hover {
      cursor: pointer;
      color: steelblue;
    }
  }

  #amount-daily-chart-message {
    text-align: center;
    width: 100%;
    padding-top: 50px;
    height: 250px;
  }

  .time-budget-title {
    position: relative;
  }

  #budgetTypeGroup {
    position: absolute;
    top: 5px;
    right: 12px;
  }

  table.simplicity-tbl {
    border: none;
    tr:nth-child(2n+1) { background-color: transparent; }
    tr:nth-child(2n) { background-color: #f5f5f5; }
    th, td { width: 50%!important; padding: 5px 14px; }
    tr:nth-child(2n+1) th, tr:nth-child(2n+1) td { padding: 9px 14px; }
    th { color: #333; border: none; background-color: transparent; }
    td { text-align: right; border: none; }
  }
  .bulk-regist-cow-detail {
    top: 14px !important;
    right: 72px !important;
    border-right: 1px solid #ccc;
    + .btn-delete {
      height: auto;
      color: #555;
      background-color: #ececec;
      border: 1px solid #ccc;
      i { color: #555; }
    }
  }
  .box-trend-graph {
    margin-bottom: 5px;
    padding: 10px;
  }
  .box-trend-info  {
    padding: 5px 5px 10px;
    .table li {
      width: 27%;
      padding: 0 16px;
      &:nth-of-type(1) { width: 22%; border-right: 1px solid #ddd; }
      &:nth-of-type(2) { width: 27%; padding-left: 30px; }
      &:nth-of-type(3) { width: 28%; }
      &:nth-of-type(4) { width: 27%; }
      &:nth-of-type(1) dt, &:nth-of-type(1) dd {
        float: none;
        width: 80%;
        margin: 0 auto;
        padding: 0;
      }
      &:nth-of-type(1) dt { margin-bottom: 5px; }
      &:nth-of-type(1) dd { margin-bottom: 20px; font-size: 1.7rem; }
    }
  }
  .box-trend-info02 > .row .section { min-height: 185px; }
  .amount-milk {
    .number {
      position: relative;
      font-size: 2rem;
      font-weight: normal;
      letter-spacing: 0;
      padding: 10px 10px 25px;
      text-align: center;
      &:after {
        content: "";
        position: absolute;
        left: 50%;
        bottom: 14px;
        display: block;
        width: 80%;
        height: 1px;
        margin-left: -40%;
        background-color: #ddd;
      }
      span {
        padding-right: 7px;
        font-size: 5rem;
        font-weight: bold;
        line-height: 1;
      }
    }
    .table {
      display: table;
      width: 100%;
      margin-bottom: 5px;
      &:nth-last-of-type(1) { margin-bottom: 0; }
      dt, dd {
        display: table-cell;
        float: none;
        width: 50%;
        margin: 0;
        padding: 0;
        font-size: 1.3rem;
      }
      dt { text-align: right; }
      dd { padding-left: 5px; }
    }
  }
  .data-mastitis {
    .hoof-4split > li { height: 52px; font-size: 1.3rem; font-weight: bold; }
    h4 + p { margin-left: 10px; }
    ul { padding: 8px; }
    li span { position: absolute; }
    li {
      .number {
        right: 20px;
        top: 17px;
        width: 1.5em;
        font-size: 3rem;
        text-align: center;
        line-height: 1;
      }
      .unit-tx {
        right: 5px;
        bottom: 4px;
        font-weight: normal;
      }
      .blind-milk {
        position: absolute;
        left: 7px;
        bottom: 6px;
        padding: 2px 3px;
        color: #fff;
        font-size: 1.1rem;
        line-height: 1;
        background-color: #a16da3;
      }
    }
  }
  .data-disease ul li { width: 52%; padding: 0 5px 5px; }
  .data-disease ul li:nth-of-type(1) { width: 48%; }

  .deliver-table {
    .calf-breed {
      width: initial;
    }
  }
}

.radio-custom {
  input {
    display: none;
    &[type="radio"]:checked + label {
      color: #333;
      font-weight: bold;
      background-color: #fff;
      border: 1px solid #d0d0d0;
    }
  }
  label {
    position: relative;
    display: inline-block;
    margin-left: 1px;
    padding: 2px 5px 2px 20px;
    color: #888;
    font-weight: normal;
    background-color: #f6f6f6;
    border: 1px solid #f6f6f6;
    border-radius: 4px;
    cursor: pointer;

    &:before {
      content: "";
      display: inline-block;
      position: absolute;
      left: 4px;
      top: 50%;
      width: 10px;
      height: 10px;
      margin-top: -5px;
      background-color: #fff;
    }
  }

  #tbj-feed + label:before, #s-tbj-feed + label:before { background-color: #6c9f00; }
  #tbj-water + label:before, #s-tbj-water + label:before { background-color: #598cff; }
  #tbj-move + label:before, #s-tbj-move + label:before { background-color: #ffd600; }
  #tbj-lie + label:before, #s-tbj-lie + label:before { background-color: #c365ff; }
  #tbj-stand + label:before, #s-tbj-stand + label:before { background-color: #666666; }
  #tbj-ruminate + label:before, #s-tbj-ruminate + label:before { background-color: #ba8800; }
  #tbj-ruminate-lie + label:before, #s-tbj-ruminate-lie + label:before { background-color: #ba8800; }
  #tbj-ruminate-stand + label:before, #s-tbj-ruminate-stand + label:before { background-color: #896D4F; }

  #tbj-all + label, #s-tbj-all + label {
    padding-left: 5px;
    &:before { display: none; }
  }

}

.yesterday-activity {
  h5:before {
    content: "";
    display: inline-block;
    width: 1.2rem;
    height: 1.2rem;
    margin-right: 6px;
    margin-bottom: -1px;
    background-color: #b4c82d;
  }
  .col-md-2 { width: 14.2858%; }

  .yesterday-ruminate h5:before { background-color: #c87f2c; }
  .yesterday-water h5:before { background-color: #598cff; }
  .yesterday-walk h5:before { background-color: #f8c515; }
  .yesterday-run h5:before { background-color: #f2669a; }
  .yesterday-lie h5:before { background-color: #b872e9; }
  .yesterday-stand h5:before { background-color: #777a71; }
  .yesterday-ruminate h5:before { background-color: #c87f2c; }
  .yesterday-ruminate-lie h5:before { background-color: #c87f2c; }
  .yesterday-ruminate-stand h5:before { background-color: #896D4F; }
}

.box-info.individual {
  th, td { width: 25%; }
  .table-col2 td { width: 75%; }
  input[type="text"] { width: 80%; }
  input[type="radio"] { margin-right: 5px; }
  label + label { margin-left: 20px; }
}

// ui-bootstrap > tab のヘッダーを隠す
.detail-contents .nav.nav-tabs {
  display: none;
}

.detail-header {
  align-items: center;
  display: flex;
  height: $cow-detail-header-height;
  justify-content: space-between;
  padding: 10px;
  background-color: rgba(60, 59, 59, 0.81);
  position: fixed;
  top: 124px;
  width: $header-width;
  z-index: 19;

  @media (pointer: coarse) {
    top: 112px;
  }
}

.tab-selector--container {
  color: #fff;

  .tab-selector {
    font-size: 1.2rem;
    border: 1px solid transparent;
    border-radius: 0;
    margin: 0 4px;
    padding: 2px 7px;
    // padding: 2.5px 5px;
    opacity: 0.75;
    transition: $default-transition;

    &:hover,
    &.active {
      background-color: $mildGrey;
      border-color: $milderGrey;
      border-radius: 5px;
      box-shadow: 0 1px 2px rgba(18, 18, 18, 0.2);
      opacity: 1;
    }
    &.disabled {
      opacity: 0.25;
      cursor: not-allowed;

      &:active,
      &:focus {
        pointer-events: none;
      }
    }
    &--changeUiVersion {
      margin: 0 0 0 32px;
      background: rgba(67, 78, 6, .4);
      border: 1px solid #768424;
      font-size: 13px;
      border-radius: 4px;
      padding: 2px 7px;

      &:hover {
        color: white(primary);
        background: #434e06;
      }
    }
  }
}

// 牛個体画面一番上のコンテナー
.cow-detail {
  // .detail-headerの高さに合わせる
  padding: $cow-detail-header-height 0 0;

  @media (pointer: coarse) {
    padding-top: 28px;
  }

  &:before {
    background-color: #fff;
    content: '';
    display: block;
    height: $cow-detail-header-height;
    margin-top: -$cow-detail-header-height;
    position: fixed;
    width: $header-width;
  }
}

// Cow Labels
.cow-label--container {
  display: flex;
  color: #fff;

  .add-label {
    align-items: center;
    display: inline-flex;
    justify-content: center;
    background-color: $mildGrey;
    border: 1px dotted #ccc;
    border-radius: 3px;
    height: 1.8rem;
    margin-left: 5px;
    width: 1.8rem;

    .icon-css-plus {
      position: relative;
      display: block;
      width: 16px;
      height: 16px;

      &::before {
        position: absolute;
        top: 7px;
        left: 3px;
        display: block;
        width: 9px;
        height: 1px;
        content: '';
        background-color: #fff;
      }

      &::after {
        position: absolute;
        top: 7px;
        left: 3px;
        display: block;
        width: 9px;
        height: 1px;
        content: '';
        transform: rotate(90deg);
        background-color: #fff;
      }
    }

    &:hover {
      cursor: pointer;
    }
  }

  .cow-label {
    border: 1px dotted #ccc;
    padding: 0 4px;
    margin-left: 5px;
  }
}

.label--title {
  // 旧アイコンフォント調整
  .icon-s-tag {
    font-size: 12px!important;
    margin: 0 5px 0 0;
    transform: rotate(90deg);
    color: #fff;
  }
}

.dataTab {
  .left-adjust {
    max-width: calc(100% - 380px);
  }

  .timeline--head {
    display: grid;
    margin-bottom: 5px;

    grid-template-columns: repeat(2, auto);
    grid-template-rows: repeat(2, auto);
  }

  .timeline--eventNameWrapper {
    min-height: 19px;

    grid-column: 1/2;
    grid-row: 1/2;
  }

  .timeline--eventName {
    font-size: 11px !important;
    display: inline-grid !important;
    height: 100%;
    margin: 0 !important;
    padding: 0 4px !important;

    align-items: center;
    justify-content: center;
  }

  .timeline--note {
    padding: 5px 0 0 0;

    grid-column: 1/3;
    grid-row: 2/3;
  }

  .timeline--daysAgo {
    font-size: 12px;
    padding: 0 12px 0 16px;
    text-align: right;
    white-space: nowrap;

    grid-column: 2/3;
    grid-row: 1/2;
  }

  // タイムラインボディから牛番号を非表示にする
  .timeline--cowIdLabel {
    display: none;
  }

  .isInputPathExternal {
    grid-template-columns: auto 1fr;
    grid-template-rows: repeat(2, auto);

    .timeline--eventNameWrapper {
      display: flex;
      border-width: 1px;
      border-style: solid;
    }

    .timeline--note {
      padding: 5px 0 0 0;

      grid-column: 1/3;
      grid-row: 2/3;
    }

    .timeline--externalLabel {
      font-size: 11px;
      display: inline-grid !important;
      padding: 0 4px !important;

      align-items: center;
      justify-content: center;
    }

    .timeline--daysAgo {
      grid-column: 2/3;
      grid-row: 1/2;
    }
  }

  @media (min-width: 1024px) {
    .timeline--head {
      grid-template-columns: auto 1fr auto;
      grid-template-rows: auto;
    }

    .timeline--note {
      padding: 0 0 0 5px;

      grid-column: 2/3;
      grid-row: 1/2;
    }

    .timeline--daysAgo {
      grid-column: 3/4;
      grid-row: 1/2;
    }

    .isInputPathExternal {
      grid-template-columns: auto 1fr;
      grid-template-rows: repeat(2, auto);
    }
  }
}

// 蹄病診断結果のグルーピング
table.lame-diagnosises, table.lame-diagnosises tr, table.lame-diagnosises td {
  margin: 0px;
  padding: 0px;
  border: none;
  background-color: white;
}

table.lame-diagnosises td {
  padding-bottom: 5px;
}

table.lame-diagnosises tr th:first-child,
table.lame-diagnosises tr td:first-child,
table.lame-diagnosises tr td {
  border: none !important;
  vertical-align: top;
}

.activity-prot-control-panel {
  flex: 1;
}
