/*---------------------------------
　コンテンツ
---------------------------------*/

/*
　@extend用のプレースホルダーセレクタ
---------------------------------*/
%__tabItem {
  font-size: 13px !important;
  display: flex;
  height: 26px;
  margin: 0 0 0 8px !important;
  padding: 0 7px;
  cursor: pointer;
  transition: opacity .2s ease;
  text-align: center;
  color: white(primary);

  align-items: center;
  justify-content: center;
}

%__buttonGroupItem {
  font-size: 12px !important;
  display: flex;
  width: 100%;
  height: 40px;
  cursor: pointer;
  text-align: center;

  align-items: center;
  justify-content: center;
}

%__icon {
  position: relative;
  display: flex;
  width: 30px;
  height: 31px;
  margin: auto !important;
  cursor: pointer;

  align-items: center;
  justify-content: center;
}

%__formSelect {
  position: relative;

  &:not(:first-child) {
    margin: 0 0 0 8px;
  }

  &:first-child {
    margin: 0;
  }

  &::after {
    position: absolute;
    top: 16px;
    right: 14px;
    display: block;
    width: 9px;
    height: 9px;
    content: '';
    pointer-events: none;
    border-top: 7px solid #0d52a2;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
  }
}

%__formInputBody {
  font-size: 14px !important;
  font-weight: 400 !important;
  position: relative;
  width: 100%;
  height: 40px;
  margin: 0 !important;
  padding: 0 8px !important;
  cursor: pointer;
  transition: all .2s ease !important;
  color: black(primary) !important;
  border: 1px solid #ddd !important;
  border-radius: 4px !important;
  outline: 0 !important;
  background: white(primary) !important;
  box-shadow: none !important;

  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  align-items: center;
  justify-content: space-between;

  &::placeholder {
    color: black(quaternary) !important;
  }

  &:-ms-input-placeholder {
    color: black(quaternary) !important;
  }

  &::-ms-input-placeholder {
    color: black(quaternary) !important;
  }

  &::-webkit-input-placeholder {
    color: black(quaternary) !important;
  }

  &::-ms-clear {
    display: none;
  }
}

%__uiGrid {
  .ui-grid-cell {
    overflow: visible;
  }

  .uTooltip {
    width: 100%;
    height: 100%;

    &::before {
      top: calc(100% - 8px);
    }
  }
}

%__uiGridError {
  display: flex;

  align-items: center;
  justify-content: space-between;
}

/*
　コンテンツ
---------------------------------*/
.contents {
  padding: 0 10px 10px;
  color: black(primary);

  &--noPrint {
    padding: 0 14px 10px;
    color: black(primary);

    @media print {
      display: none;
    }
  }

  &__inner {
    &--grow {
      display: flex;
      flex-direction: column;
      height: calc(100vh - 134px);
      background: white(primary);

      .add-line {
        margin: 15px 10px 0;
      }
    }
  }

  &__tab {
    margin: 0 0 10px -8px !important;

    +.contents {
      &__body {
        &--grow {
          height: calc(100vh - 170px);
        }
      }
    }

    +*>.contents {
      &__body {
        &--grow {
          height: calc(100vh - 170px);
        }
      }
    }

    &Items {
      display: flex;
      flex-direction: row;
    }

    &Item {
      opacity: .75;

      @extend %__tabItem;

      &:hover {
        opacity: 1;
        border-radius: 4px;
        background: black(secondary);
      }

      &--active {
        border-radius: 4px;
        background: black(secondary);

        @extend %__tabItem;
      }
    }
  }

  &__message {
    font-size: 13px !important;
    display: flex;
    height: 400px;
    background: white(primary);

    align-items: center;
    justify-content: center;
  }

  &__body {
    padding: 15px;
    background: white(primary);

    &--small {
      padding: 18px 10px 10px 16px;
      background: white(primary);
    }

    &--black {
      padding: 10px;
      background: #1f1f1f;
    }

    &--grow {
      height: calc(100vh - 134px);
      padding: 15px;
      background: white(primary);
    }

    &--rest {
      padding: 10px;
      background: white(primary);

      flex-grow: 1;

      #table-area {
        height: calc(100vh - 156px) !important;
      }
    }

    &--table {
      overflow: scroll;
      height: calc(100vh - 620px);
      text-align: center;

      .table-th-fix:not(.clone) {
        margin-top: -50px !important;
      }
    }
  }

  &__break {
    height: 10px;
    padding: 10px 0 0;
    background: #1f1f1f;
  }

  &__gridHead {
    position: relative;
    display: flex;
    width: 100%;
    padding: 10px 10px 0;

    align-items: center;
    justify-content: flex-end;

    .toolbar-alternative {
      top: 4px;
      left: 0;
    }

    .edit-btn {
      margin: 0 0 0 10px !important;
    }

    &Text {
      font-size: 13px !important;
      font-weight: 700;
    }
  }

  &__heading {
    font-size: 13px !important;
    font-weight: 700 !important;
    margin: 15px 0 0 !important;

    &--first {
      font-size: 13px !important;
      font-weight: 700 !important;
      margin-top: 0px !important;
    }

    &--underline {
      font-size: 13px !important;
      font-weight: 700 !important;
      padding: 0 0 5px;
      border-bottom: 1px solid #ccc;

      &:not(:first-child) {
        margin-top: 15px !important;
      }
    }
  }

  &__buttonGroup {
    margin: 5px 0 0 !important;

    &Items {
      &--width300 {
        display: flex;
        width: 300px;

        justify-content: space-between;
      }
    }

    &Item {
      color: #ccc;
      border-top: 1px solid #ddd;
      border-bottom: 1px solid #ddd;
      border-left: 1px solid #ddd;
      background: white(primary);

      @extend %__buttonGroupItem;

      &:last-child {
        border-right: 1px solid #ddd;
      }

      &--active {
        color: white(primary);
        border-top: 1px solid #366678;
        border-bottom: 1px solid #366678;
        border-left: 1px solid #366678;
        background: #366678;

        @extend %__buttonGroupItem;

        &+ {
          .contents {
            &__buttonGroup {
              &Item {
                padding: 0 0 0 1px;
                border-left: none;
              }
            }
          }
        }
      }
    }
  }

  &__buttons {
    display: flex;
    margin: 30px 0 0 !important;

    &--right {
      display: flex;
      width: 100%;

      justify-content: flex-end;
    }

    .uButton {
      margin: 0 0 0 24px !important;
    }
  }

  &__button {
    .uButton {
      width: 100% !important;
      margin: 0 !important;
    }
  }

  &__form {
    &Item {
      &:not(:first-child) {
        margin: 15px 0 0 !important;
      }

      &--separate {
        margin: 50px 0 0 !important;
      }
    }

    &Field {
      position: relative;
      display: flex;
      margin: 5px 0 0 !important;

      align-items: center;
      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: 100%;

      &--width240 {
        position: relative;
        display: flex;
        margin: 5px 0 0 !important;

        align-items: center;
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: 100%;

        .contents {
          &__form {
            &Input {
              &Date {
                width: 240px;
              }
            }
          }
        }
      }

      &Text {
        display: flex;
        height: 40px;
        padding: 0 10px;

        align-items: center;
        justify-content: center;
      }
    }

    &Label {
      font-size: 13px !important;
      display: flex;
      margin: 5px 0 0 !important;

      align-items: center;
    }

    &Input {
      position: relative;
      display: flex;
      width: 100%;

      &Body {
        @extend %__formInputBody;

        &--right {
          text-align: right;

          @extend %__formInputBody;
        }
      }

      &Clear {
        position: absolute;
        top: 2px;
        left: 202px;
        display: block;
        width: 36px;
        height: 36px;
        margin: 0 !important;
        cursor: pointer;
        transition: all .2s ease;
        border: 1px solid white(primary);
        border-radius: 50%;
        background: white(primary);

        flex-shrink: 0;

        &::before {
          position: absolute;
          top: calc(50% - 1px);
          left: calc(50% - 6px);
          display: block;
          width: 12px;
          height: 2px;
          content: '';
          transition: all .2s ease;
          transform: rotate(45deg);
          transform-origin: center;
          background: black(primary);
        }

        &::after {
          position: absolute;
          top: calc(50% - 1px);
          left: calc(50% - 6px);
          display: block;
          width: 12px;
          height: 2px;
          content: '';
          transition: all .2s ease;
          transform: rotate(-45deg);
          transform-origin: center;
          background: black(primary);
        }

        &:hover {
          background: #e7e7e7;
        }
      }

      &Date {
        position: relative;
        display: flex;
        width: 100%;

        &Body {
          font-size: 14px !important;
          font-weight: 400 !important;
          position: relative;
          width: 100%;
          height: 40px;
          margin: 0 !important;
          padding: 0 8px !important;
          cursor: pointer;
          transition: all .2s ease !important;
          color: black(primary) !important;
          border: 1px solid #ddd !important;
          border-radius: 4px !important;
          outline: 0 !important;
          background: white(primary) !important;
          box-shadow: none !important;

          appearance: none;
          -moz-appearance: none;
          -webkit-appearance: none;
          align-items: center;
          justify-content: space-between;
        }

        &Clear {
          position: absolute;
          top: 2px;
          right: 3px;
          display: block;
          width: 36px;
          height: 36px;
          margin: 0 !important;
          cursor: pointer;
          transition: all .2s ease;
          border: 1px solid white(primary);
          border-radius: 50%;
          background: white(primary);

          flex-shrink: 0;

          &::before {
            position: absolute;
            top: calc(50% - 1px);
            left: calc(50% - 6px);
            display: block;
            width: 12px;
            height: 2px;
            content: '';
            transition: all .2s ease;
            transform: rotate(45deg);
            transform-origin: center;
            background: black(primary);
          }

          &::after {
            position: absolute;
            top: calc(50% - 1px);
            left: calc(50% - 6px);
            display: block;
            width: 12px;
            height: 2px;
            content: '';
            transition: all .2s ease;
            transform: rotate(-45deg);
            transform-origin: center;
            background: black(primary);
          }

          &:hover {
            background: #e7e7e7;
          }
        }

        &Message {
          font-size: 14px !important;
          font-weight: 400 !important;
          display: flex;
          padding: 0 12px;
          color: red(primary);

          align-items: center;
        }
      }

      &File {
        &--single {
          display: flex;
        }

        &--multiple {
          display: block;
        }

        &Button {
          position: relative;
          display: flex;
          width: 240px;
          height: 40px;
          cursor: pointer;
          transition: all .2s ease;
          border: 1px solid #ddd;
          border-radius: 4px;
          background: white(primary);

          align-items: center;
          justify-content: space-between;

          &:hover {
            border: 1px solid white(primary);
            box-shadow: 0px 3px 6px rgba(0, 0, 0, .16);
          }
        }

        &Body {
          display: none !important;
        }

        &Label {
          font-size: 14px !important;
          font-weight: 400 !important;
          padding: 0 8px;
          color: black(tertiary);
        }

        &Icon {
          position: relative;
          display: block;
          width: 38px;
          height: 38px;

          &::before {
            position: absolute;
            top: calc(50% - 1px);
            left: calc(50% - 6px);
            display: block;
            width: 12px;
            height: 2px;
            content: '';
            background: #0d52a2;
          }

          &::after {
            position: absolute;
            top: calc(50% - 1px);
            left: calc(50% - 6px);
            display: block;
            width: 12px;
            height: 2px;
            content: '';
            transform: rotate(90deg);
            transform-origin: center;
            background: #0d52a2;
          }
        }

        &Item {
          &--single {
            position: relative;
            display: flex;
            width: 400px;
            height: 40px;

            align-items: center;
          }

          &--multiple {
            position: relative;
            display: flex;
            width: 800px;
            height: 40px;
            margin: 5px 0 0 !important;
            border: 1px solid #ddd;
            border-radius: 4px;
            background: white(primary);

            align-items: center;
            justify-content: space-between;
          }

          &Label {
            font-size: 14px !important;
            font-weight: 400 !important;
            overflow: hidden;
            padding: 0 12px;
            white-space: nowrap;
            text-overflow: ellipsis;
            color: black(secondary);
          }

          &Delete {
            position: relative;
            display: block;
            width: 36px;
            height: 36px;
            margin: 0 4px 0 0 !important;
            cursor: pointer;
            transition: all .2s ease;
            border: 1px solid white(primary);
            border-radius: 50%;
            background: white(primary);

            flex-shrink: 0;

            &::before {
              position: absolute;
              top: calc(50% - 1px);
              left: calc(50% - 6px);
              display: block;
              width: 12px;
              height: 2px;
              content: '';
              transition: all .2s ease;
              transform: rotate(45deg);
              transform-origin: center;
              background: black(primary);
            }

            &::after {
              position: absolute;
              top: calc(50% - 1px);
              left: calc(50% - 6px);
              display: block;
              width: 12px;
              height: 2px;
              content: '';
              transition: all .2s ease;
              transform: rotate(-45deg);
              transform-origin: center;
              background: black(primary);
            }

            &:hover {
              background: #e7e7e7;
            }
          }
        }
      }
    }

    &Select {
      @extend %__formSelect;
      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: 100%;

      &Body {
        font-size: 15px !important;
        font-weight: 400 !important;
        line-height: 40px !important;
        width: 100%;
        height: 40px !important;
        padding: 0 40px 0 8px !important;
        transition: all .2s ease !important;
        color: black(primary) !important;
        border: none !important;
        border-bottom: 1px solid #ccc !important;
        border-radius: 2px 2px 0 0 !important;
        outline: 0 !important;
        background: white(primary) !important;
        box-shadow: none !important;

        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;

        &::-ms-expand {
          display: none;
        }

        &:focus {
          &+.uModal {
            &__form {
              &Field {
                &Focus {
                  width: 100%;
                }
              }
            }
          }
        }
      }
    }

    &--small {
      .contents {
        &__form {
          &Item {
            position: relative;
            display: flex;

            align-items: center;

            &:not(:first-child) {
              margin: 5px 0 0 !important;
            }
          }

          &Heading {
            font-size: 13px !important;
            font-weight: 700 !important;
            width: 100px;

            flex-shrink: 0;

            &--required {
              font-size: 13px !important;
              font-weight: 700 !important;
              position: relative;
              width: 100px;

              flex-shrink: 0;

              &::after {
                font-size: 11px;
                font-weight: 700;
                position: absolute;
                top: 1px;
                right: 10px;
                display: flex;
                width: 30px;
                height: 16px;
                content: "必須";
                color: #b70000;
                border: 1px solid #b70000;

                align-items: center;
                justify-content: center;
              }
            }

            &Secondary {
              font-size: 13px !important;
              font-weight: 700 !important;
              width: 120px;

              flex-shrink: 0;

              &--required {
                font-size: 13px !important;
                font-weight: 700 !important;
                position: relative;
                width: 120px;

                flex-shrink: 0;

                &::after {
                  font-size: 11px;
                  font-weight: 700;
                  position: absolute;
                  top: 1px;
                  right: 10px;
                  display: flex;
                  width: 30px;
                  height: 16px;
                  content: "必須";
                  color: #b70000;
                  border: 1px solid #b70000;

                  align-items: center;
                  justify-content: center;
                }
              }
            }
          }

          &Lead {
            font-size: 14px !important;
            font-weight: 700 !important;
            margin: 0 0 14px !important;
          }

          &Field {
            margin: 0 !important;

            &Text {
              height: 24px;
            }
          }

          &Input {
            &--width128 {
              position: relative;
              width: 128px;
            }

            &--width252 {
              width: 252px;
            }

            &Body {
              font-size: 12px !important;
              height: 24px;
            }

            &Date {
              &--width110 {
                position: relative;
                width: 110px;
              }

              &--width128 {
                position: relative;
                width: 128px;
              }

              &Body {
                font-size: 12px !important;
                height: 24px;
              }

              &Clear {
                width: 20px;
                height: 20px;

                &::before {
                  left: calc(50% - 5px);
                  width: 11px;
                  height: 1px;
                }

                &::after {
                  left: calc(50% - 5px);
                  width: 11px;
                  height: 1px;
                }
              }
            }
          }

          &Select {
            &--width128 {
              width: 128px;

              @extend %__formSelect;

              &::after {
                top: 8px !important;
                width: 7px !important;
                height: 7px !important;
                border-top-width: 6px !important;
                border-right-width: 3px !important;
                border-left-width: 3px !important;
              }

              &:first-child {
                margin: 0 10px 0 0 !important;
              }
            }

            &Body {
              font-size: 12px !important;
              line-height: 24px !important;
              height: 24px !important;
            }
          }

          &Radio {
            display: flex;
            min-height: 24px;

            align-items: center;

            &:not(:first-child) {
              margin: 0 0 0 30px;
            }

            &Body {
              margin: 0 !important;
            }

            &Label {
              font-size: 12px !important;
              font-weight: 400;
              margin: 0 0 0 5px !important;
              cursor: pointer;
              color: black(primary);
            }
          }

          &Checkbox {
            display: flex;
            min-height: 24px;

            align-items: center;

            &:not(:first-child) {
              margin: 0 0 0 30px;
            }

            &Body {
              margin: 0 !important;
            }

            &Label {
              font-size: 12px !important;
              font-weight: 400;
              margin: 0 0 0 5px !important;
              cursor: pointer;
              color: black(primary);
            }
          }
        }
      }
    }
  }

  &__icon {
    &--size14 {
      font-size: 14px !important;

      @extend %__icon;
    }

    &--size16 {
      font-size: 16px !important;

      @extend %__icon;
    }

    &--size18 {
      font-size: 18px !important;

      @extend %__icon;
    }

    &--red {
      color: red(primary);
    }
  }

  &__grid {
    &FilterSelect {
      display: flex;
      margin: 0 0 5px !important;

      &Label {
        font-size: 12px !important;
      }

      &Body {
        margin: 0 0 0 10px !important;
      }
    }

    &Titles {
      display: flex;
      border-top: 1px solid #ddd;
      border-bottom: 1px solid #ddd;
      border-left: 1px solid #ddd;

      justify-content: space-between;
    }

    &Title {
      font-size: 12px !important;
      font-weight: 700;
      display: flex;
      width: 100%;
      padding: 5px;
      text-align: center;
      color: #fff;
      border-right: 1px solid #ddd;
      background: #777;

      align-items: center;
      justify-content: center;
    }

    &Item {
      display: flex;
      border-bottom: 1px solid #ddd;
      border-left: 1px solid #ddd;

      justify-content: space-between;
    }

    &Cell {
      font-size: 12px !important;
      display: flex;
      width: 100%;
      padding: 5px;
      border-right: 1px solid #ddd;

      align-items: center;
      justify-content: center;
    }
  }

  &__uiGrid {
    @extend %__uiGrid;

    &--disableHover {
      @extend %__uiGrid;

      .ui-grid-row {
        cursor: default;

        &:hover {
          background: transparent !important;
        }
      }
    }

    &Link {
      font-size: 13px;
    }

    &Error {
      @extend %__uiGridError;

      &--emphasis {
        background: #faecee;

        @extend %__uiGridError;
      }

      &--hover {
        background: #f4c5ca;

        @extend %__uiGridError;
      }

      &Count {
        font-size: 13px !important;
        padding: 6px;
      }

      &Link {
        width: 30px;
      }
    }

    &Center {
      display: flex;

      align-items: center;
      justify-content: center;
    }

    &Inactive {
      background: #f5f5f5 !important;
    }

    &Selected {
      background: #f2f6fc !important;
    }

    &Checkbox {
      margin: 0 !important;
    }
  }

  &__list {
    border-top: 1px solid #ddd;

    &Row {
      display: flex;
      width: 100%;
      border-right: 1px solid #ddd;
      border-bottom: 1px solid #ddd;
      border-left: 1px solid #ddd;

      align-items: flex-start;
      justify-content: space-between;
    }

    &Cell {
      display: flex;
      width: 100%;
    }

    &Heading {
      font-size: 12px !important;
      font-weight: 700;
      display: flex;
      width: 50%;
      padding: 5px;
      color: white(primary);
      background: #777;

      align-items: center;
      justify-content: center;
    }

    &Data {
      font-size: 12px !important;
      display: flex;
      width: 50%;
      padding: 5px;

      align-items: center;
      justify-content: center;
    }
  }

  &__scroll {
    overflow-x: auto;
  }

  &__card {
    &Row {
      display: flex;

      &:not(:first-child) {
        margin: 10px 0 0 !important;
      }
    }

    &Columns {
      display: flex;
      flex-direction: column;
      margin: 0 0 0 10px !important;

      flex-grow: 1;

      .contents {
        &__card {
          &Column {
            flex-shrink: 0;

            &:not(:first-child) {
              margin: 10px 0 0 !important;
            }
          }
        }
      }
    }

    &Column {
      background: white(primary);

      flex-grow: 1;

      &:not(:first-child) {
        margin: 0 0 0 10px !important;
      }

      &--width340 {
        width: 340px;
        background: white(primary);

        flex-shrink: 0;

        &:not(:first-child) {
          margin: 0 0 0 10px !important;
        }
      }
    }

    &Heading {
      font-size: 15px !important;
      font-weight: 700;
      padding: 10px;
      color: #8aa633;
      background: #f6f6f6;
    }

    &Body {
      padding: 10px;

      &Columns {
        display: flex;
      }

      &Column {
        padding: 10px;

        &--grow {
          padding: 10px;

          flex-grow: 1;
        }

        &--width320 {
          width: 320px;
          padding: 10px;
        }
      }
    }
  }

  &__chart {
    position: relative;

    .c3-line {
      opacity: 0.6 !important;
    }

    .c3-circle {
      opacity: 0.5 !important;
    }

    .c3-legend-item-tile {
      opacity: 0.6 !important;
    }

    .c3-legend-item-lessThanThreshold .c3-legend-item-tile,
    .c3-legend-item-aboveThreshold .c3-legend-item-tile {
      opacity: 1 !important;
    }

    &Panel {
      position: absolute;
      display: none;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, .16);

      &Heading {
        font-size: 12px;
        padding: 5px 10px;
        text-align: center;
        white-space: nowrap;
        color: white(primary);
        background: #777;
      }

      &Link {
        font-size: 12px;
        padding: 5px;
        cursor: pointer;
        border-right: 1px solid #ddd;
        border-bottom: 1px solid #ddd;
        border-left: 1px solid #ddd;
        background: white(primary);

        &:hover {
          background: #ccc;
        }
      }
    }
  }
}