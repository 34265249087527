/*---------------------------------
　フッターボード
---------------------------------*/
.uFooterBoard {
  font-size: 13px;
  position: fixed;
  z-index: 1;
  bottom: 0;
  left: 0;
  display: none;
  width: 100%;
  min-height: 42px;
  max-height: 100vh;
  padding: 20px 16px 12px 16px;
  transition: all .3s ease;
  background: #3b3b3b;
  box-shadow: 0 -3px 30px rgba(0, 0, 0, 0.5);

  &--closed {
    max-height: 1%;

    @extend .uFooterBoard;

    .uFooterBoard__button {
      top: 50%;
      width: 32px;
      height: 32px;
      border-color: transparent;
      border-radius: 50%;

      &Icon {
        transform: rotate(-90deg);
      }

      &Label {
        display: block;
      }

      &:hover {
        background-color: #1f1f1f;
      }
    }

    .uFooterBoard__container {
      visibility: hidden;
      opacity: 0;
    }
  }

  &__button {
    position: absolute;
    top: 0;
    right: 40px;
    display: inline-flex;
    width: 36px;
    height: 36px;
    transform: translateY(-50%);
    color: white(primary);
    border: 1px solid #727272;
    border-radius: 8px;
    background: #575757;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);

    align-items: center;
    justify-content: center;

    &Icon {
      transition: all .3s ease;
      transform: rotate(90deg);
    }

    &Label {
      font-size: 13px;
      position: absolute;
      top: 50%;
      right: calc(100% + 16px);
      display: none;
      transform: translateY(-50%);
      white-space: nowrap;
    }

    &:hover {
      background-color: #3b3b3b;
    }
  }

  &__container {
    display: flex;
    transition: all .1s ease;
  }

  &__box {
    position: relative;
    display: inline-flex;
    flex-direction: column;
    margin: 0 16px 0 -6px !important;
    padding: 12px 14px 6px 14px;
    color: white(primary);
    border: 1px solid #444;
    border-radius: 8px;
    background: #1f1f1f;

    justify-content: center;

    &:first-child {
      margin-left: 0 !important;
    }

    &Button {
      display: inline-flex;
      margin: 0 auto !important;
      padding: 8px 12px;
      border-radius: 10vw;
      background: #eee;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.16);

      align-items: center;
      align-self: center;

      &Icon {
        margin: 0 0 0 12px !important;
        transform: rotate(-90deg);
      }

      &Label {
        font-size: inherit !important;
      }

      &:hover {
        background-color: #ddd;
      }
    }

    &Container {
      display: flex;
      height: 100%;

      align-items: center;
    }

    &Icon {
      margin: 0 0 0 16px !important;
    }

    &Title {
      font-weight: 700;
      overflow: hidden;
      margin: -12px 0 -4px !important;
      transform: translateY(-50%);
    }

    &Table {
      width: auto !important;
      border-spacing: 0 !important;
      border-collapse: collapse !important;
      border: 0 !important;

      &Row {
        background: none !important;
      }

      &Header {
        font-size: inherit !important;
        font-weight: 400 !important;
        line-height: 1.4 !important;
        min-height: auto !important;
        padding: 0 10px 0 16px !important;
        text-align: left !important;
        white-space: nowrap !important;
        color: inherit !important;
        border: 0 !important;
        background: none !important;

        &:first-child {
          padding-left: 0 !important;
        }
      }

      &Data {
        font-size: 14px !important;
        line-height: 1.4 !important;
        min-height: auto !important;
        padding: 0 !important;
        text-align: left !important;
        color: inherit !important;
        border: 0 !important;
        background: none !important;
      }
    }
  }

  @media (pointer: coarse) {
    bottom: env(safe-area-inset-bottom);
    display: block;

    @media (min-height: 700px) {
      bottom: calc(env(safe-area-inset-bottom) + 56px);
    }
  }

  @media (max-width: 1023px) {
    display: block;
  }
}