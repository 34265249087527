@media print {
  .beef-history {
    .col-1 {
      width: 12%;
    }
    .col-2 {
      width: 8%;
    }
    .col-3 {
      width: 20%;
    }
    .col-4 {
      width: 20%;
    }
    .col-5 {
      width: 10%;
    }
    .col-6 {
      width: 12%;
    }
    .col-7 {
      width: 18%;
    }
    .unit {
      margin-right: 1vw;
    }
    .center {
      text-align: center !important;
    }
    .right {
      text-align: right !important;
    }
    .beef-history-print-title {
      text-align: center !important;
      margin: 2em;
    }
    .beef-history-print-thead {
      tr {
        border: none !important;
        td {
          border: none !important;
        }
      }
    }
    .print-section {
      padding: 2px;
    }
    @for $i from 1 through 5 {
      .letter-spacing-#{$i} {
        letter-spacing: 1em * $i;
        margin-left: 1em * $i;
       }
    }
    table {
      border: none !important;
    }
    td, td>span{
      font-size: 12pt;
    }
    // 共通cssの上書き
    tr:last-child td {
      border-bottom: 1px solid black;
    }
    td, th {
      border: 1px solid #000 !important;
    }
  }
}
