.relationGraph {
  .content-box {
    height: auto!important;
  }

  .separate-none {
    width:49%;
    padding:0px;
    &.margin-right {
      margin-right: 2%;
    }
    .target-startend-date {
      max-width : 150px;
      min-width : 100px;
    }
    .section {
      box-shadow: none;
      &.minHeight-m {
        min-height: 95px;
      }
      .target-checkbox-group {
        float: left;
        width: 130px;
        margin-right: 20px;
        margin-bottom: 15px;
        span.vertical {
          vertical-align: text-bottom;
        }
      }
    }
  }
  .right-shoulder {
    .right-contents {
      .search-wrapper {
        position: absolute;
        right: 20px;
        top: 10px;
        .search-box {
          label {
            display: block;
            margin-bottom: 2px;
            font-weight: normal;
          }
          div {
            float: left;
            width: 33.3%;
            &:nth-of-type(1) {
              width: 26%;
            }
            &:nth-of-type(2) {
              width: 74%;
              font-weight: normal;
            }
          }
          .search-box--options {
            display: flex;
            flex-wrap: wrap;
            font-weight: normal;

          }
          .search-box--option {
            width: 50%;
          }
          .search-box--label {
            float: left;
            width: 26%;
          }
          ul li {
            padding: 15px 0;
            border-bottom: 1px solid #eee;
            &:last-child {
              border-bottom: none;
            }
          }
        }
        .disuse {
          div {
            &:nth-of-type(2) {
              width: 74%;
              text-align: left;
            }
            p:first-child {
              margin-bottom: 7px;
            }
          }
          span {
            margin: 0 2px !important;
            font-size: 1.1rem;
          }
        }
        .text-search {
          input {
            display: inline-block;
            width: 100%;
            padding: 5px 6px;
            font-weight: normal;
            border: none;
          }
          div:nth-of-type(2) {
            width: 74%;
          }
        }
        button {
          display: block;
          width: 100%;
          padding: 0px 4px!important;
          height: 30px;
          color: #fff;
          border-radius: 6px;
          border: none;
          background: $orange;
          box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
          transition: border linear 0.2s, box-shadow linear 0.2s;
          &:hover {
            opacity: .8;
          }
          &:disabled {
            background-color: #84837f;
          }
        }
      }
      .search-btn {
        .orange-btn {
          border-radius: 6px;
          box-shadow: 0 1px 2px 0 rgba(90, 90, 90, 0.3);
          background-color: $orange;
          color: #fff;
          display: inline-block;
          font-size: 1.1rem;
          height: 28px;
          line-height: 28px;
          padding: 0 10px 0 25px;
          position: relative;
          top : -13px;
          width: 84px;
          &:hover {
            opacity : 0.8;
            text-decoration: none;
          }
          &:disabled {
            background-color: #84837f;
          }
          // 旧アイコンフォント調整
          .icon-m-search {
            font-size: 13px !important;
            top:calc(50% - 6px);
            left: 7px;
            color: #fff;
          }
        }
      }
      .search-box {
        position: absolute;
        right: 0;
        top: 45px;
        width: 300px;
        padding: 0 15px;
        color: #fff;
        background-color: #4a4a4a;
        border-radius: 2px;
        box-shadow: 0 3px 4px 0 rgba(120, 120, 120, 0.5);
        z-index: 1;
        &:before {
          content: "";
          position: absolute;
          top: -16px;
          right: 40px;
          width: 0;
          height: 0;
          border-right: 8px solid transparent;
          border-bottom: 20px solid #4a4a4a;
          border-left: 8px solid transparent;
        }
      }
      .switch-btn .edit-btn {
        min-width: 5rem;
        padding: 0 13px;
        top: 0px;
      }
    }
  }
  .fixed-tb {
    height : 264px;
    table.analysisTargetTable {
      tbody {
        tr {
          td {
            padding: 5px;
            span.vertical { vertical-align: text-bottom; }
          }
        }
      }
    }
  }
  .box {
    height: auto;
  }
  .c3-target-opacity-bar0 path{
    opacity: 0.6 !important;
  }
  .c3-target-opacity-bar1 path{
    opacity: 0.6 !important;
  }
  .c3-target-opacity-bar2 path{
    opacity: 0.6 !important;
  }
  .c3-target-opacity-bar3 path{
    opacity: 0.6 !important;
  }
  .c3-target-opacity-bar4 path{
    opacity: 0.6 !important;
  }
  .c3-target-opacity-bar5 path{
    opacity: 0.6 !important;
  }
}

