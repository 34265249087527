/*
  ページャー機能の共通スタイル
  a.pager-link-next>i.pager-link-icon
*/

// 前次のリンク
.pager-link-previous,
.pager-link-next {
  display: inline-block;
  line-height: 1;
  vertical-align: middle;
}

// リンクアイコン
.pager-link-icon {
  color: $orange;
  font-size: 2rem;
  padding-bottom: .5rem;
}
