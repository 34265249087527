@media print {
  .barn .reproduction-performance-report .summary div.table-container {
    width: 80mm;
  }
  .reproduction-performance-report h4.kindColor {
    color: #000 !important;
    font-size: 7pt;
    margin-bottom: 0;
    padding: 0px 10px;
  }
  .barn .reproduction-performance-report .summary table tbody td {
    text-align: right !important;
    border-bottom: 1px solid #333;
  }
  .barn .reproduction-performance-report .summary td:first-child {
    border-left: none !important;
  }
  .barn .reproduction-performance-report .summary table thead tr th {
    font-size: 7pt;
    padding: 7px 10px;
    text-align: center;
  }
  .barn .top--table-simple td span {
    font-size: 7pt;
  }
  .barn .reproduction-performance-report .note {
    font-size: 7pt;
  }
  .barn {
    padding: none;
  }
  .barn .reproduction-performance-report.non-print {
    display: none;
  }
  .barn .reproduction-performance-report.print {
    display: block;
  }

  .barn .reproduction-performance-report {
    .result {
      display: block;
      padding: 10px 0px;
      table {
        border-collapse: collapse;
        border: 1px solid black;
        thead {
          border: 1px solid black;
        }
        th {
          border: 1px solid black;
        }
        tr {
          td {
            div.all-data {
              text-align: right;
            }
          }
          td.data-all {
            border-left: 1px solid black;
            text-align: right;
          }
          td.data {
            border-left: 1px solid black;
            text-align: right;
          }
        }
      }
    }
  }
}