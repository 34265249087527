.table-directive {
  .table-wrapper {
    overflow: auto;
    height: calc(100vh - 229px);
  }
  // 薬品マスタ
  .medicine-name {
    min-width: 20rem;
  }
  // 精液マスタ
  .sperm-code {
    min-width: 20rem;
  }
  .sperm-name {
    min-width: 10rem;
  }
  // ホルモンプログラム
  .hormone-program-name {
    min-width: 40rem;
  }
  // 市場マスタ
  .market-name {
    min-width: 40rem;
  }
  // 共通
  .operation {
    width: 55px;
  }

  thead {
    th {
      padding-left: 10px;
      padding-right: 10px;
      border: none;
      .required {
        margin-top: 0;
      }
    }
  }

  tbody {
    td {
      border: none;
      word-break: break-all;

      &.operation {
        text-align: center;
      }
      // 操作不可
      button[disabled] {
        cursor: not-allowed;
        i {
          color: rgba(255, 0, 0, 0.4);
        }
      }
    }
    tr {
      &:last-child {
        border-bottom: solid 1px #ddd;
      }
    }
  }
}

.data-nothing {
  font-weight: bold;
  color: $orange;
}
