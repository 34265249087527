/**
 *  Buttons
 *  $1. Default styles
 */

// TEMPORARY
// REMOVE AFTER REDESIGN

// 旧アイコンフォント調整
.button-cancel {
  .icon-l-arrow-A-l01 {
    font-size: 16px !important;
    width: 16px;
    height: 16px;
    margin-right: 8px;
    color: #fff;
    border-radius: 50%;
    background-color: #ff9908;

    align-items: center;
    justify-content: center;
  }
}

.buttons-group {
  display: flex;
  justify-content: space-between;

  > button + button {
    margin-left: 20px;
  }
}

.flex-right {
  margin-left: auto !important;
}

.right-justified-group {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
  padding-bottom: 0;
}

.button-orange {
  background-color: $orange;
  color: #fff;
  position: relative;
  display: inline-block;
  height: 22px;
  padding: 0 6px 0 22px;
  min-width: 5rem;
  font-size: 1.1rem !important;
  line-height: 22px;
  border-radius: 4px;
  &:hover {
    text-decoration: none;
    opacity: .8;
  }
  &:disabled {
    background-color: #84837F;
  }
  // 旧アイコンフォント調整
  i {
    position: absolute;
    top: calc(50% - 5px);
    left: 5px;
    color: #fff;
    font-size: 12px !important;
  }
  &.no-icon {
    padding-left: 6px;
  }
}

.btn-help {
  background-color: #000;
  border-radius: 50%;
  color: #fff;
  font-size: 1rem!important;
  height: 15px;
  width: 15px;
  padding: 0;
  margin: 0 5px!important;
  &:hover,
  &:focus,
  &:active {
    color: #fff !important;
  }
}

button.add-item {
  margin-left: 1em !important;
  border: 1px solid gray !important;
  padding: 0.2em 0.5em;
}
button.remove-item {
  margin-left: 1em !important;
  border: 1px solid gray !important;
  padding: 0.2em 0.5em;
}

.button-modern {
  font-size: 13px !important;
  margin: 8px 0 !important;
  padding: 5px 12px;
  transition: all .2s ease;
  color: #2a66b7;
  border: 1px solid #ddd;
  border-radius: 32px;
  background: #fff;

  &:hover {
    border: 1px solid #fff;
    background: linear-gradient(to right, #fff, #f2f2f2);
    box-shadow: 0 1px 4px rgba(0, 0, 0, .16);
  }

  &--arrow {
    font-size: 13px !important;
    position: relative;
    margin: 8px 0 !important;
    padding: 5px 30px 5px 12px;
    transition: all .2s ease;
    color: #2a66b7;
    border: 1px solid #ddd;
    border-radius: 32px;
    background: #fff;

    &::after {
      position: absolute;
      top: calc(50% - 5px);
      right: 10px;
      content: '';
      border-top: 5px solid transparent;
      border-bottom: 5px solid transparent;
      border-left: 7px solid #212121;
    }

    &:hover {
      border: 1px solid #fff;
      background: linear-gradient(to right, #fff, #f2f2f2);
      box-shadow: 0 1px 4px rgba(0, 0, 0, .16);
    }
  }
}