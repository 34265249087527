.activity-stacked-bar-chart-message {
  text-align: center;
  width: 100%;
  padding-top: 150px;
  display: block;
}
#activity-stacked-bar-chart {
  .c3-tooltip-container {
    table {
      th,
      td {
        width: auto;
      }
    }
  }
  g.c3-ygrids {
    line.c3-ygrid {
      stroke-dasharray: none;
      stroke-opacity: 0.3;
    }
  }
}