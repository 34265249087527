.calendar {
  height:100%;
  h4 {
    color: #009943;
  }
  .flesh {
    fill: #52d8a9;
  }
  .kuutai {
    fill: #D85C5C;
  }
  .tanezukenashi {
    fill: #d88538;
  }
  .jusei {
    fill: #b985d8;
  }
  .jutai {
    fill: #80d85c;
  }
  .kannyu {
    fill: #7b96d8;
  }
  .point {
    cursor: pointer;
  }
  .point-text {
    cursor: pointer;
  }
  .cow-calendar {
    height:100%;
  }

  .legend {
    text {
      fill: #545454;
      &.legend-title {
        font-size: 23px;
      }
      &.legend-body {
        font-size: 35px;
      }
    }
  }
  .detail-frame {
    fill: #ffffff !important;
  }

  .detail {
    a text {
      fill: #175cc9;
    }
    text {
      fill: #545454;
      &.detail-title {
        font-size: 23px;
      }
      &.detail-body {
        font-size: 30px;
      }
    }
    width: 20%;
    display: inline-block;
    vertical-align: top;
    .detail dt {
      width: 100px !important;
    }
  }
}
