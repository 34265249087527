.master {
  .content-box {
    height: 100%;
  }
}

.master-wrapper {
  display: flex;

  // MasterContentDirective
  .master-content {
    padding: 10px;
    background: white;
    overflow: hidden;
    width: 600px;

    .content-title {
      h4 {
        margin-top: 0;
      }
      .add-btn {
        position: absolute;
        top: 7px;
        right: 7px;
        padding: 0;
        text-align: center;
        min-width: 6rem;
      }
      .edit-order-btn {
        position: absolute;
        top: 7px;
        right: 7rem;
        padding: 0px 8px 0px 0px;
        text-align: center;
        min-width: 5rem;

        // 旧アイコンフォント調整
        .icon-s-swap {
          font-size: 10px !important;
          position: relative;
          top: 0;
          padding: 0 3px;
          color: #fff;
        }
      }
    }

    .content {
      margin: 0;
      padding-top: 0;
      // テーブル
      .show-all-label {
        font-size: 1.3rem;
      }
      .ui-grid-viewport {
        &:hover {
          cursor: default;
        }
        *:hover{
          background: transparent;
        }
      }
    }
  }
}

.master-list-wrapper {
  height: calc(100vh - 229px);

  @media (pointer: coarse) and (min-height: 700px) {
    height: calc(100vh - 360px);
  }

  // 旧アイコンフォント調整
  .icon-s-check-box-outlined,
  .icon-s-edit,
  .icon-s-delete {
    font-size: 12px !important;
  }
}

section.u-alert-form--field {
  div.u-alert-form--field-content {
    p {
      input.u-input-width8 {
        width: 132px !important;
      }
    }
  }
}
