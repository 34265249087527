#toast-container {
  .toast {
    &.toast-error-override {
      box-shadow: 0 0.6rem 1.5rem rgba(62,62,62,.8);
      min-height: 77px;
      opacity: .9;
      padding: 22px 15px 15px 88px;
      width: 350px;
      background-color: #b01a1a ;

      &:hover {
        box-shadow: 0 0.2rem 1.5rem rgba(32,32,32,.8);
        opacity: .95;
      }

      &:before {
        left: 12px;
      }

      .toast-title {
        font-size: 1rem;
        left: 13px;
        margin-top: 1.5rem;
        position: absolute;
        top: 50%;
      }

      .toast-message {
        font-size: 1.4rem;
      }

      .icon-l-caution {
        font-size: 34px !important;
        left: 20px;
        margin-top: -2.5rem;
        position: absolute;
        top: 50%;
        animation:bounce 1.6s ease infinite normal 0s none running;
        -webkit-animation:bounce 1.6s ease infinite normal 0s none running;
        -ms-animation:bounce 1.6s ease infinite normal 0s none running;
      }
    }
    .toast-message {
      white-space: normal;
    }
  }
}

@keyframes bounce {
  0%, 100%, 35% {
      transform: translateX(0px);
  }
  5%, 15%, 25% {
      transform: translateX(2px);
  }
  10%, 20%, 30% {
      transform: translateX(-2px);
  }
}
@-webkit-keyframes bounce {
  0%, 100%, 35% {
      transform: translateX(0px);
  }
  5%, 15%, 25% {
      transform: translateX(2px);
  }
  10%, 20%, 30% {
      transform: translateX(-2px);
  }
}
