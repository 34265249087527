.help {
  @import "components/help/content";

  .help-wrapper {
    display: flex;
    height: 100%;
  }
  .help-wrapper .help-list {
    width: 800px !important;
  }

  .help-list {
    table {
      border-left: none;
      border-right: none;

      thead {
        th {
          background-color: #f6f6f6 !important;
          color: #333;
          text-align: left;
          font-size: inherit;
          padding: 6px;
        }
      }
      tbody {
        tr {
          cursor: pointer;
          border-top: 1px solid #d4d4d4 !important;

          td {
            padding: 6px;
            font-size: inherit;
          }
        }
        tr:hover {
          background-color: #f0f0f0 !important;
        }
      }
    }
  }

  tr:nth-child(odd), tr:nth-child(even) {
    background-color: white !important;
  }
}

.help-detail {
  position: relative;
  height: 100vh;
  min-height: 580px;
  background-color: #1f1f1f;

  &-vimeo {
    position: absolute;
    top: calc(50% - 265px);
    left: calc(50% - 500px);
    border: none;
  }
}