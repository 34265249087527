.side-menu {
  width: 150px;
  min-width: 150px;
  margin-right: 10px !important;
  padding: 5px;
  background: white;

  &-item {
    position: relative;
    padding: 10px;
    cursor: pointer;
    border-bottom: 1px solid rgba(192, 192, 192, 0.2);

    // 旧アイコンフォント調整
    .icon-l-arrow-A-r01 {
      font-size: 9px !important;
      position: absolute;
      top: 13px;
      right: 6px;
      display: inline-block;
      width: 9px;
      height: 9px;
      color: #fff !important;
      border-radius: 50%;
      background-color: #ff9908;
    }

    &.active {
      font-weight: bold;
      color: $primaryColorOld;
      background-color: #f0f0f0;
    }

    &:last-child {
      border-bottom: none;
    }
  }
}