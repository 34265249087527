.box-info {
  .individual {
    th {
      width: 25%;
    }
    td {
      width: 25%;
    }
  }
  input[type="radio"] {
    margin-right: 5px;
  }
  .table-col2 td {
    width: 75%;
  }
  label + label {
    margin-left: 20px;
  }
}

.result {
  height: 100%;
  padding: 10px;
  table {
    table-layout: fixed;
    background: #fff;

    // 旧アイコンフォント調整
    .icon-s-notice {
      font-size: 12px!important;
    }

    // 旧アイコンフォント調整
    .icon-s-check-box-outlined {
      font-size: 12px!important;
    }

    // 旧アイコンフォント調整
    .icon-s-edit {
      font-size: 12px!important;
    }

    // 旧アイコンフォント調整
    .icon-s-delete {
      font-size: 12px!important;
    }
  }
  td {
    text-align: center;
  }
  .col1 {
    width: 100px;
  }
  .col2 {
    width: 170px;
  }
  .col3 {
    width: 110px;
  }
  .col4 {
    width: 150px;
  }
  .col5 {
    width: 100px;
  }
  .col6 {
    width: 100px;
  }
  .col7 {
    width: 100px;
  }
  .col8 {
    width: 120px;
  }
  .col9 {
    width: 50px;
  }
  .col10 {
    width: 100px;
  }
  .col11 {
    width: 100px;
  }
  .col12 {
    width: 100px;
  }
  .col13 {
    width: 70px;
  }
  .col-w20 {
    width: 20px;
  }
  .col-w25 {
    width: 25px;
  }
  .col-w30 {
    width: 30px;
  }
  .col-w40 {
    width: 40px;
  }
  .col-w45 {
    width: 45px;
  }
  .col-w50 {
    width: 50px;
  }
  .col-w55 {
    width: 55px;
  }
  .col-w60 {
    width: 60px;
  }
  .col-w70 {
    width: 70px;
  }
  .col-w75 {
    width: 75px;
  }
  .col-w80 {
    width: 80px;
  }
  .col-w85 {
    width: 85px;
  }
  .col-w90 {
    width: 90px;
  }
  .col-w100 {
    width: 100px;
  }
  .col-w120 {
    width: 120px;
  }
  .col-w140 {
    width: 140px;
  }
  .col-w150 {
    width: 150px;
  }
  .col-w200 {
    width: 200px;
  }
  .col-w350 {
    width: 350px;
  }
}
th {
  &.blue {
    background-color: rgba(46, 139, 204, 0.75);
  }
  &.green {
    background-color: rgba(51, 153, 51, 0.75);
  }
  &.red {
    background-color: rgba(229, 20, 0, 0.75);
  }
  &.yellow {
    background-color: rgba(255, 196, 13, 0.75);
  }
  &.orange {
    background-color: rgba(243, 156, 18, 0.75);
  }
  &.pink {
    background-color: rgba(230, 113, 184, 0.75);
  }
  &.purple {
    background-color: rgba(123, 79, 157, 0.75);
  }
  &.pumpkin {
    background-color: rgba(211, 84, 0, 0.75);
  }
  &.teal {
    background-color: rgba(0, 171, 169, 0.75);
  }
}
.cowList {
  .table-area-container {
    width: 100%;
    height: 100%;
  }
  .table-area-wrapper {
    width: 100%;
    height: 100%;
    float: left;
    &.cow-board-opened {
      width: calc(100% - 460px);
    }
    #table-area {
      position: relative;
      overflow: hidden;
      padding-right: 0;
      height: calc(100vh - 190px);

      .fixed-table-wrapper {
        height: calc(100% - 47px);
      }
    }
  }
  td {
    padding: 5px;
  }
  .tb-customList {
    th {
      position: relative;
    }
    td {
      position: relative;
      padding: 5px;
    }
    .cow-labels {
      display: inline-block;
      padding: 1px 5px;
      margin-top: 2px;
      font-size: 1.1rem;
      color: #fff;
      //background-color: #8aa633;
      margin-left: 3px;
      border-radius: 2px;
    }
  }
  .cow-board-wrapper {
     width: 0px;
     height: calc(100vh - 177px);
     float: left;
     &.cow-board-opened {
       width: 460px;
     }
    .cow-board {
      height: 100%;
      width: 100%;
      top: 0px;
      right: 0px;
      transform: translateX(460px);
      &.open {
        transform: none;
      }
    }
    .react & {
      height: calc(100vh - 128px);

      @media (pointer: coarse) and (min-height: 700px) {
        height: calc(100vh - 184px);
      }
    }
  }
  .cow-labels {
    display: inline-block;
    padding: 1px 5px;
    margin-top: 2px;
    font-size: 1.1rem;
    color: #fff;
    //background-color: #8aa633;
    margin-left: 3px;
    border-radius: 2px;
  }
  .w-cowLabel { width: 9em; }
  .w-cowNo { width: 4em; }
  .w-cowUid { width: 7.4em; }
  .w-yymmdd-day { width: 10.5em; }
  .w-4letters { width: 5em; }
  .w-12rem {width: 12rem;}
  .w-11rem {width: 11rem;}
  .w-10rem {width: 10rem;}
  .w-9rem {width: 9rem;}
  .w-8rem {width: 8rem;}
  .w-7rem {width: 7rem;}
  .w-6rem {width: 6rem;}
  .w-5rem {width: 5rem;}
  .w-4rem {width: 4rem;}
  .w-3rem {width: 3rem;}
  .w-2rem {width: 2rem;}
  .w-1rem {width: 1rem;}
  .long-text {width: 38%;}
}

.searchCow {
  .separate-none {
    padding: 0;
    width: 49%;
    &.margin-right {
      margin-right: 2%;
    }
    .section {
      box-shadow: none;
      .target-checkbox-group {
        float: left;
        margin-bottom: 15px;
        margin-right: 20px;
        width: 130px;
        &.shortText {
          width: 85px;
        }
      }
      .inline-item {
        display: inline-block;
        width: 80px;
      }
      .separate {
        margin: 0 10px;
      }
    }
    button.search-button {
      display: block;
      width: 100%;
      padding: 0px 4px!important;
      height: 30px;
      color: #fff;
      border-radius: 6px;
      border: none;
      background: $orange;
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
      transition: border linear 0.2s, box-shadow linear 0.2s;
      &:hover {
        opacity: .8;
      }
      &:disabled {
        background-color: #84837f;
      }
    }
  }
}

.number-box {
  &.number-box-inner {
    padding: 0 10px;
    .section {
      min-height: 130px;
      background-color: #fafafa;
      box-shadow: none;
      border: 3px solid #ebebeb;
    }
    li .section .number {
      span {
        font-size: 5.5rem;
        padding-left: 2rem;
      }
      &.pl0 span {
        padding-left: 0;
      }
    }
  }
  li .section {
    min-height: 150px;
    .number {
      padding: 6px 10px 3px;
      font-size: 1.6rem;
      font-weight: normal;
      text-align: center;
      letter-spacing: 0;
      span {
        padding-right: .5rem;
        font-size: 5.5rem;
        font-weight: bold;
        line-height: 1;
      }
      &.attension span {
        color: #f65757;
      }
    }
    .supplement {
      font-size: 1.3rem;
      font-weight: bold;
      text-align: center;
    }
    .supplement-l {
      font-size: 2.0rem;
      font-weight: bold;
      text-align: center;
      span {
         font-size: 2.0rem;
         font-weight: bold;
         text-align: center;
       }
    }
  }
}

// 複製されたヘッダ０－のボーダを消す。
table.clone {
  border: none;
}

&.barn--headage-details #table-area,
&.barn--daily-report-details #table-area {
  height: calc(100vh - 154px)!important;
}
