/**
 *  TODO: remove !important flags
 *  Island
 *  layout component
 *  $1. Default styles
 *  $2. Custom styling for Activity Tab islands
 */

/**
 *  $1. Default styles
 */
// TODO: remove active flag, requires JS refactor
.island--wrapper.active {
  display: flex !important;
  flex-flow: row nowrap;
}

.island--wrapper {
  display: none;
  flex-flow: row wrap;
  padding-top: 10px;
}

.layout-column-left {
  width: 66.666666%;
  width: calc(100% / 3 * 2);
  padding: 0 10px 0 0;
}

.layout-column-right {
  width: 33.333333%;
  width: calc(100% / 3);
}

.island {
  margin-bottom: 10px !important;
  overflow: hidden;
}

/*
 *  $2. Custom styling for Activity Tab islands
 */
.activityCharts {
  background-color: #fff;
  padding: 10px;
  display: flex;
  flex-flow: column nowrap;
  min-width: 0;
  justify-content: center;
}

// TimeBudget chart should not expand
.activityCharts-timeBudget {
  height: auto;
  overflow: hidden;

  .graph-title-box {
    display: flex;
    justify-content: center;

    input {
      position: relative;
      z-index: 10;
    }

    input:focus {
      outline: 0;
    }
  }
}
.activityTab {
  .disabled {
    color: #ccc !important;

  }
}
// Height for Heat and Activity charts
.activity--chart-wrapper {
  min-height: 320px;
}
// Undo .barn h4 styling
.activityCharts--header {
  margin: 0 !important;
  color: $primaryColorOld;
}

// Remove left padding to create equidistant spacing
.totalStandingTime--island,
.totalLayTime--island {
  padding-left: 0;
}

/*
 * Toggle that changes the order of each island
 */
.toggler {
  // Hide heatchart
  .heatChart--island {
    display: none;
  }
  // Make activityChart the first item
  .activityChart--island {
    order: 1;
  }
  // Keep totalStandingTime at number two
  .totalStandingTime--island {
    order: 2;
  }
  // Keep totalStandingTime at number four
  .totalLayTime--island {
    order: 4;
  }
}
