// z-index
.modal {
  z-index: 10005000 !important;
}
.modal-backdrop {
  z-index: 10004000 !important;
}

.history-modal {
  min-height: 100%;
  height: 100%;
  line-height: 1.5;
  font-size: 1.3rem;
  text-align: left;
  background-color: $grey;
  .content {
    .title-box {
      position: relative;
      width: 100%;
      top: 0px;
      height: 52px;
      padding: 5px;
      text-align: center;
      box-shadow: 0 1px 2px 1px rgba(90, 90, 90, 0.3);
      z-index: 11000;
      background: #fff;
      background: linear-gradient(#ededed 0, #ffffff 100%);
      filter: progid:dximagetransform.microsoft.gradient(startcolorstr='#ededed', endcolorstr='#ffffff', gradienttype=0);
      &.add-input {
        height: 80px;
      }
    }

    section.u-alert-form--field {
      div.u-alert-form--field-content {
        p {
          input.u-input-width8 {
            width: 132px !important;
          }
        }
      }
    }
  }
  .center {
    text-align: center;
  }
  .margin-tb {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .table {
    margin-bottom: 0px;
  }
  .modal-header {
    background-color: #ffffff;
  }
  .modal-body {
    padding: 10px;
    table.table-col1 {
      .target-checkbox-group {
        span.vertical {
          vertical-align: text-bottom;
        }
      }
    }
    &.beef {
      .circle-btn:hover {
        background-color: $primaryColorOld;
      }
    }
  }
  .selection {
    display: table;
    text-align: left;
    width: 100%;
    background-color: #ffffff;
    padding: 10px;
    .max-4-cols {
      padding-left: 120px;
    }
    .max-5-cols {
      padding-left: 60px;
    }
    .selection-item {
      position: relative;
      list-style: none;
      display: inline-block;
      text-align: center;
      margin: 10px;
      &.breeding {
        background-color: #56b1bc;
      }
      &.medical {
        background-color: #bc7e9c;
      }
      &.other {
        background-color: #ace8ba;
      }
    }
    tr[ng-click], td[ng-click] {
      cursor: pointer;
      input[type="checkbox"], input[type="radio"] {
        cursor: pointer;
      }
    }
  }

  .selection.narrow {
    width: 750px;
    margin: auto;
  }

  table.tb-selectMedicine {
    td {
      word-break: break-all;
    }
    .medicine-col1 {
      width: 50px;
    }
    .medicine-col3 {
      width: 120px;
    }
    .medicine-col4 {
      width: 120px;
    }
  }
  table.tb-restartShipment {
    .restart-col1 {
      width: 150px;
    }
  }
  a.circle-btn {
    width: 98px;
    height: 100px;
    line-height: 70px;
    display: inline-block;
    text-decoration: none;
    vertical-align: middle;
    padding-top: 12px;
    border: solid #333 0.7px;
    border-radius: 50%;
    color: #333;
    font-size: 20px;
    font-weight: bold;
    background-color: #ffffff;
    box-shadow: 0 1px 2px 2px rgba(90, 90, 90, 0.3);
    &:hover {
      background-color: #8aa633;
      color: #fff;
    }
    &.long-text {
      font-size: 16px;
      line-height: 23px;
      padding-top: 25px;
    }
    &.isdisabled {
      pointer-events: none;
      opacity: 0.5;
    }
  }

  &.event-history-filter {
    .modal-body {
      overflow: hidden;
      .modal-body-inner {
        input, label {
          margin: 0;
        }
        table {
          th, td {
            padding: 10px;
          }
        }
        ul {
          margin-bottom: 0;
        }
        .event-toggle-all {
          text-align: right;
          button {
            border-radius: 6px;
            box-shadow: 0 1px 2px 0 rgba(90,90,90,.3);
            padding: 3px 6px;
            margin: .5em;
            &:hover {
              color: #ff9908;
              text-decoration: none;
            }
          }
        }
        .event-history-filter-table-wrapper {
          max-height: 300px;
          overflow: auto;
          tbody {
            tr {
              td {
                label {
                  font-weight: 400;
                }
              }
            }
          }
        }
        .event-history-filter-event-group {
          border-top: 1px solid #ddd;
          display: flex;
          &:last-child {
            border-bottom: 1px solid #ddd;
          }
          input {
            margin-right: 3px;
          }
          .group-checkbox-wrapper {
            width: 10rem;
            padding: 6px 5px;
            flex: 0 0 auto;
            justify-content: flex-start;
            align-items: center;
            background: #ddd;
          }
          .event-checkbox-container {
            flex: 1 1 auto;
            padding: 3px 0px;
            li {
              float: left;
              width: 14rem;
              padding: 3px 5px;
              display: flex;
              justify-content: flex-start;
              align-items: center;
            }
          }
        }
      }
    }
  }

  .history-selection-wrapper {
    background-color: white;
    width: 100%;
  }

  // 旧アイコンフォント調整
  .icon-s-check-box-outlined {
    font-size: 11px !important;
  }

  .icon-s-edit {
    font-size: 11px !important;
  }

  .icon-s-delete {
    font-size: 11px !important;
  }

  .icon-css-plus {
    position: relative;
    display: inline-block;
    width: 16px;
    height: 16px;
    vertical-align: top;

    &::before {
      position: absolute;
      top: 7px;
      left: 3px;
      display: block;
      width: 9px;
      height: 1px;
      content: '';
      background-color: #333;
    }

    &::after {
      position: absolute;
      top: 7px;
      left: 3px;
      display: block;
      width: 9px;
      height: 1px;
      content: '';
      transform: rotate(90deg);
      background-color: #333;
    }
  }

  .icon-css-cancel {
    position: relative;
    display: inline-block;
    width: 12px;
    height: 12px;
    margin: 0 2px 0 0;
    vertical-align: top;

    &::before {
      position: absolute;
      top: 9px;
      left: 0;
      display: block;
      width: 10px;
      height: 1px;
      content: '';
      transform: rotate(45deg);
      background-color: #333;
    }

    &::after {
      position: absolute;
      top: 9px;
      left: 0;
      display: block;
      width: 10px;
      height: 1px;
      content: '';
      transform: rotate(-45deg);
      background-color: #333;
    }
  }
}

.multiple-choice-body {
  .multiple-choice-text {
    background-color: #fff;
    text-align: center;
    margin: 0;
    padding: 18px 16px;
    span {
      font-size: 1.3rem;
    }
    .event-name {
      color: #477d17;
      font-size: 1.4rem;
      font-weight: bold;
    }
  }
  .multiple-choice-group {
    background-color: #60615e;
    text-align: center;
    padding: 10px 10px 0px 10px;
    &:last-child {
      padding: 10px 10px 10px 10px;
    }
    ul {
      margin: 0;
      background-color: #fff;
      li {
        padding: 14px 10px;
        font-size: 1.4rem;
        font-weight: bold;
        border-bottom: 1px solid #ccc;
        &:nth-last-of-type(1) {
          border-bottom: none;
        }
      }
    }
    .child--list {
      &:hover {
        background-color: #f2f2f2;
        cursor: pointer;
      }
    }
  }
}

.modal {
  &.modal-center {
    display: flex !important;
    .modal-dialog {
      margin: auto;
    }
  }
  .modal-dialog {
    &.modal-table-list-lg {
      width: 900px;
      .modal-content {
        max-height: 600px;
        div.table-content {
          max-height: 500px;
          overflow: auto;
        }
      }
    }
    &.modal-select-standard {
      width: 600px;
      .modal-content {
        max-height: 400px;
        div.table-content {
          max-height: 300px;
          overflow: auto;
        }
      }
    }
    .modal-content {
      pre {
        &.pre-style-none {
          background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
          border: medium none;
          font-family: ヒラギノ角ゴ Pro W3, Hiragino Kaku Gothic ProN, YuGothic, 游ゴシック, Meiryo, メイリオ, ＭＳ Ｐゴシック, Verdana, sans-serif;
          padding: 0;
        }
      }
      .select-farm{
        &.disabled {
          background-color: #f6f4f4;
        }
      }
    }
  }
}

.modal-653 {
  width: 653px;
}

.modal-770 {
  width: 770px;
}

.modal-890 {
  width: 892px;
}

.modal-1012 {
  width: 1012px;
}

// 牛群設定
.cow-group-setting-edit-modal,
.cow-group-setting-entry-modal {
  .menu-displayed-label {
    margin-bottom: 0;
  }
  .menu-displayed-input {
    margin-top: 0;
  }
  .text-box {
    .error-message {
      color: #FF0000;
      white-space: pre-wrap;
    }
  }
}

// マスタ関連
.master-modal {
  .notes {
    color: $orange;
  }
  .error-message {
    padding: 0;
  }
  .title-box {
    .delete-btn {
      position: absolute;
      top: 14px;
      right: 80px;
      height: 22px;
      background-color: $orange;
      min-width: 5rem;
      color: white;
      font-size: 1.1rem;
      line-height: 22px;
      border-radius: 4px;
      span {
        color: white;
      }
    }
  } // ホルモンプログラムマスタ
  .table-wrapper {
    height: 200px;
    overflow: auto;
  }
  .hormone-program-name {
    margin-bottom: 10px;
  }
  .hormone-program-name-label {
    margin-right: 40px;
  }
  .hormone-program-name-input {
    width: calc(100% - 178px);
  }
  .master-table {
    table-layout: fixed; // 精液マスタ
    &.sperm {
      th {
        width: 35%;
      }
    } // ホルモンプログラムマスタ
    &.hormone-program {
      border: none;
      .treatment {
        width: 115px;
      }
      .treatment-period {
        width: 100px;
      }
    }

    // 受精卵マスタ
    &.embryo {
      th {
        width: 140px;
      }
      .sperm-no {
        width: 185px;
        height: 22px;
        border: none;
        background-color: #f6f4f4;
        box-shadow: none;
        float: left;
      }
      td.donor dl {
        margin: 0px;
        dt {
          width: 80px;
          padding: 2px;
          text-align: right;
          font-weight: normal;
          height: 26px;
        };
        dd {
          margin-left: 95px;
          padding: 2px;
          background-color: inherit;
          border-top: none;
          height: 26px;
          .donor-select {
            position: absolute;
            right: 5%;
          }
        }
      }
    }

    th {
      position: relative;
    }
    td {
      div {
        &:first-child {
          margin-bottom: 3px;
        }
        input[type="checkbox"] {
          margin: 0 5px 0 5px;
          width: initial;
        }
        label {
          font-weight: normal;
        }
      }
      input,
      select {
        width: 100%;
        margin: 0;
      }
    }

    tr.master-visible {
      div, label {
        margin-bottom: 0px;
      }
    }
  }
}

// 薬品選択画面
.select-medicine-modal {
  height: 695px;
  max-height: 75vh;
  overflow: hidden;
  .content {
    height: 100%;
    form {
      height: calc(100% - 53px);
      .modal-body {
        height: 100%;
        overflow: hidden;
        .scroll-outer {
          height: 100%;
          overflow: hidden;
          &.medicine-modal {
            display: inherit;

            h4 {
                margin-top: 0px;
                margin-bottom: 0px;
            }
          }
          .notes {
            height: 19px;
            color: $orange;
          }
          .table-content {
            height: calc(100% - 103px);
            th {
              box-sizing: content-box;
            }
            .medicine-col1 {
              width: 50px;
            }
            .medicine-col2 {
              width: auto;
            }
            .medicine-col3 {
              width: 120px;
            }
            .medicine-col4 {
              width: 120px;
            }
            .medicine-col5 {
              width: 100px;
            }
            .medicine-col6 {
              width: 100px;
            }
            input[type=checkbox] {
              margin: 0;
            }
            input[type=text] {
              width: 80%;
              &:disabled {
                background-color: rgba(90, 90, 90, 0.1);
              }
            }
            select {
              width: 100%;
              &:disabled {
                background-color: rgba(90, 90, 90, 0.1);
              }
            }
          }
        }
      }
    }
  }
}

.fixed-header-modal {
  .modal-body {
    th {
      box-sizing: content-box;
    }
  }
}

.help-modal {
  width: 0;
  height: 0;
  overflow: visible !important;
  .modal-dialog {
    margin: 0;
  }
  .modal-content {
    background: none;
    border: none;
    overflow: visible;
    box-shadow: none;
  }
}

.help-content {
  cursor: move;
  background-color: #fff;
  box-shadow: 0 5px 15px rgba(0, 0, 0, .5);
  overflow: auto;
  position: fixed;
  width: 350px;
}

.modal-dialog {
  h4.with-add-btn {
    display: flex;
    .text {
      flex: 1;
      vertical-align: middle;
      display: table-cell;
    }
    button {
      flex: 1;
      text-align: right;
      font-weight: 400;
      min-width: 60px;
      text-align: center;
    }
  }
}

.report-embryo-recovery.filter-dialog {
  .modal-body {
    label {
      text-align: right;
    }
  }
}

.deprecated-customlist-copy-modal {
  height: 695px;
  max-height: 75vh;
  .content {
    .customlist-modal-body-wrapper {
      height: calc(100% - 53px);
      .modal-body {
        height: 100%;
        .scroll-outer {
          display: inherit;
          height: 100%;
          overflow: hidden;
          .table-content {
            height: calc(100% - 103px);
            overflow: auto;
          }
        }
      }
    }
  }
}
