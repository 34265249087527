/* Unique Style of 'Login'
----------------------------------------*/
.login {
  font-size: 1.4rem;
  margin: 0 auto;
  padding-top: 8rem;
  position: absolute;
  height: 100vh;
  width: 100vw;
  overflow: auto;
  top: 0;
  background-color: #fff; }
@media (min-width: 451px) {
  .login {
    display: table-cell;
    padding-top: 6rem; }
  .login.is-collapse {
    padding-top: 0;
    vertical-align: middle; } }

.login .l-alert {
  background-color: #f4ebeb;
  border: 0.1rem solid #d22424;
  color: #d22424;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  font-size: 1.5rem;
  line-height: 1.2;
  margin: 2.4rem auto;
  padding: 1.2rem;
  max-width: 40rem; }

.login .l-form {
  background-color: #f0f0f0;
  margin: 0 -1.5rem 3.2rem;
  padding: 4rem 1.6rem; }
.login .l-form_button {
  background-image: linear-gradient(-80deg, rgba(64, 131, 150, 0.74) 0%, rgba(6, 58, 117, 0.74) 100%);
  background-color: #6c9fad;
  border: none;
  border-radius: 0.2rem;
  color: #fff;
  font-size: 1.8rem;
  height: 5rem;
  transition: background .3s ease;
  width: 100%; }
.login .l-form_button:hover, .login .l-form_button:focus {
  background-color: #133a65;
  outline: none; }
.login .l-form_checkbox {
  opacity: 0;
  position: absolute; }
.login .l-form_checkbox ~ [class^="icon-check"] {
  color: #999;
  display: inline-flex;
  align-items: center;
  font-size: 2.4rem;
  height: 3.2rem;
  margin-right: 0.5rem;
  transition: color .3s ease; }
.login .l-form_checkbox ~ [class^="icon-check"]::before {
  content: "\e917"; }
.login .l-form_checkbox:checked ~ [class^="icon-check"] {
  animation: .08s linear input-checked;
  color: #477d17; }
.login .l-form_checkbox:checked ~ [class^="icon-check"]::before {
  content: "\e916"; }
.login .l-form_checkbox:focus ~ [class^="icon-check"] {
  color: #477d17; }
.login .l-form_checkbox.is-clear ~ [class^="icon-check"] {
  color: #999; }
.login .l-form_field {
  border: none;
  border-bottom: 0.1rem solid transparent;
  border-radius: 0;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.2;
  order: 1;
  padding: 1.4rem 1.2rem;
  transition: border .3s ease;
  width: 100%; }
.login .l-form_field:focus {
  border-bottom-color: #295688;
  outline: none;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none; }
.login .l-form_field:focus ~ b {
  color: #295688; }
.login .l-form_group {
  margin-bottom: 3.2rem; }
.login .l-form_group[data-style="checkbox"] {
  display: inline-flex; }
.login .l-form_label {
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-wrap: wrap; }
.login .l-form_label b {
  display: block;
  line-height: 1.2;
  margin-bottom: 1.2rem;
  transition: color .3s ease; }
.login .l-form_label:focus b {
  color: #295688; }
.login .l-form_label.is-alert b {
  color: #d22323; }
.login .l-form_label.is-alert .l-form_field {
  border-bottom-color: #d22323; }

.login .l-title {
  font-weight: 400;
  font-size: 1.6rem;
  margin-bottom: 4rem;
  margin-top: 0;
  text-align: center; }
.login .l-title_object {
  display: block;
  margin: 0 auto 0.5rem;
  width: 7.7rem; }
.login .l-title + .l-alert {
  margin-top: -1.6rem; }

.login .l-genom-auth-title {
  font-weight: 400;
  font-size: 1.6rem;
  margin-bottom: 2rem;
  margin-top: 0;
  text-align: center; }
.login .l-genom-auth-description {
  font-weight: 400;
  font-size: 1.6rem;
  margin-bottom: 2rem;
  margin-top: 0;
  text-align: center; }
.login .l-genom-auth-description + .l-alert {
  margin-top: -1.6rem; }

.login .l-utility {
  line-height: 1.2;
  margin-bottom: 7rem;
  text-align: center; }

.login .l-utility a {
  font-size: 14px;
    color: inherit;
    text-decoration: none; }
.login .l-utility a:hover, a:focus {
  color: inherit;
  text-decoration: underline; }
@media (pointer: none) {
  .login .l-utility a[href^="tel:"] {
    pointer-events: none; } }
.login .l-form {
  padding-right: 2.8rem;
  padding-left: 2.8rem;
}
@media (min-width: 451px) {
  .login .l-container {
    margin-bottom: 10rem;
    padding-top: 4rem; }
  .login .l-form {
    box-shadow: 0 0.2rem 0.8rem rgba(62, 62, 62, 0.5);
    margin-right: auto;
    margin-left: auto;
    padding-right: 2.8rem;
    padding-left: 2.8rem;
    width: 40rem; }
  .login .l-form_group[data-style="checkbox"] {
    margin-top: -0.8rem;
    margin-bottom: 2.4rem; }
  .login .l-utility {
    margin-bottom: 0; }
  .login .l-utility a {
    text-decoration: underline; }
  .login .l-utility a:hover {
    text-decoration: none; } }

@keyframes input-checked {
  0% {
    transform: scale(0.6); }
  90% {
    transform: scale(0.9); }
  100% {
    transform: scale(1); } }
