/*---------------------------------
　出生報告　印刷画面
---------------------------------*/
.certificateBirth {
  &--print {
    display: none;

    @media print {
      line-height: 1.1;
      display: flex;
      width: 750pt;
      margin: auto !important;

      justify-content: space-between;
      page-break-before: always;

      .certificateBirth {
        &__head {
          display: flex;
        }

        &__heading {
          font-size: 43pt;
          font-weight: 700;
          margin: 3pt 0 0 35pt;
          letter-spacing: -4.5pt;

          &--sub {
            font-size: 22pt;
            font-weight: 700;
            margin: 0 0 0 12pt;
            letter-spacing: -0.5pt;
          }
        }

        &__side {
          width: 439pt;
          margin: 0 0 0 16pt;
        }

        &__no {
          display: flex;

          align-items: center;
          justify-content: flex-end;

          &Label {
            font-size: 11pt;
            letter-spacing: -0.2pt;
          }

          &Value {
            font-size: 11pt;
            width: 227pt;
            margin: 0 10pt 0 7pt;
            padding: 0 0 0 1pt;
            border-bottom: 1pt solid #000;
          }
        }

        &__fax {
          display: flex;

          &Label {
            font-size: 11pt;
            font-weight: 700;
            margin: 6pt 0 0;
            text-decoration: underline;
            letter-spacing: -0.6pt;
          }

          &Nos {
            margin: 3pt 0 0 1pt;
          }

          &No {
            display: flex;

            align-items: center;

            &Label {
              font-size: 11pt;
            }

            &Body {
              font-size: 18pt;
              font-weight: 700;
              margin: 0 0 0 2pt;
              text-decoration: underline;
              letter-spacing: 3.1pt;
            }

            &Note {
              font-size: 11pt;
              margin: 0 0 0 2pt;
            }
          }
        }

        &__body {
          margin: 19pt 0 0;
        }

        &__table {
          line-height: 1.2;
          width: 750pt;
          margin: 4pt 0 0;
          border-spacing: 0;
          border-collapse: collapse;
          border: 1pt solid #000;
        }

        &__row {
          &:nth-child(1) {
            height: 38pt;
            border-bottom: 3pt double #000;
          }

          &:nth-child(2) {
            height: 40pt;
            border-bottom: 3pt double #000;
          }

          &:not(:last-child):nth-child(n+3) {
            height: 78pt;
          }

          &:last-child {
            height: 52pt;
            border-top: 3pt double #000;
          }
        }

        &__cell {
          min-height: auto;
          padding: 0 !important;
          border: 1pt solid #000 !important;

          &:nth-child(1) {
            width: 29pt;
          }

          &:nth-child(2) {
            width: 186pt;
          }

          &:nth-child(3) {
            width: 203pt;
          }

          &:nth-child(4) {
            width: 73pt;
          }

          &:nth-child(5) {
            width: 187pt;
          }

          &:nth-child(6) {
            width: 73pt;
          }
        }

        &__farmCode {
          display: flex;

          align-items: center;

          &Label {
            display: flex;
            flex-direction: column;
            width: 179pt;
            height: 36pt;
            text-align: center;
            letter-spacing: 1pt;
            border-right: 1pt solid #000;

            justify-content: center;
          }

          &Values {
            display: flex;
            width: 300pt;
            padding: 0 10pt;

            align-items: center;
            justify-content: space-between;
          }

          &Value {
            font-size: 18pt;
            display: flex;
            width: 25pt;
            height: 33pt;
            border: 1pt solid #000;

            align-items: center;
            justify-content: center;
          }
        }

        &__label {
          text-align: center;
        }

        &__fontSize11 {
          font-size: 11pt;
        }

        &__fontSize16 {
          font-size: 16pt;
        }

        &__letterSpacing3 {
          margin: 0 -3pt 0 0;
          letter-spacing: 3pt;
        }

        &__letterSpacing15 {
          margin: 0 -15pt 0 0;
          letter-spacing: 15pt;
        }

        &__values {
          &--uid {
            display: flex;
            width: 145pt;
            margin: auto;

            align-items: center;
            flex-wrap: wrap;
          }

          &--birthday {
            display: flex;
            width: 182pt;
            margin: auto;

            align-items: center;

            .certificateBirth {
              &__value {

                &:nth-child(2),
                &:nth-child(4) {
                  margin-right: 5pt;
                }
              }
            }
          }

          &--breed {
            display: flex;
            width: auto;
            margin: auto;

            align-items: center;
            justify-content: center;

            .certificateBirth {
              &__value {
                margin: 0;

                &:nth-child(1) {
                  border-right: 1pt dashed #000;
                }

                &:nth-child(2) {
                  margin-left: -1pt;
                  border-left: none;
                }
              }
            }
          }
        }

        &__value {
          font-size: 18pt;
          display: flex;
          width: 25pt;
          height: 33pt;
          margin: 2pt;
          border: 1pt solid #000;

          align-items: center;
          justify-content: center;
          flex-shrink: 0;

          &:not(:last-child):nth-child(n+6) {
            border: 2pt solid #000;
          }
        }

        &__date {
          line-height: 1;
          position: relative;
          display: flex;
          width: 182pt;
          margin: auto;

          align-items: center;

          &Label {
            font-size: 12pt;

            &:first-child {
              position: absolute;
              top: 0;
              left: 2pt;
            }

            &:not(:first-child) {
              width: 60pt;
              text-align: right;
            }

            &:nth-child(2) {
              padding: 0 6pt 0 0;
            }

            &:nth-child(3) {
              padding: 0 2pt 0 0;
            }
          }
        }

        &__genders {
          text-align: center;
        }

        &__gender {
          position: relative;

          &:not(:first-child) {
            margin: 12pt 0 0;
          }

          &Value {
            position: absolute;
            top: 0;
            left: 9pt;
            display: block;
            width: 17pt;
            height: 17pt;
            border: 1pt solid #000 !important;
            border-radius: 50%;
          }
        }

        &__contact {
          display: flex;

          &Label {
            display: flex;
            width: 162pt;
            height: 50pt;
            border-right: 1pt solid #000;

            flex-shrink: 0;
            align-items: center;
            justify-content: center;
          }

          &Body {
            display: flex;
            flex-direction: column;
            padding: 3pt 7pt;

            flex-grow: 1;
            justify-content: space-between;
          }

          &Value {
            display: flex;

            &Label {
              font-size: 14pt;
            }

            &Body {
              font-size: 23pt;
              margin: 0 0 0 10pt;
              letter-spacing: 6pt;
            }
          }

          &Note {
            font-size: 12pt;
            display: flex;

            justify-content: center;
          }
        }
      }
    }
  }
}