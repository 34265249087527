/*---------------------------------
　見出し
---------------------------------*/

/*
　@extend用のプレースホルダーセレクタ
---------------------------------*/
%uHeading__numberButton {
  display: flex;
  width: 32px;
  height: 32px;
  transition: background .2s linear;
  text-decoration: none !important;
  color: white(primary) !important;
  border-radius: 50%;

  align-items: center;
  justify-content: center;

  [class*=icon-] {
    font-size: 24px
  }

  .icon-l-arrow-A-l01 {
    margin-left: -.1em
  }

  .icon-l-arrow-A-r01 {
    margin-right: -.1em
  }

  +.uHeading {
    &__number {
      &Main {
        margin-left: 24px !important;
      }
    }
  }
}

%uHeading__message {
  font-size: 14px !important;
  font-weight: 700;
  margin: 0 0 0 10px !important;
  padding: 2px;
}

%uHeading__stopShipmentButton {
  display: flex;
  margin: 0 0 0 12px !important;
  padding: 4px 4px 4px 10px;
  transition: all .3s ease;
  border-radius: 4px;

  align-items: center;

  @media (min-width: 960px) {
    padding-right: 10px;
  }

  @media (min-width: 1280px) {
    padding-right: 4px;
  }

  [class*=icon-] {
    @media (min-width: 960px) {
      display: none;
    }

    @media (min-width: 1280px) {
      display: block;
    }
  }

  &:hover {
    box-shadow: 0 2px 5px rgba(0, 0, 0, .8);
  }
}

%uHeading__settingsButton {
  display: flex;
  width: 40px;
  height: 40px;
  margin: 0 12px !important;
  transition: all .1s linear;
  border: 2px solid #707070;
  border-radius: 50%;
  box-shadow: 0 1px 2px rgba(0, 0, 0, .16);

  align-items: center;
  justify-content: center;

  &:hover {
    border-color: #444;
    background: #444;
  }
}

%uHeading__settingsBoard {
  font-size: 15px;
  position: absolute;
  z-index: 4;
  top: calc(100% - 13px);
  right: 14px;
  width: 160px;
  transition: opacity .1s linear;
  color: black(primary);
  border-radius: 8px;
  background: white(primary);
  box-shadow: 0 3px 30px rgba(0, 0, 0, .5);
}

%uHeading__pagerLink {
  position: relative;
  width: 40px;
  height: 40px;
  transition: all .1s linear;
  border: 2px solid #707070;
  border-radius: 50%;
  box-shadow: 0 1px 2px rgba(0, 0, 0, .16);

  &::before {
    position: absolute;
    top: 50%;
    right: 50%;
    display: block;
    width: 11px;
    height: 11px;
    content: '';
    transform: translate(75%, -50%) rotate(-45deg);
    border-top: 2px solid rgba(255, 255, 255, .7);
    border-left: 2px solid rgba(255, 255, 255, .7);
  }

  &:hover {
    border-color: #444;
    background: #444;
  }

  &:last-child {
    &::before {
      transform: translate(25%, -50%) rotate(135deg);
    }
  }
}

/*
　見出し
---------------------------------*/
.uHeading {
  position: fixed;
  z-index: 20;
  top: 56px;
  left: 0;
  display: flex;
  width: 100%;
  height: 72px;
  color: white(primary);
  background: #1f1f1f;

  align-items: center;

  @media (pointer: coarse), (max-width: 959px) {
    height: 56px;
  }

  /*
  　見出し - タイトル
  ---------------------------------*/
  &__title {
    font-size: 17px !important;
    font-weight: 700;
    position: relative;
    display: flex;
    height: 100%;
    margin: 0 !important;
    padding: 0 24px;
    color: white(primary);

    align-items: center;
    flex-shrink: 0;
  }

  /*
  　見出し - 戻るボタン
  ---------------------------------*/
  &__back {
    font-size: 13px;
    position: relative;
    left: 0;
    display: flex;
    width: 117px;
    height: 40px;
    padding: 0 14px 0 16px;
    transition: all .2s linear;
    text-decoration: none !important;
    color: white(primary) !important;
    border-top: 1px solid #909090;
    border-right: 1px solid #909090;
    border-bottom: 1px solid #909090;
    border-radius: 0 44px 44px 0;

    align-items: center;
    justify-content: space-between;
    flex-shrink: 0;

    &:hover {
      left: -9px;
      border-top: 1px solid #505050;
      border-right: 1px solid #505050;
      border-bottom: 1px solid #505050;
      background: linear-gradient(to right, #393939, #4e4e4e);
      box-shadow: 0 3px 6px rgba(0, 0, 0, .16);
    }
  }

  /*
  　見出し - 牛番号
  ---------------------------------*/
  &__number {
    display: flex;
    padding: 0 24px;

    align-items: center;


    @media (min-width: 1024px) {
      padding-right: 40px;
    }

    @media (pointer: coarse) and (orientation:landscape),
    (max-width: 959px) {
      padding: 0 24px 0 16px;
    }

    +.uHeading {
      &__title {
        padding: 0 32px;

        &::before {
          position: absolute;
          top: calc(50% - 16px);
          left: 0;
          width: 1px;
          height: 32px;
          content: '';
          background: black(secondary);
        }
      }
    }

    &Button {
      border: 1px solid #4d4d4d;
      background: #444;
      box-shadow: 0 3px 6px rgba(0, 0, 0, .16);

      @extend %uHeading__numberButton;

      &:hover {
        background: #373737;
      }

      &--disabled {
        pointer-events: none;
        background: rgba(68, 68, 68, .32);

        @extend %uHeading__numberButton;

        [class*=icon-] {
          opacity: .12;
        }
      }
    }

    &Main {
      display: flex;
      flex-direction: column;
      min-width: 50px;

      align-items: center;
      justify-content: flex-end;

      +.uHeading {
        &__number {
          &Button {
            margin-left: 24px !important;

            &--disabled {
              margin-left: 24px !important;
            }
          }
        }
      }
    }

    &Heading {
      font-size: 12px !important;
      font-weight: 700;
    }

    &Body {
      font-size: 21px !important;
      font-weight: 700;

      &--cowNo {
        font-size: 21px !important;
        font-weight: 700;
        line-height: 1.2;
        display: -webkit-box;
        overflow: hidden;
        max-width: 8em;
        max-height: 2.4em;
        word-break: break-all;

        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;

        @media (max-width: 1024px) {
          font-size: 17px !important;
        }
      }
    }
  }

  /*
  　見出し - ページ
  ---------------------------------*/
  &__pages {
    padding: 0 48px 0 16px;

    &+.uHeading__actionbar_container {

      .uHeading__actionbar--count {
        padding-left: 32px;
        border-left: 1px solid #707070;
      }
    }

    &Items {
      display: flex;

      align-items: center;
    }

    &Item {
      font-size: 17px !important;
      position: relative;
      display: flex;
      color: white(secondary);

      align-items: center;

      &:not(:first-child) {
        margin: 0 0 0 50px !important;

        &::before {
          position: absolute;
          top: 13px;
          left: -33px;
          display: block;
          width: 16px;
          height: 1px;
          content: '';
          background: #707070;
        }
      }

      &--active {
        font-size: 17px !important;
        position: relative;
        display: flex;
        color: white(primary);

        align-items: center;

        &::after {
          font-weight: 700;
          position: absolute;
          z-index: -1;
          top: -3px;
          left: -14px;
          display: block;
          width: calc(100% + 28px);
          height: 32px;
          content: '';
          color: white(primary);
          border-radius: 8px;
          background: #3b3b3b;

          @media all and (-ms-high-contrast: none) {
            top: -2px;
          }
        }

        &:not(:first-child) {
          margin: 0 0 0 50px !important;

          &::before {
            position: absolute;
            top: 13px;
            left: -39px;
            display: block;
            width: 16px;
            height: 1px;
            content: '';
            background: #707070;
          }
        }

        &+.uHeading__pagesItem {
          &::before {
            left: -27px;
          }
        }
      }
    }
  }

  /*
  　見出し - 件数
  ---------------------------------*/
  &__count {
    display: flex;
    padding: 0 40px 0 16px;

    align-items: center;

    &Body {
      font-size: 17px !important;
      font-weight: 700;
    }

    &Append {
      font-size: 15px !important;
      font-weight: 700;
      line-height: 17px;
      margin: 0 0 0 5px !important;
    }
  }

  /*
  　見出し - フォーム（検索条件）
  ---------------------------------*/
  &__form {
    display: flex;

    flex-wrap: wrap;

    &Label {
      margin: 0 5px !important;

      align-self: center;
    }

    &Select {
      margin: 0 2px !important;
    }

    .edit-btn {
      margin: 0 0 0 10px;

      &:disabled {
        cursor: default;
        color: hsla(0, 0%, 100%, .47);
        border-color: #1f1f1f;
        background: #1f1f1f;
        box-shadow: none;
      }
    }
  }

  /*
  　見出し - メッセージ
  ---------------------------------*/
  &__message {
    color: white(primary);

    @extend %uHeading__message;

    &--error {
      color: #ff573f;

      @extend %uHeading__message;
    }
  }

  /*
  　見出し - 右寄せ
  ---------------------------------*/
  &__right,
  &__right--utility {
    display: flex;
    padding: 0 10px 0 0;

    flex-grow: 1;
    justify-content: flex-end;
  }

  /*
  　見出し - 出荷停止
  ---------------------------------*/
  &__stopShipment {
    position: absolute;
    top: 68px;
    left: 0;
    display: flex;
    width: 100%;
    height: 48px;
    padding: 0 12px !important;
    background: #1f1f1f;

    justify-content: flex-end;
    align-items: center;

    @media (min-width: 960px) {
      position: relative;
      top: 0;
    }

    &Button {
      &--primary {
        background: #9a03ad;

        @extend %uHeading__stopShipmentButton;

        &:hover {
          background: #bc17d1;
        }
      }

      &--secondary {
        background: #6606c6;

        @extend %uHeading__stopShipmentButton;

        &:hover {
          background: #7f22dd;
        }
      }

      &Heading {
        font-size: 14px !important;
        font-weight: 700;
        padding: 0 4px 0 0;

        @media (min-width: 960px) {
          display: none;
        }

        @media (min-width: 1280px) {
          display: block;
        }
      }

      &Body {
        font-size: 14px !important;
      }
    }
  }

  /*
  　見出し - ボタン（印刷、登録など）
  ---------------------------------*/
  &__button {
    display: flex;
    height: 36px;
    padding: 0 16px;
    white-space: nowrap;
    color: white(primary);
    border: 1px solid #4d4d4d;
    border-radius: 4px;
    background: #444;
    box-shadow: 0 3px 6px rgba(#000, .16);

    align-items: center;

    [class*="icon-"] {
      margin: 0 10px 0 -2px;
    }

    &:hover {
      background: darken(#444, 5%);
    }

    &:disabled {
      cursor: default;
      color: rgba(255, 255, 255, .47);
      border-color: #1f1f1f;
      background: #1f1f1f;
      box-shadow: none;

      &:hover {
        cursor: default;
        color: rgba(255, 255, 255, .47);
        border-color: #1f1f1f;
        background: #1f1f1f;
        box-shadow: none;
      }
    }

    &--secondary {
      display: flex;
      height: 40px;
      margin: 0 0 0 12px !important;
      padding: 0 15px;
      transition: all .1s linear;
      white-space: nowrap;
      color: white(primary);
      border: 2px solid #707070;
      border-radius: 20px;
      box-shadow: 0 1px 2px rgba(0, 0, 0, .16);

      align-items: center;

      [class*="icon-"] {
        margin: 0 12px 0 0;
      }

      &:hover {
        border-color: #444;
        background: #444;
      }

      &[disabled] {
        color: white(tertiary);
        border: 2px solid rgba(112, 112, 112, .34);

        &:hover {
          border: 2px solid rgba(112, 112, 112, .34);
          background: none;
        }
      }
    }

    &--tertiary {
      display: flex;
      height: 40px;
      margin: 0 0 0 16px !important;
      padding: 0 15px;
      transition: all .1s linear;
      white-space: nowrap;
      color: white(primary);
      border: 2px solid #707070;
      border-radius: 20px;
      box-shadow: 0 1px 2px rgba(0, 0, 0, .16);

      align-items: center;

      [class*="icon-"] {
        margin: 0 12px 0 0;
      }

      &:hover {
        border-color: #444;
        background: #444;
      }

      &[disabled] {
        color: white(tertiary);
        border: 2px solid rgba(112, 112, 112, .34);

        &:hover {
          border: 2px solid rgba(112, 112, 112, .34);
          background: none;
        }
      }
    }
  }

  /*
  　見出し - 設定
  ---------------------------------*/
  &__settings {
    display: flex;
    background-color: #1f1f1f;

    align-items: center;

    &Button {
      @extend %uHeading__settingsButton;

      &--open {
        border-color: black(secondary);
        background: black(secondary);

        @extend %uHeading__settingsButton;
      }

      &--overwriteOpen {
        border-color: black(secondary);
        background: black(secondary);

        @extend %uHeading__settingsButton;

        &:hover {
          border-color: black(secondary);
          background: black(secondary);
        }
      }
    }

    &Board {
      visibility: hidden;
      opacity: 0;

      @extend %uHeading__settingsBoard;

      &--open {
        visibility: visible;
        opacity: 1;

        @extend %uHeading__settingsBoard;
      }

      &Items {
        padding: 12px 0;
      }

      &Link {
        font-size: 15px;
        display: flex;
        padding: 13px 20px;
        transition: all .1s linear;
        text-decoration: none !important;
        color: black(primary) !important;

        &:hover {
          background: #f2f2f2;
        }
      }
    }

    @media (pointer: coarse),
    (max-width: 959px) {
      background-color: transparent;
    }

    .uHeading__pager+& {
      padding-left: 8px;
      border-left: 1px solid #707070;

      @media (pointer: coarse) and (orientation:portrait) {
        padding-left: 0;
        border-left: none;
      }
    }
  }

  /*
  　見出し - 基本情報
  ---------------------------------*/
  &__info {
    display: flex;

    &Item {
      display: flex;
      padding: 0 16px 0 0;

      align-items: center;
      flex: 0 0 auto;

      @media (min-width: 1024px) {
        padding-right: 24px;
      }

      @media (pointer: coarse),
      (max-width: 959px) {
        flex-direction: column;
        padding: 0 16px 0 0;

        align-items: flex-start;
        justify-content: center;
      }

      &Body {
        font-size: 17px !important;
        font-weight: 700;
        line-height: 1.2;
        white-space: nowrap;

        @media (max-width: 959px) {
          font-size: 15px !important;
        }

        &--cowGroupName {
          font-size: 17px !important;
          font-weight: 700;
          line-height: 1.2;
          display: -webkit-box;
          overflow: hidden;
          max-width: 6em;
          max-height: 2.6em;
          word-break: break-all;

          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;

          @media (min-width: 1280px) {
            max-width: 5em;
          }

          @media (min-width: 1400px) {
            max-width: 8em;
          }

          @media (pointer: coarse),
          (max-width: 959px) {
            font-size: 15px !important;
            max-width: 12em;
            max-height: none;
            white-space: normal;
            text-overflow: ellipsis;

            -webkit-line-clamp: 1;
          }
        }

        &--pen {
          font-size: 17px !important;
          font-weight: 700;
          line-height: 1.2;
          display: -webkit-box;
          overflow: hidden;
          max-width: 3em;
          max-height: 2.6em;
          word-break: break-all;

          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;

          @media (pointer: coarse),
            (max-width: 959px) {
            font-size: 15px !important;
            max-width: 6em;
            max-height: none;
            white-space: normal;
            text-overflow: ellipsis;

            -webkit-line-clamp: 1;
          }
        }

        &--cowUid {
          font-size: 17px !important;
          font-weight: 700;
          line-height: 1.2;

          @media (pointer: coarse) {
            line-height: 1.2;
          }
        }
      }

      &Heading {
        font-size: 13px !important;
        padding: 0 10px 0 0;
        white-space: nowrap;
        color: rgba(255, 255, 255, .67);

        @media (pointer: coarse),
          (max-width: 959px) {
          padding: 0;
        }
      }

      &Small {
        font-size: 15px !important;

        @media (max-width: 959px) {
          font-size: 14px !important;
        }
      }

      &Button {
        position: relative;
        display: flex;
        height: 36px;
        margin: 0 0 0 -12px !important;
        padding: 0 12px;
        transition: all .1s ease;
        color: inherit;
        border-radius: 18px;

        align-items: center;

        &:hover {
          text-decoration: none;
          color: inherit;
          background: #3b3b3b;
        }

        @media (max-width: 959px) {
          flex-direction: column;
          height: 48px;
          margin: 0 0 0 -8px !important;
          padding: 0 9px;
          border-radius: 12px;

          align-items: flex-start;
          justify-content: center;
        }

        @media (pointer: coarse) {
          flex-direction: column;
          margin: 0 !important;
          padding: 0 16px 0 0;
          pointer-events: none;

          align-items: flex-start;
        }

        .uHeading__infoItemBody {
          @media (max-width: 959px) {
            font-size: 16px !important;
          }
        }

        .uTooltip {
          &::before {
            font-size: 12px;
            top: calc(100% - 2px);
            transition: all .5s ease;
            background: #5f6469;
            box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);

            @media (max-width: 959px) {
              left: calc(50% + 12px);
              padding: 4px 10px;
            }
          }
        }
      }

      &--cowUid {
        display: flex;
        padding: 0 24px 0 0;

        align-items: center;
        flex-grow: 1;

        @media (pointer: coarse), (max-width: 959px) {
          flex-direction: column;
          padding: 0 16px 0 0;

          align-items: flex-start;
          justify-content: center;
        }
      }
    }
  }

  /*
  　見出し - ページャー
  ---------------------------------*/
  &__pager {
    display: flex;
    padding: 0 24px;
    text-align: center;

    align-items: center;

    @media (pointer: coarse) and (min-height: 700px) {
      display: none;
    }

    @media (max-width: 1279px),
    (pointer: coarse) and (max-height: 699px) {
      position: fixed;
      right: 24px;
      bottom: 24px;
      padding: 1px 0;
      color: #212121;
      border: 1px solid #fff;
      border-radius: 50vw;
      background: #f2f2f2;
      box-shadow: 0 3px 6px rgba(0, 0, 0, .32);
    }

    &Current,
    &Heading {
      font-size: 13px !important;
    }

    &Current {
      @media (max-width: 1279px), (pointer: coarse) and (max-height: 699px) {
        line-height: 1.2;
      }
    }

    &Heading {
      overflow: hidden;
      max-width: 10em;
      white-space: nowrap;
      text-overflow: ellipsis;

      @media (max-width: 1279px), (pointer: coarse) and (max-height: 699px) {
        font-weight: 700;
        width: 13.7em;
      }
    }

    &Link {
      @extend %uHeading__pagerLink;

      @media (max-width: 1279px), (pointer: coarse) and (max-height: 699px) {
        border: 1px solid rgba(#8d8d8d, .08);
      }

      &:hover {
        &::before {
          @media (max-width: 1279px), (pointer: coarse) and (max-height: 699px) {
            border-color: #fff;
          }
        }
      }

      &::before {
        @media (max-width: 1279px), (pointer: coarse) and (max-height: 699px) {
          border-color: #212121;
        }
      }

      &:last-child {
        &::before {
          @media (max-width: 1279px), (pointer: coarse) and (max-height: 699px) {
            transform: translate(25%, -50%) rotate(135deg);
          }
        }
      }

      &--disabled {
        pointer-events: none;
        opacity: .16;
        border-color: #707070;

        @extend %uHeading__pagerLink;

        @media (max-width: 1279px), (pointer: coarse) and (max-height: 699px) {
          border-color: rgba(141, 141, 141, .08);
          background: none;
        }

        &::before {
          @media (max-width: 1279px), (pointer: coarse) and (max-height: 699px) {
            border-color: #212121;
          }
        }
      }
    }

    &Main {
      padding: 0 16px;

      @media (max-width: 1279px), (pointer: coarse) and (max-height: 699px) {
        padding: 0;
      }
    }
  }

  /*
  　アクションバー
  ---------------------------------*/
  &__actionbar_container {
    position: relative;
    display: flex;
    width: 100%;

    align-items: center;
  }

  &__actionbar {
    position: absolute;
    z-index: 30;
    top: 0px;
    display: flex;
    overflow: hidden;
    width: 0;
    height: 100%;
    background: #1f1f1f;

    align-items: center;
    justify-content: center;

    &--count {
      display: flex;
      padding: 0 12px 0 16px;

      align-items: center;

      &Explanation {
        font-size: 14px !important;
        font-weight: 700;
        line-height: 17px;
        margin: 0 0 0 5px !important;
        white-space: nowrap;
      }
    }

    &--actions {
      display: flex;
      padding: 0 40px 0 0px;

      align-items: center;
      flex-grow: 1;
      justify-content: flex-start;
    }
  }

  &__actionbar.display {
    visibility: visible;
    width: 100%;
  }
}
