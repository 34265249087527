/*---------------------------------
　メニューリスト
---------------------------------*/
.uMenuList {
  width: 100%;
  height: auto;
  padding: 0 10px 10px;

  &__inner {
    max-width: 100vw;
    padding: 0 14px;
  }

  &__lead {
    font-size: 15px !important;
    margin: 0 0 30px !important;
    color: white(primary);
  }

  &__row {
    display: flex;
    margin: 0 -8px !important;

    flex-wrap: wrap;
  }

  &__col {
    width: 200px;
    margin: 0 8px 13px !important;
    padding: 20px 8px 40px;
    border-radius: 4px;
    background: linear-gradient(to right, #2f2f2f, #272727);
    box-shadow: 0 3px 6px rgba(0, 0, 0, .16);

    @media screen and (max-width: 959px) {
      width: calc(50% - 16px);
    }
  }

  &__category {
    font-size: 13px !important;
    margin: 0 0 22px !important;
    padding: 0 10px;
    color: rgba(255, 255, 255, .7);
  }

  &__item {
    margin: 0 0 1px !important;
  }

  &__link {
    font-size: 14px !important;
    display: block;
    width: 100%;
    padding: 6px 10px;
    text-decoration: none !important;
    color: white(primary) !important;
    border: 1px solid transparent;
    border-radius: 4px;

    &:hover {
      border: 1px solid rgba(137, 137, 137, .1);
      background: linear-gradient(to right, rgba(150, 150, 150, .4), rgba(114, 114, 114, .4));
      box-shadow: 0 1px 2px rgba(0, 0, 0, .16);
    }
  }
}