/*---------------------------------
　ヘッダー
---------------------------------*/

/*
　@extend用のプレースホルダーセレクタ
---------------------------------*/
%__search {
  position: relative;
  display: flex;
  width: 47px;
  height: 56px;
  padding: 0;
  background: white(primary);

  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

%__searchIdFormSelectItems {
  position: absolute;
  z-index: -1;
  top: 100%;
  width: 129px;
  padding: 12px 0;
  transition: opacity .2s linear;
  background: white(primary);
  box-shadow: 0 3px 30px rgba(0, 0, 0, .5);
}

%__searchIdButton {
  display: flex;
  width: 40px;
  height: 40px;
  cursor: pointer;
  transition: all .2s linear;
  border-radius: 50%;

  align-items: center;
  justify-content: center;

  .icon-m-search {
    color: black(primary);
  }

  @media (min-width: 1351px) {
    display: none;
  }
}

%__searchIdModal {
  position: absolute;
  z-index: -1;
  top: 56px;
  right: 0;
  flex-direction: column;
  width: 320px;
  transition: opacity .2s linear;
  background: white(primary);
  box-shadow: 0 3px 30px rgba(0, 0, 0, .5);
}

%__searchDetailButton {
  position: relative;
  display: flex;
  width: 42px;
  height: 40px;
  cursor: pointer;
  transition: all .2s linear;
  border-radius: 50%;

  align-items: center;
  justify-content: center;

  .icon-m-search {
    color: black(primary);
  }
}

%__notification {
  position: relative;
  display: flex;
  width: 58px;
  height: 56px;
  padding: 0;
  background: white(primary);

  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

%__notificationButton {
  display: flex;
  width: 40px;
  height: 40px;
  cursor: pointer;
  transition: all .2s linear;
  border-radius: 50%;

  align-items: center;
  justify-content: center;

  .icon-m-bell-outlined {
    color: black(primary);
  }
}

%__notificationBoard {
  position: absolute;
  z-index: -1;
  top: 100%;
  right: 0;
  width: 360px;
  padding: 0;
  transition: opacity .2s linear;
  background: white(primary);
  box-shadow: 0 3px 30px rgba(0, 0, 0, .5);
}

%__accountButton {
  display: flex;
  width: 40px;
  height: 40px;
  cursor: pointer;
  transition: all .2s linear;
  border-radius: 50%;

  align-items: center;
  justify-content: center;

  .icon-m-account-outlined {
    color: black(primary);
  }
}

%__accountBoard {
  position: absolute;
  z-index: -1;
  top: 100%;
  right: 8px;
  width: 232px;
  padding: 0;
  transition: opacity .2s linear;
  background: white(primary);
  box-shadow: 0 3px 30px rgba(0, 0, 0, .5);
}

/*
　ヘッダー
---------------------------------*/
.uHeader {
  position: fixed;
  z-index: 21;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  background: white(primary);

  /*
　　ヘッダー - ロゴ
  ---------------------------------*/
  &__logo {
    width: 56px;
    height: 56px;
    background-image: url('../images/header-logo.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 35px auto;

    flex-shrink: 0;

    &Sand {
      width: 56px;
      height: 56px;
      background-image: url('../images/header-logo-sand.png');
      background-repeat: no-repeat;
      background-position: center;
      background-size: 56px auto;

      flex-shrink: 0;
    }
  }

  /*
　　ヘッダー - 全カテゴリー
  ---------------------------------*/
  &__trigger {
    position: fixed;
    z-index: 22;
    top: 0;
    left: 56px;
    display: flex;
    width: 75px;
    height: 56px;
    transition: width .2s linear;
    text-decoration: none !important;
    background: linear-gradient(to right, #f2f2f2, #eaeaea);

    align-items: center;

    @media (min-width: 769px) {
      width: 160px;
    }

    @media (pointer: coarse) and (min-height: 700px) {
      z-index: 1002;
      top: auto;
      right: 0;
      bottom: env(safe-area-inset-bottom);
      left: auto;
      width: 56px;
      transition: none;
      border-left: 1px solid #d9d9d9;
      background: none;
    }

    &::after {
      position: absolute;
      top: 25px;
      right: 15px;
      display: block;
      width: 9px;
      height: 9px;
      content: '';
      pointer-events: none;
      border-top: 7px solid #0d52a2;
      border-right: 4px solid transparent;
      border-left: 4px solid transparent;

      @media (pointer: coarse) and (min-height: 700px) {
        display: none;
      }
    }

    &Bars {
      position: relative;
      width: 20px;
      height: 14px;
      margin: 0 0 0 16px !important;
      border-top: 2px solid black(primary);
      border-bottom: 2px solid black(primary);

      flex-shrink: 0;

      @media (pointer: coarse)and (min-height: 700px) {
        margin: 0 0 0 18px !important;
        border-top: 2px solid black(secondary);
        border-bottom: 2px solid black(secondary);
      }

      &::before {
        position: absolute;
        top: calc(50% - 1px);
        width: 100%;
        content: '';
        border-top: 2px solid black(primary);

        @media (pointer: coarse)and (min-height: 700px) {
          border-top: 2px solid black(secondary);
        }
      }
    }

    &Label {
      visibility: hidden;
      transition: opacity .2s linear .2s;
      opacity: 0;

      @media (min-width: 769px) {
        font-size: 13px !important;
        font-weight: 700;
        display: block;
        visibility: visible;
        margin: 0 0 0 12px !important;
        white-space: nowrap;
        opacity: 1;
        color: black(primary);
      }

      @media (pointer: coarse)and (min-height: 700px) {
        display: none;
      }
    }
  }

  /*
　　ヘッダー - メニュー
  ---------------------------------*/
  &__menu {
    margin: 0 0 0 75px !important;
    transition: margin .2s linear;
    background: white(primary);

    flex-grow: 1;

    @media (min-width: 769px) {
      margin: 0 0 0 160px !important;
    }

    &Items {
      display: flex;
      visibility: hidden;
      width: 0;
      height: 56px;
      margin: 0 12px !important;
      transition: width, opacity .2s linear;
      opacity: 0;

      align-items: center;

      @media (min-width: 901px) {
        visibility: visible;
        width: 100%;
        opacity: 1;
      }

      @media (pointer: coarse)and (min-height: 700px) {
        visibility: hidden;
        opacity: 0;
      }
    }

    &Item {
      margin: 0 4px !important;
    }

    &Link {
      font-size: 13px;
      display: flex;
      height: 33px;
      padding: 0 8px;
      transition: all .2s linear;
      white-space: nowrap;
      text-decoration: none !important;
      color: black(primary) !important;
      border-radius: 4px;
      background: white(primary);

      align-items: center;

      &:hover {
        background: linear-gradient(to right, white(primary), #f2f2f2);
        box-shadow: 0 3px 6px rgba(0, 0, 0, .24);
      }
    }
  }

  /*
　　ヘッダー - 検索ボックス
  ---------------------------------*/
  &__search {
    @extend %__search;

    @media (min-width: 1351px) {
      width: auto;
      background: linear-gradient(to right, #f2f2f2, #eaeaea);
    }

    @media (pointer: coarse) and (min-height: 700px) {
      display: none;
    }

    &--depositor {
      @extend %__search;

      @media (pointer: coarse) and (min-height: 700px) {
        display: none;
      }
    }

    &Id {
      &Form {
        display: none;

        @media (min-width: 1351px) {
          display: flex;
          height: 56px;

          align-items: center;
        }

        &Select {
          position: relative;
          display: flex;
          height: 100%;

          align-items: center;

          &::before {
            position: absolute;
            top: 25px;
            right: 13px;
            display: block;
            width: 9px;
            height: 9px;
            content: '';
            pointer-events: none;
            border-top: 7px solid #0d52a2;
            border-right: 4px solid transparent;
            border-left: 4px solid transparent;
          }

          &::after {
            position: absolute;
            top: 4px;
            right: 0;
            display: block;
            width: 1px;
            height: 48px;
            content: '';
            background: white(primary);
          }

          &Label {
            font-size: 14px !important;
            display: flex;
            width: 129px;
            height: 100%;
            padding: 4px 0 4px 13px;
            cursor: pointer;
            color: black(primary);

            align-items: center;
          }

          &Items {
            visibility: hidden;
            opacity: 0;

            @extend %__searchIdFormSelectItems;

            &--open {
              visibility: visible;
              opacity: 1;

              @extend %__searchIdFormSelectItems;
            }
          }

          &Item {
            font-size: 14px !important;
            display: flex;
            height: 50px;
            padding: 0 16px;
            cursor: pointer;
            transition: background .2s linear;
            color: black(primary);

            align-items: center;

            &:hover {
              background: #f2f2f2;
            }
          }
        }

        &Text {
          position: relative;
          display: flex;

          align-items: center;

          @media (min-width: 1351px) {
            width: 283px;
          }

          &::after {
            position: absolute;
            top: 4px;
            right: 0;
            display: block;
            width: 1px;
            height: 48px;
            content: '';
            background: white(primary);
          }

          &Body {
            font-size: 14px !important;
            font-weight: 700 !important;
            line-height: 56px !important;
            display: block !important;
            height: 56px !important;
            padding: 0 0 0 13px !important;
            transition: none !important;
            color: black(primary) !important;
            border: none !important;
            border-radius: 0 !important;
            outline: 0 !important;
            background: none !important;
            box-shadow: none !important;

            appearance: none;
            -moz-appearance: none;
            -webkit-appearance: none;

            @media (min-width: 1351px) {
              width: 236px;
            }

            &::placeholder {
              color: black(quaternary) !important;
            }

            &:-ms-input-placeholder {
              color: black(quaternary) !important;
            }

            &::-ms-input-placeholder {
              color: black(quaternary) !important;
            }

            &::-webkit-input-placeholder {
              color: black(quaternary) !important;
            }

            &::-ms-clear {
              display: none;
            }
          }

          .icon-s-search {
            width: 40px;
            height: 40px;
            margin: 0 7px 0 0;
            cursor: pointer;
            transition: all .2s linear;
            vertical-align: baseline;
            border-radius: 50%;

            flex-shrink: 0;

            &:hover {
              background: white(primary);
              box-shadow: 0 3px 6px rgba(0, 0, 0, .16);
            }
          }
        }
      }

      &Button {
        @extend %__searchIdButton;

        &:hover {
          background-color: #e7e7e7;
        }

        &--open {
          background-color: #e7e7e7;

          @extend %__searchIdButton;
        }
      }

      &Modal {
        visibility: hidden;
        opacity: 0;

        @extend %__searchIdModal;

        @media (min-width: 1351px) {
          display: none;
        }

        &--open {
          visibility: visible;
          opacity: 1;

          @extend %__searchIdModal;

          @media (min-width: 1351px) {
            display: none;
          }
        }

        &Item {
          display: flex;
          border-bottom: 1px solid #ddd;
        }

        &Label {
          font-weight: 700;
          display: flex;
          width: 80px;
          padding: 15px;
          background: #f2f2f2;

          flex-shrink: 0;
        }

        &Text {
          width: 220px;
          margin: 10px !important;
          padding: 6px !important;
          border: 0 !important;
          border-bottom: 1px solid #ddd !important;
          border-radius: 0 !important;
          outline: none;
          box-shadow: none !important;
        }

        &Options {
          display: flex;
          flex-direction: column;
          padding: 15px 10px;
        }

        &Option {
          position: relative;
          padding: 0 0 0 20px !important;
          cursor: pointer;

          &::before {
            position: absolute;
            top: 2px;
            left: 0;
            width: 15px;
            height: 15px;
            content: '';
            border: 1px solid #b5b5b5;
            border-radius: 50%;
          }

          &::after {
            position: absolute;
            top: 5px;
            left: 3px;
            width: 9px;
            height: 9px;
            content: "";
            border-radius: 50%;
            background: white(primary);
          }

          &:not(:last-child) {
            margin: 0 0 10px !important;
          }

          &Input {
            display: none;

            &:checked {
              &+.uHeader {
                &__search {
                  &Id {
                    &Modal {
                      &Option {
                        &::before {
                          border-color: #477d17;
                        }

                        &::after {
                          background: #477d17;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }

        &Foot {
          display: flex;
          padding: 10px;

          justify-content: space-between;

          .btn-default {
            color: #1d79b2;
            border: none;
          }

          .btn-primary {
            padding: 6px 20px;
            text-indent: .3em;
            letter-spacing: .3em;
            border: none;
            border-radius: 3px;
            background-color: #1d79b2;
            box-shadow: 0 1px 2.5px rgba(18, 18, 18, .4);

            &:hover {
              background: #196a9c;
            }
          }
        }
      }
    }

    &Detail {
      display: none;

      @media (min-width: 1351px) {
        display: flex;
        width: 48px;
        height: 56px;

        justify-content: center;
        align-items: center;
      }

      &Button {
        @extend %__searchDetailButton;

        &:hover {
          background: white(primary);
          box-shadow: 0 3px 6px rgba(0, 0, 0, .16);
        }

        &--depositor {
          @extend %__searchDetailButton;

          &:hover {
            background-color: #e7e7e7;
          }
        }
      }

      &Icon {
        &--window {
          position: relative;
          top: -2px;
          left: 2px;
          display: block;
          width: 10px;
          height: 10px;
          border: 2px solid #444;

          &::before {
            position: absolute;
            top: 1px;
            left: -5px;
            display: block;
            width: 10px;
            height: 10px;
            content: '';
            border-bottom: 2px solid #444;
            border-left: 2px solid #444;
          }
        }
      }
    }
  }

  /*
　　ヘッダー - お知らせ
  ---------------------------------*/
  &__notification {
    @extend %__notification;

    @media (min-width: 1351px) {
      width: 85px;
      padding: 0 9px 0 0;

      justify-content: flex-end;
    }

    &--depositor {
      @extend %__notification;
    }

    &Button {
      @extend %__notificationButton;

      &:hover {
        background-color: #e7e7e7;
      }

      &--open {
        background-color: #e7e7e7;

        @extend %__notificationButton;
      }
    }

    &Board {
      visibility: hidden;
      opacity: 0;

      @extend %__notificationBoard;

      &--open {
        visibility: visible;
        opacity: 1;

        @extend %__notificationBoard;
      }

      &Head {
        display: flex;
        overflow: hidden;
        height: 64px;
        background: #444;

        justify-content: space-between;
        align-items: center;
      }

      &Heading {
        font-size: 15px !important;
        font-weight: 700 !important;
        margin: 0 !important;
        padding: 0 24px !important;
        text-align: left !important;
        color: white(primary);
      }

      &Close {
        padding: 12px;
        cursor: pointer;

        .icon-css-cancel {
          position: relative;
          display: block;
          width: 20px;
          height: 20px;

          &::before {
            position: absolute;
            top: 8px;
            left: 3px;
            display: block;
            width: 14px;
            height: 2px;
            content: '';
            transform: rotate(45deg);
            background-color: white(primary);
          }

          &::after {
            position: absolute;
            top: 8px;
            left: 3px;
            display: block;
            width: 14px;
            height: 2px;
            content: '';
            transform: rotate(-45deg);
            background-color: white(primary);
          }
        }
      }

      &Items {
        line-height: 1.2em;
        overflow: auto;
        max-height: 50vh;
        background-color: white;
      }

      &Item {
        padding: 24px 16px;
        border-bottom: 1px solid #ddd;

        &:last-child {
          border-bottom: none;
        }

        &Head {
          display: flex;

          justify-content: space-between;
        }

        &Category {
          padding: 5px 8px;

          &--maintenance {
            color: notificationCategory(maintenance);
            background-color: notificationCategory(background);
          }

          &--update {
            color: notificationCategory(update);
            background-color: notificationCategory(background);
          }

          &--incident {
            color: notificationCategory(incident);
            background-color: #f2f2f2;
          }

          &--information {
            color: notificationCategory(information);
            background-color: notificationCategory(background);
          }
        }

        &Date {
          color: #999;
        }

        &Heading {
          font-weight: 700;
          margin: 15px 0 0 !important;
          color: #212121;
        }

        &Body {
          margin: 10px 0 0 !important;
        }
      }
    }
  }

  /*
　　ヘッダー - アカウント
  ---------------------------------*/
  &__account {
    position: relative;
    display: flex;
    width: 56px;
    height: 56px;
    background: white(primary);

    align-items: center;
    flex-shrink: 0;

    &Button {
      display: flex;
      width: 40px;
      height: 40px;
      cursor: pointer;
      transition: all .2s linear;
      border-radius: 50%;

      align-items: center;
      justify-content: center;

      &:hover {
        background-color: #e7e7e7;
      }

      .icon-m-account-outlined {
        color: black(primary);
      }
    }

    &Button {
      @extend %__accountButton;

      &:hover {
        background-color: #e7e7e7;
      }

      &--open {
        background-color: #e7e7e7;

        @extend %__accountButton;
      }
    }

    &Board {
      visibility: hidden;
      opacity: 0;

      @extend %__accountBoard;

      &--open {
        visibility: visible;
        opacity: 1;

        @extend %__accountBoard;
      }

      &Head {
        padding: 24px 16px 20px;
        border-bottom: 1px solid #ccc;
      }

      &Heading {
        font-size: 15px !important;
        font-weight: 400 !important;
        padding: 0 !important;
        text-align: left !important;

        &Address {
          font-size: 15px;
          display: block;
          overflow: hidden;
          margin: 4px 0 0 !important;
          white-space: nowrap;
          text-overflow: ellipsis;
          color: rgba(33, 33, 33, .62);
        }
      }

      &Items {
        padding: 12px 0;

        &--changeUiVersion {
          height: 56px;
          background: #f0f0f0;
        }
      }

      &Item {
        &--changeUiVersion {
          display: flex;
          height: 56px;

          align-items: center;
          justify-content: center;
        }
      }

      &Link {
        font-size: 15px;
        display: flex;
        padding: 13px 16px;
        transition: background .3s ease;
        text-decoration: none !important;
        color: black(primary) !important;

        &:hover {
          background-color: #f2f2f2;
        }

        &--changeUiVersion {
          font-size: 14px;
          display: flex;
          width: 202px;
          height: 36px;
          color: #0f6d9f;
          border: 1px solid #0f6d9f;
          border-radius: 18px;
          background: white(primary);

          align-items: center;
          justify-content: center;

          &:hover {
            font-weight: 700;
            text-decoration: none;
            background: #f0faff;
          }
        }
      }
    }
  }
}