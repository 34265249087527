/*---------------------------------
　並び替えスイッチ
---------------------------------*/

/*
　@extend用のプレースホルダーセレクタ
---------------------------------*/
%uSortOrderSwitcher {
  display: flex;
  flex-direction: column;
  width: 48px;
  height: 208px;
  padding: 4px 0;
  border: 1px solid #eee;
  border-radius: 100px;

  align-items: center;
  justify-content: space-between;
}

%__button {
  position: relative;
  display: block;
  width: 40px;
  height: 40px;
  cursor: pointer;
  border-radius: 50%;
  background: white(primary);
  box-shadow: 0px 1px 2px rgba(0, 0, 0, .16);
  transition: all .2s ease;

  &:hover {
    box-shadow: 0px 3px 6px rgba(0, 0, 0, .16);

    @media (pointer: coarse) {
      box-shadow: 0px 1px 2px rgba(0, 0, 0, .16);
    }
  }
}

%__button--disabled {
  position: relative;
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #f2f2f2;
}

%__buttonFirst {
  &::before {
    position: absolute;
    top: 14px;
    left: calc(50% - 6px);
    width: 12px;
    height: 2px;
    content: '';
    pointer-events: none;
  }

  &::after {
    position: absolute;
    top: 16px;
    left: calc(50% - 6px);
    width: 12px;
    height: 12px;
    content: '';
    pointer-events: none;
    border-right: 6px solid transparent;
    border-left: 6px solid transparent;
  }
}

%__buttonUp {
  &::before {
    position: absolute;
    top: calc(50% - 7px);
    left: calc(50% - 6px);
    width: 12px;
    height: 12px;
    content: '';
    pointer-events: none;
    border-right: 6px solid transparent;
    border-left: 6px solid transparent;
  }
}

%__buttonDown {
  &::before {
    position: absolute;
    top: calc(50% - 4px);
    left: calc(50% - 6px);
    width: 12px;
    height: 12px;
    content: '';
    pointer-events: none;
    border-right: 6px solid transparent;
    border-left: 6px solid transparent;
  }
}

%__buttonLast {
  &::before {
    position: absolute;
    top: 12px;
    left: calc(50% - 6px);
    width: 12px;
    height: 12px;
    content: '';
    pointer-events: none;
    border-right: 6px solid transparent;
    border-left: 6px solid transparent;
  }

  &::after {
    position: absolute;
    bottom: 14px;
    left: calc(50% - 6px);
    width: 12px;
    height: 2px;
    content: '';
    pointer-events: none;
  }
}

/*
　並び替えスイッチ
---------------------------------*/
.uSortOrderSwitcher {
  background: #f2f2f2;

  @extend %uSortOrderSwitcher;

  /*
  　並び替えスイッチ - 無効
  ---------------------------------*/
  &--disabled {
    background: white(primary);

    @extend %uSortOrderSwitcher;
  }

  /*
  　並び替えスイッチ - ボタン
  ---------------------------------*/
  &__button {

    /*
    　並び替えスイッチ - ボタン - 最初へ
    ---------------------------------*/
    &First {
      @extend %__button;
      @extend %__buttonFirst;

      &::before {
        background: #000;
      }

      &::after {
        border-bottom: 10px solid #000;
      }

      &--disabled {
        @extend %__button--disabled;
        @extend %__buttonFirst;

        &::before {
          background: #ddd;
        }

        &::after {
          border-bottom: 10px solid #ddd;
        }
      }
    }

    /*
    　並び替えスイッチ - ボタン - 上へ
    ---------------------------------*/
    &Up {
      @extend %__button;
      @extend %__buttonUp;

      &::before {
        border-bottom: 10px solid #000;
      }

      &--disabled {
        @extend %__button--disabled;
        @extend %__buttonUp;

        &::before {
          border-bottom: 10px solid #ddd;
        }
      }
    }

    /*
    　並び替えスイッチ - ボタン - 下へ
    ---------------------------------*/
    &Down {
      margin: 14px 0 0;

      @extend %__button;
      @extend %__buttonDown;

      &::before {
        border-top: 10px solid #000;
      }

      &--disabled {
        margin: 14px 0 0;

        @extend %__button--disabled;
        @extend %__buttonDown;

        &::before {
          border-top: 10px solid #ddd;
        }
      }
    }

    /*
    　並び替えスイッチ - ボタン - 最後へ
    ---------------------------------*/
    &Last {
      @extend %__button;
      @extend %__buttonLast;

      &::before {
        border-top: 10px solid #000;
      }

      &::after {
        background: #000;
      }

      &--disabled {
        @extend %__button--disabled;
        @extend %__buttonLast;

        &::before {
          border-top: 10px solid #ddd;
        }

        &::after {
          background: #ddd;
        }
      }
    }
  }
}