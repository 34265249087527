/**
 *  Chart components
 *  $1. Default styles
 */

/**
  $1. Default styles
 */

.timeline-chart {
  display: block;
  overflow: hidden;

  // 高さ修正する時はtimeline-chart-directive.jsのheight値も修正するように
  // FOUC回避のためサイズ指定
  height: 250px;

  .c3-tooltip {
    width: 200px;
  }
}

.region0 {
  fill: #868A08;
}
.region1 {
  fill: #BDBDBD;
}
.region2 {
  fill: #8A4B08;
}

.chart-header {
  display: flex;
  justify-content: space-between;
  padding-bottom: 1em;
  .chart-header-item {
    flex-grow: 1;
    flex-basis: 0;
    text-align: center;
    .btn-next, .btn-back {
      color: #ff9908;
      font-size: 18px;
    }
    input {
      font-size: 15px;
      font-weight: 700;
    }
  }
  .chart-nav {
    text-align: right;
  }

  // 旧アイコンフォント調整
  .icon-l-arrow-A-l01,
  .icon-l-arrow-A-r01 {
    width: 14px;
    height: 14px;
    font-size: 14px !important;
    display: inline-block;
    color: #fff!important;
    border-radius: 50%;
    background-color: #ff9908;
    &.disabled {
      background-color: #ccc!important;
    }
  }
}

.c3-regions .dysstasia-gte-25 {
  fill: #ff0000;
}

// 分娩兆候指数チャート
.c3-regions .calving-high {
  fill: #bc0822;
}

.c3-regions .calving-low {
  fill: #ff9e08;
}

// チャート共通
.chart-grid-line-red line {
  stroke: red;
}

.chart-grid-line-orange line {
  stroke: orange;
}

.chart-grid-line-gray line {
  stroke: gray;
}
