/*---------------------------------
　ボード
---------------------------------*/
.uBoard {
  position: relative;
  overflow: hidden;
  min-height: calc(100vh - 56px);
  padding: 20px 0 0;

  @media (pointer: coarse), (max-width: 1023px) {
    padding: 20px 0 170px;
  }
}