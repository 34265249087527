/*---------------------------------
　 フォーム
---------------------------------*/

/*
　キーフレームの定義
---------------------------------*/
@keyframes animationOpacity {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

/*
　@extend用のプレースホルダーセレクタ
---------------------------------*/
%uForm__Label {
  font-size: 14px !important;
  font-weight: 700;
  line-height: 1.5;
  position: relative;
  display: flex;
  width: 152px;
  padding: 42px 24px 0 24px;
  color: black(secondary);
  background: white(primary);

  flex-shrink: 0;
}

%uForm__FieldMessage {
  font-size: 13px;
  font-weight: 700;
  position: absolute;
  z-index: 1;
  bottom: -18px;
  left: 0;
  display: block;
  height: 18px;
  padding: 0 4px;
  transform: translate3d(0, -1px, 0);
  animation: animationOpacity .5s ease forwards;
  white-space: nowrap;
  opacity: 0;
  color: white(primary);

  justify-content: center;
  align-items: center;
}

%uForm__Text {
  position: relative;

  &:not(:first-child) {
    margin: 0 0 0 8px;
  }

  &:first-child {
    margin: 0;
  }
}

%uForm__TextPrepend {
  font-size: 14px;
  font-weight: 400;
  position: absolute;
  top: 0;
  display: flex;
  width: 40px;
  height: 40px;
  pointer-events: none;
  color: black(tertiary);

  justify-content: center;
  align-items: center;
}

%uForm__select {
  position: relative;

  &:not(:first-child) {
    margin: 0 0 0 8px;
  }

  &:first-child {
    margin: 0;
  }

  &::after {
    position: absolute;
    top: calc(50% - 5px);
    right: 14px;
    display: block;
    width: 9px;
    height: 9px;
    content: '';
    pointer-events: none;
    border-top: 7px solid #0d52a2;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
  }
}

%uForm__FieldFocus {
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  transition: all .2s ease;
  pointer-events: none;
  border-bottom: 2px solid #2a66b7;
}

/*
　フォーム
---------------------------------*/
.uForm {
  &:not(:first-child) {
    margin: 24px 0 0 !important;
  }

  /*
    　フォーム - items
    ---------------------------------*/
  &__items {
    overflow: hidden;
    border-radius: 8px;
    background: #eee;
  }

  /*
    　フォーム - item
    ---------------------------------*/
  &__item {
    display: flex;

    &:last-child {
      .uForm {
        &__label {
          padding-bottom: 47px;

          &--required {
            padding-bottom: 47px;
          }
        }

        &__group {
          padding-bottom: 47px;

          &--number {
            padding-bottom: 47px;
          }
        }

        &__field {
          padding-bottom: 47px;
        }
      }
    }
  }

  /*
    　フォーム - ラベル
    ---------------------------------*/
  &__label {
    @extend %uForm__Label;

    &--required {
      padding-right: 56px !important;

      @extend %uForm__Label;

      &::after {
        font-size: 12px;
        font-weight: 700;
        position: absolute;
        top: 43px;
        right: 16px;
        display: flex;
        width: 30px;
        height: 16px;
        content: '必須';
        color: #b70000;
        border: 1px solid #b70000;

        justify-content: center;
        align-items: center;
      }
    }
  }

  /*
    　フォーム - グループ
    ---------------------------------*/
  &__group {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 100%;

    .uForm {
      &__field {
        padding: 32px 24px 0 24px !important;

        &:not(:first-child) {
          padding-top: 24px !important;
        }
      }
    }
  }

  /*
    　フォーム - フィールド
    ---------------------------------*/
  &__field {
    position: relative;
    display: flex;
    padding: 32px 24px 0 24px;

    align-items: center;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 100%;

    &--stick {
      position: relative;
      display: flex;
      margin: -1px 0 0;
      padding: 0 24px 0 24px;
      border-top: 1px solid #eee;

      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: 100%;
    }

    &--radio {
      position: relative;
      display: flex;
      padding: 9px 24px 0 16px;

      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: 100%;
    }

    &Focus {
      @extend %uModal__formFieldFocus;
    }

    &Text {
      font-size: 14px !important;
      margin: 10px 0 0 !important;
      color: black(secondary);
    }
  }

  /*
    　フォーム - 横並び
    ---------------------------------*/
  &__directionColumns {
    display: flex;
    padding: 0 16px;
    border-radius: 16px;
    background: #3b3b3b;

    justify-content: space-between;
  }

  /*
    　フォーム - directionColumn
    ---------------------------------*/
  &__directionColumn {
    position: relative;
    padding: 20px 0 16px;

    &Label {
      font-size: 14px !important;
      position: absolute;
      top: -9px;
      left: 5px;
      color: white(primary);
    }

    [class^="uForm__select"] {
      margin: 0 !important;
    }

    &Arrow {
      position: relative;
      top: calc(50% - 4px);
      width: 32px;
      height: 10px;

      &Body {
        display: block;
        width: 10px;
        height: 10px;
        margin: auto !important;
        content: '';
        pointer-events: none;
        border-top: 5px solid transparent;
        border-bottom: 5px solid transparent;
        border-left: 8px solid white(primary);

        &::before {
          position: absolute;
          top: 0;
          left: 0;
          display: block;
          width: 10px;
          height: 10px;
          content: '';
          pointer-events: none;
          border-top: 5px solid transparent;
          border-bottom: 5px solid transparent;
          border-left: 8px solid white(primary);
        }

        &::after {
          position: absolute;
          top: 0;
          right: 0;
          display: block;
          width: 10px;
          height: 10px;
          content: '';
          pointer-events: none;
          border-top: 5px solid transparent;
          border-bottom: 5px solid transparent;
          border-left: 8px solid white(primary);
        }
      }
    }
  }

  /*
    　フォーム - テキストボックス
    ---------------------------------*/
  &__text {
    @extend %uForm__Text;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 100%;

    &--width94 {
      width: 94px;

      @extend %uForm__Text;
    }

    &--width128 {
      width: 128px;

      @extend %uForm__Text;
    }

    &--width196 {
      width: 196px;

      @extend %uForm__Text;
    }

    &--width264 {
      width: 264px;

      @extend %uForm__Text;
    }

    &--width298 {
      width: 298px;

      @extend %uForm__Text;
    }

    &Body {
      font-size: 15px !important;
      font-weight: 400 !important;
      line-height: 40px !important;
      display: block !important;
      width: 100%;
      height: 40px !important;
      padding: 0 8px !important;
      transition: border, background .2s ease !important;
      color: black(primary) !important;
      border: none !important;
      border-bottom: 1px solid #ccc !important;
      border-radius: 4px 4px 0 0 !important;
      outline: 0 !important;
      background: white(primary) !important;
      box-shadow: none !important;

      appearance: none;
      -moz-appearance: none;
      -webkit-appearance: none;

      &:disabled {
        pointer-events: none;
        opacity: 1;
      }

      &::placeholder {
        color: black(quaternary) !important;
      }

      &:-ms-input-placeholder {
        color: black(quaternary) !important;
      }

      &::-ms-input-placeholder {
        color: black(quaternary) !important;
      }

      &::-webkit-input-placeholder {
        color: black(quaternary) !important;
      }

      &::-ms-clear {
        display: none;
      }

      &:focus {
        &+.uForm {

          &__field {
            &Focus {
              width: 100%;
            }
          }
        }
      }

      &.is-error {
        border-bottom: 1px solid red(primary) !important;
        background: #faecee !important;

        &:focus {
          &+.uForm {
            &__field {
              &Focus {
                width: 0;
              }
            }
          }
        }
      }

      &.is-warning {
        border-bottom: 1px solid #ff9908 !important;
        background: #fcf6ed !important;

        &:focus {
          &+.uForm {
            &__field {
              &Focus {
                width: 0;
              }
            }
          }
        }
      }

      &--disabled {
        font-size: 15px !important;
        font-weight: 400 !important;
        line-height: 40px !important;
        display: block !important;
        width: 100%;
        height: 40px !important;
        padding: 0 8px !important;
        transition: border, background .2s ease !important;
        pointer-events: none;
        color: black(primary) !important;
        border: none !important;
        border-bottom: 1px solid rgba(204, 204, 204, 0.32) !important;
        border-radius: 4px 4px 0 0 !important;
        outline: 0 !important;
        background: #e7e7e7 !important;
        box-shadow: none !important;

        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;

        &::placeholder {
          color: black(quaternary) !important;
        }

        &:-ms-input-placeholder {
          color: black(quaternary) !important;
        }

        &::-ms-input-placeholder {
          color: black(quaternary) !important;
        }

        &::-webkit-input-placeholder {
          color: black(quaternary) !important;
        }

        &::-ms-clear {
          display: none;
        }
      }
    }
  }

  /*
    　フォーム - セレクトボックス
    ---------------------------------*/
  &__select {
    @extend %uForm__select;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 100%;

    &--width94 {
      width: 94px;

      @extend %uForm__select;
    }

    &--width128 {
      width: 128px;

      @extend %uForm__select;
    }

    &--width196 {
      width: 196px;

      @extend %uForm__select;
    }

    &--width240 {
      width: 240px;

      @extend %uForm__select;
    }

    &--width264 {
      width: 264px;

      @extend %uForm__select;
    }

    &--width298 {
      width: 298px;

      @extend %uForm__select;
    }

    &Body {
      font-size: 15px !important;
      font-weight: 400 !important;
      line-height: 40px !important;
      width: 100%;
      height: 40px !important;
      padding: 0 40px 0 8px !important;
      transition: all .2s ease !important;
      color: black(primary) !important;
      border: none !important;
      border-bottom: 1px solid #ccc !important;
      border-radius: 4px 4px 0 0 !important;
      outline: 0 !important;
      background: white(primary) !important;
      box-shadow: none !important;

      appearance: none;
      -moz-appearance: none;
      -webkit-appearance: none;

      &::-ms-expand {
        display: none;
      }

      &:focus {
        &+.uForm {
          &__field {
            &Focus {
              width: 100%;
            }
          }
        }
      }

      &.is-error {
        border-bottom: 1px solid red(primary) !important;
        background: #faecee !important;

        &:focus {
          &+.uForm {
            &__field {
              &Focus {
                width: 0;
              }
            }
          }
        }
      }

      &.is-warning {
        border-bottom: 1px solid #ff9908 !important;
        background: #fcf6ed !important;

        &:focus {
          &+.uForm {
            &__field {
              &Focus {
                width: 0;
              }
            }
          }
        }
      }

      &--disabled {
        font-size: 15px !important;
        font-weight: 400 !important;
        line-height: 40px !important;
        width: 100%;
        height: 40px !important;
        padding: 0 40px 0 8px !important;
        transition: all .2s ease !important;
        pointer-events: none;
        color: black(primary) !important;
        border: none !important;
        border-bottom: 1px solid rgba(204, 204, 204, 0.32) !important;
        border-radius: 4px 4px 0 0 !important;
        outline: 0 !important;
        background: #e7e7e7 !important;
        box-shadow: none !important;

        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;

        &::-ms-expand {
          display: none;
        }
      }

      &--secondary {
        font-size: 17px !important;
        font-weight: 400 !important;
        line-height: 48px !important;
        width: 100%;
        height: 48px !important;
        padding: 0 40px 0 10px !important;
        transition: all .2s ease !important;
        color: black(primary) !important;
        border: none !important;
        border-bottom: 1px solid #ccc !important;
        border-radius: 4px !important;
        outline: 0 !important;
        background: white(primary) !important;
        box-shadow: none !important;

        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;

        &::-ms-expand {
          display: none;
        }

        &:focus {
          box-shadow: 0px 0px 12px #3d95fc !important;
        }
      }
    }
  }

  /*
    　フォーム - ラジオボタン
    ---------------------------------*/
  &__radio {
    position: relative;
    display: flex;

    flex-wrap: wrap;

    &:not(:first-child) {
      margin: 0 0 0 8px;
    }

    &:first-child {
      margin: 0;
    }

    &Label {
      display: flex !important;
      height: 40px !important;
      margin: 0 31px 0 0 !important;
      cursor: pointer;

      justify-content: flex-start;
      align-items: center;

      &:hover {
        .uForm {
          &__radio {
            &Button {
              background: rgba(229, 229, 229, .72);

              @media (pointer: coarse) {
                background: none;
              }

              &::before {
                background: rgba(242, 242, 242, .32);

                @media (pointer: coarse) {
                  background: #f2f2f2;
                }
              }
            }

            &Body {
              &:checked {
                +.uForm {
                  &__form {
                    &__radio {
                      &Button {
                        background: rgba(222, 230, 237, .72);

                        @media (pointer: coarse) {
                          background: none;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      &--disabled {
        display: flex !important;
        height: 40px !important;
        margin: 0 31px 0 0 !important;
        cursor: pointer;
        pointer-events: none;

        justify-content: flex-start;
        align-items: center;

        .uForm__radio {
          &Body {
            &:checked {
              &+.uForm {
                &__form {
                  &__radio {
                    &Button {
                      &::before {
                        border-color: #ccc;
                      }

                      &::after {
                        background: #ccc;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    &Body {
      display: none;

      &:checked {
        &+.uForm {

          &__radio {
            &Button {
              &::before {
                border: 1px solid #2a66b7;
                background: rgba(255, 255, 255, .5);
              }

              &::after {
                opacity: 1;
              }
            }
          }
        }
      }
    }

    &Button {
      position: relative;
      display: block;
      width: 36px;
      height: 36px;
      transition: background .2s ease;
      border-radius: 50%;
      background: none;

      &::before {
        position: absolute;
        top: calc(50% - 10px);
        left: calc(50% - 10px);
        display: block;
        width: 20px;
        height: 20px;
        content: '';
        transition: background .2s ease, border .2s ease;
        border: 1px solid #ccc;
        border-radius: 50%;
        background: #f2f2f2;
      }

      &::after {
        position: absolute;
        top: calc(50% - 5px);
        left: calc(50% - 5px);
        display: block;
        width: 10px;
        height: 10px;
        content: '';
        transition: opacity .2s ease;
        opacity: 0;
        border-radius: 50%;
        background: #2a66b7;
      }
    }

    &Text {
      font-size: 14px !important;
      font-weight: 400;
      margin: 0 0 0 5px;
      color: black(secondary);
    }
  }

  /*
  　フッター
  ---------------------------------*/
  &__foot {
    position: relative;
    display: flex;
    flex-direction: row-reverse;
    height: 96px;
    padding: 24px 16px 32px;

    align-items: center;
  }
}