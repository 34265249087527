.report-summary-container {
  margin-bottom: 10px;
  background-color: #606060;

  .report-summary-title {
    color: #fff;
    margin: 0 15px;
    text-align: left;
  }
  .report-summary-content {
    padding: 0px;
    margin: 0px;
    .unsettled {
      color: #999;
    }
    .note {
      text-align: left;
      color: #fff;
      padding: 10px 20px;
      background-color: #606060;
    }
    .data-nothing-box {
      padding: 10px;
      .data-nothing-font {
        color: #fff;
        font-weight: 700;
      }
    }
    .report-summary-box-wrap {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin: 0px 5px;
    }
  }
}
