/**
 *  Form components
 *  standard styles for
 *   - forms
 *   - inputs
 *   - labels
 *  $1. Default styles
 *  $2. custom widths
 *  $3. modifications
 */

/**
 *  $1. Default styles
 */
.input { // to replace .barn input[type="text"]
  border: 1px solid $lighterGreyOld;
  border-radius: 3px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  color: $greyOld;
  line-height: 18px;
  padding: 0.25em !important;
  width: 100%;
}

// 数字ステッパーを隠す
.input[type="number"]::-webkit-inner-spin-button,
.input[type="number"]::-webkit-outer-spin-button {
  /* display: none;  <- will crash browser. DO NOT USE!!! */
  -webkit-appearance: none;
  margin: 0;
}
// 数字ステッパーを隠す
.input[type="number"] {
  -moz-appearance: textfield;
}

.select {
  width: 100%;
}

.checkbox {
  cursor: pointer;
  display: inline-block;
  margin: 0 !important;
}

// when <label><input[checkbox /></label>
.checkbox--label {
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  min-height: 30px;
}

/**
 *  $2. custom widths
 */
.input-width2 {
  width: 2em;
}

.u-input-width5 {
  width: 5em;
}

/**
 *  $3. modifications
 */

.select.error,
.input.error {
  border: 1px solid rgba(255, 0, 0, 0.5) !important;
  box-shadow: 0 0 3px rgba(255, 0, 0, 0.5) !important;
}

// TODO
.label {}
.form {}
.inputRadio {}
.inputCheckbox {}
