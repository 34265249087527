/*---------------------------------
　ラベル
---------------------------------*/

/*
　@extend用のプレースホルダーセレクタ
---------------------------------*/
%uLabel--timelineCategory {
  font-size: 13px !important;
  line-height: 1;
  display: flex;
  height: 24px;
  padding: 0 5px;
  white-space: nowrap;
  white-space: nowrap;
  color: white(primary);
  border-radius: 4px;

  align-items: center;
}

/*
　ラベル
---------------------------------*/
.uLabel {
  &--timelineCategory {
    &01 {
      border: 1px solid #b11970;
      background: #b11970;

      @extend %uLabel--timelineCategory;
    }

    &02 {
      border: 1px solid #a800a2;
      background: #ab19b1;

      @extend %uLabel--timelineCategory;
    }

    &03 {
      border: 1px solid #522df0;
      background: #4c27e8;

      @extend %uLabel--timelineCategory;
    }

    &04 {
      border: 1px solid #8e7d4d;
      background: #8d7126;

      @extend %uLabel--timelineCategory;
    }

    &05 {
      border: 1px solid #0062b4;
      background: #0062b4;

      @extend %uLabel--timelineCategory;
    }

    &06 {
      border: 1px solid #6b4577;
      background: #6b4577;

      @extend %uLabel--timelineCategory;
    }
  }


}