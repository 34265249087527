%uGrid--reproduction__bodyItem {
  position: relative;
  display: flex;
  width: 142px;

  align-items: center;
  justify-content: center;

  &:last-child {
    width: 115px;

    justify-content: flex-end;
  }
}

/*
　グリッド
---------------------------------*/
.uGrid {

  /*
  　グリッド - 繁殖
  ---------------------------------*/
  &--reproduction {
    position: relative;
    overflow: hidden;

    @media (max-width: 1324px), (pointer: coarse) {
      margin: 0 -24px 0 0 !important;
    }

    &::after {
      position: absolute;
      top: 0;
      right: 0;
      display: block;
      width: 300px;
      height: 37px;
      content: '';
    }

    .uGrid {
      &__inner {
        position: relative;
      }

      &__scroll {
        z-index: 3;
        overflow-x: scroll;
        overflow-y: hidden;
        max-height: 347px;
        margin: 0 430px -17px 42px;

        @media (max-width: 1324px) {
          overflow-x: visible;
          margin: 0 0 -17px 42px;
        }

        @media (pointer: coarse) {
          overflow-x: visible;
          margin: 0 0 0 35px;
        }

        &--open {
          overflow-x: scroll;
          overflow-y: visible;
          margin: 0 430px -17px 42px;

          @media (max-width: 1324px) {
            margin: 0 0 -17px 42px;
          }

          @media (pointer: coarse) {
            overflow-x: visible;
            margin: 0 0 0 35px;
          }
        }
      }

      &__scrollbar {
        position: absolute;
        z-index: 4;
        bottom: -7px;
        left: 42px;
        width: 643px;
        height: 16px;

        @media (max-width: 1324px) {
          width: calc(100vw - 226px);
        }

        @media (pointer: coarse) {
          display: none;
        }

        &--fixed {
          position: fixed;
          z-index: 19;
          bottom: 0;
          left: 218px;
          width: 643px;
          height: 24px;
          padding: 4px 0;
          background: rgba(31, 31, 31, .92);

          @media (max-width: 1324px) {
            width: calc(100vw - 226px);
          }

          @media (pointer: coarse) {
            display: none;
          }
        }

        &Inner {
          border-radius: 8px;

          &:hover {
            background: #444;

            .uGrid__scrollbarBody {
              border-color: #444;
            }
          }
        }

        &Body {
          position: relative;
          width: 50px;
          height: 16px;
          padding: 4px;

          &Inner {
            height: 8px;
            border-radius: 8px;
            background: #909090;

            &:hover {
              background: #858585;
            }
          }
        }
      }

      &__head {
        &Items {
          display: flex;
          height: 37px;
          padding: 0 0 0 21px;
        }

        &Item {
          font-size: 13px;
          font-weight: 700;
          display: flex;
          width: 142px;
          color: rgba(255, 255, 255, .67);

          align-items: center;
          justify-content: center;
          flex-shrink: 0;

          &--ellipsis {
            font-size: 13px;
            font-weight: 700;
            position: absolute;
            top: 0;
            display: flex;
            width: 2em;
            height: 37px;
            color: rgba(255, 255, 255, .67);
            background: #272727;

            align-items: center;
            justify-content: center;

            @media (max-width: 1324px), (pointer: coarse) {
              display: none;
            }

            &:first-child {
              left: 42px;
            }

            &:last-child {
              right: 426px;
            }
          }

          &--calving {
            font-size: 13px;
            font-weight: 700;
            position: absolute;
            top: 0;
            right: 16px;
            display: flex;
            width: 112px;
            height: 37px;
            color: rgba(255, 255, 255, .67);
            background: #272727;

            align-items: center;
            justify-content: center;

            @media (max-width: 1324px), (pointer: coarse) {
              display: none;
            }
          }
        }
      }

      &__body {
        &Item {
          display: flex;
          margin: 0 0 16px 0;

          &:not(:first-child) {
            .uGrid__bodyGroupItem--pregnant {
              .uGrid__bodyGroupItemInner {
                border: none;
              }
            }
          }

          &:first-child {
            .uGrid__bodyFoot {
              &::before {
                @media (max-width: 1324px), (pointer: coarse) {
                  font-size: 13px;
                  font-weight: 700;
                  position: absolute;
                  top: -37px;
                  right: 16px;
                  display: flex;
                  width: 112px;
                  height: 37px;
                  content: '分娩';
                  color: rgba(255, 255, 255, 0.67);

                  align-items: center;
                  justify-content: center;
                }
              }
            }
          }
        }

        &Head {
          position: absolute;
          z-index: 2;
          left: -7px;
          display: flex;
          width: 42px;
          height: 139px;

          align-items: center;
          justify-content: center;

          &Body {
            font-size: 17px;
            font-weight: 700;
            color: white(primary);
          }
        }

        &Main {
          position: relative;
          display: flex;
          height: 139px;
          border-radius: 4px 0 0 4px;
          background: white(primary);

          flex-grow: 1;
          flex-shrink: 0;
        }

        &Group {
          position: relative;
          z-index: 1;
          display: flex;
          width: 100%;
          padding: 16px 16px 7px 16px;

          &Inner {
            z-index: 1;
            display: flex;
            flex-direction: column;
          }

          &Items {
            display: flex;
            min-width: 136px;
            height: 88px;
            padding: 12px 16px 12px 5px;
            border-radius: 4px;
            background: #f2f2f2;

            &--fresh {
              display: flex;
              height: 88px;
              padding: 11px 0 13px 21px;
            }
          }

          &Item {
            @extend %uGrid--reproduction__bodyItem;

            &--fresh {
              @extend %uGrid--reproduction__bodyItem;

              .uGrid__bodyGroupItemDays {
                left: -42px;
              }

              .uGrid__bodyGroupItemInner {
                display: flex;
                width: 100px;
                background: #f0f0f0;

                align-items: center;
                justify-content: center;

                &Name {
                  color: #0d52a2;
                }
              }
            }

            &--none {
              @extend %uGrid--reproduction__bodyItem;

              .uGrid__bodyGroupItemInner {
                background: #ddd;

                &Name {
                  color: black(tertiary);
                }

                &Date {
                  color: black(tertiary);
                }
              }
            }

            &--waitingForPregnancyTest {
              @extend %uGrid--reproduction__bodyItem;

              &::before {
                font-size: 13px;
                font-weight: 700;
                position: absolute;
                right: 0;
                bottom: -20px;
                width: 88px;
                content: '妊娠鑑定待ち';
                text-align: center;
                color: #bc08a7;
              }

              .uGrid__bodyGroupItemInner {
                border: 2px solid #b2079e;
                background: #ffe8fc;

                &Name {
                  color: #bc08a7;
                }

                &Date {
                  color: #bc08a7;
                }
              }

              .uGrid__bodyGroupItemInnerLabel {
                top: -12px;
                right: 7px;
              }
            }

            &--pregnant {
              @extend %uGrid--reproduction__bodyItem;

              .uGrid__bodyGroupItemInner {
                border: 2px solid #e56eb7;
              }

              .uGrid__bodyGroupItemInnerLabel {
                top: -12px;
                right: 7px;
              }
            }

            &Days {
              position: absolute;
              top: 0;
              left: -27px;
              width: 54px;
              height: 64px;
              cursor: pointer;
              text-align: center;

              &:hover {
                .uGrid__bodyGroupItemDaysText {
                  color: white(primary);
                  background: #2a66b7;
                }
              }

              &Text {
                font-size: 13px;
                font-weight: 700;
                display: flex;
                width: 26px;
                height: 26px;
                margin: -4px auto 0;
                pointer-events: none;
                color: #2a66b7;
                border-radius: 50%;
                background: white(primary);

                align-items: center;
                justify-content: center;
              }

              &Arrow {
                position: absolute;
                top: calc(50% - 6px);
                left: 0;
                width: 54px;
                height: 11px;
                pointer-events: none;

                &::before {
                  position: absolute;
                  top: 5px;
                  left: 12px;
                  display: block;
                  width: 20px;
                  height: 1px;
                  content: '';
                  background: black(tertiary);
                }

                &::after {
                  position: absolute;
                  top: 0;
                  right: 12px;
                  width: 10px;
                  height: 11px;
                  content: '';
                  border-top: 5px solid transparent;
                  border-bottom: 5px solid transparent;
                  border-left: 7px solid black(tertiary);
                }
              }
            }

            &Inner {
              position: relative;
              width: 88px;
              height: 64px;
              padding: 11px 0;
              border-radius: 4px;
              background: white(primary);


              &Label {
                font-size: 13px;
                line-height: 1.2;
                position: absolute;
                top: -10px;
                right: 9px;
                display: inline-flex;
                height: 20px;
                padding: 0 3px;
                color: black(secondary);
                border: 1px solid black(tertiary);
                border-radius: 4px;
                background: white(primary);

                align-items: center;
              }

              &Name {
                font-size: 14px;
                font-weight: 700;
                overflow: hidden;
                height: 21px;
                padding: 0 8px;
                white-space: nowrap;
                text-overflow: ellipsis;
                color: black(primary);
              }

              &Date {
                font-size: 13px;
                text-align: center;
                color: black(secondary);
              }
            }
          }

          &Foot {
            display: flex;
            padding: 7px 21px 0 0;

            justify-content: flex-end;

            &Label {
              font-size: 13px;
              display: flex;
              white-space: nowrap;
              color: black(secondary);

              align-items: center;
            }

            &Value {
              font-size: 14px;
              display: flex;
              margin: 0 0 0 8px;
              color: black(secondary);

              align-items: center;
            }
          }
        }

        &Line {
          position: absolute;
          top: 54px;
          left: 0;
          width: 100%;
          height: 11px;

          &::before {
            position: absolute;
            top: 5px;
            left: 16px;
            display: block;
            width: calc(100% - 16px);
            height: 1px;
            content: '';
            background: black(tertiary);
          }

          &--dashed {
            position: absolute;
            top: 54px;
            left: 0;
            width: 100%;
            height: 11px;

            &::before {
              position: absolute;
              top: 5px;
              left: 16px;
              display: block;
              width: calc(100% - 16px);
              height: 1px;
              content: '';
              background-image: linear-gradient(to right, black(tertiary), black(tertiary) 4px, transparent 4px, transparent 8px);
              background-size: 8px 1px;
            }
          }
        }

        &Delimiter {
          &Left {
            position: absolute;
            z-index: 3;
            left: 42px;
            display: flex;
            height: 139px;
            pointer-events: none;

            @media (pointer: coarse) {
              left: 35px;
            }

            &::before {
              display: block;
              width: 24px;
              height: 100%;
              content: '';
              background: linear-gradient(to left, rgba(39, 39, 39, 0) 0%, rgba(39, 39, 39, .16) 41%, rgba(39, 39, 39, .8) 100%);
            }
          }

          &Right {
            position: absolute;
            z-index: 3;
            right: 410px;
            display: flex;
            height: 139px;
            pointer-events: none;

            @media (max-width: 1324px), (pointer: coarse) {
              display: none;
            }

            &::before {
              display: block;
              width: 32px;
              height: 100%;
              content: '';
              background: linear-gradient(to right, rgba(221, 221, 221, 0) 0%, rgba(221, 221, 221, .48) 41%, rgba(180, 180, 180, 0.8) 100%);
            }

            &::after {
              display: block;
              width: 20px;
              height: 100%;
              content: '';
              background: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 27%, rgba(255, 255, 255, .67) 60%, rgba(255, 255, 255, 0) 100%);
            }
          }
        }

        &Foot {
          position: absolute;
          z-index: 2;
          right: 0;
          display: flex;
          width: 430px;
          height: 139px;
          pointer-events: none;
          border-radius: 0 4px 4px 0;
          background: white(primary);

          justify-content: flex-end;

          @media (max-width: 1324px), (pointer: coarse) {
            position: relative;
            min-width: 430px;
          }

          &::after {
            @media (max-width: 1324px), (pointer: coarse) {
              display: block;
              width: 24px;
              height: 100%;
              margin: 0 -24px 0 0;
              content: '';
            }
          }
        }

        &Arrow {
          position: absolute;
          top: 54px;
          left: 0;
          width: calc(100% - 126px);
          height: 11px;

          &::before {
            position: absolute;
            top: 5px;
            left: 0;
            display: block;
            width: calc(100% - 4px);
            height: 1px;
            content: '';
            background: black(tertiary);
          }

          &::after {
            position: absolute;
            top: 0;
            right: -1px;
            width: 10px;
            height: 11px;
            content: '';
            border-top: 5px solid transparent;
            border-bottom: 5px solid transparent;
            border-left: 7px solid black(tertiary);
          }

          &--dashed {
            position: absolute;
            top: 54px;
            left: 0;
            width: calc(100% - 126px);
            height: 11px;

            &::before {
              position: absolute;
              top: 5px;
              left: 0;
              display: block;
              width: calc(100% - 4px);
              height: 1px;
              content: '';
              background-image: linear-gradient(to right, black(tertiary), black(tertiary) 4px, transparent 4px, transparent 8px);
              background-size: 8px 1px;
            }

            &::after {
              position: absolute;
              top: 0;
              right: -1px;
              width: 10px;
              height: 11px;
              content: '';
              border-top: 5px solid transparent;
              border-bottom: 5px solid transparent;
              border-left: 7px solid black(tertiary);
            }
          }
        }

        &Dry {
          z-index: 2;
          width: 192px;
          height: 86px;
          margin: 16px 0;

          @extend %uGrid--reproduction__bodyItem;

          &--expected {
            z-index: 1;
            width: 192px;
            height: 86px;
            margin: 16px 0;

            @extend %uGrid--reproduction__bodyItem;

            .uGrid__bodyDryInner {
              border: 2px solid #1766ac;
              background: #e8f4ff;

              &Name {
                color: #0d52a2;
              }

              &Date {
                color: #0d52a2;
              }
            }
          }

          &Inner {
            width: 96px;
            height: 64px;
            padding: 11px 0;
            border-radius: 4px;
            background: #f2f2f2;

            &Name {
              font-size: 14px;
              font-weight: 700;
              height: 21px;
              text-align: center;
              color: black(primary);
            }

            &Date {
              font-size: 13px;
              text-align: center;
              color: black(secondary);
            }
          }
        }

        &DoNotBreed {
          z-index: 2;
          width: 192px;
          height: 86px;
          margin: 16px 0;

          @extend %uGrid--reproduction__bodyItem;

          &Arrow {
            position: absolute;
            top: calc(50% - 5px);
            left: 0;
            width: 48px;
            height: 11px;

            &::before {
              position: absolute;
              top: 5px;
              left: 0;
              display: block;
              width: 100%;
              height: 1px;
              content: '';
              background: black(tertiary);
            }

            &::after {
              position: absolute;
              top: 0;
              right: -3px;
              width: 10px;
              height: 11px;
              content: '';
              border-top: 5px solid transparent;
              border-bottom: 5px solid transparent;
              border-left: 7px solid black(tertiary);
            }
          }

          &Inner {
            width: 96px;
            height: 64px;
            padding: 11px 0;
            border: 2px solid #6b4577;
            border-radius: 4px;
            background: #fff;

            &Name {
              font-size: 14px;
              font-weight: 700;
              height: 21px;
              text-align: center;
              color: #6b4577;
            }

            &Date {
              font-size: 13px;
              text-align: center;
              color: #6b4577;
            }
          }
        }

        &Fattening,
        &Eliminate {
          position: absolute;
          z-index: 1;
          top: 0;
          left: 144px;
          width: 158px;
          height: 86px;
          margin: 16px 0;

          @extend %uGrid--reproduction__bodyItem;

          &Arrow {
            position: absolute;
            top: calc(50% - 5px);
            left: -144px;
            width: 175px;
            height: 11px;

            &::before {
              position: absolute;
              top: 5px;
              left: 0;
              display: block;
              width: 100%;
              height: 1px;
              content: '';
              background: black(tertiary);
            }

            &::after {
              position: absolute;
              top: 0;
              right: -3px;
              width: 10px;
              height: 11px;
              content: '';
              border-top: 5px solid transparent;
              border-bottom: 5px solid transparent;
              border-left: 7px solid black(tertiary);
            }
          }

          &Inner {
            width: 96px;
            height: 64px;
            padding: 11px 0;
            border: 2px solid #1b5e65;
            border-radius: 4px;
            background: #fff;

            &Name {
              font-size: 14px;
              font-weight: 700;
              height: 21px;
              text-align: center;
              color: #1b5e65;
            }

            &Date {
              font-size: 13px;
              text-align: center;
              color: #1b5e65;
            }
          }
        }

        &FallingDead {
          position: absolute;
          z-index: 1;
          top: 0;
          left: 144px;
          width: 158px;
          height: 86px;
          margin: 16px 0;

          @extend %uGrid--reproduction__bodyItem;

          &Arrow {
            position: absolute;
            top: calc(50% - 5px);
            left: -144px;
            width: 175px;
            height: 11px;

            &::before {
              position: absolute;
              top: 5px;
              left: 0;
              display: block;
              width: 100%;
              height: 1px;
              content: '';
              background: black(tertiary);
            }

            &::after {
              position: absolute;
              top: 0;
              right: -3px;
              width: 10px;
              height: 11px;
              content: '';
              border-top: 5px solid transparent;
              border-bottom: 5px solid transparent;
              border-left: 7px solid black(tertiary);
            }
          }

          &Inner {
            width: 96px;
            height: 64px;
            padding: 11px 0;
            border: 2px solid #931c21;
            border-radius: 4px;
            background: #fff;

            &Name {
              font-size: 14px;
              font-weight: 700;
              height: 21px;
              text-align: center;
              color: #931c21;
            }

            &Date {
              font-size: 13px;
              text-align: center;
              color: #931c21;
            }
          }
        }

        &Days {
          display: flex;
          flex-direction: column;
          width: 110px;
          padding: 67px 0 0 4px;

          &Body {
            &:not(:first-child) {
              margin: 1px 0 0;
            }
          }

          &Label {
            font-size: 13px;
            display: inline-flex;
            width: 4em;
            color: black(secondary);

            justify-content: flex-end;
          }

          &Value {
            font-size: 14px;
            margin: 0 0 0 5px;
            color: black(primary);
          }
        }

        &Day {
          position: relative;
          width: 128px;
          padding: 16px 16px 0 0;

          &Label {
            font-size: 13px;
            line-height: 1.2;
            position: absolute;
            top: 7px;
            right: 23px;
            display: inline-flex;
            height: 20px;
            padding: 0 3px;
            color: #666;
            border: 1px solid #757575;
            border-radius: 4px;
            background: #fff;

            align-items: center;
          }

          &Inner {
            font-size: 14px;
            display: flex;
            width: 112px;
            height: 86px;
            color: black(primary);
            border-radius: 4px;
            background: #f2f2f2;

            align-items: center;
            justify-content: center;

            &--expected {
              font-size: 15px;
              font-weight: 700;
              display: flex;
              flex-direction: column;
              width: 112px;
              height: 86px;
              color: white(primary);
              border: 1px solid #d92aaa;
              border-radius: 4px;
              background: linear-gradient(to right, #f56e9e, #cc1e6f);

              align-items: center;
              justify-content: center;

              &::after {
                font-size: 13px;
                content: '予定日';
              }
            }
          }
        }

        &Timeline {
          &Backdrop {
            position: fixed;
            z-index: 18;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(255, 255, 255, 0);
          }

          &Inner {
            position: fixed;
            z-index: 19;
            width: 364px;
            max-height: 448px;
            padding: 21px 16px;
            border-radius: 8px;
            background: white(primary);
            box-shadow: 0 3px 16px rgba(0, 0, 0, .32);
          }

          &Head {
            display: flex;
            height: 20px;

            align-items: center;
          }

          &Heading {
            font-size: 15px;
            font-weight: 700;
            color: black(primary);

            flex-shrink: 0;
          }

          &Text {
            font-size: 14px;
            margin: 0 0 0 16px;
            color: black(primary);

            flex-grow: 1;
          }

          &Close {
            position: relative;
            width: 20px;
            height: 20px;

            flex-shrink: 0;

            &::before {
              position: absolute;
              top: 9px;
              left: -2px;
              display: block;
              width: 24px;
              content: '';
              transform: rotate(45deg);
              border-top: 2px solid black(secondary);
            }

            &::after {
              position: absolute;
              top: 9px;
              left: -2px;
              display: block;
              width: 24px;
              content: '';
              transform: rotate(-45deg);
              border-top: 2px solid black(secondary);
            }
          }

          &Body {
            position: relative;

            &::before {
              position: absolute;
              top: 0;
              left: 12px;
              display: block;
              width: 304px;
              height: 16px;
              content: '';
              border-radius: 4px 4px 0 0;
              background: linear-gradient(to bottom, rgba(238, 238, 238, .8), rgba(238, 238, 238, 0));
            }

            &::after {
              position: absolute;
              bottom: 0;
              left: 12px;
              display: block;
              width: 304px;
              height: 16px;
              content: '';
              border-radius: 4px 4px 0 0;
              background: linear-gradient(to bottom, rgba(238, 238, 238, 0), rgba(238, 238, 238, .8));
            }
          }

          &Items {
            overflow-y: scroll;
            max-height: 306px;
            margin: 21px 0 0;
          }

          &Item {
            border-radius: 4px;
            background: #eee;

            &:not(:first-child) {
              margin: 4px 0 0;
            }

            &Head {
              display: flex;
              min-height: 56px;

              &Date {
                font-size: 14px !important;
                line-height: 1.2;
                display: flex;
                width: 74px;
                text-align: center;
                color: black(primary);

                align-items: center;
                justify-content: center;
              }

              &Body {
                display: flex;

                align-items: center;
                justify-content: center;
              }

              &Category {
                font-size: 13px !important;
                line-height: 1;
                padding: 4px 5px;
                color: white(primary);
                border-radius: 4px;
                background: #826ef5;
              }

              &Text {
                font-size: 14px !important;
                font-weight: 700;
                margin: 0 0 0 8px !important;
                color: black(primary);
              }
            }

            &Body {
              padding: 0 14px 14px 82px;

              &Items {
                line-height: 2.1;
                padding: 0 0 0 12px;
                border-left: 2px solid #ccc;
              }

              &Item {
                display: flex;
              }

              &Heading {
                font-size: 13px !important;
                width: 100px;
                color: black(secondary);

                flex-shrink: 0;
              }

              &Text {
                font-size: 13px !important;
                font-weight: 700;
                color: black(secondary);
              }
            }
          }

          &Button {
            font-size: 14px !important;
            display: flex;
            width: 202px;
            height: 36px;
            margin: 16px auto 0 !important;
            text-decoration: none !important;
            color: #2a66b7 !important;
            border: 1px solid white(primary);
            border-radius: 18px;
            background: linear-gradient(to right, white(primary), #f0f0f0);
            box-shadow: 0 1px 2px rgba(0, 0, 0, .16);

            align-items: center;
            justify-content: center;

            &:hover {
              border: 1px solid #e6f7ff;
              background: linear-gradient(to right, #fafdff, #f7fbff);
            }
          }
        }
      }

      &__pager {
        position: absolute;
        z-index: 4;
        top: calc(50% - 9px);
        left: 22px;
        width: calc(100% - 432px);
        height: 40px;

        @media (max-width: 1324px), (pointer: coarse) {
          display: none;
        }

        &Prev {
          position: absolute;
          top: 0;
          left: 0;
          display: block;
          width: 40px;
          height: 40px;
          border-radius: 50%;
          background: white(primary);
          box-shadow: 0 3px 6px rgba(0, 0, 0, .16);

          &:hover {
            background: linear-gradient(to right, #2a66b7, #087a84);
            box-shadow: 0 3px 12px rgba(0, 0, 0, .44);

            &::before,
            &::after {
              border-color: white(primary);
            }
          }

          &::before {
            position: absolute;
            top: 14px;
            left: 12px;
            display: block;
            width: 11px;
            height: 11px;
            content: '';
            transform: rotate(45deg);
            border-bottom: 2px solid #2a66b7;
            border-left: 2px solid #2a66b7;
          }

          &::after {
            position: absolute;
            top: 14px;
            left: 21px;
            display: block;
            width: 11px;
            height: 11px;
            content: '';
            transform: rotate(45deg);
            border-bottom: 2px solid #2a66b7;
            border-left: 2px solid #2a66b7;
          }
        }

        &Next {
          position: absolute;
          top: 0;
          right: 0;
          display: block;
          width: 40px;
          height: 40px;
          border-radius: 50%;
          background: white(primary);
          box-shadow: 0 3px 6px rgba(0, 0, 0, .16);

          &:hover {
            background: linear-gradient(to right, #2a66b7, #087a84);
            box-shadow: 0 3px 12px rgba(0, 0, 0, .44);

            &::before,
            &::after {
              border-color: white(primary);
            }
          }

          &::before {
            position: absolute;
            top: 14px;
            left: 8px;
            display: block;
            width: 11px;
            height: 11px;
            content: '';
            transform: rotate(45deg);
            border-top: 2px solid #2a66b7;
            border-right: 2px solid #2a66b7;
          }

          &::after {
            position: absolute;
            top: 14px;
            left: 17px;
            display: block;
            width: 11px;
            height: 11px;
            content: '';
            transform: rotate(45deg);
            border-top: 2px solid #2a66b7;
            border-right: 2px solid #2a66b7;
          }
        }
      }

      &__foot {
        position: relative;
        z-index: 3;
        padding: 32px 0 0 60px;
        background: linear-gradient(to right, #272727, #292929);
      }

      &__more {
        font-size: 13px;
        position: relative;
        display: flex;
        width: 147px;
        height: 40px;
        padding: 0 0 0 15px;
        text-decoration: none !important;
        color: white(primary) !important;
        border: 1px solid rgba(112, 112, 112, .08);
        border-radius: 20px;
        background: linear-gradient(to right, #3b3b3b, #414243);
        box-shadow: 0 3px 6px rgba(0, 0, 0, .16);

        align-items: center;

        &::after {
          position: absolute;
          top: 16px;
          right: 15px;
          width: 9px;
          height: 9px;
          content: '';
          border-top: 6px solid white(primary);
          border-right: 4px solid transparent;
          border-left: 4px solid transparent;
        }

        &:hover {
          border: 1px solid rgba(255, 255, 255, 0);
          background: linear-gradient(to right, #666, #555);
        }
      }

      &__close {
        font-size: 13px;
        position: relative;
        display: flex;
        width: 147px;
        height: 40px;
        padding: 0 0 0 15px;
        text-decoration: none !important;
        color: white(primary) !important;
        border: 1px solid rgba(112, 112, 112, .08);
        border-radius: 20px;
        background: linear-gradient(to right, #3b3b3b, #414243);
        box-shadow: 0 3px 6px rgba(0, 0, 0, .16);

        align-items: center;

        &::after {
          position: absolute;
          top: 13px;
          right: 15px;
          width: 9px;
          height: 9px;
          content: '';
          border-right: 4px solid transparent;
          border-bottom: 6px solid white(primary);
          border-left: 4px solid transparent;
        }

        &:hover {
          border: 1px solid rgba(255, 255, 255, 0);
          background: linear-gradient(to right, #666, #555);
        }
      }
    }
  }
}

.cowDetail--reproduction {
  width: auto;
  max-width: 1179px;

  @media (pointer: coarse) {
    width: auto;
  }
}