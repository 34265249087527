/*---------------------------------
　カード
---------------------------------*/

/*
　カード
---------------------------------*/
.uCard {
  [class^="uContent"] & {
    margin: 0 -24px;

    .uContent--width987 & {
      @media (max-width: 1131px) {
        overflow-x: scroll;
      }
    }

    @media (pointer: coarse) {
      overflow-x: scroll;
    }
  }

  &--secondary {
    display: flex;
    margin: 0 10px 10px !important;

    flex-wrap: wrap;

    .uCard {
      &__inner {
        width: 424px;
        margin: 0 5px 10px !important;
        padding: 24px 16px 24px 16px;

        flex-shrink: 0;
      }

      &__row {
        &--operation {
          display: flex;
          flex-direction: row-reverse;
          margin: 24px 0 0 !important;

          .uButton {
            font-size: 14px;
            display: flex;
            width: 176px;
            padding: 0 5px 0 19px;
            text-indent: 0;
            letter-spacing: 0;

            align-items: center;
            justify-content: space-between;
          }
        }
      }
    }
  }

  &__outer {
    padding: 0 24px;

    .uContent--width987 & {
      width: 987px;
    }

    .uContent--width1016 & {
      width: 1016px;
    }
  }

  &__inner {
    overflow: hidden;
    border-radius: 4px;
    background: white(primary);
  }

  &__row {
    display: flex;

    &:not(:first-child) {
      margin: 10px 0 0 !important;
    }
  }

  &__columns {
    display: flex;
    flex-direction: column;
    margin: 0 0 0 10px !important;

    flex-grow: 1;
  }

  &__column {
    background: white(primary);

    flex-grow: 1;

    &:not(:first-child) {
      margin: 0 0 0 10px !important;
    }
  }

  &__body {
    padding: 10px;

    &Columns {
      display: flex;
    }

    &Column {
      padding: 10px;

      &--grow {
        flex-grow: 1;
      }

      &--center {
        display: flex;
        height: 100%;

        align-items: center;
        justify-content: center;
      }

      &--width552 {
        width: 552px;
      }

      &--width584 {
        width: 584px;
      }

      &Inner {
        padding: 20px;

        &--secondary {
          height: 100%;
          padding: 20px;
          background: #f2f2f2;
        }
      }
    }
  }
}