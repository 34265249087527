.activity-scatter-plot-chart-wrap {
  background-color: #ffffff;
  height: 380px;
  margin-bottom: 10px;
  position: relative;
}
.activity-scatter-plot-chart-message {
  width: 100%;
  height: 100%;
  text-align: center;
  line-height: 380px;
}
.scatter-plot-chart {
  min-height: 380px;
  .c3-target-self {
    circle {
      stroke: #000000;
      stroke-width: 3px;
      opacity: 1 !important;
    }
  }
  .c3-target-normal {
    circle {
      opacity: 0.3 !important;
    }
  }
  .c3-target-fertilization,
  .c3-target-sick {
    circle {
      opacity: 0.9 !important;
    }
  }
  .targets-panel {
    // edge, firefoxでposition relativeになる為、importantを付ける
    position: absolute !important;
    z-index: 10000;
    background-color: #ffffff;
    box-shadow:5px 5px 6px -3px #acc2b7;
    border: 1px solid rgba(128, 128, 128, 0.3);
    font-size: 1.2rem;
    .title {
      background-color: #8d8d8d;
      padding: 5px 10px;
      text-align: center;
      color: #ffffff;
      font-weight: bold;
    }
    .target {
      cursor: pointer;
      padding: 2px 5px;
      border-bottom: 1px solid rgba(170, 170, 170, 0.3);
      &:last-child {
        border-bottom: none;
      }
      &:hover {
        background-color: #8aa633;
        color: white;
      }
    }
  }
  svg {
    g.c3-regions {
      g.c3-region {
        &.plot-estrus {
          fill : #ff0000;
        }
        &.plot-sick {
          fill : #B277C1;
        }
        text {
          fill : black;
          fill-opacity: 1;
        }
      }
    }
    g.c3-xgrids {
      line.c3-xgrid {
        stroke-dasharray: none;
        stroke-opacity: 0.3;
      }
    }
    g.c3-ygrids {
      line.c3-ygrid {
        stroke-dasharray: none;
        stroke-opacity: 0.3;
      }
    }
  }
  .c3-tooltip-container {
    table {
      th {
        padding: 5px 10px;
      }
      th,
      td {
        width: auto;
      }
    }
  }
}
// cow detail
.cow-detail {
  .activity-scatter-plot-chart-wrap {
    &:last-child {
      margin-bottom: none;
    }
  }
}
