.machinery {
  .box {
    height: auto;
  }
  a:hover.none-link {
    text-decoration: none;
    cursor: default;
  }
  span.latest {
    font-size:2.6rem;
    font-weight: bold;
  }

  .box-left {
    width: 50%;
    min-width: 490px;
  }
  .box-right {
    width: 50%;
  }
  .box.graph {
    padding: 5px;

    &:last-child {
      margin-bottom: 0px;
    }
  }
  td {
    text-align: right;
  }
  .summary {
    margin-top: 20px;
    border: 1px solid #009943;
    > li {
      width: 25%;
      &:nth-child(n+2) {
        border-left: 1px solid #009943;
      }
    }
    li li {
      display: block;
      margin: 0;
      padding-left: 10px;
      padding-right: 10px;
      color: #009943;
      text-align: center;
      &:first-child {
        padding-top: 5px;
      }
      &:last-child {
        font-weight: bold;
        font-size: 2.6rem;
      }
    }
  }
  table {
    margin-top: 15px;
    tr:nth-child(n+2) th {
      border-top: 1px solid #ccc;
      border-right: 1px solid #ccc;
      color: #333;
      background: #f0f0f0;
    }
    th .unit p {
      display: block;
    }
  }
  li .unit {
    margin-left: px;
    color: #009943;
    font-weight: bold;
    font-size: 1.4rem;
  }
}

