.alertLabel {
  font-size: 12px !important;
  font-weight: 700;
  display: inline-block;
  height: 22px;
  margin: 0 5px 0 0 !important;
  padding: 1px 8px;
  text-align: center;
  white-space: nowrap;
  color: #555;
  border-bottom: 1px solid #ccc;
  background: #fff;

  &:hover {
    border-color: #dedede;
    border-radius: 2px;
    background-color: #dedede;
  }

  &--acuteIllness {
    position: absolute;
    left: 6px;
    width: 62px;
    padding: 1px 0;
    color: #ea6300;
    border: 1px solid #e25f00;
    border-radius: 2px;

    &:hover {
      border-color: #fff;
      background-color: #fae2d0;
    }
  }

  &--illness {
    position: absolute;
    right: 215px;
    padding: 1px 7px;
    color: #c02c94;
    border: 1px solid #c02c94;
    border-radius: 2px;

    &:hover {
      border-color: #fff;
      background-color: #ecd8e6;
    }
  }

  &--chronicIllness {
    position: absolute;
    right: 169px;
    padding: 1px 7px;
    color: #c02c94;
    border-bottom: 1px solid #c02c94;

    &:hover {
      border-color: #ecd8e6;
      border-radius: 2px;
      background-color: #ecd8e6;
    }
  }
}
