.ui-datepicker {
  display: none;
  width: 200px !important;
  padding: 1px !important;
  background: #fff;
  //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
  box-shadow: 0 2px 2px rgba(0,0,0,0.3);
  th {
    font-size: 1.2rem;
    border-top: 1px solid #ccc;
    border-right: 1px solid #ccc;
    text-align: center;
    padding: 0;
    color: #fff;
    background: #999;
    &:first-child {
      color: #f00;
    }
    &:last-child {
      color: #00d9ff;
    }
  }
  td {
    font-size: 1.2rem;
    border-top: 1px solid #ccc;
    border-right: 1px solid #ccc;
    text-align: center;
    line-height: 20px;
    padding: 0;
    a {
      font-size: 1.2rem;
      display: block;
      color: #111;
      font-weight: bold;
      text-decoration: none;
    }
    &:first-child a {
      color: #f00;
    }
    &:last-child a {
      color: #00d9ff;
    }
    span {
      display: block;
    }
  }
  a:hover {
    text-decoration: none;
    background: #8dddb0;
  }
}
.ui-widget-header {
  border: 1px solid #aaa;
  color: #fff;
  font-weight: bold;
  background: #111;
}
.ui-datepicker-calendar {
  width: 198px;
  border: 1px solid #ccc;
}
.ui-datepicker-title {
  font-weight: bold;
  line-height: 24px;
  font-size: 1.2rem;
  text-align: center;
}
.ui-datepicker-prev {
  position: absolute;
  top: 3px !important;
  left: 2px !important;
  background: url(../images/sprite.png) 0 -29px !important;
  width: 20px !important;
  height: 20px !important;
  &:hover {
    top: 3px;
    left: 2px;
    background: url(../images/sprite.png) 0 -49px !important;
    width: 20px !important;
    height: 20px !important;
  }
  span {
    display: none !important;
  }
}
.ui-datepicker-next {
  position: absolute;
  top: 3px !important;
  right: 2px !important;
  background: url(../images/sprite.png) -20px -29px !important;
  width: 20px !important;
  height: 20px !important;
  &:hover {
    top: 3px;
    right: 2px;
    background: url(../images/sprite.png) -20px -49px !important;
    width: 20px !important;
    height: 20px !important;
  }
  span {
    display: none !important;
  }
}
.ui-state-highlight {
  color: #fff !important;
  font-weight: bold;
  background: #009943 !important;
  &:hover {
    color: #fff !important;
    font-weight: bold;
    background: #009943 !important;
  }
}
.ui-datepicker {
  &.large {
    width: auto !important;
    .ui-datepicker-prev {
      top: 7px !important;
      left: 7px !important;
    }

    .ui-datepicker-next {
      top: 7px !important;
      right: 7px !important;
    }

    .ui-datepicker-title {
      line-height: 30px !important;
      font-size: 1.5rem;
    }

    .ui-datepicker-month,
    .ui-datepicker-year {
      font-size: 1.5rem;
    }

    td {
      a, span {
        font-size: 1.5rem;
        padding: 12px 14px;
      }
    }
  }
}
.ui-datepicker-buttonpane {
  display: flex;
  height: 39px;
  padding: 0 3px;

  align-items: center;
}

.ui-datepicker-current {
  font-size: 13px;
  font-weight: 700;
  display: flex;
  width: 50px;
  height: 29px;
  color: black(primary);

  align-items: center;
  justify-content: center;

  &:hover {
    border-radius: 8px;
    background: #f0f0f0;
  }
}

.ui-datepicker-close {
  display: none;
}