/*---------------------------------
　コンテンツ
---------------------------------*/

/*
　@extend用のプレースホルダーセレクタ
---------------------------------*/

%uContent {
  position: relative;
  margin: 0 8px !important;
  padding: 24px;
  border-radius: 4px;
  background: linear-gradient(to right, #272727, #292929);

  @media (pointer: coarse) {
    margin: 0 !important;
  }

  &:not(:first-child) {
    margin-top: 8px !important;
  }
}

%uContent__tabItem {
  position: relative;
  display: flex;
  width: 84px;
  height: 36px;
  text-decoration: none !important;

  align-items: center;
  justify-content: center;
}

%uContent__tabItemBefore {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  content: '';
  border: 1px solid #898989;
  border-radius: 18px;
  background: linear-gradient(to right, #969696, #727272);
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.16);
}

%uContent__uiGrid {
  .ui-grid-cell {
    overflow: visible;
  }

  .uTooltip {
    width: 100%;
    height: 100%;

    &::before {
      top: calc(100% - 8px);
    }
  }
}

/*
　コンテンツ
---------------------------------*/
.uContent {
  @extend %uContent;

  &--width987 {
    max-width: 987px;

    @extend %uContent;
  }

  &--width1016 {
    max-width: 1016px;

    @extend %uContent;
  }

  &--groupTransferInput {
    width: 848px;
    height: 620px;

    .uContent {
      &__items {
        display: flex;
        max-height: 100%;
        padding: 0 8px;
      }

      &__item {
        padding: 26px 24px 0;
        border: 1px solid #333;
        border-radius: 8px;
        background: #2e2e2e;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, .16);

        flex-grow: 1;

        &:not(:first-child) {
          margin: 0 0 0 12px !important;
        }

        &--secondary {
          position: relative;
          width: 183px;
          margin: 0 0 0 12px !important;

          flex-shrink: 0;

          .uContent {
            &__item {
              &Inner {
                height: 100%;
                padding: 8px 4px 8px 0;
                border: 1px solid #333;
                border-radius: 8px;
                background: #2e2e2e;
                box-shadow: 0px 3px 6px rgba(0, 0, 0, .16);

                &::before {
                  position: absolute;
                  top: 58px;
                  left: 24px;
                  display: block;
                  width: calc(100% - 48px);
                  height: 20px;
                  content: '';
                  background: linear-gradient(to bottom, rgba(46, 46, 46, .8), rgba(46, 46, 46, 0));
                }

                &::after {
                  position: absolute;
                  bottom: 0;
                  left: 24px;
                  display: block;
                  width: calc(100% - 48px);
                  height: 40px;
                  content: '';
                  background: linear-gradient(to bottom, rgba(46, 46, 46, 0), rgba(46, 46, 46, .8), #2e2e2e);
                }
              }

              &Scroll {
                overflow-x: hidden;
                overflow-y: scroll;
                max-height: 100%;
                padding: 0 0 28px 24px;

                &::-webkit-scrollbar {
                  position: relative;
                  width: 8px;
                }

                &::-webkit-scrollbar-button {
                  display: none;
                }

                &::-webkit-scrollbar-track {
                  border-radius: 4px;
                  background: #2e2e2e;

                  &:hover {
                    background: #444;

                    &::-webkit-scrollbar-thumb {
                      background: none;
                    }
                  }
                }

                &::-webkit-scrollbar-thumb {
                  height: 17px;
                  border-radius: 4px;
                  background: #909090;

                  &:hover {
                    background: #ccc;
                  }
                }
              }
            }

            &__heading--secondary {
              position: absolute;
              top: 0;
              left: 24px;
              width: calc(100% - 48px);
              padding: 31px 0 12px;
              background: #2e2e2e;
            }
          }

          .uList--tertiary {
            margin-top: 67px !important;
          }
        }
      }
    }
  }

  &__inner {
    &::before {
      position: absolute;
      z-index: 3;
      top: 0;
      left: 0;
      display: block;
      width: 24px;
      height: 100%;
      content: '';
      background: linear-gradient(to left, rgba(39, 39, 39, 0) 0%, rgba(39, 39, 39, .16) 41%, rgba(39, 39, 39, .56) 100%);
    }

    &::after {
      position: absolute;
      z-index: 3;
      top: 0;
      right: 0;
      display: block;
      width: 24px;
      height: 100%;
      content: '';
      background: linear-gradient(to right, rgba(39, 39, 39, 0) 0%, rgba(39, 39, 39, .16) 41%, rgba(39, 39, 39, .56) 100%);
    }
  }

  &__head {
    display: flex;
    margin: 0 0 18px 8px !important;

    align-items: center;
    justify-content: space-between;
  }

  &__heading {
    font-size: 17px !important;
    font-weight: 700;
    color: white(primary);

    &--secondary {
      font-size: 14px !important;
      font-weight: 700;
      color: white(secondary);
    }
  }

  &__button {
    font-size: 14px;
    display: flex;
    height: 36px;
    padding: 0 23px;
    text-decoration: none !important;
    color: white(primary) !important;
    border: 1px solid rgba(112, 112, 112, 0.08);
    border-radius: 18px;
    background: linear-gradient(to right, #3b3b3b, #414243);
    box-shadow: 0px 3px 6px rgba(0, 0, 0, .16);

    align-items: center;

    &:hover {
      border: 1px solid rgba(255, 255, 255, 0);
      background: linear-gradient(to right, #666, #555);
    }
  }

  &__conditions {
    display: flex;

    align-items: center;

    &Heading {
      font-size: 13px !important;
      margin: 0 15px 0 0 !important;
      white-space: nowrap;
      color: white(primary);
    }

    &Items {
      display: flex;
      margin: -16px 0 0 !important;

      flex-wrap: wrap;
    }

    &Item {
      position: relative;
      height: 36px;
      margin: 16px 8px 0 0 !important;
      padding: 0 12px;
      transition: all .1s linear;
      border: 1px dashed black(secondary);
      border-radius: 18px;

      &:hover {
        text-decoration: none;
        background: #444;
      }
    }

    &Title {
      display: block;

      &::before {
        font-size: 13px;
        position: absolute;
        top: -10px;
        left: 10px;
        display: block;
        padding: 0 3px;
        content: attr(data-text);
        white-space: nowrap;
        color: white(secondary);
        background: linear-gradient(to bottom, #272727 0%, #272727 50%, rgba(255, 255, 255, 0) 51%, rgba(255, 255, 255, 0) 100%);
      }

      &::after {
        font-size: 13px;
        display: block;
        visibility: hidden;
        height: 0;
        content: attr(data-text);
      }
    }

    &Value {
      font-size: 13px !important;
      display: flex;
      height: 100%;
      white-space: nowrap;
      color: white(primary);

      align-items: center;

      &Text {
        font-size: 13px !important;
        color: white(primary);

        &:hover {
          text-decoration: none;
          color: white(primary);
        }
      }
    }

    &Arrow {
      position: relative;
      margin: 0 12px !important;

      &::before {
        position: absolute;
        top: -4px;
        left: -2px;
        display: block;
        width: 9px;
        height: 9px;
        content: '';
        pointer-events: none;
        border-top: 4px solid transparent;
        border-bottom: 4px solid transparent;
        border-left: 7px solid white(secondary);
      }
    }

    &Date {
      font-size: 14px;
      width: 5.5em;
      color: white(primary);
      border: none;
      background: none;

      &:focus {
        outline: 0;
      }
    }

    &Separate {
      width: 1px;
      height: 40px;
      margin: 0 20px 0 12px !important;
      background: #575757;
    }

    &Button {
      font-size: 13px !important;
      position: relative;
      display: flex;
      height: 36px;
      padding: 0 16px;
      color: white(primary);
      border-radius: 18px;
      background: #444;
      box-shadow: 0 3px 6px rgba(0, 0, 0, .16);

      align-items: center;

      &:hover {
        text-decoration: none;
        color: white(primary);
      }

      &--arrow {
        font-size: 13px !important;
        position: relative;
        display: flex;
        height: 36px;
        padding: 0 16px;
        color: white(primary);
        border-radius: 18px;
        background: #444;
        box-shadow: 0 3px 6px rgba(0, 0, 0, .16);

        align-items: center;

        &:hover {
          text-decoration: none;
          color: white(primary);
        }

        &::after {
          display: block;
          width: 9px;
          height: 9px;
          margin: 0 0 0 16px;
          content: '';
          pointer-events: none;
          border-top: 7px solid white(primary);
          border-right: 4px solid transparent;
          border-left: 4px solid transparent;
        }
      }
    }
  }

  &__message {
    font-size: 13px !important;
    margin: 10px 0 !important;
    color: white(primary);

    &--warning {
      font-size: 14px !important;
      line-height: 1.45;
      display: inline-block;
      margin: 13px 0 0 !important;
      padding: 6px 12px;
      color: #c9e81c;
      border: 1px solid #8da700;
      border-radius: 8px;
      background: #2b3300;

      &+.uContent__uiGrid,
      &+.uContent__uiGrid--disableHover {
        height: calc(100vh - 292px);
      }
    }

    &:first-child {
      margin-top: 0 !important;
    }
  }

  &__tab {
    &Items {
      display: flex;
      margin: 40px 0 12px !important;
    }

    &Item {
      @extend %uContent__tabItem;

      &::before {
        opacity: 0.12;

        @extend %uContent__tabItemBefore;
      }

      &:not(:first-child) {
        margin: 0 0 0 8px !important;
      }

      &:hover {
        &::before {
          opacity: 0.4;

          @extend %uContent__tabItemBefore;
        }

        .uContent__tabItemText {
          color: white(primary);
        }
      }

      &--active {
        @extend %uContent__tabItem;

        &::before {
          opacity: 0.72;

          @extend %uContent__tabItemBefore;
        }

        &:not(:first-child) {
          margin: 0 0 0 8px !important;
        }

        .uContent__tabItemText {
          color: white(primary);
        }
      }

      &Text {
        font-size: 13px !important;
        z-index: 1;
        color: white(secondary);
      }
    }
  }

  &__table {
    margin: 20px 0 0 !important;
    padding: 10px;
    border-radius: 8px;
    background: #fff;

    flex-grow: 1;

    &Inner {
      overflow: scroll;
      height: calc(100vh - 460px);
      text-align: center;
    }

    &Add {
      display: flex;
      height: 64px;
      margin: 0 -10px !important;
      background: #eee;

      align-items: center;
      justify-content: center;

      &Button {
        font-size: 14px !important;
        font-weight: 700;
        width: 264px;
        height: 40px;
        transition: all .1s linear;
        color: black(primary);
        border: 1px dashed #bfbfbf;
        border-radius: 20px;
        background: white(primary);

        &:hover {
          border: 1px dashed white(primary);
          background: #e6e6e6;
        }
      }
    }

    &ActionButtons {
      display: flex;
      height: 80px;
      margin: 0 -10px -10px !important;
      padding: 0 32px;
      border-radius: 0 0 8px 8px;
      background: white(primary);
      box-shadow: 0 3px 30px rgba(0, 0, 0, .5);

      align-items: center;
      justify-content: space-between;
    }

    &ActionButton {

      .uButton,
      [class^="uButton--"] {
        &:first-child {
          margin: 0 !important;
        }
      }
    }
  }

  &__uiGrid {
    height: calc(100vh - 245px);
    margin: 12px 0 0 !important;
    background: white(primary);

    @extend %uContent__uiGrid;

    &--disableHover {
      height: calc(100vh - 245px);
      margin: 12px 0 0 !important;
      background: white(primary);

      @extend %__uiGrid;

      .ui-grid-row {
        cursor: default;

        &:hover {
          background: transparent !important;
        }
      }
    }
  }
}

.uSideBoard {
  +.uMain {
    .uContent__foot {
      width: calc(100% - 216px);
    }
  }

  &--closed {
    +.uMain {
      .uContent__foot {
        width: calc(100% - 32px);
      }
    }
  }
}