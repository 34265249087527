.bulk-registration {
  .content .content-box .section { margin-bottom: 0; }
  .section .right-shoulder h4 { margin-bottom: 10px; font-size: 1.4rem; color: #333; }
  .bulk-regist-list01 {
    dl {
      margin-top: 10px;
      margin-bottom: 15px;
      display: inline-block;
      float: none;
      width: auto;
      margin-left: 0;
      padding: 0;
      font-size: 1.4rem;
      dt {
        margin-right: 3px;
        display: inline-block;
        float: none;
        width: auto;
        margin-left: 0;
        padding: 0;
        font-size: 1.4rem;
        padding-top: 5px;
        padding-bottom: 5px;
      }
      dd {
        display: inline-block;
        float: none;
        width: auto;
        margin-left: 0;
        padding: 0;
        font-size: 1.4rem;
        padding-top: 5px;
        padding-bottom: 5px;
        position: relative;
        margin-right: 12px;
        line-height: 1;
        border-radius: 5px;
        border: 1px dashed #ccc;
        &:nth-last-of-type(1) { margin-right: 0; }
        a {
          display: inline-block;
          padding: 6px 28px 6px 7px;
          color: #333;
          font-size: 1.4rem;
          &:hover {
            background-color: #eee;
            text-decoration: none;
          }
        }
        input.date-ver {
          position: relative;
          width: 120px;
          padding-top: 3px;
          padding-bottom: 3px;
          font-size: 1.4rem;
          border: none;
          background: transparent url("../images/icon-edit.svg") no-repeat 100px 4px;
          background-size: 15px 15px;
          box-shadow: none;
          cursor: pointer;
          &:hover { background-color: #eee; }
        }
      }
    }
  }
  .table-scroll {
    .tbl-repro-dis {
      .col1, .col2, .col5, .col6, .col8, .col9 { width: 9%; }
      .col3, .col4 { width: 7%; }
      .col7, .col10 { width: 6%; }
      .col11 { width: 20%; }
    }
    .exist-checkbox {
      .col1 { width: 2%; }
      .col2, .col3, .col6, .col7, .col9, .col10 { width: 9%; }
      .col4, .col5 { width: 7%; }
      .col8, .col11 { width: 6%; }
      .col12 { width: 18%; }
      tr td:nth-of-type(1) { text-align: center; }
    }
    input[type="text"], select { width: 100%; }
    select { height: 25px;}
    input[type="checkbox"] { margin-right: 0; }
  }
}
.exist-footer-btn {
  position: relative;
  padding-bottom: 100px;
  .table-scroll {
    height: calc(100vh - 472px);
    min-height: 150px;
    overflow: auto;
  }
  .btn-box {
    position: absolute;
    width: calc(100% - 20px);
  }
}
