body.print {
  font-size: 10pt;
  background-color: #fff;
  -webkit-print-color-adjust: exact;
  .barn {
    font-size: 10pt;
  }
  .content-box * {
    min-width: 1px!important;
    font-size: 7pt!important;
  }
  .print-dspnone { display: none!important; }
  .content .content-box .section {
    box-shadow: none;
  }

  .option-wrapper {
    margin-bottom: 8px;
    padding-right: 8px;
    text-align: center;
    > div {
      display: inline-block;
      margin-right: 14px;
      &:nth-last-of-type(1) { margin-right: 0; border-right: none; }
      label { width: auto!important; }
    }
  }
  .content-box {
    padding-top: 15px;
    .p10 { padding: 0; }
  }
  .cow-labels {
    margin: 0!important;
    padding: 0!important;
    color: #000!important;
    background-color: transparent!important;
    &::after { content: "、"; display: inline; }
  }
  .cow-labels-wrapper > span > span:nth-last-of-type(1) .cow-labels::after { display: none; }
  .text-join {
    &:not(:last-child) {
      &::after {
        content: "、";
        display: inline;
      }
    }
  }
  table, th, td {
    border-collapse: collapse;
    border-spacing: 0;
  }
  table {
    width: auto!important;
    max-width: 100%;
    margin: 0 auto;
    border-color: #333;
  }
  th {
    padding: 3px!important;
    color: #000!important;
    background-color: #bbb!important;
  }
  td {
    padding: 2px 3px!important;
    text-align: left!important;
    span { text-align: left!important; }
    &.text-center {
      text-align: center !important;
    }
    &.text-right {
      text-align: right !important;
    }
  }
  .barn table.tb-col tr:first-child th:first-child { border-left-color: #333; }
  .barn table.tb-col tr:first-child th:last-child { border-right-color: #333; }
  th, td { border-top: 1px solid #333!important; border-right: 1px dotted #888; }
  th, table.tb-col tbody tr:nth-of-type(1) td{ border-top: 1px solid #333!important; }
  thead tr th:first-child,
  tbody tr td:nth-of-type(1) {
    border-left: 1px solid #333!important;
  }

  tr td:last-child { border-right: 1px solid #333; }
  tr:last-child td { border-bottom: 1px solid #333; }
  .w-maxwidth200 { max-width: 200px!important; }

  .logo,
  .main-header,
  .sub-header,
  .main-nav,
  .toolbar,
  .noPrint,
  .uHeader,
  .uHeader__trigger,
  .uHeading {
    display: none !important;
  }
  .wrapper .content {
    padding: 0 !important;
  }
  .hide-title {
    display: block !important;
    position: static !important;

    h2 {
      padding: 0 10px 5px;
      font-size: 12pt !important;
      line-height: 1.6 !important;
      border-bottom: 1px solid #333;
      letter-spacing: .08em;
      font-weight: bold;
      text-align: center;
    }
  }

  #customlist-table {
    margin-top: 0 !important;
    thead {
      visibility: visible !important;
    }
  }
  #customlist-table.clone {
    display: none !important;
  }

  .cowList {
    #table-area {
      height: auto !important;
    }
    .table-area-wrapper {
      &.cow-board-opened {
        width: 100% !important;
      }
    }

    .cow-board-wrapper {
      display: none !important;
    }

    .no-print {
      display: none !important;
    }
  }
}

_::-webkit-full-page-media, _:future, :root body.print table {
    width: 100%!important;
}

/**
  Items to hide when printing
*/
@media print {
  .logo,
  .main-header, // Main Header
  .sub-header, // Sub Header
  .main-nav, // Main Nav
  .toolbar, // Lists toolbar
  .noPrint,
  .uHeader,
  .uHeader__trigger,
  .uHeading {
    display: none !important;
  }
  .wrapper .content {
    padding: 0 !important;
  }
  .hide-title {
    display: block !important;
    position: static !important;

    h2 {
      padding: 0 10px 5px;
      font-size: 12pt !important;
      line-height: 1.6 !important;
      border-bottom: 1px solid #333;
      letter-spacing: .08em;
      font-weight: bold;
      text-align: center;
    }
  }
}

// 印刷レイアウト改善対応
body.print .modernPrint {
  * {
    margin: 0mm !important;
    padding: 0mm !important;
    font-size: 7pt !important;
  }

  &--inner {
    text-align: left;
  }

  @media screen {
    width: 100vw;
    height: 100vh;
    padding: 10px !important;
    background: #eee;

    &--inner {
      width: 297mm;
      height: 210mm;
      padding: 10mm !important;
      background: #fff;
      box-shadow: 0 0.5mm 2mm rgba(0, 0, 0, 0.3);
    }

    &--body {
      height: 175mm !important;
      overflow: hidden;
    }
  }

  &--head {
    display: flex !important;
    align-items: center;
    gap: 4.8mm;
    padding: 0 0 2mm !important;
    margin: 0 0 4mm !important;
    border-bottom: 0.5pt solid #000;
  }

  &--heading {
    font-size: 12pt !important;
    font-weight: bold;

    white-space: nowrap;
  }

  &--condition {
    display: flex !important;

    font-weight: normal;
    gap: 2.4mm;

    * {
      font-size: 9pt !important;
    }
  }

  &--body {
    width: auto;
    height: auto;
  }

  #customlist-table {
    margin: 0 !important;
    border: none;

    thead {
      th {
        border: none !important;
        text-align: left;
        padding: 1mm 1.6mm !important;

        &.long-text {
          min-width: 40mm !important;
          width: auto !important;
        }

        &.dummy {
          min-width: 40mm !important;
        }

        &.breeding_exclusion_reason {
          min-width: 9mm !important;
        }

        &.castrat_method {
          min-width: 12mm !important;
        }

        &.dehorn_method {
          min-width: 12mm !important;
        }

        &.expelled_reason {
          min-width: 9mm !important;
        }

        &.gender {
          min-width: 9mm !important;
        }

        &.latest_breeding_method {
          min-width: 9mm !important;
        }

        &.pregnancy {
          min-width: 9mm !important;
        }

        &.pregnant_breeding_method {
          min-width: 9mm !important;
        }

        &.state {
          min-width: 12mm !important;
        }

        &.death_reason {
          min-width: 9mm !important;
        }

        .sort-icon {
          display: none;
        }

        .customlist-th-inner {
          justify-content: flex-start;
        }
      }
    }

    tbody {
      &.disableEntryColumn {
        .entry-column {
          display: none;
        }
      }

      tr {
        &:nth-child(odd) {
          background-color: #f5f5f5 !important;
        }

        &:last-child {
          td {
            border-bottom: 1px solid #333 !important;
          }
        }
      }

      td {
        border: none !important;
        border-top: 1px solid #333 !important;
        padding: 0.8mm 1.6mm !important;
        vertical-align: top;

        &:before {
          display: block;
          float: left;
          height: 6.5mm;
          content: '';
        }

        .cowNo {
          font-weight: bold;
          color: #333 !important;
        }
      }

      .cow-labels-wrapper {
        &>span {
          margin: -0.25mm !important;

          &>span {
            background-color: #ddd !important;
            padding: 0.5mm !important;
            margin: 0.25mm !important;
          }
        }
      }

      .cow-labels {
        display: inline !important;

        &::after {
          content: '';
          display: none;
        }
      }
    }
  }
}
