&.cow-label-master {
  .show-all-label {
    font-size: 1.3rem;
  }
  .ui-grid-viewport {
    &:hover {
      cursor: default;
    }
    *:hover{
      background: transparent;
    }
  }
}
