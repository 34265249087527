/*---------------------------------
　モーダル
---------------------------------*/

/*
　キーフレームの定義
---------------------------------*/
@keyframes animationOpacity {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

/*
　@extend用のプレースホルダーセレクタ
---------------------------------*/
%uModal {
  line-height: 1.4;
  position: fixed;
  z-index: 10005000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  overflow-x: scroll;
  overflow-y: auto;
  box-sizing: border-box;
  width: 100%;
  animation: animationOpacity .5s ease forwards;
  word-break: break-all;
  opacity: 0;
  outline: 0;
  background: rgba(31, 31, 31, .9);

  @media (min-width: 600px) {
    overflow-x: hidden;
  }

  @media (min-height: 600px) {
    overflow-y: hidden;
  }

  @media print {
    display: none;
  }
}

%uModal__formLabel {
  font-size: 14px;
  font-weight: 700;
  line-height: 1.5;
  position: relative;
  display: flex;
  width: 152px;
  padding: 42px 24px 0 24px;
  color: black(secondary);
  background: white(primary);

  flex-shrink: 0;
}

%uModal__formFieldMessage {
  font-size: 13px;
  font-weight: 700;
  position: absolute;
  z-index: 1;
  bottom: -18px;
  left: 0;
  display: block;
  height: 18px;
  padding: 0 4px;
  transform: translate3d(0, -1px, 0);
  animation: animationOpacity .5s ease forwards;
  white-space: nowrap;
  opacity: 0;
  color: white(primary);

  justify-content: center;
  align-items: center;
}

%uModal__formText {
  position: relative;

  &:not(:first-child) {
    margin: 0 0 0 8px;
  }

  &:first-child {
    margin: 0;
  }
}

%uModal__formTextPrepend {
  font-size: 14px;
  font-weight: 400;
  position: absolute;
  top: 0;
  display: flex;
  width: 40px;
  height: 40px;
  pointer-events: none;
  color: black(tertiary);

  justify-content: center;
  align-items: center;
}

%uModal__formAutocomplete {
  @extend %uModal__formText;

  &::after {
    position: absolute;
    top: 16px;
    right: 14px;
    display: block;
    width: 9px;
    height: 9px;
    content: '';
    pointer-events: none;
    border-top: 7px solid #0d52a2;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
  }
}

%uModal__formAutoinput {
  position: relative;

  &:not(:first-child) {
    margin: 0 0 0 8px;
  }

  &:first-child {
    margin: 0;
  }
}

%uModal__formSelect {
  position: relative;

  &:not(:first-child) {
    margin: 0 0 0 8px;
  }

  &:first-child {
    margin: 0;
  }

  &::after {
    position: absolute;
    top: 16px;
    right: 14px;
    display: block;
    width: 9px;
    height: 9px;
    content: '';
    pointer-events: none;
    border-top: 7px solid #0d52a2;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
  }
}

%uModal__formFieldFocus {
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  transition: all .2s ease;
  pointer-events: none;
  border-bottom: 2px solid #2a66b7;
}

%uModal__historyItemInner {
  position: relative;
  padding: 16px 12px 12px;
  border-radius: 4px;
  background: #eee;
}

/*
　モーダル
---------------------------------*/
.uModal {
  @extend %uModal;

  /*
  　モーダル - 通常サイズ
  ---------------------------------*/
  .uModal {
    &__inner {
      width: 600px;
      margin: 4vh auto;
    }

    &__form {
      &Grid {
        &Scroll {
          width: 567px;

          @media (min-height: 600px) {
            max-height: calc(92vh - 323px);
          }
        }

        &Items,
        &Items--2col {
          width: 550px;
        }
      }

      &Uigrid {
        &Body {
          @media (min-height: 600px) {
            height: calc(92vh - 269px) !important;
          }

          .ui-grid {
            @media (min-height: 600px) {
              height: calc(92vh - 268px) !important;
            }

            &-viewport {
              @media (min-height: 600px) {
                height: calc(92vh - 319px) !important
              }
            }
          }
        }
      }
    }
  }

  /*
  　モーダル - 小さいサイズ
  ---------------------------------*/
  &--small {
    @extend %uModal;

    .uModal {
      &__inner {
        width: 464px;
        margin: 4vh auto;
      }

      &__form {
        &Grid {
          &Scroll {
            width: 431px;

            @media (min-height: 600px) {
              max-height: calc(92vh - 323px);
            }
          }

          &Items,
          &Items--2col {
            width: 414px;
          }
        }
      }
    }
  }

  /*
  　モーダル - サブモーダル（モーダルに重ねて表示）
  ---------------------------------*/
  &--sub {
    @extend %uModal;

    .uModal {
      &__inner {
        width: 96vw;
        min-width: 600px;
        margin: 7vh auto;

        @media (min-width: 960px) {
          width: 80vw;
        }

        @media (min-width: 1280px) {
          width: 1024px;
        }
      }

      &__form {
        &Grid {
          &Scroll {
            width: calc(96vw - 33px);
            min-width: 567px;

            @media (min-width: 960px) {
              width: calc(80vw - 33px);
            }

            @media (min-width: 1280px) {
              width: 991px;
            }

            @media (min-height: 600px) {
              max-height: calc(86vh - 323px);
            }
          }

          &Items,
          &Items--2col {
            width: calc(96vw - 50px);
            min-width: 550px;

            @media (min-width: 960px) {
              width: calc(80vw - 50px);
            }

            @media (min-width: 1280px) {
              width: 974px;
            }
          }
        }
      }
    }
  }

  /*
  　モーダル - 横幅730px
  ---------------------------------*/
  &--width730 {
    @extend %uModal;

    .uModal {
      &__inner {
        width: 730px;
        margin: 4vh auto;
      }

      &__form {
        &Grid {
          &Scroll {
            width: 697px;

            @media (min-height: 600px) {
              max-height: calc(92vh - 323px);
            }
          }

          &Items,
          &Items--2col {
            width: 680px;
          }
        }
      }
    }
  }

  /*
  　モーダル - 横幅768px
  ---------------------------------*/
  &--width768 {
    @extend %uModal;

    .uModal {
      &__inner {
        width: 768px;
        margin: 4vh auto;
      }
    }
  }

  /*
  　モーダル - 詳細検索
  ---------------------------------*/
  &--condition {
    @extend %uModal;

    .uModal {
      &__inner {
        width: 623px;
        margin: 4vh auto;
      }
    }
  }

  /*
  　モーダル - ヘルプ
  ---------------------------------*/
  &--help {
    @extend %uModal;

    .uModal {
      &__inner {
        width: 472px;
        margin: 4vh auto;
      }
    }
  }

  /*
  　モーダル - 絞り込み表示
  ---------------------------------*/
  &--filter {
    display: flex;
    overflow-y: auto;

    @extend %uModal;
    align-items: center;

    .uModal {
      &__inner {
        width: 600px;
        width: 100%;
        max-width: 685px;
        max-height: 100vh;
        margin: 4vh auto;
        border-radius: 8px;
        background: white(primary);

        @media (pointer: coarse) {
          max-height: -webkit-fill-available;
        }
      }

      &__close {
        z-index: 1;

        &::before,
        &::after {
          top: 20px;
          left: 50%;
        }

        &::before {
          transform: translateX(-50%) rotate(45deg);
        }

        &::after {
          transform: translateX(-50%) rotate(-45deg);
        }

        &:hover {
          opacity: 1;

          .uModal__closeLabel::before {
            background: #444;
          }
        }

        &Label {
          font-size: 13px;
          display: none;
          margin: 7px 0 0;

          &::before {
            position: absolute;
            z-index: -1;
            top: 0;
            left: 50%;
            width: 40px;
            height: 40px;
            margin: 0 auto;
            content: "";
            transition: all .2s ease;
            transform: translateX(-50%);
            border-radius: 50%;
          }
        }
      }

      &__table {
        &Row {
          position: relative;
          background: none !important;

          _:-ms-input-placeholder,
          :root & {
            display: flex;
          }
        }

        &Header,
        &Data {
          font-size: inherit !important;
          position: relative;
          min-height: auto !important;
          padding: 5px 8px !important;
          text-align: left !important;
          color: inherit !important;
          border: 0 !important;
          background: none !important;

          _:-ms-input-placeholder,
          :root & {
            position: relative;
            display: flex;

            align-items: center;
          }
        }

        &Header {
          z-index: 1;

          &::before {
            position: absolute;
            z-index: 1;
            top: 0;
            right: 0;
            width: 1px;
            height: 100%;
            content: "";
            background: white(primary);
          }

          .uModal__formToggle::before {
            border-radius: 8px 0 0 8px;
          }

          .uModal__formToggleBody+.uModal__formToggleButton {
            min-width: 104px;
            border: 2px solid white(primary);
            background: white(primary);
          }

          .uModal__formToggleBody:checked+.uModal__formToggleButton {
            border-color: #0d52a2;
          }

          .uModal__formToggleLabel:hover .uModal__formToggleBody:checked+.uModal__formToggleButton {
            border: 2px solid #0d52a2;
            background: white(primary);
          }

          _:-ms-input-placeholder,
          :root & {
            flex: 0 0 auto;
          }
        }

        &Data {
          &:not(:first-child) {
            .uModal__formToggle::before {
              border-radius: 0 8px 8px 0;
            }
          }

          _:-ms-input-placeholder,
          :root & {
            width: 100%;
          }
        }

        &Foot {
          .uModal__formToggle {
            margin-right: auto;
            margin-left: auto;

            justify-content: center;
          }

          .uModal__formToggleButton {
            min-width: 81px;
            padding: 0 12px;
            background: white(primary);

            &::before {
              display: none;
            }
          }
        }
      }
    }

    .uButton,
    .uButton--cancel {
      margin-left: 24px;
      border-radius: 10vw;
    }

    .uModal__body {
      border-radius: 0 0 8px 8px;
      background: white(primary);
    }

    .uModal__close {
      width: 40px;
      height: 40px;
      border-radius: 50%;

      &::before,
      &::after {
        border-top-color: #212121;
      }

      &:hover {
        background: #eee;

        &::before,
        &::after {
          border-top-color: rgba(33, 33, 33, 0.62);
        }
      }
    }

    .uModal__form {
      padding: 0 16px 36px;
    }

    .uModal__head {
      padding-right: 16px;
    }

    .uModal__heading {
      font-size: 14px;
      font-weight: 500;
      color: inherit;
    }

    .uModal__formToggle {
      position: static;
      margin: 0;
      padding: 6px 0;
      border-radius: 8px;
      background: #e6e6e6;

      &::before {
        position: absolute;
        top: 4px;
        bottom: 4px;
        left: 0;
        width: 100%;
        content: "";
        border-radius: 8px;
        background: #eee;
      }

      _:-ms-input-placeholder,
      :root & {
        max-width: 509px;
      }
    }

    .uModal__formToggleButton {
      font-size: 13px;
      white-space: nowrap;

      justify-content: flex-start;
    }

    .uModal__formToggleLabel {
      margin: 4px !important;
    }
  }

  /*
  　モーダル - 頭数詳細表示
  ---------------------------------*/
  &--numberOfCow {
    @extend %uModal;

    .uModal {
      &__inner {
        position: relative;
        width: 100%;
        max-width: 888px;
        height: 100%;
        margin: auto;
        background: #242424;
        box-shadow: 0px 3px 30px rgba(0, 0, 0, 0.5);

        &::before,
        &:after {
          position: absolute;
          z-index: 1;
          left: 0;
          width: calc(100% - 20px);
          height: 40px;
          content: "";
        }

        &::before {
          top: 72px;
          height: 32px;
          background: linear-gradient(0deg, rgba(36, 36, 36, 0) 0%, rgba(36, 36, 36, 0.8) 100%);
        }

        &::after {
          bottom: 0;
          background: linear-gradient(180deg, rgba(36, 36, 36, 0) 0%, rgba(36, 36, 36, 0.8) 100%);
        }
      }

      &__body {
        position: absolute;
        top: 72px;
        bottom: 0;
        left: 0;
        overflow-y: scroll;
        width: calc(100% - 8px);
        margin: 0 8px 0 0;
        padding: 32px 23px calc(env(safe-area-inset-bottom) + 40px) 40px;
        color: white(primary);

        scrollbar-width: thin;

        &::-webkit-scrollbar {
          position: relative;
          width: 8px;
        }

        &::-webkit-scrollbar-button {
          display: none;
        }

        &::-webkit-scrollbar-track {
          border-radius: 4px;
          background: #242424;

          &:hover {
            background: #444;

            &::-webkit-scrollbar-thumb {
              background: none;
            }
          }
        }

        &::-webkit-scrollbar-thumb {
          height: 17px;
          border-radius: 4px;
          background: #b2b2b2;

          &:hover {
            background: #ccc;
          }
        }
      }

      &__column {
        width: calc(1 / 3 * 100%);
        padding: 0 16px;

        .uRightBoard__card {
          margin-bottom: 32px !important;
          background-color: #1f1f1f;
        }

        .uRightBoard__cardTableRelation {
          background-color: #2a2a2a;
        }
      }

      &__head {
        height: 72px;
        padding-right: 32px;
        padding-left: 40px;
        border-bottom: 1px solid #000;
      }

      &__heading {
        font-size: 21px;
      }

      &__row {
        display: flex;
        margin: 0 -16px;

        flex-wrap: wrap;
      }
    }

    @media screen and (max-width: 1023px) {
      .uModal__column {
        width: 50%;
      }

      .uModal__inner {
        max-width: 608px;
      }
    }
  }

  /*
  　モーダル - 作業日報タイムライン表示
  ---------------------------------*/
  &--timeline {
    @extend %uModal;

    .uModal__close {
      z-index: 1;

      &::before,
      &::after {
        top: 20px;
        left: 50%;
      }

      &::before {
        transform: translateX(-50%) rotate(45deg);
      }

      &::after {
        transform: translateX(-50%) rotate(-45deg);
      }

      &:hover {
        opacity: 1;

        .uModal__closeLabel::before {
          background: #444;
        }
      }
    }

    .uModal__closeLabel {
      font-size: 13px;
      margin: 7px 0 0;

      &::before {
        position: absolute;
        z-index: -1;
        top: 0;
        left: 50%;
        width: 40px;
        height: 40px;
        margin: 0 auto;
        content: "";
        transition: all .2s ease;
        transform: translateX(-50%);
        border-radius: 50%;
      }
    }

    .uModal__heading {
      font-size: 21px;
    }

    .uModal__filter {
      display: flex;
      margin: 0 0 13px;
      padding: 1px 16px 1px 0;
      border: 1px solid rgba(57, 57, 57, 0.48);
      border-radius: 16px;
      background: linear-gradient(90deg, #2c2c2c 0%, #2e2e2e 100%);
      box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.32);

      align-items: center;

      .uWidget__date {
        margin: 0 auto 0 0 !important;
        border: 0;

        &-next,
        &-prev {
          width: 40px;
          height: 40px;
        }
      }
    }

    .uModal__filterButton {
      display: inline-flex;
      width: 40px;
      height: 40px;
      color: white(primary);
      border-radius: 50%;
      background: none;

      align-items: center;
      justify-content: center;

      &:hover {
        background-color: #444;
      }

      &:active {
        background-color: #707070;
      }
    }

    .uModal__inner {
      width: 100%;
      max-width: 496px;
      margin: 4vh auto;
    }

    .uModal__history {
      overflow: visible;
      width: 100%;
      max-width: 496px;
      padding: 24px 6px;
    }

    .uModal__historyInner {
      overflow-y: scroll;
      max-height: calc(92vh - 171px);

      scrollbar-width: thin;

      &::-webkit-scrollbar {
        position: relative;
        width: 8px;
      }

      &::-webkit-scrollbar-button {
        display: none;
      }

      &::-webkit-scrollbar-track {
        border-radius: 4px;
        background: #3b3b3b;

        &:hover {
          background: #444;

          &::-webkit-scrollbar-thumb {
            background: none;
          }
        }
      }

      &::-webkit-scrollbar-thumb {
        height: 17px;
        border: 1px solid #585858;
        border-radius: 4px;
        background: #444;

        &:hover {
          background: #ccc;
        }
      }
    }

    .uModal__historyMain {
      padding-top: 24px;
      padding-right: 32px;
      padding-left: 24px;
    }

    .uWidget--dailyReport__timeline {
      &Head {

        _:-ms-input-placeholder,
        :root & {
          max-width: 367px;
        }
      }

      &Item {
        margin-left: 51px !important;
      }
    }
  }

  /*
  　ヘッダー
  ---------------------------------*/
  &__head {
    display: flex;
    height: 64px;
    padding: 0 12px 0 24px;

    align-items: center;
    justify-content: flex-end;

    &--single {
      display: flex;
      height: 64px;
      padding: 0;

      align-items: center;
    }

    &--tabletHeight90 {
      display: flex;
      height: 64px;
      padding: 0 12px 0 24px;

      align-items: center;
      justify-content: flex-end;

      @media (pointer: coarse) {
        height: 90px;
      }
    }

    &Info {
      margin: 0 0 0 32px;
      color: white(primary);

      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: 100%;

      &Heading {
        font-size: 13px;
        font-weight: 700;
      }

      &Text {
        font-size: 15px;
        font-weight: 700;
        display: flex;

        align-items: center;

        &--ellipsis {
          font-size: 15px;
          font-weight: 700;
          overflow: hidden;
          max-width: 240px;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        &--secondary {
          font-size: 14px;
          font-weight: 700;
        }
      }
    }
  }

  &__heading {
    font-size: 21px;
    font-weight: 700;
    color: white(primary);

    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 100%;

    &--secondary {
      font-size: 21px;
      font-weight: 700;
      color: white(primary);

      flex-shrink: 0;
    }
  }

  &__subHeading--tablet {
    display: none;

    @media (pointer: coarse) {
      font-size: 17px;
      display: block;
      margin: 4px 0 0;
    }
  }

  &__close {
    font-size: 13px;
    font-weight: 400;
    position: relative;
    display: flex;
    width: 48px;
    height: 48px;
    margin: 0 0 0 24px;
    padding: 27px 0 0;
    cursor: pointer;
    transition: all .2s ease;
    color: white(primary);

    justify-content: center;
    flex-shrink: 0;

    &:hover {
      opacity: 0.72;
    }

    &::before {
      position: absolute;
      top: 12px;
      left: 10px;
      display: block;
      width: 26px;
      content: '';
      transform: rotate(45deg);
      border-top: 2px solid white(primary);
    }

    &::after {
      position: absolute;
      top: 12px;
      left: 10px;
      display: block;
      width: 26px;
      content: '';
      transform: rotate(-45deg);
      border-top: 2px solid white(primary);
    }
  }

  /*
  　追加ボタン
  ---------------------------------*/
  &__add {
    font-size: 13px;
    font-weight: 400;
    position: relative;
    display: block;
    width: 40px;
    height: 40px;
    margin: 0 0 0 24px;
    cursor: pointer;
    transition: all .2s ease;
    border: 1px solid white(primary);
    border-radius: 50%;
    box-shadow: 0 1px 2px rgba(0, 0, 0, .16);

    flex-shrink: 0;

    &:hover {
      box-shadow: 0 3px 6px rgba(0, 0, 0, .16);

      @media (pointer: coarse) {
        box-shadow: 0 1px 2px rgba(0, 0, 0, .16);
      }
    }

    &::before {
      position: absolute;
      top: calc(50% - 1px);
      left: calc(50% - 8px);
      display: block;
      width: 16px;
      height: 2px;
      content: '';
      border-top: 2px solid black(primary);
    }

    &::after {
      position: absolute;
      top: calc(50% - 1px);
      left: calc(50% - 8px);
      display: block;
      width: 16px;
      height: 2px;
      content: '';
      transform: rotate(90deg);
      border-top: 2px solid black(primary);
    }
  }

  /*
  　ボタン
  ---------------------------------*/
  &__button {
    font-size: 13px !important;
    display: flex;
    height: 40px;
    padding: 0 15px;
    transition: all .2s ease;
    white-space: nowrap;
    text-decoration: none !important;
    color: black(primary) !important;
    border: 1px solid #ddd;
    border-radius: 40px;
    background: white(primary);

    align-items: center;
    justify-content: center;

    &:hover {
      border: 1px solid white(primary);
      box-shadow: 0px 3px 6px rgba(0, 0, 0, .16);
    }
  }

  /*
  　検索ボックス
  ---------------------------------*/
  &__search {
    position: relative;
    width: 320px;

    flex-shrink: 0;

    &Body {
      font-size: 15px !important;
      font-weight: 400 !important;
      line-height: 40px !important;
      display: block !important;
      width: 100%;
      height: 40px !important;
      padding: 0 16px !important;
      transition: all .2s ease !important;
      letter-spacing: -1px;
      color: black(primary) !important;
      border: 1px solid #ddd !important;
      border-radius: 20px !important;
      outline: 0 !important;
      background: #f2f2f2 !important;
      box-shadow: none !important;

      appearance: none;
      -moz-appearance: none;
      -webkit-appearance: none;

      &:hover,
      &:focus {
        border: 1px solid #2A6BB7 !important;
        background: #f2f6fc !important;
      }

      &::placeholder {
        color: black(quaternary) !important;
      }

      &:-ms-input-placeholder {
        color: black(quaternary) !important;
      }

      &::-ms-input-placeholder {
        color: black(quaternary) !important;
      }

      &::-webkit-input-placeholder {
        color: black(quaternary) !important;
      }

      &::-ms-clear {
        display: none;
      }
    }

    .icon-s-search {
      position: absolute;
      top: 0;
      right: 14px;
      height: 40px;
    }
  }

  /*
  　本体
  ---------------------------------*/
  &__body {
    overflow: hidden;
    border-radius: 2px;

    &--radius4 {
      overflow: hidden;
      border-radius: 4px;
    }

    &--radius8 {
      overflow: hidden;
      border-radius: 8px;
    }
  }

  /*
  　エラーメッセージ
  ---------------------------------*/
  &__message {
    font-size: 15px;
    line-height: 1.7;
    padding: 10px 12px;
    color: red(primary);
    border: 2px solid red(primary);
    background: #faecee;

    &~.uModal {
      &__text {
        margin-top: 21px;
      }

      &__form {
        &Grid {
          &Inner {
            margin-top: 16px;
          }
        }
      }
    }
  }

  /*
  　タイトル
  ---------------------------------*/
  &__title {
    font-size: 17px;
    font-weight: 700;
    line-height: 1.4;
    margin: 0 0 18px;
    color: black(primary);
  }

  /*
  　テキスト
  ---------------------------------*/
  &__text {
    font-size: 15px;
    font-weight: 700;
    color: black(primary);

    &Emphasis {
      font-size: 15px;
      font-weight: 700;
      color: red(primary);
    }

    &--secondary {
      font-size: 14px;
      line-height: 1.75;
      color: black(primary);
    }
  }

  /*
  　ボックス
  ---------------------------------*/
  &__box {
    margin: 0 -8px;
    padding: 20px 24px;
    border-radius: 8px;
    background: #f0f0f0;

    &:not(:first-child) {
      margin-top: 27px;
    }

    &Buttons {
      margin: 0 -8px;

      &:not(:first-child) {
        margin: 19px -8px 0;
      }
    }

    .uButton,
    [class^="uButton--"] {
      width: 100%;

      &:not(:first-child) {
        margin: 12px 0 0;
      }
    }
  }

  /*
  　リスト
  ---------------------------------*/
  &__list {
    margin: 28px 0 0;
    padding: 24px;
    background: #f2f2f2;

    &Items {}

    &Item {
      display: flex;

      &:not(:first-child) {
        margin: 17px 0 0;
      }
    }

    &Heading {
      font-size: 14px;
      font-weight: 700;
      width: 70px;
      color: black(secondary);

      flex-shrink: 0;
    }

    &Text {
      font-size: 15px;
      font-weight: 400;
      padding: 0 0 0 26px;
      color: black(primary);
    }
  }

  /*
  　フォーム
  ---------------------------------*/
  &__form {
    &Confirm {
      padding: 31px 24px 47px;
      background: white(primary);
    }

    &Help {
      padding: 31px 24px 6px;
      background: white(primary);
    }

    /*
    　フォーム - グリッド
    ---------------------------------*/
    &Grid {
      padding: 37px 24px;
      background: white(primary);

      &--search {
        padding: 20px 24px;
        background: white(primary);

        .uModal__formGridBody {
          margin-top: 20px;
        }

        .uModal__formGridTitle:first-child {
          width: 52px;
          min-width: 52px;
        }

        .uModal__formGridCell:first-child {
          width: 52px;
          min-width: 52px;
        }
      }

      &--nohead {
        padding: 30px 24px 48px;
        background: white(primary);
      }

      &Head {
        display: flex;

        align-items: center;
        justify-content: flex-end;

        &+.uModal {
          &__message {
            margin-top: 16px;
          }

          &__form {
            &Grid {
              &Inner {
                margin: 16px 0 0;
              }
            }
          }
        }
      }

      &Heading {
        font-size: 15px;
        font-weight: 700;
        color: black(primary);

        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: 100%;
      }

      &Inner {
        position: relative;
      }

      &Body {
        position: relative;
        border: 1px solid #eee;

        &::after {
          @media (min-height: 600px) {
            position: absolute;
            bottom: 0;
            left: 0;
            display: block;
            width: 100%;
            height: 20px;
            content: '';
            pointer-events: none;
            background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, .8));
          }
        }
      }

      &Titles {
        display: flex;
        width: 100%;
        border-bottom: 1px solid white(primary);

        align-items: center;
      }

      &Title {
        font-size: 13px;
        font-weight: 700;
        display: flex;
        height: 48px;
        padding: 10px;
        text-align: left;
        color: black(primary);
        background: #f2f2f2;

        align-items: center;

        &:first-child {
          width: 60px;
          min-width: 60px;
        }

        &:not(:first-child) {
          width: 100%;
        }

        &:only-child {
          font-size: 13px;
          font-weight: 700;
          display: flex;
          width: 100%;
          height: 48px;
          padding: 0 15px;
          text-align: left;
          color: black(primary);
          background: #f2f2f2;

          align-items: center;
        }

        &--full {
          font-size: 13px;
          font-weight: 700;
          display: flex;
          width: 100%;
          height: 48px;
          padding: 10px;
          text-align: left;
          color: black(primary);
          background: #f2f2f2;

          align-items: center;
        }

        &--grow {
          font-size: 13px;
          font-weight: 700;
          display: flex;
          height: 48px;
          padding: 10px 24px 10px 0;
          text-align: left;
          color: black(primary);
          background: #f2f2f2;

          align-items: center;
          flex-grow: 1;
        }

        &--width76 {
          font-size: 13px;
          font-weight: 700;
          display: flex;
          width: 76px;
          height: 48px;
          padding: 10px 15px;
          text-align: left;
          color: black(primary);
          background: #f2f2f2;

          align-items: center;
          flex-shrink: 0;
        }

        &--width92 {
          font-size: 13px;
          font-weight: 700;
          display: flex;
          width: 92px;
          height: 48px;
          padding: 10px 15px;
          text-align: left;
          color: black(primary);
          background: #f2f2f2;

          align-items: center;
          flex-shrink: 0;
        }

        &--width105 {
          font-size: 13px;
          font-weight: 700;
          display: flex;
          width: 105px;
          height: 48px;
          padding: 10px 10px 10px 0;
          text-align: left;
          color: black(primary);
          background: #f2f2f2;

          align-items: center;
          flex-shrink: 0;
        }

        &--width108 {
          font-size: 13px;
          font-weight: 700;
          display: flex;
          width: 108px;
          height: 48px;
          padding: 10px;
          text-align: left;
          color: black(primary);
          background: #f2f2f2;

          align-items: center;
          flex-shrink: 0;
        }

        &--width136 {
          font-size: 13px;
          font-weight: 700;
          display: flex;
          width: 136px;
          height: 48px;
          padding: 10px;
          text-align: left;
          color: black(primary);
          background: #f2f2f2;

          align-items: center;
          flex-shrink: 0;
        }

        &--width176 {
          font-size: 13px;
          font-weight: 700;
          display: flex;
          width: 176px;
          height: 48px;
          padding: 10px;
          text-align: left;
          color: black(primary);
          background: #f2f2f2;

          align-items: center;
          flex-shrink: 0;
        }
      }

      &Scroll {
        overflow-x: hidden;
        overflow-y: hidden;

        @media (min-height: 600px) {
          overflow-y: auto;
        }
      }

      &Items {
        width: 592px;

        &::after {
          @media (min-height: 600px) {
            display: block;
            width: 100%;
            height: 20px;
            content: '';
          }
        }

        &--2col {
          font-size: 0;
          overflow-y: hidden;
          width: 592px;
          margin-bottom: -1px;

          .uModal {
            &__form {
              &Grid {

                &Item,
                &Item--selected {
                  display: inline-flex;
                  width: 50%;

                  &:nth-child(2n+1) {
                    border-right: 1px solid #eee;
                  }
                }

                &Cell {
                  overflow: hidden;
                  padding-left: 0 !important;

                  &:first-child {
                    width: 53px;
                    min-width: 53px;
                    padding: 0 !important;
                  }
                }
              }
            }
          }

          &::after {
            @media (min-height: 600px) {
              display: block;
              width: 100%;
              height: 21px;
              margin: -1px 0 0;
              content: '';
              border-top: 1px solid #eee;
            }
          }
        }

        .uModal {
          &__form {
            &Field {
              &Message {
                line-height: 1.3;
                position: relative;
                z-index: 11;
                bottom: 0;
                height: auto;
                margin: 1px 0 0 0;
                padding: 1px 8px;
                white-space: normal;
              }
            }
          }
        }
      }

      &Item {
        display: flex;

        align-items: center;

        &:not(:last-child) {
          border-bottom: 1px solid #eee;
        }

        &:last-child {
          @media (min-height: 600px) {
            border-bottom: 1px solid #eee;
          }
        }

        &:hover {
          font-weight: 700;
          background: #f2f2f2;

          @media (pointer: coarse) {
            font-weight: 400;
            background: none;
          }
        }

        &--selected {
          font-weight: 700;
          display: flex;
          background: #f2f6fc;

          align-items: center;

          &:not(:last-child) {
            border-bottom: 1px solid #eee;
          }

          &:last-child {
            @media (min-height: 600px) {
              border-bottom: 1px solid #eee;
            }
          }

          &:hover {
            background: #f2f6fc;

            @media (pointer: coarse) {
              background: #f2f6fc;
            }
          }
        }

        &--disableHover {
          display: flex;

          &:not(:last-child) {
            border-bottom: 1px solid #eee;
          }

          &:last-child {
            @media (min-height: 600px) {
              border-bottom: 1px solid #eee;
            }
          }

          .uModal {
            &__form {
              &Grid {
                &Cell {
                  cursor: default;

                  &:first-child {
                    padding: 10px;
                  }
                }
              }
            }
          }

          & [class^="uModal__formGridCell"] {
            cursor: default;
          }
        }
      }

      &Cell {
        font-size: 14px;
        display: flex;
        cursor: pointer;
        transition: background .2s ease;
        color: black(primary);

        align-items: center;

        &:first-child {
          width: 60px;
          min-width: 60px;
        }

        &:not(:first-child) {
          font-size: 14px;
          width: 100%;
          min-height: 40px;
          padding: 10px;
          transition: background .2s ease;
          color: black(primary);
        }

        &--full {
          font-size: 14px;
          display: flex;
          width: 100%;
          padding: 10px;
          cursor: pointer;
          transition: background .2s ease;
          color: black(primary);

          align-items: center;
        }

        &--grow {
          font-size: 14px;
          display: flex;
          padding: 10px 24px 10px 0;
          cursor: pointer;
          transition: background .2s ease;
          color: black(primary);

          align-items: center;
          flex-grow: 1;
        }

        &--width76 {
          font-size: 14px;
          display: flex;
          width: 76px;
          padding: 10px 15px;
          transition: background .2s ease;
          color: black(primary);

          align-items: center;
          flex-shrink: 0;
        }

        &--width92 {
          font-size: 14px;
          display: flex;
          width: 92px;
          padding: 10px 15px;
          transition: background .2s ease;
          color: black(primary);

          align-items: center;
          flex-shrink: 0;
        }

        &--width105 {
          font-size: 14px;
          display: flex;
          width: 105px;
          padding: 10px 10px 10px 0;
          cursor: pointer;
          transition: background .2s ease;
          color: black(primary);

          align-items: center;
          flex-shrink: 0;
        }

        &--width108 {
          font-size: 14px;
          display: flex;
          width: 108px;
          padding: 10px;
          transition: background .2s ease;
          color: black(primary);

          align-items: center;
          flex-shrink: 0;
        }

        &--width136 {
          font-size: 14px;
          display: flex;
          width: 136px;
          padding: 10px;
          transition: background .2s ease;
          color: black(primary);

          align-items: center;
          flex-shrink: 0;
        }

        &--width176 {
          font-size: 14px;
          display: flex;
          width: 176px;
          padding: 10px;
          transition: background .2s ease;
          color: black(primary);

          align-items: center;
          flex-shrink: 0;
        }

        &Ellipsis {
          font-size: 14px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        &Vertical {
          &Top {
            flex-grow: 1;
            flex-shrink: 1;
            flex-basis: 100%;
            align-self: flex-start;
          }
        }

        &SensorNumber {
          display: flex;

          &Text {
            font-size: 14px;
          }

          &Separate {
            font-size: 14px;
            width: 34px;
            text-align: center;
            color: rgba(33, 33, 33, .33);
          }
        }
      }

      &Radio {
        position: relative;
        display: flex;
        width: 100%;

        flex-wrap: wrap;

        &Label {
          display: flex !important;
          width: 40px !important;
          height: 40px !important;
          margin: auto !important;
          cursor: pointer;

          justify-content: flex-start;
          align-items: center;
        }

        &Body {
          display: none;

          &:checked {
            &+.uModal {
              &__form {
                &Grid {
                  &Radio {
                    &Button {
                      &::before {
                        border: 1px solid #2a66b7;
                        background: rgba(255, 255, 255, .5);
                      }

                      &::after {
                        opacity: 1;
                      }
                    }
                  }
                }
              }
            }
          }
        }

        &Button {
          position: relative;
          display: block;
          width: 36px;
          height: 36px;
          transition: background .2s ease;
          border-radius: 50%;
          background: none;

          &::before {
            position: absolute;
            top: calc(50% - 10px);
            left: calc(50% - 10px);
            display: block;
            width: 20px;
            height: 20px;
            content: '';
            transition: background .2s ease, border .2s ease;
            border: 1px solid #ccc;
            border-radius: 50%;
            background: #f2f2f2;
          }

          &::after {
            position: absolute;
            top: calc(50% - 5px);
            left: calc(50% - 5px);
            display: block;
            width: 10px;
            height: 10px;
            content: '';
            transition: opacity .2s ease;
            opacity: 0;
            border-radius: 50%;
            background: #2a66b7;
          }
        }
      }
    }

    /*
    　フォーム - ui-grid
    ---------------------------------*/
    &Uigrid {
      padding: 35px 24px 44px;
      background: white(primary);

      &Heading {
        font-size: 15px;
        font-weight: 700;
        color: black(primary);
      }

      &Inner {
        display: flex;
        margin: 24px 0 0;
      }

      &Body {
        position: relative;

        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: 100%;

        &::after {
          @media (min-height: 600px) {
            position: absolute;
            bottom: 0;
            left: 1px;
            display: block;
            width: calc(100% - 19px);
            height: 20px;
            content: '';
            pointer-events: none;
            background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, .8));
          }
        }

        .ui-grid {
          box-sizing: border-box;
          width: 100%;
          height: 100% !important;
          border: 1px solid #eee;

          &-header {
            border-bottom: 1px solid white(primary) !important;

            &-viewport {
              width: 100% !important;
            }

            &-canvas {
              width: 100% !important;
            }

            &-cell {
              font-size: 13px;
              font-weight: 700;
              display: flex !important;
              width: 100% !important;
              min-width: auto !important;
              max-width: 100% !important;
              height: 48px;
              padding: 0 15px;
              color: black(primary);
              border: none !important;

              align-items: center;

              * {
                padding: 0 !important;
              }

              &-row {
                width: 100%;
                background: #f2f2f2 !important;
              }
            }
          }

          &-viewport {
            overflow-x: hidden !important;
            overflow-y: visible !important;
            width: 100% !important;
            height: 100% !important;
            cursor: default !important;

            &::-webkit-scrollbar {
              width: 18px;
            }

            &::-webkit-scrollbar-button {
              display: none;
            }

            &::-webkit-scrollbar-track {
              background: #fafafa;
            }

            &::-webkit-scrollbar-thumb {
              height: 100px;
              border: 5px solid #fafafa;
              border-radius: 10px;
              background: #c8c9c9;

              &:hover {
                background: #858585;
              }
            }

            @media (min-height: 600px) {
              overflow-y: scroll !important;
            }
          }

          &-canvas {
            position: relative;
            width: 100% !important;
            height: 100% !important;
          }

          &-cell {
            position: relative;
            display: block;
            width: 100% !important;
            min-width: auto !important;
            max-width: 100% !important;
            transition: background .2s ease !important;
            border: none !important;
            background: none !important;

            &::before {
              position: absolute;
              z-index: 2;
              top: calc(50% - 10px);
              left: 16px;
              display: block;
              width: 20px;
              height: 20px;
              content: '';
              border: 1px solid #ccc;
              border-radius: 50%;
              background: #f2f2f2;
            }

            &:hover {
              font-weight: 700 !important;
              background: #f2f2f2 !important;

              @media (pointer: coarse) {
                font-weight: 400 !important;
                background: none !important;
              }

              &::before {
                background: rgba(242, 242, 242, .5);

                @media (pointer: coarse) {
                  background: #f2f2f2;
                }
              }
            }

            &-contents {
              font-size: 14px;
              font-weight: 400;
              position: relative;
              display: block;
              overflow: visible !important;
              padding: 10px 10px 10px 52px !important;
              cursor: pointer;
              white-space: normal;
              word-break: break-word;
              color: black(primary);

              align-items: center;

              &::after {
                position: absolute;
                z-index: 2;
                top: calc(50% - 5px);
                left: 21px;
                display: block;
                width: 10px;
                height: 10px;
                content: '';
                transition: opacity .2s ease;
                opacity: 0;
                border-radius: 50%;
                background: #2a66b7;
              }
            }
          }

          &-row {
            transition: background .2s ease !important;

            &:not(:first-child) {
              .ui-grid {
                &-cell {
                  border-top: 1px solid #eee !important;
                }
              }
            }

            &:last-child {
              @media (min-height: 600px) {
                padding: 0 0 20px;
              }

              .ui-grid {
                &-cell {
                  @media (min-height: 600px) {
                    border-bottom: 1px solid #eee !important;
                  }
                }
              }
            }

            &:hover {
              background: none !important;
            }

            &-selected {
              background: #f2f6fc !important;

              &:hover {
                background: #f2f6fc !important;
              }

              &::before {
                border: 1px solid #2a66b7;
                background: rgba(255, 255, 255, .5) !important;
              }

              .ui-grid-cell-contents {
                font-weight: 700 !important;

                &::after {
                  opacity: 1;
                }
              }
            }
          }
        }
      }

      &Switcher {
        display: flex;
        width: 64px;
        min-width: 64px;

        justify-content: flex-end;
        align-items: center;

        &Inner {
          position: fixed;
          z-index: 1;
          top: calc(50% - 104px);
          right: 30px;

          @media (min-width: 600px) {
            right: auto;
            left: calc(50% + 221px);
          }

          @media (min-height: 600px) {
            position: relative;
            top: 0;
            left: 0;
          }
        }
      }
    }

    /*
    　フォーム - items
    ---------------------------------*/
    &Items {
      width: 600px;
      background: #eee;

      &--separate {
        border-top: 1px solid white(primary);
        background: #eee;

        .uModal {
          &__form {
            &Label {
              padding-top: 18px !important;
              padding-bottom: 42px !important;
            }

            &Field {
              padding-top: 18px !important;
              padding-bottom: 42px !important;

              &--radio {
                padding-bottom: 31px !important;
              }
            }
          }
        }
      }
    }

    /*
    　フォーム - item
    ---------------------------------*/
    &Item {
      display: flex;

      &:last-child {
        .uModal {
          &__form {
            &Label {
              padding-bottom: 47px;

              &--required {
                padding-bottom: 47px;
              }
            }

            &Group {
              padding-bottom: 47px;

              &--number {
                padding-bottom: 47px;
              }
            }

            &Field {
              padding-bottom: 47px;
            }
          }
        }
      }

      &--small {
        display: flex;

        .uModal {
          &__form {
            &Label {
              width: 111px;
              padding: 0 30px;

              align-items: center;
              @extend %uModal__formLabel;
            }

            &Field {
              &--radio {
                padding: 9px 16px;
              }
            }
          }
        }
      }
    }

    /*
    　フォーム - ラベル
    ---------------------------------*/
    &Label {
      @extend %uModal__formLabel;

      &--required {
        padding-right: 56px !important;

        @extend %uModal__formLabel;

        &::after {
          font-size: 12px;
          font-weight: 700;
          position: absolute;
          top: 43px;
          right: 16px;
          display: flex;
          width: 30px;
          height: 16px;
          content: '必須';
          color: #b70000;
          border: 1px solid #b70000;

          justify-content: center;
          align-items: center;
        }
      }
    }

    /*
    　フォーム - グループ
    ---------------------------------*/
    &Group {
      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: 100%;

      .uModal {
        &__form {
          &Field {
            padding: 32px 24px 0 24px !important;

            &:not(:first-child) {
              padding-top: 24px !important;
            }

          }
        }
      }

      &--number {
        counter-reset: number;

        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: 100%;

        .uModal {
          &__form {
            &Field {
              padding: 32px 24px 0 24px !important;

              &:not(:first-child) {
                padding-top: 24px !important;
              }

              &::before {
                font-size: 13px;
                font-weight: 400;
                position: absolute;
                bottom: 0;
                left: 7px;
                display: flex;
                width: 1em;
                height: 40px;
                content: counter(number);
                counter-increment: number;
                white-space: nowrap;
                color: black(tertiary);

                justify-content: center;
                align-items: center;
              }
            }
          }
        }
      }

      &Add {
        padding: 24px 24px 0 24px !important;

        &Button {
          display: flex;
          width: 196px;
          height: 40px;
          cursor: pointer;
          transition: all .2s ease;
          border: 1px solid #ddd;
          border-radius: 4px;
          background: white(primary);

          align-items: center;
          justify-content: space-between;

          &:hover {
            border: 1px solid white(primary);
            box-shadow: 0px 3px 6px rgba(0, 0, 0, .16);
          }
        }

        &Label {
          font-size: 14px;
          padding: 0 8px;
          color: black(tertiary);
        }

        &Icon {
          position: relative;
          display: block;
          width: 38px;
          height: 38px;

          &::before {
            position: absolute;
            top: calc(50% - 1px);
            left: calc(50% - 6px);
            display: block;
            width: 12px;
            height: 2px;
            content: '';
            background: #0d52a2;
          }

          &::after {
            position: absolute;
            top: calc(50% - 1px);
            left: calc(50% - 6px);
            display: block;
            width: 12px;
            height: 2px;
            content: '';
            transform: rotate(90deg);
            transform-origin: center;
            background: #0d52a2;
          }
        }
      }

      &Delete {
        position: relative;
        display: block;
        width: 36px;
        height: 36px;
        margin: 0 0 0 14px;
        cursor: pointer;
        transition: all .2s ease;
        border: 1px solid #ddd;
        border-radius: 50%;
        background: #f2f2f2;

        flex-shrink: 0;

        &::before {
          position: absolute;
          top: calc(50% - 1px);
          left: calc(50% - 6px);
          display: block;
          width: 12px;
          height: 2px;
          content: '';
          transition: all .2s ease;
          transform: rotate(45deg);
          transform-origin: center;
          background: black(tertiary);
        }

        &::after {
          position: absolute;
          top: calc(50% - 1px);
          left: calc(50% - 6px);
          display: block;
          width: 12px;
          height: 2px;
          content: '';
          transition: all .2s ease;
          transform: rotate(-45deg);
          transform-origin: center;
          background: black(tertiary);
        }

        &:hover {
          border: 1px solid white(primary);
          background: white(primary);
          box-shadow: 0px 3px 6px rgba(0, 0, 0, .16);

          &::before {
            background: black(primary);
          }

          &::after {
            background: black(primary);
          }
        }
      }
    }

    /*
    　フォーム - フィールド
    ---------------------------------*/
    &Field {
      position: relative;
      display: flex;
      padding: 32px 24px 0 24px;

      align-items: center;
      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: 100%;

      &--stick {
        position: relative;
        display: flex;
        margin: -1px 0 0;
        padding: 0 24px 0 24px;
        border-top: 1px solid #eee;

        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: 100%;
      }

      &--radio {
        position: relative;
        display: flex;
        padding: 9px 24px 0 16px;

        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: 100%;
      }

      &--nopadding {
        padding: 0 !important;
      }

      &Label {
        font-size: 13px;
        font-weight: 400;
        position: absolute;
        top: -8px;
        left: 8px;
        pointer-events: none;
        color: black(tertiary);
      }

      &Focus {
        @extend %uModal__formFieldFocus;
      }

      &Message {
        background: #b2001a;

        @extend %uModal__formFieldMessage;

        &--warning {
          background: #ff9908;

          @extend %uModal__formFieldMessage;
        }
      }

      &Text {
        font-size: 14px;
        margin: 0 0 0 8px;
        color: rgba(33, 33, 33, 0.47);
      }
    }

    /*
    　フォーム - リスト
    ---------------------------------*/
    &List {
      width: 100%;
      padding: 6px 8px 8px 8px;
      border: 1px solid white(primary);

      &Row {
        display: flex;
        width: 100%;

        justify-content: space-between;
        align-items: flex-start;

        &:not(:first-child) {
          border-top: 1px solid #eee;
        }
      }

      &Cell {
        position: relative;
        display: flex;
        overflow: hidden;
        width: 100%;
        padding: 0 8px;
        background: white(primary);
      }

      &Heading {
        font-size: 13px;
        font-weight: 400;
        min-width: 86px;
        padding: 6px 0 4px;
        color: black(secondary);
      }

      &Data {
        font-size: 13px;
        font-weight: 400;
        padding: 6px 0 4px 8px;
        color: black(primary);

        &:first-child {
          overflow: hidden;
          width: 346px;
          padding: 6px 0 4px;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }

      &4digits {
        font-size: 13px;
        font-weight: 700;
        padding: 0 4px;
      }

      &Delete {
        position: absolute;
        top: 0;
        right: 0;
        display: block;
        width: 28px;
        height: 28px;
        margin: 0 0 0 14px;
        cursor: pointer;

        flex-shrink: 0;

        &::before {
          position: absolute;
          top: calc(50% - 1px);
          left: calc(50% - 6px);
          display: block;
          width: 12px;
          height: 2px;
          content: '';
          transition: all .2s ease;
          transform: rotate(45deg);
          transform-origin: center;
          background: black(tertiary);
        }

        &::after {
          position: absolute;
          top: calc(50% - 1px);
          left: calc(50% - 6px);
          display: block;
          width: 12px;
          height: 2px;
          content: '';
          transition: all .2s ease;
          transform: rotate(-45deg);
          transform-origin: center;
          background: black(tertiary);
        }

        &:hover {
          &::before {
            background: black(primary);
          }

          &::after {
            background: black(primary);
          }
        }
      }
    }

    /*
    　フォーム - テキストボックス
    ---------------------------------*/
    &Text {
      @extend %uModal__formText;
      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: 100%;

      &--width94 {
        width: 94px;

        @extend %uModal__formText;
      }

      &--width128 {
        width: 128px;

        @extend %uModal__formText;
      }

      &--width196 {
        width: 196px;

        @extend %uModal__formText;
      }

      &--width264 {
        width: 264px;

        @extend %uModal__formText;
      }

      &--width298 {
        width: 298px;

        @extend %uModal__formText;
      }

      &--sensorNumber {
        position: relative;
        display: flex;

        .uModal {
          &__form {
            &Text {
              &Body {
                &:nth-of-type(1) {
                  font-size: 14px !important;
                  width: 40px;
                }

                &:nth-of-type(2) {
                  font-size: 14px !important;
                  width: 72px;
                }

                &:focus {
                  &~.uModal {
                    &__form {
                      &Field {
                        &Focus {
                          width: 100%;
                        }
                      }
                    }
                  }
                }
              }
            }

            &Grid {
              &Cell {
                &Inner {
                  width: 128px;
                }
              }
            }
          }
        }
      }

      &Prepend {
        left: 0;

        @extend %uModal__formTextPrepend;

        &~.uModal {
          &__form {
            &Text {
              &Body {
                padding-left: 40px !important;
              }
            }
          }
        }
      }

      &Append {
        right: 0;

        @extend %uModal__formTextPrepend;

        &~.uModal {
          &__form {
            &Text {
              &Body {
                padding-right: 40px !important;
              }
            }
          }
        }
      }

      &Separate {
        font-size: 14px;
        display: flex;
        height: 40px;
        color: rgba(33, 33, 33, .33);
        border-bottom: 1px solid #ccc;

        align-items: center;
        justify-content: center;

        &--error {
          font-size: 14px;
          display: flex;
          height: 40px;
          color: rgba(33, 33, 33, .33);
          border-bottom: 1px solid #bc0822;
          background: #faecee !important;

          align-items: center;
          justify-content: center;
        }
      }

      &Icon {
        &--window {
          position: relative;
          top: -2px;
          left: 2px;
          display: block;
          width: 9px;
          height: 9px;
          border: 2px solid #0d52a2;

          &::before {
            position: absolute;
            top: 1px;
            left: -5px;
            display: block;
            width: 9px;
            height: 9px;
            content: '';
            border-bottom: 2px solid #0d52a2;
            border-left: 2px solid #0d52a2;
          }
        }
      }

      &Body {
        font-size: 15px !important;
        font-weight: 400 !important;
        line-height: 40px !important;
        display: block !important;
        width: 100%;
        height: 40px !important;
        padding: 0 8px !important;
        transition: border, background .2s ease !important;
        color: black(primary) !important;
        border: none !important;
        border-bottom: 1px solid #ccc !important;
        border-radius: 2px 2px 0 0 !important;
        outline: 0 !important;
        background: white(primary) !important;
        box-shadow: none !important;

        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;

        &:disabled {
          pointer-events: none;
          opacity: 1;
        }

        &::placeholder {
          color: black(quaternary) !important;
        }

        &:-ms-input-placeholder {
          color: black(quaternary) !important;
        }

        &::-ms-input-placeholder {
          color: black(quaternary) !important;
        }

        &::-webkit-input-placeholder {
          color: black(quaternary) !important;
        }

        &::-ms-clear {
          display: none;
        }

        &:focus {
          &+.uModal {
            &__form {
              &Field {
                &Focus {
                  width: 100%;
                }
              }
            }
          }
        }

        &.is-error {
          border-bottom: 1px solid red(primary) !important;
          background: #faecee !important;

          &:focus {
            &+.uModal {
              &__form {
                &Field {
                  &Focus {
                    width: 0;
                  }
                }
              }
            }
          }
        }

        &.is-warning {
          border-bottom: 1px solid #ff9908 !important;
          background: #fcf6ed !important;

          &:focus {
            &+.uModal {
              &__form {
                &Field {
                  &Focus {
                    width: 0;
                  }
                }
              }
            }
          }
        }

        &--disabled {
          font-size: 15px !important;
          font-weight: 400 !important;
          line-height: 40px !important;
          display: block !important;
          width: 100%;
          height: 40px !important;
          padding: 0 8px !important;
          transition: border, background .2s ease !important;
          pointer-events: none;
          color: black(primary) !important;
          border: none !important;
          border-bottom: 1px solid rgba(204, 204, 204, 0.32) !important;
          border-radius: 2px 2px 0 0 !important;
          outline: 0 !important;
          background: #e7e7e7 !important;
          box-shadow: none !important;

          appearance: none;
          -moz-appearance: none;
          -webkit-appearance: none;

          &::placeholder {
            color: black(quaternary) !important;
          }

          &:-ms-input-placeholder {
            color: black(quaternary) !important;
          }

          &::-ms-input-placeholder {
            color: black(quaternary) !important;
          }

          &::-webkit-input-placeholder {
            color: black(quaternary) !important;
          }

          &::-ms-clear {
            display: none;
          }
        }
      }
    }

    /*
    　フォーム - ui-select
    ---------------------------------*/
    &Autocomplete {
      @extend %uModal__formAutocomplete;
      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: 100%;

      &--width94 {
        width: 94px;

        @extend %uModal__formAutocomplete;
      }

      &--width128 {
        width: 128px;

        @extend %uModal__formAutocomplete;
      }

      &--width196 {
        width: 196px;

        @extend %uModal__formAutocomplete;
      }

      &--width264 {
        width: 264px;

        @extend %uModal__formAutocomplete;
      }

      &--width298 {
        width: 298px;

        @extend %uModal__formAutocomplete;
      }

      &Ui {
        position: relative !important;
        height: 40px !important;
        outline: 0 !important;

        &Selected {
          font-size: 15px !important;
          font-weight: 400 !important;
          line-height: 40px !important;
          display: block !important;
          overflow: hidden !important;
          width: 100% !important;
          height: 40px !important;
          padding: 0 40px 0 8px !important;
          white-space: nowrap !important;
          text-overflow: ellipsis !important;
        }

        &.open {
          &+.uModal {
            &__form {
              &Field {
                &Focus {
                  width: 100%;
                }
              }
            }
          }
        }

        .caret {
          display: none !important;
        }

        .ui-select {
          &-match {
            outline: 0 !important;
            box-shadow: none !important;

            &-text {
              float: none !important;
              width: 100%;
              padding: 0 !important;
            }
          }

          &-toggle {
            font-size: 15px !important;
            font-weight: 400 !important;
            position: relative !important;
            display: block !important;
            overflow: hidden !important;
            width: 100% !important;
            height: 40px !important;
            margin: 0 !important;
            padding: 0 !important;
            cursor: pointer !important;
            transition: none !important;
            text-align: left !important;
            vertical-align: middle !important;
            white-space: nowrap !important;
            color: black(primary) !important;
            border: none !important;
            border-bottom: 1px solid #ccc !important;
            border-radius: 0 !important;
            background: #fff !important;
            box-shadow: none;
          }

          &-placeholder {
            font-size: 15px !important;
            line-height: 40px !important;
            padding: 0 40px 0 8px !important;
            color: black(quaternary);
          }

          &-search {
            font-size: 15px !important;
            font-weight: 400 !important;
            line-height: 40px !important;
            width: 100%;
            height: 40px !important;
            padding: 0 40px 0 8px !important;
            transition: all .2s ease !important;
            color: black(primary) !important;
            border: none !important;
            border-bottom: 1px solid #ccc !important;
            border-radius: 2px 2px 0 0 !important;
            outline: 0 !important;
            background: white(primary) !important;
            box-shadow: none !important;

            appearance: none !important;
            -moz-appearance: none !important;
            -webkit-appearance: none !important;

            &::placeholder {
              color: black(quaternary) !important;
            }

            &:-ms-input-placeholder {
              color: black(quaternary) !important;
            }

            &::-ms-input-placeholder {
              color: black(quaternary) !important;
            }

            &::-webkit-input-placeholder {
              color: black(quaternary) !important;
            }

            &::-ms-clear {
              display: none;
            }
          }
        }
      }
    }

    /*
    　フォーム - 自動入力
    ---------------------------------*/
    &Autoinput {
      @extend %uModal__formAutoinput;
      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: 100%;

      &--width94 {
        width: 94px;

        @extend %uModal__formAutoinput;
      }

      &--width128 {
        width: 128px;

        @extend %uModal__formAutoinput;
      }

      &--width196 {
        width: 196px;

        @extend %uModal__formAutoinput;
      }

      &--width264 {
        width: 264px;

        @extend %uModal__formAutoinput;
      }

      &--width298 {
        width: 298px;

        @extend %uModal__formAutoinput;
      }

      &Body {
        font-size: 15px !important;
        font-weight: 400 !important;
        line-height: 40px !important;
        display: block !important;
        overflow: hidden !important;
        width: 100%;
        height: 40px !important;
        padding: 0 8px !important;
        transition: all .2s ease !important;
        white-space: nowrap !important;
        text-overflow: ellipsis !important;
        color: black(primary) !important;
        border: none !important;
        border-bottom: 1px solid #ccc !important;
        border-radius: 0 !important;
        outline: 0 !important;
        background: #e7e7e7 !important;
        box-shadow: none !important;
      }
    }

    /*
    　フォーム - セレクトボックス
    ---------------------------------*/
    &Select {
      @extend %uModal__formSelect;
      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: 100%;

      &--width94 {
        width: 94px;

        @extend %uModal__formSelect;
      }

      &--width128 {
        width: 128px;

        @extend %uModal__formSelect;
      }

      &--width196 {
        width: 196px;

        @extend %uModal__formSelect;
      }

      &--width264 {
        width: 264px;

        @extend %uModal__formSelect;
      }

      &--width298 {
        width: 298px;

        @extend %uModal__formSelect;
      }

      &Append {
        right: 35px;

        @extend %uModal__formTextPrepend;
      }

      &Body {
        font-size: 15px !important;
        font-weight: 400 !important;
        line-height: 40px !important;
        width: 100%;
        height: 40px !important;
        padding: 0 40px 0 8px !important;
        transition: all .2s ease !important;
        color: black(primary) !important;
        border: none !important;
        border-bottom: 1px solid #ccc !important;
        border-radius: 2px 2px 0 0 !important;
        outline: 0 !important;
        background: white(primary) !important;
        box-shadow: none !important;

        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;

        &::-ms-expand {
          display: none;
        }

        &:focus {
          &+.uModal {
            &__form {
              &Field {
                &Focus {
                  width: 100%;
                }
              }
            }
          }
        }

        &.is-error {
          border-bottom: 1px solid red(primary) !important;
          background: #faecee !important;

          &:focus {
            &+.uModal {
              &__form {
                &Field {
                  &Focus {
                    width: 0;
                  }
                }
              }
            }
          }
        }

        &.is-warning {
          border-bottom: 1px solid #ff9908 !important;
          background: #fcf6ed !important;

          &:focus {
            &+.uModal {
              &__form {
                &Field {
                  &Focus {
                    width: 0;
                  }
                }
              }
            }
          }
        }

        &--disabled {
          font-size: 15px !important;
          font-weight: 400 !important;
          line-height: 40px !important;
          width: 100%;
          height: 40px !important;
          padding: 0 40px 0 8px !important;
          transition: all .2s ease !important;
          pointer-events: none;
          color: black(primary) !important;
          border: none !important;
          border-bottom: 1px solid rgba(204, 204, 204, 0.32) !important;
          border-radius: 2px 2px 0 0 !important;
          outline: 0 !important;
          background: #e7e7e7 !important;
          box-shadow: none !important;

          appearance: none;
          -moz-appearance: none;
          -webkit-appearance: none;

          &::-ms-expand {
            display: none;
          }
        }
      }
    }

    /*
    　フォーム - ラジオボタン
    ---------------------------------*/
    &Radio {
      position: relative;
      display: flex;

      flex-wrap: wrap;

      &:not(:first-child) {
        margin: 0 0 0 8px;
      }

      &:first-child {
        margin: 0;
      }

      &Label {
        display: flex !important;
        height: 40px !important;
        margin: 0 31px 0 0 !important;
        cursor: pointer;

        justify-content: flex-start;
        align-items: center;

        &:hover {
          .uModal {
            &__form {
              &Radio {
                &Button {
                  background: rgba(229, 229, 229, .72);

                  @media (pointer: coarse) {
                    background: none;
                  }

                  &::before {
                    background: rgba(242, 242, 242, .32);

                    @media (pointer: coarse) {
                      background: #f2f2f2;
                    }
                  }
                }

                &Body {
                  &:checked {
                    +.uModal {
                      &__form {
                        &Radio {
                          &Button {
                            background: rgba(222, 230, 237, .72);

                            @media (pointer: coarse) {
                              background: none;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }

        &--disabled {
          display: flex !important;
          height: 40px !important;
          margin: 0 31px 0 0 !important;
          cursor: pointer;
          pointer-events: none;

          justify-content: flex-start;
          align-items: center;

          .uModal__formRadio {
            &Body {
              &:checked {
                &+.uModal {
                  &__form {
                    &Radio {
                      &Button {
                        &::before {
                          border-color: #ccc;
                        }

                        &::after {
                          background: #ccc;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      &Body {
        display: none;

        &:checked {
          &+.uModal {
            &__form {
              &Radio {
                &Button {
                  &::before {
                    border: 1px solid #2a66b7;
                    background: rgba(255, 255, 255, .5);
                  }

                  &::after {
                    opacity: 1;
                  }
                }
              }
            }
          }
        }
      }

      &Button {
        position: relative;
        display: block;
        width: 36px;
        height: 36px;
        transition: background .2s ease;
        border-radius: 50%;
        background: none;

        &::before {
          position: absolute;
          top: calc(50% - 10px);
          left: calc(50% - 10px);
          display: block;
          width: 20px;
          height: 20px;
          content: '';
          transition: background .2s ease, border .2s ease;
          border: 1px solid #ccc;
          border-radius: 50%;
          background: #f2f2f2;
        }

        &::after {
          position: absolute;
          top: calc(50% - 5px);
          left: calc(50% - 5px);
          display: block;
          width: 10px;
          height: 10px;
          content: '';
          transition: opacity .2s ease;
          opacity: 0;
          border-radius: 50%;
          background: #2a66b7;
        }
      }

      &Text {
        font-size: 14px;
        font-weight: 400;
        margin: 0 0 0 5px;
        color: black(secondary);
      }
    }

    /*
    　フォーム - チェックボックス
    ---------------------------------*/
    &Checkbox {
      position: relative;
      display: flex;

      flex-wrap: wrap;

      &:not(:first-child) {
        margin: 0 0 0 8px;
      }

      &:first-child {
        margin: 0;
      }

      &Label {
        display: flex !important;
        height: 40px !important;
        margin: 0 40px 0 0 !important;
        cursor: pointer;

        justify-content: flex-start;
        align-items: center;

        &:hover {
          .uModal {
            &__form {
              &Checkbox {
                &Button {
                  background: #f2f2f2;

                  @media (pointer: coarse) {
                    background: none;
                  }

                  &::before {
                    background: none;

                    @media (pointer: coarse) {
                      background: #f2f2f2;
                    }
                  }
                }

                &Body {
                  &:checked {
                    +.uModal {
                      &__form {
                        &Checkbox {
                          &Button {
                            background: #eff3f7;

                            @media (pointer: coarse) {
                              background: none;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      &Body {
        display: none;

        &:checked {
          &+.uModal {
            &__form {
              &Checkbox {
                &Button {
                  &::before {
                    border: 1px solid #2863b2;
                    background: #2a66b7;
                  }

                  &::after {
                    opacity: 1;
                  }
                }
              }
            }
          }
        }
      }

      &Button {
        position: relative;
        display: flex;
        width: 36px;
        height: 36px;
        transition: background .2s ease;
        border-radius: 50%;
        background: none;

        justify-content: center;
        align-items: center;

        &::before {
          display: block;
          width: 20px;
          height: 20px;
          content: '';
          transition: background .2s ease, border .2s ease;
          border: 1px solid #ddd;
          border-radius: 2px;
          background: #f2f2f2;
        }

        &::after {
          position: absolute;
          top: 13px;
          left: 12px;
          display: block;
          width: 12px;
          height: 7px;
          content: '';
          transition: opacity .2s ease;
          transform: rotate(-45deg);
          opacity: 0;
          border-bottom: 2px solid white(primary);
          border-left: 2px solid white(primary);
        }
      }

      &Text {
        font-size: 14px;
        font-weight: 400;
        margin: 0 0 0 5px;
        color: black(secondary);
      }
    }

    /*
    　フォーム - トグルボタン
    ---------------------------------*/
    &Toggle {
      position: relative;
      display: flex;
      margin: -8px 0 0 -8px;

      flex-wrap: wrap;

      &+.uModal__formFieldMessage {
        margin-top: 5px;
      }

      &Label {
        display: flex !important;
        height: 36px !important;
        margin: 8px 0 0 8px !important;
        cursor: pointer;

        justify-content: flex-start;
        align-items: center;

        &:hover {
          .uModal {
            &__form {
              &Toggle {
                &Button {
                  color: black(secondary);
                  border: 1px solid white(primary);
                  background: #f2f2f2;
                  box-shadow: 0px 3px 6px rgba(0, 0, 0, .16);

                  @media (pointer: coarse) {
                    color: black(tertiary);
                    border: 1px solid #ddd;
                    background: #f2f2f2;
                    box-shadow: none;
                  }
                }

                &Body {
                  &:checked {
                    &+.uModal {
                      &__form {
                        &Toggle {
                          &Button {
                            font-weight: 700;
                            color: #0d52a2;
                            border: 1px solid white(primary);
                            background: #f2f2f2;
                            box-shadow: 0px 3px 6px rgba(0, 0, 0, .16);

                            @media (pointer: coarse) {
                              font-weight: 700;
                              color: #0d52a2;
                              border: 1px solid white(primary);
                              background: linear-gradient(to right, #fff, #f2f2f2);
                              box-shadow: 0px 1px 2px rgba(0, 0, 0, .16);
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      &Body {
        display: none;

        &:checked {
          &+.uModal {
            &__form {
              &Toggle {
                &Button {
                  font-weight: 700;
                  color: #0d52a2;
                  border: 1px solid white(primary);
                  background: linear-gradient(to right, #fff, #f2f2f2);
                  box-shadow: 0px 1px 2px rgba(0, 0, 0, .16);

                  &::before {
                    border-right: 2px solid #0d52a2;
                    border-bottom: 2px solid #0d52a2;
                  }
                }
              }
            }
          }
        }
      }

      &Button {
        font-size: 13px;
        font-weight: 400;
        position: relative;
        display: flex;
        width: 100%;
        height: 36px;
        padding: 0 13px 0 31px;
        transition: all .2s ease;
        color: black(tertiary);
        border: 1px solid #ddd;
        border-radius: 16px;
        background: #f2f2f2;
        box-shadow: none;

        justify-content: center;
        align-items: center;

        &::before {
          position: absolute;
          top: 10px;
          left: 14px;
          display: block;
          width: 6px;
          height: 11px;
          content: '';
          transition: all .2s ease;
          transform: rotate(45deg);
          border-right: 2px solid rgba(33, 33, 33, .16);
          border-bottom: 2px solid rgba(33, 33, 33, .16);
        }

        &::selection {
          background: none;
        }
      }
    }

    /*
    　フォーム - テキストエリア
    ---------------------------------*/
    &Textarea {
      position: relative;

      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: 100%;

      &:not(:first-child) {
        margin: 0 0 0 8px;
      }

      &:first-child {
        margin: 0;
      }

      &Body {
        font-family: inherit !important;
        font-size: 15px !important;
        font-weight: 400 !important;
        display: block !important;
        overflow: auto !important;
        width: 100%;
        height: 160px !important;
        margin: 0 !important;
        padding: 8px !important;
        resize: none !important;
        transition: all .2s ease !important;
        vertical-align: baseline !important;
        color: black(primary) !important;
        border: none !important;
        border-bottom: 1px solid #ccc !important;
        border-radius: 2px 2px 0 0 !important;
        outline: 0 !important;
        background: white(primary) !important;
        box-shadow: none !important;

        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;

        &:focus {
          &+.uModal {
            &__form {
              &Field {
                &Focus {
                  width: 100%;
                }
              }
            }
          }
        }
      }
    }

    /*
    　フォーム - ファイル
    ---------------------------------*/
    &File {
      font-weight: 400 !important;
      line-height: 40px !important;
      position: relative;
      display: block !important;
      width: 100%;
      height: 40px !important;
      margin: 0 !important;
      padding: 0 8px !important;
      transition: all .2s ease !important;
      border: none !important;
      border-bottom: 1px solid #ccc !important;
      border-radius: 2px 2px 0 0 !important;
      outline: 0 !important;
      background: white(primary) !important;
      box-shadow: none !important;

      .icon-m-upload-file {
        font-size: 20px !important;
        right: 0;
        color: #0d52a2;

        @extend %uModal__formTextPrepend;
      }

      &Body {
        display: none !important;
      }

      &Label {
        font-size: 15px !important;
        color: black(secondary) !important;
      }
    }

    /*
    　フォーム - 検索ボックス
    ---------------------------------*/
    &Search {
      display: flex;
      background: #f2f2f2;

      align-items: center;

      &Body {
        font-size: 17px !important;
        font-weight: 400 !important;
        line-height: 1.5 !important;
        display: block !important;
        width: 100%;
        height: 64px !important;
        padding: 0 !important;
        color: black(primary) !important;
        border: none !important;
        border-radius: none !important;
        outline: 0 !important;
        background: none !important;
        box-shadow: none !important;

        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;

        &::placeholder {
          color: black(quaternary) !important;
        }

        &:-ms-input-placeholder {
          color: black(quaternary) !important;
        }

        &::-ms-input-placeholder {
          color: black(quaternary) !important;
        }

        &::-webkit-input-placeholder {
          color: black(quaternary) !important;
        }

        &::-ms-clear {
          display: none;
        }
      }

      .icon-m-search {
        display: flex;
        width: 64px;
        height: 64px;

        align-items: center;
        justify-content: center;
      }

      &CowSelect {
        visibility: hidden;
        height: 0;
        transition: all .4s ease !important;
        opacity: 0;
        background: #f2f2f2;

        &--open {
          position: relative;
          visibility: visible;
          height: 302px;
          transition: all .4s ease !important;
          opacity: 1;
          background: #f2f2f2;

          &::after {
            position: absolute;
            bottom: 24px;
            left: 0;
            width: 100%;
            height: 20px;
            content: '';
            pointer-events: none;
            background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(240, 240, 240, 0.8));
          }
        }

        &Head {
          display: flex;
          height: 47px;
          margin: 0 16px;
          border-top: 1px solid #ddd;
          border-bottom: 1px solid #ddd;

          align-items: center;
          justify-content: space-between;
        }

        &Heading {
          font-size: 15px;
          padding: 0 0 0 16px;
          color: black(primary);
        }

        &Total {
          font-size: 15px;
          padding: 0 16px 0 0;
          color: black(secondary);
        }

        &Scroll {
          overflow: scroll;
          height: 230px;
          margin: 0 16px;
        }

        &List {
          width: 100%;
          border-spacing: 0;
          border-collapse: collapse;
          border: none;
        }

        &Titles {
          margin: 16px 16px 0;
          background: none !important;
        }

        &Title {
          font-size: 13px;
          font-weight: 400;
          line-height: 29px;
          position: sticky;
          top: 0;
          height: 29px;
          min-height: auto !important;
          padding: 16px 8px 0 !important;
          text-align: left !important;
          vertical-align: middle !important;
          white-space: nowrap;
          color: rgba(33, 33, 33, 0.62);
          border: none !important;
          background: #f2f2f2 !important;
        }

        &Items {
          margin: 0 16px;
        }

        &Item {
          height: 40px;
          background: none !important;

          &:not(:first-child) {
            border-top: 1px solid transparent;
          }
        }

        &Cell {
          font-size: 15px;
          min-height: auto !important;
          padding: 0 8px !important;
          vertical-align: middle;
          white-space: nowrap;
          color: black(primary);
          border: none !important;

          .icon-s-search {
            color: black(secondary);
          }
        }
      }

      &ErrorMessage {
        visibility: hidden;
        height: 0;
        transition: all .4s ease !important;
        opacity: 0;
        background: #f2f2f2;

        &--open {
          visibility: visible;
          height: 137px;
          transition: all .4s ease !important;
          opacity: 1;
          background: #f2f2f2;
        }

        &Inner {
          margin: 0 16px;
          padding: 22px 48px;
          border-top: 1px solid #ddd;
        }

        &Heading {
          font-size: 17px;
          position: relative;
          color: black(primary);

          .icon-m-help-outlined {
            position: absolute;
            top: 1px;
            left: -40px;
            color: black(secondary);
          }
        }

        &Text {
          font-size: 14px;
          line-height: 1.8;
          margin: 20px 0 0;
          color: black(secondary);
        }
      }
    }

    /*
    　フォーム - 条件
    ---------------------------------*/
    &Condition {
      position: relative;
      display: flex;
      overflow: hidden;
      max-height: calc(92vh - 64px);

      @media (-ms-high-contrast: none) {
        height: 100vh;
      }

      @media (pointer: coarse) {
        max-height: calc(92vh - 134px);
      }

      &Main {
        overflow-x: hidden;
        overflow-y: scroll;
        width: 463px;

        scroll-behavior: smooth;

        &--openSelectMultiple {
          overflow: hidden;
          width: 463px;

          scroll-behavior: smooth;
        }
      }

      &Body {
        margin: 0 0 80px;
        padding: 26px 16px 47px;
        border-radius: 2px 0 0 0;
        background: white(primary);

        &::-webkit-scrollbar {
          width: 10px;
        }
      }

      &Foot {
        position: absolute;
        bottom: 0;
        left: 0;
        display: flex;
        flex-direction: row-reverse;
        width: 463px;
        height: 80px;
        padding: 0 24px;
        border-top: 1px solid #ddd;
        border-radius: 0 0 0 2px;
        background: white(primary);

        align-items: center;

        &::before {
          position: absolute;
          z-index: -1;
          bottom: 80px;
          left: 0;
          display: block;
          width: 463px;
          height: 100vh;
          content: '';
          pointer-events: none;
          background: white(primary);
        }

        &::after {
          position: absolute;
          z-index: 1;
          top: -21px;
          left: 0;
          display: block;
          width: calc(100% - 16px);
          height: 20px;
          content: '';
          pointer-events: none;
          background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.8));
        }
      }

      &Items {
        counter-reset: conditionNumber;
      }

      &Item {
        &:not(:first-child) {
          padding: 20px 0 0;
        }

        &Head {
          display: flex;

          align-items: center;
          justify-content: space-between;
        }

        &Body {
          padding: 12px;
          border: 1px solid #eee;
          border-radius: 4px;

          align-items: center;
        }
      }

      &Label {
        font-size: 14px;
        font-weight: 700;
        width: 81px;
        padding: 0 0 0 16px;
        color: black(primary);

        flex-shrink: 0;

        &::before {
          content: '条件'counter(conditionNumber);
          counter-increment: conditionNumber;
        }
      }

      &Group {
        padding: 23px 12px 17px;
        border-radius: 4px;
        background: linear-gradient(to right, #eee, #e6e6e6);

        flex-grow: 1;
      }

      &Field {
        position: relative;
        display: flex;

        align-items: center;
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: 100%;

        &:not(:first-child) {
          padding: 24px 0 0;
        }

        &Focus {
          z-index: 0;

          @extend %uModal__formFieldFocus;
        }

        &Label {
          font-size: 13px;
          font-weight: 400;
          position: absolute;
          top: -10px;
          left: 8px;
          pointer-events: none;
          color: black(tertiary);
        }

        &Text {
          font-size: 15px;
          margin: 0 0 0 8px;
          color: rgba(33, 33, 33, 0.47);
        }

        .ui-select {
          &-container {
            width: 100%;
            padding: 0;
            cursor: pointer;
            transition: all .2s ease;
            white-space: nowrap;
            text-overflow: ellipsis;
            border: none;
            border-bottom: 1px solid #ccc;
            border-radius: 2px 2px 0 0;
            background: transparent;
            box-shadow: none;

            &::after {
              position: absolute;
              top: calc(50% - 4px);
              right: 14px;
              display: block;
              width: 9px;
              height: 9px;
              content: '';
              pointer-events: none;
              border-top: 7px solid #0d52a2;
              border-right: 4px solid transparent;
              border-left: 4px solid transparent;
            }

            &.ng-empty {
              .ui-select-match {
                &::before {
                  content: '対象を選択してください';
                  color: rgba(33, 33, 33, 0.47) !important;
                }
              }
            }

            &.open {
              .ui-select {
                &-search {
                  visibility: visible;
                  opacity: 1;
                }

                &-choices,
                &-no-choice {
                  top: 100% !important;
                  cursor: default;
                  transform: translate3d(0, 56px, 0);
                }
              }
            }
          }

          &-match {
            display: flex;
            min-height: 40px;
            padding: 9px 30px 7px 8px;

            flex-wrap: wrap;

            >span {
              &:not(:last-child) {
                &::after {
                  content: '、';
                }
              }
            }

            &-item {
              font-size: 14px !important;
              font-weight: 400;
              line-height: 1.7;
              margin: 0 !important;
              padding: 0;
              pointer-events: none;
              color: black(primary);
              border: none;
              background: transparent;

              >span {
                font-size: inherit;
              }
            }

            &-close {
              display: none;
            }
          }

          &-search {
            line-height: 40px !important;
            position: absolute;
            z-index: 3;
            visibility: hidden;
            width: calc(100% - 24px) !important;
            height: 40px !important;
            margin: 8px 12px !important;
            padding: 0 8px 0 50px !important;
            transition: opacity .4s ease;
            opacity: 0;
            border-radius: 9999px;

            &-container {
              position: absolute;
              visibility: hidden;
              width: 100%;
              height: 56px;
              transition: opacity .4s ease;
              opacity: 0;
              background: #f2f2f2;

              &--open {
                position: absolute;
                z-index: 2;
                visibility: visible;
                width: 100%;
                height: 56px;
                transition: opacity .4s ease;
                opacity: 1;
                background: #f2f2f2;
              }
            }

            &-inner {
              display: flex;
              width: calc(100% - 24px);
              height: 40px;
              margin: 8px 12px;
              padding: 0 0 0 15px;
              border-radius: 9999px;
              background: white(primary);

              align-items: center;
            }

            &::placeholder {
              color: rgba(33, 33, 33, 0.47) !important;
            }

            &:-ms-input-placeholder {
              color: rgba(33, 33, 33, 0.47) !important;
            }

            &::-ms-input-placeholder {
              color: rgba(33, 33, 33, 0.47) !important;
            }

            &::-webkit-input-placeholder {
              color: rgba(33, 33, 33, 0.47) !important;
            }

            &::-ms-clear {
              display: none;
            }
          }

          &-choices {
            z-index: 2;
            float: none;
            min-width: auto;
            max-height: 200px;
            margin: 0;
            padding: 16px;
            transition: opacity .4s ease;
            border: none;
            border-radius: 0px;
            background: white(primary);
            box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);

            &-group {
              display: flex;

              flex-wrap: wrap;
              gap: 8px;
            }

            &-row {
              &:hover {
                background: none;
              }

              &.disabled {
                .ui-select-choices-row-inner-button {
                  font-weight: 700 !important;
                  color: #0d52a2 !important;
                  border: 1px solid white(primary);
                  background: linear-gradient(to right, #fff, #f2f2f2) !important;
                  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.16);

                  &::before {
                    border-right: 2px solid #0d52a2;
                    border-bottom: 2px solid #0d52a2;
                  }

                  &:hover {
                    background: #f2f2f2 !important;
                    box-shadow: 0px 3px 6px rgba(0, 0, 0, .16);

                    @media (pointer: coarse) {
                      background: linear-gradient(to right, #fff, #f2f2f2) !important;
                      box-shadow: 0px 1px 2px rgba(0, 0, 0, .16);
                    }
                  }
                }
              }

              &-inner {
                padding: 0 !important;
                background: transparent !important;

                &-button {
                  font-size: 13px;
                  font-weight: 400 !important;
                  position: relative;
                  display: flex !important;
                  width: 100%;
                  height: 36px;
                  padding: 0 13px 0 31px !important;
                  cursor: pointer !important;
                  transition: all .2s ease;
                  color: #757575 !important;
                  border: 1px solid #ddd;
                  border-radius: 16px;
                  background: #f2f2f2 !important;
                  box-shadow: none;

                  justify-content: center;
                  align-items: center;

                  &::before {
                    position: absolute;
                    top: 10px;
                    left: 14px;
                    display: block;
                    width: 6px;
                    height: 11px;
                    content: '';
                    transition: all .2s ease;
                    transform: rotate(45deg);
                    pointer-events: none;
                    border-right: 2px solid rgba(33, 33, 33, 0.16);
                    border-bottom: 2px solid rgba(33, 33, 33, 0.16);
                  }

                  &:hover {
                    color: black(secondary);
                    border: 1px solid white(primary);
                    background: #f2f2f2;
                    box-shadow: 0px 3px 6px rgba(0, 0, 0, .16);

                    @media (pointer: coarse) {
                      color: black(tertiary);
                      border: 1px solid #ddd;
                      background: #f2f2f2;
                      box-shadow: none;
                    }
                  }
                }
              }
            }
          }

          &-no-choice {
            z-index: 2;
            float: none;
            min-width: auto;
            margin: 0;
            padding: 17px 16px;
            transition: opacity .4s ease;
            border: none;
            border-radius: 0px;
            background: white(primary);
            box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);

            >span,
            >li {
              font-size: inherit;
              color: black(primary);
            }
          }
        }
      }

      &Text {
        @extend %uModal__formText;
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: 100%;

        &--width144 {
          width: 144px;

          @extend %uModal__formText;
        }

        &Prepend {
          left: 0;
          color: rgba(33, 33, 33, 0.47);

          @extend %uModal__formTextPrepend;

          &~.uModal {
            &__form {
              &Condition {
                &Text {
                  &Body {
                    padding-left: 40px !important;
                  }
                }
              }
            }
          }
        }

        &Append {
          right: 0;
          color: rgba(33, 33, 33, 0.47);

          @extend %uModal__formTextPrepend;

          &~.uModal {
            &__form {
              &Condition {
                &Text {
                  &Body {
                    padding-right: 40px !important;
                  }
                }
              }
            }
          }
        }

        &Body {
          font-size: 14px !important;
          font-weight: 400 !important;
          line-height: 40px !important;
          display: block !important;
          width: 100%;
          height: 40px !important;
          padding: 0 8px !important;
          transition: border, background .2s ease !important;
          color: black(primary) !important;
          border: none !important;
          border-bottom: 1px solid #ccc !important;
          border-radius: 2px 2px 0 0 !important;
          outline: 0 !important;
          background: white(primary) !important;
          box-shadow: none !important;

          appearance: none;
          -moz-appearance: none;
          -webkit-appearance: none;

          &:disabled {
            pointer-events: none;
            opacity: 1;
          }

          &::placeholder {
            color: black(quaternary) !important;
          }

          &:-ms-input-placeholder {
            color: black(quaternary) !important;
          }

          &::-ms-input-placeholder {
            color: black(quaternary) !important;
          }

          &::-webkit-input-placeholder {
            color: black(quaternary) !important;
          }

          &::-ms-clear {
            display: none;
          }

          &:focus {
            &+.uModal {
              &__form {
                &Condition {
                  &Field {
                    &Focus {
                      z-index: 0;
                      width: 100%;
                    }
                  }
                }
              }
            }
          }

          &--disabled {
            font-size: 14px !important;
            font-weight: 400 !important;
            line-height: 40px !important;
            display: block !important;
            width: 100%;
            height: 40px !important;
            padding: 0 8px !important;
            transition: border, background .2s ease !important;
            pointer-events: none;
            color: #e7e7e7 !important;
            border: none !important;
            border-bottom: 1px solid rgba(204, 204, 204, 0.32) !important;
            border-radius: 2px 2px 0 0 !important;
            outline: 0 !important;
            background: #e7e7e7 !important;
            box-shadow: none !important;

            appearance: none;
            -moz-appearance: none;
            -webkit-appearance: none;

            &::placeholder {
              color: #e7e7e7 !important;
            }

            &:-ms-input-placeholder {
              color: #e7e7e7 !important;
            }

            &::-ms-input-placeholder {
              color: #e7e7e7 !important;
            }

            &::-webkit-input-placeholder {
              color: #e7e7e7 !important;
            }

            &::-ms-clear {
              display: none;
            }

            &::selection {
              color: transparent;
            }
          }
        }
      }

      &Select {
        @extend %uModal__formSelect;
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: 100%;

        &--width144 {
          width: 144px;

          @extend %uModal__formSelect;
        }

        &--width200 {
          width: 200px;
          color: rgba(33, 33, 33, 0.47);

          @extend %uModal__formSelect;
        }

        &Append {
          right: 35px;
          color: rgba(33, 33, 33, 0.47);

          @extend %uModal__formTextPrepend;
        }

        &Body {
          font-size: 14px !important;
          font-weight: 400 !important;
          line-height: 40px !important;
          width: 100%;
          height: 40px !important;
          padding: 0 30px 0 8px !important;
          transition: all .2s ease !important;
          color: black(primary) !important;
          border: none !important;
          border-bottom: 1px solid #ccc !important;
          border-radius: 2px 2px 0 0 !important;
          outline: 0 !important;
          background: white(primary) !important;
          box-shadow: none !important;

          appearance: none;
          -moz-appearance: none;
          -webkit-appearance: none;

          &::-ms-expand {
            display: none;
          }

          &:focus {
            &+.uModal {
              &__form {
                &Condition {
                  &Field {
                    &Focus {
                      width: 100%;
                    }
                  }
                }
              }
            }
          }
        }
      }

      &SelectMultiple {
        width: 100%;
        background: white(primary);

        @extend %uModal__formSelect;

        &::after {
          top: calc(50% - 4px);
        }

        &Body {
          overflow: hidden;
          width: 100%;
          height: 40px;
          padding: 9px 30px 7px 8px;
          cursor: pointer;
          transition: all .2s ease;
          white-space: nowrap;
          text-overflow: ellipsis;
          border-bottom: 1px solid #ccc;
          border-radius: 2px 2px 0 0;
          background: white(primary);

          &Text {
            font-size: 14px !important;
            font-weight: 400;
            line-height: 1.7;
            color: black(primary);
          }

          &::selection {
            background: none;
          }
        }

        &Placeholder {
          font-size: 14px;
          line-height: 1.7;
          position: absolute;
          top: 0;
          left: 0;
          padding: 9px 8px 7px 8px;
          pointer-events: none;
          color: rgba(33, 33, 33, 0.47);
        }

        &Focus {
          top: -1px;

          @extend %uModal__formFieldFocus;

          &--focus {
            top: -1px;
            width: 100%;

            @extend %uModal__formFieldFocus;
          }
        }

        &Backdrop {
          display: none;

          &--open {
            position: fixed;
            z-index: 1;
            top: 0;
            left: 0;
            display: block;
            width: 100%;
            height: 100%;
          }
        }

        &List {
          position: absolute;
          overflow: hidden;
          height: 0;
          opacity: 0;

          &--open {
            position: absolute;
            z-index: 2;
            top: 40px;
            left: 0;
            visibility: visible;
            width: 100%;
            transition: opacity .4s ease;
            opacity: 1;
            background: white(primary);
            box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);

            &::before {
              position: absolute;
              z-index: 2;
              top: 0;
              left: 0;
              display: block;
              width: calc(100% - 16px);
              height: 20px;
              content: '';
              pointer-events: none;
              background: linear-gradient(to bottom, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0));
            }

            &::after {
              position: absolute;
              z-index: 2;
              bottom: 0;
              left: 0;
              display: block;
              width: calc(100% - 16px);
              height: 20px;
              content: '';
              pointer-events: none;
              background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.8));
            }
          }
        }

        &Scroll {
          display: flex;
          overflow-y: scroll;
          width: 100%;
          max-height: 220px;
          padding: 12px 8px 0 8px;

          flex-wrap: wrap;
        }

        &Items {
          display: flex;

          flex-wrap: wrap;

          &::after {
            display: block;
            width: 100%;
            height: 20px;
            content: '';
          }
        }

        &Item {
          display: flex !important;
          height: 36px !important;
          margin: 8px 0 0 8px !important;
          cursor: pointer;

          justify-content: flex-start;
          align-items: center;

          &:hover {
            .uModal {
              &__form {
                &Condition {
                  &SelectMultiple {
                    &Item {
                      &Button {
                        color: black(secondary);
                        border: 1px solid white(primary);
                        background: #f2f2f2;
                        box-shadow: 0px 3px 6px rgba(0, 0, 0, .16);

                        @media (pointer: coarse) {
                          color: black(tertiary);
                          border: 1px solid #ddd;
                          background: #f2f2f2;
                          box-shadow: none;
                        }
                      }

                      &Body {
                        &:checked {
                          &+.uModal {
                            &__form {
                              &Condition {
                                &SelectMultiple {
                                  &Item {
                                    &Button {
                                      font-weight: 700;
                                      color: #0d52a2;
                                      border: 1px solid white(primary);
                                      background: #f2f2f2;
                                      box-shadow: 0px 3px 6px rgba(0, 0, 0, .16);

                                      @media (pointer: coarse) {
                                        font-weight: 700;
                                        color: #0d52a2;
                                        border: 1px solid white(primary);
                                        background: linear-gradient(to right, #fff, #f2f2f2);
                                        box-shadow: 0px 1px 2px rgba(0, 0, 0, .16);
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          &Body {
            display: none;

            &:checked {
              &+.uModal {
                &__form {
                  &Condition {
                    &SelectMultiple {
                      &Item {
                        &Button {
                          font-weight: 700;
                          color: #0d52a2;
                          border: 1px solid white(primary);
                          background: linear-gradient(to right, #fff, #f2f2f2);
                          box-shadow: 0px 1px 2px rgba(0, 0, 0, .16);

                          &::before {
                            border-right: 2px solid #0d52a2;
                            border-bottom: 2px solid #0d52a2;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          &Button {
            font-size: 13px;
            font-weight: 400;
            position: relative;
            display: flex;
            width: 100%;
            height: 36px;
            padding: 0 13px 0 31px;
            transition: all .2s ease;
            color: black(tertiary);
            border: 1px solid #ddd;
            border-radius: 16px;
            background: #f2f2f2;
            box-shadow: none;

            justify-content: center;
            align-items: center;

            &::before {
              position: absolute;
              top: 10px;
              left: 14px;
              display: block;
              width: 6px;
              height: 11px;
              content: '';
              transition: all .2s ease;
              transform: rotate(45deg);
              border-right: 2px solid rgba(33, 33, 33, .16);
              border-bottom: 2px solid rgba(33, 33, 33, .16);
            }

            &::selection {
              background: none;
            }
          }
        }
      }

      &Toggle {
        display: flex;
        flex-direction: row-reverse;
        margin: 23px 0 0;

        &Body {
          display: none;

          &:checked {
            &+.uModal {
              &__form {
                &Condition {
                  &Toggle {
                    &Button {
                      &::before {
                        border: 1px solid #5b8bc3;
                        background: #e7eef7;
                      }

                      &::after {
                        right: 16px;
                        background: #0d52a2;
                      }
                    }
                  }
                }
              }
            }
          }
        }

        &Button {
          font-size: 14px;
          font-weight: 400;
          position: relative;
          display: inline-flex;
          padding: 10px 90px 10px 18px;
          cursor: pointer;
          color: black(primary);
          border: 1px solid #eee;
          border-radius: 4px;

          &::before {
            position: absolute;
            top: calc(50% - 10px);
            right: 12px;
            width: 48px;
            height: 20px;
            content: '';
            transition: all .2s ease !important;
            border: 1px solid #ddd;
            border-radius: 10px;
            background: #eee;
          }

          &::after {
            position: absolute;
            top: calc(50% - 7px);
            right: 42px;
            width: 14px;
            height: 14px;
            content: '';
            transition: all .2s ease !important;
            border-radius: 50%;
            background: rgba(33, 33, 33, 0.24);
          }

          &::selection {
            background: none;
          }
        }
      }

      &Delete {
        position: relative;
        display: flex;
        width: 40px;
        height: 40px;
        margin: 0 4px 0 0;
        cursor: pointer;
        transition: all .2s ease;
        color: black(secondary);
        border-radius: 50%;

        align-items: center;
        justify-content: center;
        flex-shrink: 0;

        &:hover {
          background: #f2f2f2;

          @media (pointer: coarse) {
            background: none;
          }
        }
      }

      &Add {
        display: flex;
        border: 1px solid #eee;
        border-radius: 4px;

        align-items: center;

        &:not(:first-child) {
          margin: 12px 0 0;
        }

        &::selection {
          background: none;
        }

        &Button {
          display: flex;
          cursor: pointer;

          flex-grow: 1;
          align-items: center;
        }

        &Text {
          font-size: 14px;
          display: flex;
          height: 52px;
          color: black(tertiary);

          align-items: center;
          flex-grow: 1;

          &::selection {
            background: none;
          }
        }

        &Icon {
          position: relative;
          display: block;
          width: 42px;
          height: 42px;
          margin: 0 6px 0 0;

          flex-shrink: 0;

          &::before {
            position: absolute;
            top: calc(50% - 1px);
            left: calc(50% - 7px);
            display: block;
            width: 14px;
            height: 2px;
            content: '';
            background: #0d52a2;
          }

          &::after {
            position: absolute;
            top: calc(50% - 1px);
            left: calc(50% - 7px);
            display: block;
            width: 14px;
            height: 2px;
            content: '';
            transform: rotate(90deg);
            transform-origin: center;
            background: #0d52a2;
          }
        }
      }

      &Side {
        position: relative;
        width: 160px;
        color: rgba(255, 255, 255, 0.7);
        border-radius: 0 2px 2px 0;
        background: linear-gradient(to right, #222, rgba(31, 31, 31, .8));

        &::after {
          position: absolute;
          bottom: 0;
          left: 0;
          display: block;
          width: 100%;
          height: 20px;
          content: '';
          background: linear-gradient(to bottom, rgba(34, 34, 34, 0), rgba(34, 34, 34, 0.8));
        }

        &Heading {
          font-size: 14px;
          font-weight: 700;
          padding: 47px 16px 0;
          color: white(secondary);
        }

        &List {
          overflow-y: scroll;
          height: calc(100% - 56px);
          margin: 6px 4px 0 0;
          padding: 0 16px;

          &::-webkit-scrollbar {
            width: 8px;
          }

          &::-webkit-scrollbar-button {
            display: none;
          }

          &::-webkit-scrollbar-track {
            background: #222;
          }

          &::-webkit-scrollbar-thumb {
            height: 100px;
            border: 1px solid #444;
            border-radius: 10px;
            background: #333;
          }
        }

        &Items {
          padding: 0 0 30px;
        }

        &Title {
          font-size: 13px;
          margin: 20px 0 0;
        }

        &Text {
          font-size: 13px;
          font-weight: 700;
          line-height: 1.4;
          margin: 1px 0 0;

          &--empty {
            font-size: 13px;
            line-height: 1.4;
            margin: 1px 0 0;
            color: black(tertiary);
          }
        }
      }
    }

    /*
    　フォーム - インフォメーション
    ---------------------------------*/
    &Info {
      font-size: 15px;
      font-weight: bold;
      line-height: 1.8;
      padding: 10px 15px;
      letter-spacing: 1px;
      color: #245ea2;
      border: 2px solid #245ea2;
      border-radius: 5px;
      background: #e2e9f0;
    }
  }

  /*
  　スクロール領域
  ---------------------------------*/
  &__scroll {
    position: relative;

    &::before {
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      display: block;
      width: 152px;
      height: 40px;
      content: '';
      pointer-events: none;
      background: linear-gradient(to bottom, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0));
    }

    &::after {
      position: absolute;
      z-index: 1;
      top: 0;
      right: 24px;
      display: block;
      width: 424px;
      height: 40px;
      content: '';
      pointer-events: none;
      background: linear-gradient(to bottom, rgba(238, 238, 238, .8), rgba(238, 238, 238, 0));
    }

    .uModal {
      &__form {
        overflow-x: hidden;
        overflow-y: visible;
        width: 100%;

        @media (min-height: 600px) {
          overflow-y: auto;
          max-height: calc(92vh - 144px);
        }
      }
    }

    &+.uModal {
      &__foot {
        &::before {
          position: absolute;
          z-index: -1;
          bottom: 80px;
          left: 0;
          display: block;
          width: 600px;
          height: 200px;
          content: '';
          pointer-events: none;
          border-left: 152px solid white(primary);
          background: #eee;
        }

        &::after {
          position: absolute;
          right: 24px;
          bottom: 80px;
          display: block;
          width: 424px;
          height: 40px;
          content: '';
          pointer-events: none;
          background: linear-gradient(to bottom, rgba(238, 238, 238, 0), rgba(238, 238, 238, .8));
        }
      }
    }
  }

  /*
  　フッター
  ---------------------------------*/
  &__foot {
    position: relative;
    display: flex;
    flex-direction: row-reverse;
    height: 80px;
    padding: 0 24px;
    border-top: 1px solid #ddd;
    background: white(primary);

    align-items: center;

    .uButton--rounded {
      margin-right: auto;
    }

    &--secondary {
      position: relative;
      display: flex;
      flex-direction: row-reverse;
      height: 80px;
      padding: 0 17px;
      background: white(primary);

      align-items: center;
    }
  }

  /*
  　ゴーストボタン
  ---------------------------------*/
  &__ghostButton {
    &Items {
      padding: 32px 0 0 24px;
    }

    &Item {
      &:not(:first-child) {
        margin: 24px 0 0;
      }
    }

    &Standard {
      &Body {
        font-size: 14px;
        display: inline-flex;
        height: 40px;
        padding: 0 32px;
        text-decoration: none !important;
        color: white(primary) !important;
        border: 1px solid #ccc;
        border-radius: 99px;
        background: rgba(0, 0, 0, .16);
        box-shadow: 3px 6px 30px rgba(0, 0, 0, .5);

        align-items: center;
        justify-content: center;
      }
    }

    &Select {
      position: relative;
      display: inline-flex;

      &::after {
        position: absolute;
        top: 16px;
        right: 20px;
        display: block;
        width: 8px;
        height: 8px;
        content: '';
        pointer-events: none;
        border-top: 7px solid white(primary);
        border-right: 4px solid transparent;
        border-left: 4px solid transparent;
      }

      &Body {
        font-size: 14px !important;
        font-weight: 400 !important;
        height: 40px !important;
        padding: 0 43px 0 19px !important;
        color: white(primary) !important;
        border: 1px solid #ccc !important;
        border-radius: 99px !important;
        outline: 0 !important;
        background: rgba(0, 0, 0, .16) !important;
        box-shadow: 3px 6px 30px rgba(0, 0, 0, .5) !important;

        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;
      }
    }
  }

  /*
  　タブ
  ---------------------------------*/
  &__tab {
    position: fixed;
    top: calc(4vh + 64px);
    left: calc(50% - 502px);
    display: flex;
    width: 108px;
    padding: 10px 0;
    border-radius: 15px;
    background: #2c2c2c;
    box-shadow: 0 6px 12px rgba(0, 0, 0, .32);

    justify-content: center;

    @media (pointer: coarse) {
      z-index: 10005002;
      top: auto;
      bottom: 24px;
      left: 50%;
      display: flex;
      width: auto;
      height: 48px;
      padding: 6px 4px;
      transform: translate3d(-50%, 0, 0);
      border-radius: 24px;

      .uModal__inner & {
        display: none;
      }
    }

    &Backdrop {
      position: fixed;
      z-index: 18;
      z-index: 10005001;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0);
    }

    &Items {
      @media (pointer: coarse) {
        display: flex;
      }
    }

    &Item {
      margin: 10px 0;

      @media (pointer: coarse) {
        margin: 0 2px;
      }

      &:nth-child(n+6) {
        @media (pointer: coarse) {
          display: none;
        }
      }

      &--more {
        display: none;

        @media (pointer: coarse) {
          display: block;
          margin: 0 2px 0 11px;
        }

        .uModal__tabLink {
          width: 56px;
          color: white(primary);

          &--active {
            width: 56px;
            color: white(primary);
          }
        }

        .icon-m-dots {
          z-index: 1;
        }
      }
    }

    &Link {
      position: relative;
      display: flex;
      width: 84px;
      height: 36px;
      text-decoration: none !important;

      align-items: center;
      justify-content: center;

      &--active {
        position: relative;
        display: flex;
        width: 84px;
        height: 36px;
        text-decoration: none !important;

        align-items: center;
        justify-content: center;

        &::before {
          position: absolute;
          width: 100%;
          height: 100%;
          content: '';
          opacity: 0.72;
          border: 1px solid #898989;
          border-radius: 50px;
          background: linear-gradient(to right, #969696, #727272);
          box-shadow: 0 1px 2px rgba(0, 0, 0, .16);
        }

        .uModal__tabText {
          font-size: 14px;
          font-weight: 700;
          z-index: 1;
          color: white(primary);
        }
      }
    }

    &Text {
      font-size: 13px;
      z-index: 1;
      color: white(primary);
    }

    &Lists {
      position: absolute;
      right: 0;
      bottom: 100%;
      display: block;
      visibility: visible;
      overflow-y: scroll;
      width: 300px;
      max-height: 377px;
      opacity: 1;
      border-radius: 4px;
      background: #eee;
      box-shadow: 0 3px 30px rgba(0, 0, 0, 0.5);
    }

    &List {
      font-size: 14px !important;
      display: flex;
      height: 53px;
      padding: 0 16px;
      white-space: nowrap;
      text-decoration: none !important;
      color: black(primary) !important;
      background: white(primary);

      align-items: center;

      &:not(:first-child) {
        margin: 1px 0 0 !important;
      }

      &::after {
        position: absolute;
        right: 18px;
        display: block;
        width: 12px;
        height: 12px;
        content: '';
        transform: rotate(45deg);
        border-top: 2px solid black(primary);
        border-right: 2px solid black(primary);
      }

      &--active {
        font-size: 14px !important;
        font-weight: 700;
        display: flex;
        height: 53px;
        padding: 0 16px;
        white-space: nowrap;
        text-decoration: none !important;
        color: black(primary) !important;
        background: #f2f6fc;

        align-items: center;
        justify-content: space-between;

        &:not(:first-child) {
          margin: 1px 0 0 !important;
        }

        &::after {
          font-size: 14px;
          font-weight: 400;
          content: '表示中';
          color: black(primary) !important;
        }
      }
    }
  }

  /*
  　繁殖作業履歴
  ---------------------------------*/
  &__history {
    position: relative;
    overflow-y: scroll;
    width: 768px;
    min-height: 335px;
    max-height: calc(92vh - 64px);
    border: 1px solid rgba(57, 57, 57, .48);
    border-radius: 16px;
    background: linear-gradient(to right, #2c2c2c, #2e2e2e);
    box-shadow: 0 6px 12px rgba(0, 0, 0, .32);

    @media (pointer: coarse) {
      max-height: calc(92vh - 194px);
    }

    &Inner {
      display: flex;

      flex-grow: 1;
    }

    &Main {
      display: flex;
      min-height: 335px;
      padding: 23px 20px 23px 24px;

      flex-grow: 1;
    }

    &Days {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 56px;
      padding: 16px 8px 20px;
      border-radius: 8px;
      background: #333;

      flex-shrink: 0;

      &Heading {
        font-size: 13px;
        line-height: 1.3;
        text-align: center;
        color: white(secondary);
      }

      &Line {
        position: relative;
        display: flex;
        height: 100%;
        padding: 20px 0 0;

        flex-grow: 1;
        justify-content: center;

        &::after {
          display: block;
          width: 1px;
          height: 100%;
          content: '';
          background: #666;
        }
      }
    }

    &Events {
      padding: 0 13px 0 17px;

      flex-grow: 1;
    }

    &Year {
      width: 48px;
      border-radius: 8px;
      background: #333;

      flex-shrink: 0;
    }

    &Items {
      padding: 20px 0;
    }

    &Item {
      &Interval {
        position: absolute;
        top: 0;
        left: -73px;
        display: flex;
        flex-direction: column;
        width: 56px;
        height: calc(100% + 20px);

        &Value {
          font-size: 13px;
          display: flex;
          height: 30px;
          margin: 14px 0 0;
          color: white(secondary);
          background: #333;

          align-items: center;
          justify-content: center;
        }

        &Line {
          position: relative;
          display: flex;

          flex-grow: 1;
          justify-content: center;

          &::before {
            position: absolute;
            top: -4px;
            right: 0;
            left: 0;
            width: 11px;
            height: 10px;
            margin: auto;
            content: '';
            border-right: 5px solid transparent;
            border-bottom: 7px solid #666;
            border-left: 5px solid transparent;
          }
        }
      }

      &Year {
        font-size: 14px;
        font-weight: 700;
        position: absolute;
        top: 0;
        left: 601px;
        display: flex;
        width: 48px;
        height: 37px;
        padding: 18px 0 0;
        color: rgba(255, 255, 255, .4);
        background: #333;

        justify-content: center;

        &:not([data-index="0"]) {
          border-top: 4px solid #2e2e2e;
        }
      }

      &Head {
        display: flex;

        align-items: center;

        &Label {
          font-size: 13px;
          font-weight: 700;
          display: inline-flex;
          height: 28px;
          padding: 0 8px;
          color: white(primary);
          border: 1px solid #522df0;
          border-radius: 4px;
          background: #4c27e8;

          align-items: center;
        }

        &Text {
          font-size: 14px;
          font-weight: 700;
          margin: 0 0 0 16px;
          color: black(primary);
        }
      }

      &Body {
        display: flex;
        margin: 12px 0 0;
        padding: 12px 0;
        border-radius: 4px;
        background: #ddd;

        &Date {
          display: flex;
          width: 67px;
          margin: 10px 0;
          text-align: center;

          justify-content: center;
          flex-shrink: 0;

          &Inner {
            font-size: 13px;
            width: 3em;
            color: black(primary);
          }
        }

        &Items {
          display: flex;
          flex-direction: column;
          padding: 10px 16px;
          border-left: 1px solid #ccc;

          justify-content: center;
          flex-grow: 1;
        }

        &Item {
          display: flex;

          &:not(:first-child) {
            margin: 5px 0 0;
          }

          &Heading {
            font-size: 14px;
            width: 120px;
            padding: 0 18px 0 0;
            color: black(secondary);

            flex-shrink: 0;
          }

          &Value {
            font-size: 14px;
            font-weight: 700;
            color: black(primary);
          }
        }

        &Group {
          margin: 12px 0 0;
          border-radius: 4px;
          background: #ddd;

          &Head {
            position: relative;
            display: flex;
            height: 42px;
            margin: 0 16px;
            border-bottom: 1px solid white(primary);

            align-items: center;

            &Heading {
              font-size: 14px;
              width: 102px;
              color: black(secondary);

              flex-shrink: 0;
            }

            &Value {
              font-size: 14px;
              font-weight: 700;
              color: black(primary);
            }
          }

          &Label {
            font-size: 14px;
            margin: 16px 0 0;
            padding: 0 16px;
            color: black(secondary);
          }

          &Items {
            margin: 6px 0 0;
            padding: 0 16px 14px;

          }

          &Item {
            position: relative;
            display: flex;

            &Year {
              font-size: 14px;
              font-weight: 700;
              position: absolute;
              top: 0;
              left: 366px;
              display: flex;
              width: 48px;
              padding: 18px 0 0;
              color: rgba(255, 255, 255, .4);
              background: #333;

              justify-content: center;

              &:not([data-index="0"]) {
                border-top: 4px solid #2e2e2e;
              }
            }

            &Heading {
              font-size: 14px;
              padding: 4px 16px 4px 0;
            }

            &Value {
              font-size: 13px;
              padding: 4px 16px;
              border-left: 1px solid #ccc;
            }
          }
        }
      }

      &--primary {
        position: relative;
        margin: 0 0 60px;
        border-bottom: 1px solid rgba(87, 87, 87, .32);

        .uModal__historyItemInner {
          width: 435px;
          margin: 20px 0 40px 135px;

          @extend %uModal__historyItemInner;
        }

        .uModal__historyItemDays {
          position: absolute;
          bottom: calc(100% - 29px);
          left: -68px;
          visibility: hidden;
          width: 68px;
          content: '';
          border-bottom: 1px solid #666;
          border-left: 1px solid #666;

          &::before {
            position: absolute;
            top: -10px;
            left: -6px;
            width: 11px;
            height: 10px;
            content: '';
            border-right: 5px solid transparent;
            border-bottom: 7px solid #666;
            border-left: 5px solid transparent;
          }

          &Body {
            position: absolute;
            top: 29px;
            left: calc(50% - 102px);
            display: block;
            width: 133px;
            height: 72px;
            border-radius: 4px;
            background: #eee;

            &Items {
              display: flex;
              flex-direction: column;
              height: 100%;
              padding: 14px 0 14px 6px;

              justify-content: space-between;
            }

            &Item {
              display: flex;
            }

            &Heading {
              font-size: 13px;
              display: block;
              width: 82px;
              color: black(secondary);

              flex-shrink: 0;
            }

            &Value {
              font-size: 13px;
              display: block;
              white-space: nowrap;
              color: black(primary);
            }
          }
        }
      }

      &--secondary {
        position: relative;

        .uModal__historyItemInner {
          width: 377px;
          margin: 20px 0 20px 211px;

          @extend %uModal__historyItemInner;
        }

        .uModal__historyItemDays {
          position: absolute;
          top: 30px;
          left: -42px;
          visibility: hidden;
          width: 42px;
          content: '';
          border-top: 1px solid #666;
          border-left: 1px solid #666;

          &Heading {
            font-size: 13px;
            display: block;
            margin: 10px 0 0;
            text-align: center;
            color: white(secondary);
          }

          &Value {
            font-size: 13px;
            display: block;
            margin: 2px 0 0;
            text-align: center;
            color: white(secondary);
          }
        }
      }

      &--tertiary {
        position: relative;

        .uModal__historyItemInner {
          width: 377px;
          margin: 20px 0 20px 207px;

          @extend %uModal__historyItemInner;
        }

        .uModal__historyItemBodyGroupItemYear {
          top: -20px;
        }
      }

      &--quaternary {
        position: relative;

        .uModal__historyItemInner {
          width: 377px;
          margin: 20px 0 20px 207px;

          @extend %uModal__historyItemInner;
        }
      }
    }
  }

  /*
  　TOPへ戻る
  ---------------------------------*/
  &__pageTop {
    position: fixed;
    right: calc(50% - 486px);
    bottom: 4vh;
    width: 80px;

    @media (pointer: coarse) {
      display: none;
    }

    &Button {
      position: relative;
      display: block;
      width: 58px;
      height: 58px;
      margin: auto;
      border: 1px solid #414141;
      border-radius: 50%;
      background: #2e2e2e;
      box-shadow: 0 6px 12px rgba(0, 0, 0, .16);

      &::before {
        position: absolute;
        top: 26px;
        right: 18px;
        left: 22px;
        display: block;
        width: 12px;
        height: 12px;
        content: '';
        transform: rotate(45deg);
        border-top: 2px solid white(primary);
        border-left: 2px solid white(primary);
      }
    }

    &Text {
      font-size: 14px;
      margin: 16px 0 0;
      text-align: center;
      white-space: nowrap;
      color: white(primary);
    }
  }

  &__table {
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse;
    border: 0 !important;
  }

  &__row {
    background: none !important;
  }

  &__header {
    font-size: inherit !important;
    min-height: auto !important;
    text-align: left !important;
    color: inherit !important;
    border: 0 !important;
    background: none !important;
  }

  &__data {
    font-size: inherit !important;
    min-height: auto !important;
    text-align: left !important;
    color: inherit !important;
    border: 0 !important;
    background: none !important;
  }
}
