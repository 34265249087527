.activity-rate-chart-message {
  text-align: center;
  width: 100%;
  padding-top: 130px;
  display: block;
}
.summary-activity-rate-chart-message {
  text-align: center;
  width: 100%;
  padding-top: 50px;
  display: block;
}
#activity-rate-chart {
  svg {
    g.c3-regions {
      g.c3-region {
        &.score-color1 { fill : steelblue; }
        &.score-color2 { fill : #389c64; }
        &.score-color3 { fill : #418632; }
        &.score-color4 { fill : #ffff00; }
        &.score-color5 { fill : #ff0000; }
      }
    }
  }
  .c3-tooltip-container {
    table {
      th,
      td {
        width: auto;
      }
    }
  }
  .c3-xgrid-lines {
    .c3-xgrid-line {
      &.breeding-date {
        line {
          stroke: #bc0822;
        }
        text {
          fill: #bc0822;
        }
      }
    }
  }
}
