/*
  よく使われてる共通のもの（色、書体、スペーシング、など）
  $1. COLORS - DEPRECATED
  $2. Redesigned Colors
  $3. Widths & spacing
  $4. Common font sizes
  $5. Branding
  $6. Animation & transitions
  $7. Media query widths
*/

// $1. COLORS - DEPRECATED
$orange: #ff9908;
$limeGreen: #8aa633;
$darkLimeGreen: #7c9825;
$beige: #f5f5f5;
$lighterGreyOld : #ccc;
$greyOld : #555;

// ブランド色
$primaryColorOld: $limeGreen;
$primaryColorDarkOld: $darkLimeGreen;

// ホバーするときの色
$rowHoverColor: $primaryColorOld;

/**
  $2. Redesigned color palette
*/
$darkestGrey: #262626;
$darkerGrey: #2d2d2d;
$darkGrey: #383838;
$midGrey: #4e4e4e;
$grey: #60615e;
$lightGrey: #ddd;
$lighterGrey: #f2f2f2;

$mildGrey: #878787;
$milderGrey: #8f8f8f;

$lightGreyBorder: #ccc;

$warningRed: #f00;

// Branding colours
$desamisGreen: #477d17;
$desamisGreenDark: #3b6713;
$textGrey: #333;
$body-bg: #1f1f1f;

// Shadows

$default-shadow: 0 0.6rem 1.5rem rgba(62, 62, 62, 0.8);

/**
  $3. Widths & spacing
*/
// Header and sub headers
$header-height: 56px;
$sub-header-height: 72px;
$content-offset: $header-height+$sub-header-height;

// Main nav
$nav-width-minimised: 60px;
$nav-width-maximised: 200px;

// Header width
$header-width: 100%;
$search-component-width: 240px;
$event-component-width: 305px;
$title-component-width: calc(100% - #{$search-component-width} - #{$event-component-width});

$cow-detail-header-height: 48px;

// Top left logo
$logo-height: $header-height;
$nav-toggler-height: $sub-header-height;

/**
  $4. Common font sizes
*/
// default menu text size
$nav-font-size: 1.5rem;

/**
  $5. Branding
*/
$brandColor : $desamisGreen;

// Brand line across header
$imageColourBorderWidth: 5px;

/**
  $6. Animation & transitions
*/
// Default transition for animations
$default-transition: all 100ms ease;

/**
  $7. Media query widths
*/

$wide-screen: "only screen and (min-width: 1200px)";

$small-screen: "only screen and (max-width: 1024px)";

$tablet-landscape: "only screen and (min-device-width: 900px) and (max-device-width: 1200px)";

$tablet-portrait: "only screen and (min-device-width: 600px) and (max-device-width: 900px)";