.alert-calving-sensor-dropped {
  overflow: auto;

  .alert-calving-sensor-dropped-title {
    .uHeading__title {
      font-size: 21px !important;
    }

    &-count {
      font-size: 21px;
      margin-left: 25px;
    }
  }

  .alert-calving-sensor-dropped-content {
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
  }

  .alert-calving-sensor-dropped-card-wrap {
    box-sizing: border-box;
    padding: 5px;
  }

  .alert-calving-sensor-dropped-card {
    background: #ffffff;
    border-radius: 4px;
    margin: 0;
    overflow: auto;
    padding: 24px 16px 24px 16px;
    width: 424px;

    &-content {
      margin-bottom: 24px;

      .content-primary {
        display: flex;
        margin-bottom: 24px;
        flex-wrap: wrap;

        .content-item {
          margin: 0 40px 0 0;
          padding: 8px 0 0 0;

          .content-item-label {
            color: #2121219e;
            font-size: 13px;
            font-weight: 700;
            margin: 0 0 8px 0;
          }

          .content-item-value {
            color: #212121;
            display: block;
            font-size: 15px;
            font-weight: 400;

            &.calving-sensor-number {
              font-size: 17px;
            }
          }
        }
      }

      .content-secondary {
        border-radius: 4px;

        &-title {
          color: #2121219e;
          font-size: 13px;
          font-weight: 700;
          margin: 0 0 10px 8px;
        }

        &-body {
          background: #f0f0f0;
          padding: 24px 16px 24px 16px;
        }

        .content-row {
          display: flex;
          flex-wrap: wrap;
          margin-bottom: 12px;

          &:last-child {
            margin-bottom: 0;
          }
        }

        .content-item {
          display: flex;
          flex: 1;
          margin: 0 0 0 0;

          &-label {
            color: #2121219e;
            font-size: 13px;
            font-weight: 400;
            min-width: 80px;
            width: 80px;
          }

          &-value {
            color: #2121219e;
            font-size: 13px;
            font-weight: 700;
            word-break: break-all;

            &.cow-no {
              color: #2a66b7;
            }
          }
        }
      }
    }

    &-operation {
      align-items: center;
      display: flex;
      height: 100%;
      justify-content: flex-end;

      &-button {
        align-items: center;
        background: linear-gradient(90deg,#387ac7,#0d3898);
        border: 1px solid rgba(0,0,0,.12);
        border-radius: 4px;
        box-shadow: 0 2px 3px rgba(0,0,0,.36);
        color: #fff;
        cursor: pointer;
        display: flex;
        font-size: 14px;
        font-weight: 700;
        height: 40px;
        justify-content: space-between;
        margin: 0 0 0 24px;
        opacity: 1;
        padding: 0 5px 0 19px;
        transition: all .1s ease;
        width: 176px;

        &:hover {
          border: 1px solid rgba(0, 0, 0, 0.08);
          background: linear-gradient(to right, #3e8eeb, #377adc, #1f34a8);
          box-shadow: 0 3px 6px rgba(0, 0, 0, 0.36);
        }
      }
    }
  }

  @media screen and (max-width: 1024px) and (pointer: coarse) {
    .alert-calving-sensor-dropped-card-wrap {
      box-sizing: border-box;
      flex-basis: 33%;
      max-width: 33%;
      min-width: 320px;
      padding: 10px;
    }

    .alert-calving-sensor-dropped-card {
      width: 100%;

      &-content {
        .content-secondary {
          .content-row {
            flex-direction: column;
            margin-bottom: 0;

            .content-item {
              margin-bottom: 8px;

              &-label {
                min-width: 88px;
                width: 88px;
              }
            }

            &:last-child .content-item {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 768px) and (pointer: coarse) {
    .alert-calving-sensor-dropped-card-wrap {
      box-sizing: border-box;
      flex-basis: 50%;
      max-width: 50%;
      min-width: 360px;
      padding: 10px;
    }
  }
}
