.schedule-list {
  .toolbar {
    background: #fff;
    overflow: auto;
    height: 45px;

    // 旧アイコンフォント調整
    span {
      font-size: 1.5rem !important;
      display: flex;
      align-items: center;
    }
  }

  h4 {
    display: flex;
    justify-content: space-between;
  }

  .left-area {
    padding: 0 6px;
  }

  .right-area {
    padding: 0 6px;
  }

  .content-box .section {
    margin-bottom: 0px;
  }

  .content-box div.p10 {
    padding-bottom: 0px !important;
  }

  .customlist-th-inner {
    justify-content: flex-start;
  }

  .cow-labels {
    font-size: 1.2rem !important;
    font-weight: 700;
    display: inline-block;
    margin: 2px 0 0 3px;
    padding: 1px 5px;
    color: #666;
    border: 1px solid #eee;
    border-radius: 2px;
    background-color: #f0f0f0 !important;
  }

  .task-area {
    margin-bottom: 80px;
  }
}

.barn--reproductionPlannedEventsList {
  min-width: auto !important;

  .result {
    display: grid;
    padding: 0 !important;
  }

  .table-fix {
    width: auto !important;

    .col-w30 {
      min-width: 30px;
    }

    .col-w60 {
      min-width: 60px;
    }

    .col-w70 {
      min-width: 70px;
    }

    .col-w80 {
      min-width: 80px;
    }

    .col-w90 {
      min-width: 90px;
    }

    .col-w100 {
      min-width: 100px;
    }

    .col-w110 {
      min-width: 110px;
    }

    .col-w120 {
      min-width: 120px;
    }

    .col-w150 {
      min-width: 150px;
    }

    .col-w190 {
      min-width: 190px;
    }
  }
}
