.reproduction-summary {

  .sort-icon {
    display: none;
  }

  .strech-vertical {
    height: 100%;
    display: flex;
    .u-loader {
      margin: auto;
    }
  }

  .toolbar-alternative.display {
    min-width: auto;
    max-width: auto;
    padding: 1em;
    margin: none;
    right: 0.5em;
    top: calc(50% - 16px);
    background-color: initial;
    color: white;
  }

  .message {
    font-size: 1.2em;
    &.error {
      color: red;
    }
  }
  .split-view {
    display: flex;
    flex-direction: row;
    height: auto;

    .split-view-panel.left {
      width: 662px;
      flex-shrink: 0;
    }
    .split-view-panel.right {
      flex-grow: 1;

      .panel-content {
        height: calc(100vh - 2em - 113px);
        .ui-grid-header-cell, .ui-grid-cell {
          cursor: default;
        }
      }
    }
    .split-view-panel {
      .panel-content {
        background-color: white;

        margin: 0 1em 1em;
        padding: 1em;
      }

      .summary-table {
        border-collapse: collapse;
      }

      .summaries, .summary-headers {
        border-left: 1px solid gray;
      }

      .row {
        border-top: 1px solid gray;
        display: flex;
        flex-direction: row;

        .cell {
          flex: 1;
          padding: 0.3rem;
          min-width: 98px;
          border-right: 1px solid gray;
          text-align: right;

          .notes {
            font-size: 1.1rem;
          }
        }
        .cell.value {
          cursor: pointer;
          &:hover {
            background-color: lightgray;
          }
          &.selected {
            background-color: lightgray;
          }
        }

      }
      .summary-headers {
        border-right: 1px solid gray;
        .row {
          .cell {
            border-right: none;
          }
        }
      }

      .totals {
        background-color: #eee;
        border-bottom: 1px solid gray;
        border-right: 1px solid gray;
        .row {
          .cell {
            border-right: none;
          }
        }
      }
    }
  }
}
