@media print {
  #customlist-table {
    margin-top: 0 !important;
    thead {
      visibility: visible !important;
    }
    a[href]:after {
      content: "";
    }
  }
  #customlist-table.clone {
    display: none !important;
  }

  .fixed-table-wrapper {
    overflow: visible !important;
    height: 100% !important;
    width: 100%;
  }
}
