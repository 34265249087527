.carcass-report {
  @media print {
    .print {
      display: block !important;
    }
    .no-print {
      display: none !important;
    }
    .table-info.print {
      display: flex !important;
      div{
        padding: 5px;
      }
    }
    .fixed-tb {
      height: 100% !important;
      overflow: visible !important;
      carcass-report-table-print {
        height: 100% !important;
        overflow: visible !important;
        .carcass-report-table-print {
          $cols: 27;
          $col-width: calc(100% / #{$cols});
          height: 100% !important;
          overflow: visible !important;
          border: none !important;
          th, td {
            width: $col-width;
            border: 1px solid #333 !important;
            border-right: none !important;
            border-bottom: none !important;
          }
          tr {
            th:last-child, td:last-child {
              border-right: 1px solid #333 !important;
            }
          }
          thead {
            tr:first-child {
              th:first-child {
                border-right: none !important;
                border-top: none !important;
                border-left: none !important;
              }
            }
          }
          tbody {
            border-bottom: 1px solid #333 !important;
            .category {
              border-top: none !important;
              border-bottom: none !important;
            }
            tr {
              td.category.filled {
                border-top: 1px solid #333 !important;
              }
            }
            td {
              white-space: nowrap;
            }
          }
          tr.border-bottom-thick {
            border-bottom: 2px solid #333 !important;
          }
        }
      }
    }
  }
}
