.row {
  margin: 0;
  padding: 0;
}

.center {
  text-align: center;
}

.no-padding {
  padding: 0px;
}

a {
  font-size: 1.2rem;
  color: #449dff;

  &:hover,
  &:active,
  &:visited,
  &:link {
    text-decoration: none;
    outline: none;
  }

  &:hover {
    cursor: pointer;
    text-decoration: underline;

    img {
      opacity: 0.8;

      filter: alpha(opacity=80);
    }
  }
}

.clear-fix {
  zoom: 1;

  &:before {
    display: block;
    overflow: hidden;
    content: "";
  }

  &:after {
    display: block;
    clear: both;
    overflow: hidden;
    content: "";
  }
}

.hover:hover {
  opacity: 0.8;

  filter: alpha(opacity=80);
}

.error-message {
  padding: 2px;
  color: #ff573f;
}

.warning-message {
  padding: 2px;
  color: #ff9908;
}

ol,
ul {
  list-style: none;
}

table {
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
  border: 1px solid #ddd;
  border-top: none;
  border-bottom: none;

  th {
    font-size: 1.2rem;
    min-height: 33px;
    padding: 5px;
    text-align: center;
    vertical-align: middle;
    color: #fff;
    border-top: 1px solid #dbdbdb;
    border-right: 1px solid #eee;
    background: #777;

    span {
      font-weight: normal;
    }

    .unit {
      margin-left: 0;
      color: #fff;
    }
  }

  td {
    font-size: 1.2rem;
    min-height: 1px;
    padding: 5px;
    vertical-align: middle;
    border-top: 1px solid #ddd;
    border-right: 1px solid #ddd;

    &.stylish {
      text-align: left !important;
    }

    .cow-no {
      font-weight: bold;
      text-align: center;
    }
  }

  &.tb-row4 {
    border-left: none;

    th {
      border-right: none;
      border-left: none;
    }

    td {
      border-right: none;
    }

    tr {
      &:first-child th {
        border-top: 1px solid #979694;
      }

      &:last-child th {
        border-bottom: 1px solid #979694;
      }
    }
  }

  &.tb-col {
    th {
      border-top: none;
    }

    tr {
      &:first-child th {
        &:first-child {
          border-left: 1px solid #979694;
        }

        &:last-child {
          border-right: 1px solid #979694;
        }
      }

      &:nth-of-type(1) td {
        border-top: none;
      }
    }
  }

  tr:nth-child(odd) {
    background-color: #f7f7f7;
  }

  &.stylish {
    border: none;

    th {
      text-align: left;
      border: none;
    }

    tr,
    td {
      border: none;
    }

    td {
      font-size: 1.2rem;
      min-height: 30px;
      text-align: left;
      vertical-align: middle;
    }
  }

  &.stylish-with-border {
    border: 1px solid #ddd;

    tr {
      &:first-child th {
        border-top: 1px solid #979694;
      }

      &:last-child th {
        border-bottom: 1px solid #979694;
      }
    }

    th {
      text-align: left;
    }

    td {
      font-size: 1.2rem;
      min-height: 20px;
      padding: 2px;
      text-align: left;
      vertical-align: middle;
    }
  }

  &.w260 {
    width: 260px !important; // override .barn *
  }

  &.w400 {
    width: 400px !important; // override .barn *
  }

  &.smart {
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse;
    border: none;

    tr {
      border-bottom: 1px solid #eee;

      &:first-child th {
        &:first-child {
          border-left: none;
        }

        &:last-child {
          border-right: none;
        }

        &:nth-of-type(1) td {
          border-top: none;
        }
      }
    }

    th {
      font-size: 1.2rem;
      min-height: 25px;
      padding: 5px 0 5px 5px;
      text-align: left;
      text-align: left;
      vertical-align: middle;
      color: #000;
      border-right: none;
      border-bottom: 1px solid #303030;
      background: #eee;

      span {
        font-weight: bold;
      }

      .unit {
        margin-left: 0;
        color: #dfdfdf;
        color: #fff;
      }
    }

    tr:nth-child(odd) {
      background-color: #fff;
    }

    td {
      font-size: 1.2rem;
      min-height: 20px;
      text-align: left;
      vertical-align: middle;
      border: none;
    }

    tr th:first-child,
    tr td:first-child {
      border-left: none;
    }
  }
}

img {
  width: 100%;
  border: 0;
}

p {
  font-size: 1.2rem;
  margin: 0;
}

b {
  font-weight: bold;
}

strong {
  font-weight: bold;
}

abbr[title] {
  border-bottom: 1px dotted;
}

input {
  font-size: 100%;
  line-height: normal;
  margin: 0;
  vertical-align: baseline;
}

textarea {
  font-size: 1.2rem;
  line-height: 18px;
  display: inline-block;
  overflow: auto;
  height: 5em;
  margin: 0;
  padding: 2px 6px;
  transition: border linear 0.2s, box-shadow linear 0.2s;
  vertical-align: baseline;
  color: #555;
  border: 1px solid #ccc;
  border-radius: 3px;
  background: #fff;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);

  &:focus {
    outline: 0;
  }
}

button {
  font-size: 100%;
  line-height: normal;
  margin: 0;
  cursor: pointer;
  vertical-align: baseline;
  border: none;
  outline: none;
  background: transparent;

  &:focus {
    outline: 0;
  }

  &.alert-action {
    font-size: 1.1rem;
    display: inline-block;
    padding: 1px 4px;
    cursor: pointer;
    text-align: center;
    color: #ee9210;
    border: 1px solid #ff9908;

    &:hover {
      color: #fff;
      background-color: #ff9908;
    }
  }

  &.provisional-delete-button {
    color: red;
  }
}

select {
  font-size: 1.2rem;
  line-height: 18px;
  display: inline-block;
  margin: 0;
  padding: 2px 6px;
  transition: border linear 0.2s, box-shadow linear 0.2s;
  vertical-align: baseline;
  color: #555;
  border: 1px solid #ccc;
  border-radius: 3px;
  background: #fff;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  min-height: 2em;
}

input[type="button"],
input[type="reset"],
input[type="submit"] {
  cursor: pointer;
}

input[type="checkbox"] {
  margin-right: 5px;
  vertical-align: middle;

  &.no-margin {
    margin: 0;
  }
}

h4 {
  font-size: 1.5rem;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 15px;
  padding: 10px;
  vertical-align: middle;
  background-color: #f6f6f6;

  i {
    font-size: 3rem;
    margin-top: -3px;
    color: #739315;
  }
}

h5 {
  font-size: 1.6rem;
  font-weight: bold;
  padding: 18px 10px 10px;
  text-align: center;

  &.tag {
    font-size: 1.2rem;
    display: inline-block;
    min-width: 110px;
    margin-top: -15px;
    padding: 3px 10px;
    text-align: center;
    color: #fff;
    border: none;
    border-radius: 0 0 5px 5px;
    background: #009943;
  }
}

span {
  font-size: 1.2rem;

  &.item-label-w60 {
    display: inline-block;
    width: 60px;
    text-align: right;
  }

  &.fix-length-w400 {
    display: inline-block;
    width: 400px;
  }

  &.fix-length-w350 {
    display: inline-block;
    width: 350px;
  }

  &.fix-length-w320 {
    display: inline-block;
    width: 320px;
  }

  &.vertical-align-middle {
    vertical-align: middle;
  }
}

li {
  font-size: 1.2rem;
}

dt {
  font-size: 1.2rem;
  float: left;
  clear: both;
  width: 120px;
  height: auto;
  padding: 7px 10px;
}

dd {
  font-size: 1.2rem;
  width: auto;
  height: auto;
  margin-left: 120px;
  padding: 7px 10px;
  border-top: 1px dotted #ccc;
  background: #fff;
}

dl {
  zoom: 1;
  width: 100%;

  &:before {
    display: block;
    overflow: hidden;
    content: "";
  }

  &:after {
    display: block;
    clear: both;
    overflow: hidden;
    content: "";
  }
}

.barn .wrapper {
  .content {
    position: relative;
    overflow: hidden;
    margin: 0;
    padding-top: $content-offset;
  }

  .fixed-tb {
    overflow: auto;
    width: 100%;
    height: calc(100vh - 186px);

    th {
      padding: 8px 0;
    }
  }

  .table-fix {
    th {
      font-size: 1.3rem;

      span {
        font-size: 1.3rem;
      }
    }

    td {
      font-size: 1.4rem;

      a,
      span {
        font-size: 1.4rem;
      }
    }
  }

  table.smart {
    th {
      font-size: 1.3rem;
    }

    td {
      font-size: 1.4rem;
    }
  }

  table.stylish {
    th {
      font-size: 1.3rem;
    }

    td {
      font-size: 1.4rem;
    }
  }

  .ui-grid-cell-contents {
    font-size: 1.4rem;

    a {
      font-size: 1.4rem;
      color: #0b57d0;

      &:hover,
      &:focus {
        color: #0b57d0;
      }
    }
  }

  .ui-grid-cell {
    span {
      font-size: 1.4rem;
    }
  }
}

.scroll-outer {
  overflow: auto;
}

.display-none {
  display: none !important;
}

.display-block {
  display: block;
}

.overflow-hidden {
  overflow: hidden;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.clear-both {
  clear: both;
}

.table {
  display: table;
  width: 100%;

  li {
    display: table-cell;
    padding: 0;
  }

  &.fixed {
    table-layout: fixed;
  }
}

.table-fix {
  table-layout: fixed;
}

.input-block {
  display: block;
  width: 100% !important;
}

.width-block {
  display: block;
  width: 100%;
}

.p0 {
  padding: 0 !important;
}

.p5 {
  padding: 5px;
}

.p10 {
  padding: 10px;
}

.align-right {
  text-align: right !important;
}

.align-left {
  text-align: left !important;
}

.align-center {
  text-align: center !important;
}

.unit {
  font-size: 1.1rem;
  font-weight: normal;
  margin-left: 5px;
  color: #666;
}

.clear-btn {
  font-size: 1.1rem;
  font-weight: normal;
  left: -25px;
  color: #666;
}

input[type="text"],
input[type="number"] {
  font-size: 1.2rem;
  line-height: 18px;
  display: inline-block;
  padding: 2px 6px;
  transition: border linear 0.2s, box-shadow linear 0.2s;
  color: #555;
  border: 1px solid #ccc;
  border-radius: 3px;
  background: #fff;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);

  &.view-only {
    background-color: #f6f4f4;
  }

  &.view-only-w400 {
    width: 400px;
    background-color: #f6f4f4;
  }

  &.vertical-align-middle {
    vertical-align: middle;
  }
}

input[type="password"] {
  font-size: 1.2rem;
  line-height: 18px;
  display: inline-block;
  padding: 2px 6px;
  transition: border linear 0.2s, box-shadow linear 0.2s;
  color: #555;
  border: 1px solid #ccc;
  border-radius: 3px;
  background: #fff;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);

  &:focus {
    border-color: rgba(82, 168, 236, 0.8);
    outline: 0;
    outline: thin dotted #111111;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(82, 168, 236, 0.6);
  }
}

.sprite {
  display: inline-block;
  vertical-align: middle;
  background: url(../images/sprite.png) 0 0 no-repeat;
}

.section h4:first-child {
  margin-top: 0;
}

.section-inner01 {
  padding: 10px 10px 0;

  .section {
    padding-bottom: 10px;
    box-shadow: none !important;
  }
}

.add-border01 {
  border: 1px solid #dadada !important;
}

.right-shoulder {
  position: relative;
  width: 100%;

  .edit-btn {
    position: absolute;
    top: 6px;
    right: 6px;
  }
}

tr:last-child td {
  border-bottom: 1px solid #ddd;
}

// ui-bootstrap tabs
.nav.nav-tabs {
  padding-left: 5px;
  border: none;

  >li {
    font-weight: bold;
    position: relative;
    margin-right: 5px;
    border: none;
    border-bottom: 0;

    >a {
      font-size: 1.3rem;
      line-height: 1;
      position: relative;
      top: 4px;
      display: block;
      width: 100%;
      height: 100%;
      padding: 12px 17px;
      color: #444;
      border: none;
      border-radius: 3px 3px 0 0;
      background-color: rgba(210, 204, 150, .7);

      &:hover {
        top: 0;
        text-decoration: none;
        color: #333;
        border-radius: 3px 3px 0 0;
        background-color: #fff;
        box-shadow: 0 1px 2px 0 rgba(90, 90, 90, 0.3);
      }
    }

    &.active {
      border: none;

      >a {
        line-height: 1;
        top: 0;
        color: #333;
        border: none;
        background-color: #fff;
        box-shadow: 0 1px 2px 0 rgba(90, 90, 90, 0.3);

        &:hover {
          line-height: 1;
          border: none;
        }

        &:focus {
          border: none;
        }
      }
    }
  }
}

.number-box {
  li {
    .section {
      min-height: 150px;

      .number {
        font-size: 1.6rem;
        font-weight: normal;
        padding: 6px 10px 3px;
        text-align: center;
        letter-spacing: 0;

        span {
          font-size: 5.5rem;
          font-weight: bold;
          line-height: 1;
          padding-right: .5rem;
        }

        &.attension span {
          color: #f65757;
        }
      }

      .supplement {
        font-size: 1.3rem;
        font-weight: bold;
        text-align: center;
      }

      i {
        font-size: 3rem;
        position: absolute;
        top: 3px;
        right: 4px;
        display: block;
      }
    }

    &:last-child .section .number span {
      padding-right: 0;
    }
  }

  &.number-box-inner {
    padding: 0 10px;

    .section {
      position: relative;
      min-height: 130px;
      border: 3px solid #ebebeb;
      background-color: #fafafa;
      box-shadow: none;
    }

    li .section .number {
      span {
        font-size: 5.5rem;
        padding-left: 2rem;
      }

      &.pl0 span {
        padding-left: 0;
      }
    }
  }
}

.comma-group {
  .comma-group-item:last-child {
    .comma {
      display: none;
    }
  }
}

.mb-7 {
  margin-bottom: 7px !important;
}

.barn .form-w85 {
  width: 85px;
}

/**
 * kindXXXなどの牧場種類の表示切替
 * ブランドカラーは緑に統一事になったため切り替えが要らなくなったが
 * 使用箇所が多すぎて対処するのは時間かかる。
 * 暫定的にここにスタイルを設定して、デザイン刷新のタイミングで削除する予定
 */
.kindColor {
  color: $primaryColorOld !important;
}

.kindBackgroundColor {
  background-color: $primaryColorOld !important;
}

.kindBorderLeftColor {
  border-left-color: $primaryColorOld !important;
}

label {
  &.stylish {
    margin-top: 5px;
    cursor: pointer;
  }

  &.no-margin {
    margin: 0;
  }

  &.font-weight-normal {
    font-weight: normal;
  }
}

// Common width values
.col-w30 {
  width: 30px;
}

.col-w40 {
  width: 40px;
}

.col-w45 {
  width: 45px;
}

.col-w50 {
  width: 50px;
}

.col-w70 {
  width: 70px;
}

.col-w60 {
  width: 60px;
}

.col-w80 {
  width: 80px;
}

.col-w90 {
  width: 90px;
}

.col-w100 {
  width: 100px;
}

.col-w120 {
  width: 120px;
}

.col-w130 {
  width: 130px;
}

.col-w150 {
  width: 150px;
}

.col-w180 {
  width: 180px;
}

.col-w200 {
  width: 200px;
}

.col-w250 {
  width: 250px;
}

.col-w280 {
  width: 280px;
}

.no-border {
  border: 0 !important;
}

.no-wrap {
  white-space: nowrap;
}

.barn .select2-search input,
.barn .select2-search-choice-close,
.barn .select2-container .select2-choice abbr,
.barn .select2-container .select2-choice .select2-arrow b,
.modal .select2-search input,
.modal .select2-search-choice-close,
.modal .select2-container .select2-choice abbr,
.modal .select2-container .select2-choice .select2-arrow b {
  background-image: url('../images/select2x2.png') !important;
  background-repeat: no-repeat !important;
  background-size: 60px 40px !important;
}

.hide-title {
  display: none;
}

// 他の.disabledと被らないようにselect-追加したが、基本は汎用クラスのつもり。
// 現時刻(2017/08.31)で確認できる .disabledが牛状態活動タブ選択ボタンのみ
.selection-disabled {
  background-color: $lightGrey !important;
}

.state-error {
  color: #ff573f !important;
}

.state-error--secondary {
  color: #bc0822 !important;
}

.state-warning {
  color: #6f8d15 !important;
}

.flex-container {
  display: flex;

  .right-justified-item {
    margin-left: auto;
  }
}

.direction-column {
  flex-direction: column;
}

.justify-center {
  justify-content: center;
}

.align-items-center {
  align-items: center;
}

.flex-grow-1 {
  flex-grow: 1;
}

.flex-left-item {
  margin-right: auto;
}

.flex-buttons-right-justified {
  display: flex;

  justify-content: flex-end;
}

.left-justified {
  flex: 1 0 auto;
}

.simple-tab {
  display: inline-block;
  width: 100%;
  padding: 10px;
  border: 1.2px solid #999;
  border-radius: 3px;
}

.simple-tab-selector {
  display: inline-block;
  width: 100px;
  margin: 0 1px;
  padding: 3px 0;
  cursor: pointer;
  transition: ease 0.2s opacity;
  text-align: center;
  color: #999;
  border: 1.2px solid #999;
  border-bottom: none;
  border-radius: 3px;
  background: #ddd;

  &:hover {
    opacity: 0.5;
  }
}

.active-tab {
  color: #666;
  background: #fafafa;
}

.enclosed-area {
  display: inline-block;
  width: 100%;
  padding: 8px;
  border: 1px dashed #888;
  border-radius: 3px;

  .icon-css-plus {
    position: relative;
    display: inline-block;
    width: 12px;
    height: 12px;

    &::before {
      position: absolute;
      top: 7px;
      left: 0;
      display: block;
      width: 9px;
      height: 1px;
      content: '';
      background-color: #000;
    }

    &::after {
      position: absolute;
      top: 7px;
      left: 0;
      display: block;
      width: 9px;
      height: 1px;
      content: '';
      transform: rotate(90deg);
      background-color: #000;
    }
  }

  .icon-css-cancel {
    position: relative;
    display: inline-block;
    width: 12px;
    height: 12px;

    &::before {
      position: absolute;
      top: 7px;
      left: 0;
      display: block;
      width: 9px;
      height: 1px;
      content: '';
      transform: rotate(45deg);
      background-color: #000;
    }

    &::after {
      position: absolute;
      top: 7px;
      left: 0;
      display: block;
      width: 9px;
      height: 1px;
      content: '';
      transform: rotate(-45deg);
      background-color: #000;
    }
  }
}

.condition-tool-bar {
  min-height: 24px;
}

.item-group-header {
  font-size: 1.4rem;
  font-weight: bold;
  text-decoration: underline;
}

.sort-order-box {
  margin-top: 10px;
  margin-left: 50px;

  .sort-order-list {
    width: 350px;
    height: 250px;
  }

  .order-change-button {
    position: relative;
    display: block;
    width: 20px;
    height: 30px;
  }

  .icon-css-first {
    position: absolute;
    top: 9px;
    left: calc(50% - 6px);
    width: 12px;
    height: 12px;
    border-right: 6px solid transparent;
    border-bottom: 10px solid #ff9908;
    border-left: 6px solid transparent;

    &::before {
      position: absolute;
      top: 0;
      left: calc(50% - 6px);
      width: 12px;
      height: 1px;
      content: '';
      background: #ff9908;
    }
  }

  .icon-css-up {
    position: absolute;
    top: calc(50% - 7px);
    left: calc(50% - 6px);
    width: 12px;
    height: 12px;
    border-right: 6px solid transparent;
    border-bottom: 10px solid #ff9908;
    border-left: 6px solid transparent;
  }

  .icon-css-down {
    position: absolute;
    top: calc(50% - 4px);
    left: calc(50% - 6px);
    width: 12px;
    height: 12px;
    border-top: 10px solid #ff9908;
    border-right: 6px solid transparent;
    border-left: 6px solid transparent;
  }

  .icon-css-last {
    position: absolute;
    top: 9px;
    left: calc(50% - 6px);
    width: 12px;
    height: 12px;
    border-top: 10px solid #ff9908;
    border-right: 6px solid transparent;
    border-left: 6px solid transparent;

    &::after {
      position: absolute;
      bottom: 0;
      left: calc(50% - 6px);
      width: 12px;
      height: 1px;
      content: '';
      background: #ff9908;
    }
  }
}

.link-button-cancel {
  display: flex;
  text-decoration: none;
  color: #333;

  align-items: center;

  // 旧アイコンフォント調整
  .icon-l-arrow-A-l01 {
    font-size: 16px !important;
    width: 16px;
    height: 16px;
    margin: 0 5px;
    color: #fff;
    border-radius: 50%;
    background-color: $orange;

    align-items: center;
    justify-content: center;
  }

  &:hover {
    text-decoration: none;
    opacity: .8;
  }
}

.search-button {
  width: 65px;
  margin: 0 4px 0 4px !important; // override .barn *
  padding: 3px 5px;
  vertical-align: middle;
  border-radius: 6px;
  box-shadow: 0 1px 2px 0 rgba(90, 90, 90, 0.3);

  &:hover {
    text-decoration: none;
    color: $orange;
  }

  .search-button-icon {
    font-size: 2.0rem;
    vertical-align: middle;

    &:hover {
      text-decoration: none;
      color: $orange;
    }
  }

  .search-button-caption {
    font-size: 1.2rem;
  }
}

.filter-button {
  width: 60px;
  vertical-align: middle;
  border-radius: 6px;
  box-shadow: 0 1px 2px 0 rgba(90, 90, 90, 0.3);

  &:hover {
    text-decoration: none;
    color: $orange;
  }

  .filter-button-icon {
    font-size: 12px!important;
    margin: 0 4px 0 0;
    vertical-align: middle;

    &:hover {
      text-decoration: none;
      color: $orange;
    }
  }
}

.horizontal-arrow-button {
  // 旧アイコンフォント調整
  .icon-l-arrow-A-r01,
  .icon-l-arrow-A-l01 {
    background-color: #ff9908;
    width: 10px;
    height: 10px;
    color: #fff;
    border-radius: 50%;
    font-size: 16px!important;
  }
}

.up-arrow-button {
  font-size: 2.8rem;
  vertical-align: bottom;
  color: #ff9908;
}

.down-arrow-button {
  font-size: 2rem;
  vertical-align: bottom;
  color: #ff9908;
}

.accordion-toggle-button {
  width: 1.3rem;
  height: 1.3rem;
  color: #00f;
  border-radius: 50%;
  background-color: #eee;

  &.open {
    transform: rotate(180deg);
  }
}

.cow-select {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  cursor: pointer;

  align-items: center;
  justify-content: center;
}

.switch-farm-button {
  font-size: 2.0rem !important;
  margin-top: 5px !important;
}

.config-button {
  height: 20px;

  .config-icon {
    margin-top: 0px !important; // override .barn;
  }
}

.simple-radio-button {
  margin-top: 5px !important; // override vender.css;
  vertical-align: top;
}

.border-bottom-none {
  border-bottom: none !important; // override vender.css;
}

.force-center {
  text-align: center !important; // override .barn *;
}

.padding-vertical-30 {
  padding-top: 30px !important; // override .barn *;
  padding-bottom: 30px !important; // override .barn *;
}

.right-70 {
  right: 70px !important; // override .barn *;
}

.padding-top-10 {
  padding-top: 10px !important; // override .barn *
}

.margin-top-10 {
  margin-top: 10px !important; // override .barn *
}

.margin-top-2 {
  margin-top: 2px !important; // override .barn *
}

.margin-top-3 {
  margin-top: 3px !important; // override .barn *
}

.margin-top-4 {
  padding-top: 4px !important; // override .barn *
}

.margin-top-5 {
  margin-top: 5px !important; // override .barn *
}

.margin-top-50 {
  margin-top: 50px !important; // override .barn *
}

.margin-top-150 {
  margin-top: 150px !important; // override .barn *
}

.margin-top-ignore {
  margin-top: 0 !important; // override .barn *
}

.margin-bottom-0 {
  margin-bottom: 0px !important; // override .barn *
}

.margin-bottom-5 {
  margin-bottom: 5px !important; // override .barn *
}

.margin-bottom-10 {
  margin-bottom: 10px !important; // override .barn *
}

.margin-bottom-15 {
  margin-bottom: 15px !important; // override .barn *
}

.margin-bottom-30 {
  margin-bottom: 30px !important; // override .barn *
}

.margin-bottom-100 {
  margin-bottom: 100px !important; // override .barn *
}

.margin-right-5 {
  margin-right: 5px !important; // override .barn *
}

.margin-right-10 {
  margin-right: 10px !important; // override .barn *
}

.margin-right-15 {
  margin-right: 15px !important; // override .barn *
}

.margin-right-30 {
  margin-right: 30px !important; // override .barn *
}

.margin-right-50 {
  margin-right: 50px !important; // override .barn *
}

.margin-right-70 {
  margin-right: 70px !important; // override .barn *
}

.margin-right-ignore {
  margin-right: 0 !important; // override .barn *
}

.margin-left-5 {
  margin-left: 5px !important; // override .barn *
}

.margin-left-9 {
  margin-left: 9px !important; // override .barn *
}

.margin-left-10 {
  margin-left: 10px !important; // override .barn *
}

.margin-left-15 {
  margin-left: 15px !important; // override .barn *
}

.margin-left-20 {
  margin-left: 20px !important; // override .barn *
}

.margin-left-30 {
  margin-left: 30px !important; // override .barn *
}

.margin-left-50 {
  margin-left: 50px !important; // override .barn *
}

.margin-left-110 {
  margin-left: 110px !important; // override .barn *
}

.margin-left-300 {
  margin-left: 300px !important; // override .barn *
}

.padding-top-10 {
  padding-top: 10px;
}

.padding-ignore {
  padding: 0 !important;
}

.padding-top-ignore {
  padding-top: 0 !important; // override .barn *
}

@for $i from 1 through 70 {
  .w#{$i * 10} {
    width: #{$i * 10}px !important;
  }
}

@for $i from 10 through 100 {
  .w-percentage#{$i} {
    width: percentage($i * 0.01);
  }
}

.h-auto {
  height: auto!important;
}

.h22 {
  height: 22px;
}

.h30 {
  height: 30px !important;
}

.h40 {
  height: 40px;
}

.h50 {
  height: 50px !important;
}


.h200 {
  height: 200px !important;
}

.h460 {
  height: 460px !important;
}

.h600 {
  height: 600px !important;
}

.mw500 {
  max-width: 500px;
}

.mw800 {
  max-width: 800px;
}

.mw900 {
  max-width: 900px;
}

.mw1000 {
  max-width: 1000px;
}

.mw1200 {
  max-width: 1200px;
}

.mw1300 {
  max-width: 1300px;
}

.mw1555 {
  max-width: 1555px;
}

.min-width-ignore {
  min-width: 0 !important;
}

.mh400 {
  max-height: 400px;
}

.mh500 {
  max-height: 500px;
}

.fixed-table-wrapper {
  overflow: auto;
  height: calc(100vh - 156px);
}

.vertical-align-top {
  vertical-align: top;
}

.vertical-align-middle {
  vertical-align: middle;
}

.vertical-align-sub {
  vertical-align: sub !important;
}

.simple-break-line {
  border-bottom: solid 0.5px silver;
}

.notes-area {
  height: 4em;
}

.view-only {
  background-color: #f6f4f4;
}

.simple-caption {
  font-size: 1.4rem !important; // override .barn *
}

.small-caption {
  font-size: 1.2rem;
  font-weight: normal;
}

.no-data-caption {
  font-weight: 700;
  color: #fff;
}

.indication {
  font-size: 1.6rem !important; // override .barn *
  font-weight: bold;
}

.strong-indication {
  color: $orange;
}

.item-header {
  font-size: 1.6rem;
  font-weight: bold;
  text-decoration: underline
}

.item-caption {
  font-size: 1.4rem !important; // override .barn *
  font-weight: bold;
}

.item-value {
  font-size: 1.4rem;
  font-weight: normal;
}

.right-aligned {
  text-align: right;
}

.events--list {
  height: calc(100vh - 290px);

  .barn--daily-report-details & {
    height: calc(100vh - 202px);
  }
}

.value-changed {
  font-weight: bold;
  color: #ff4500 !important; // override .barn *
}

.state-confirmed {
  font-size: 1.6rem !important; // override .barn *
  font-weight: bold;
  color: green;
}

.state-unconfirmed {
  font-size: 1.6rem !important; // override .barn *
  font-weight: bold;
  color: red;
}

.state-recoverd {
  font-size: 1.6rem !important; // override .barn *
  font-weight: bold;
  color: #6495ED;
}

.sorted-none {
  &:hover {
    cursor: pointer;
    color: #ccc;
  }

  .customlist-th-inner {
    display: flex;
    font-size: 1.3rem;

    justify-content: center;
    align-items: center;

    div.sort-icon {
      width: 10px;
      height: 18px;
      margin-left: 5px;
      background-image: url('../images/sorted-normal.svg');
      background-repeat: no-repeat;

      flex: 0 0 auto;
    }
  }
}

.sorted-asc {
  color: #333 !important;
  background-color: #e8e8e8 !important;

  &:hover {
    cursor: pointer;
  }

  .customlist-th-inner {
    display: flex;
    font-size: 1.3rem;

    justify-content: center;
    align-items: center;

    div.sort-icon {
      width: 10px;
      height: 18px;
      margin-left: 5px;
      background-image: url('../images/sorted-asc.svg');
      background-repeat: no-repeat;

      flex: 0 0 auto;
    }
  }
}

.sorted-desc {
  color: #333 !important;
  background-color: #e8e8e8 !important;

  &:hover {
    cursor: pointer;
  }

  .customlist-th-inner {
    display: flex;
    font-size: 1.3rem;

    justify-content: center;
    align-items: center;

    div.sort-icon {
      width: 10px;
      height: 18px;
      margin-left: 5px;
      background-image: url('../images/sorted-desc.svg');
      background-repeat: no-repeat;

      flex: 0 0 auto;
    }
  }
}

.white-space-pre {
  white-space: pre;
}

.print-section {
  page-break-after: always;
}

.module-form {
  padding: 2rem 1.6rem 0;
  background-color: #fff;
}

.module-form .mf-icon,
.module-form .mf-unit {
  font-size: calc(1em + .4rem);
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  pointer-events: none;
  color: #777;
}

.module-form .mf-icon:first-child,
.module-form .mf-unit:first-child {
  left: 1.2rem;
}

.module-form .mf-icon:last-child,
.module-form .mf-unit:last-child {
  right: 1.2rem;
}

.module-form .mf-action {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin: 8.8rem -1.6rem 0;
  padding: 3.5rem .6rem 1.5rem;
  background-color: #f2f2f2;
}

.module-form .mf-action_button {
  font-size: calc(1em + .5rem);
  width: 100%;
  height: 5.6rem;
  margin-right: 1rem;
  margin-left: 1rem;
  border-color: transparent;
  background-color: #fff;
}

.module-form .mf-action_button[type="submit"] {
  font-weight: 700;
  color: #fff;
  background-color: #5d7836;
}

.module-form .mf-action_button[type="submit"]:not(:first-child) {
  width: 162.5%;
}

.module-form .mf-addgroup {
  position: relative;
  margin: 5rem auto 2rem;
  padding: 4.5rem 0 2.5rem;
  border-top: .1rem solid #ddd;
  border-bottom: .1rem solid #ddd;
}

.module-form .mf-addgroup_button {
  width: 2.5rem;
  height: 2.5rem;
  padding: 0;
  border: none;
  background-color: #f2f2f2;
}

.module-form .mf-addgroup_button.button-close {
  font-size: calc(1em + 1.3rem);
  line-height: 1;
  position: absolute;
  top: 4.1rem;
  right: 0;
}

.module-form .mf-addgroup+.mf-addgroup {
  margin-top: -2rem;
  border-top: none;
}

.module-form .mf-addgroup.mf-no-border {
  margin-top: -2rem;
  border-top: none;
}

.module-form .mf-checkbox {
  font-size: calc(1em + .2rem);
  margin-bottom: 1.2rem;
}

.module-form .mf-checkbox_label {
  font-weight: 400;
  position: relative;
  margin-bottom: 0;
  padding-left: 2.3rem;
}

.module-form .mf-checkbox_label::before {
  font-family: 'icomoon' !important;
  font-size: calc(1em + .4rem);
  font-weight: normal;
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  position: absolute;
  top: .1em;
  left: 0;
  margin-right: .5rem;
  content: "\e917";
  text-transform: none;
  color: #b5b5b5;

  speak: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.module-form .mf-checkbox input:checked+.mf-checkbox_label::before {
  content: "\e917";
  color: #477d17;
}

.module-form .mf-checkboxgroup {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;

  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.module-form .mf-checkboxgroup_item {
  font-size: calc(1em + .4rem);
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  width: 50%;
  min-height: 4.5rem;
  color: #ccc;
  border: .1rem solid #ddd;
  border-right: none;
}

.module-form .mf-checkboxgroup um-form-input:last-child .mf-checkboxgroup_item,
.module-form .mf-checkboxgroup um-form-input:nth-child(even) .mf-checkboxgroup_item {
  border-right: .1rem solid #ddd;
}

.module-form .mf-checkboxgroup um-form-input:nth-child(n+3) .mf-checkboxgroup_item {
  border-top: none;
}

.module-form .mf-checkboxgroup_label {
  font-weight: 400;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin: 0;
  padding: .9rem 1.3rem;
  transition: all .3s ease;

  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.module-form .mf-checkboxgroup_label::before {
  font-family: 'icomoon' !important;
  font-size: calc(1em + .2rem);
  font-weight: normal;
  font-style: normal;
  font-variant: normal;
  margin-right: .5rem;
  content: "\e917";
  text-transform: none;

  speak: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.module-form .mf-checkboxgroup input:checked+.mf-checkboxgroup_label {
  color: #fff;
  background-color: #366678;
}

.module-form .mf-checkboxgroup input:checked+.mf-checkboxgroup_label::before {
  content: "\e917";
  color: #fff;
}

.module-form .mf-field {
  position: relative;
  width: 100%;
}

.module-form .mf-field .form-control {
  font-size: 1.6rem;
  height: 4.5rem;
  padding: 1rem 1.2rem;
  resize: none;
  border-color: transparent;
  border-radius: 0;
  background-color: #f2f2f2;
  box-shadow: none;
}

.module-form .mf-field .form-control::-webkit-input-placeholder,
.module-form .mf-field .form-control:placeholder-shown {
  color: #ccc;
}

.module-form .mf-field .form-control[disabled] {
  color: #777;
  border-style: dashed;
  border-color: #ddd;
  background-color: #fff;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.module-form .mf-field .form-control[rows] {
  height: auto;
}

.module-form .mf-field .form-control.has-icon:not(:last-child):not(select),
.module-form .mf-field .form-control.has-unit:not(:last-child):not(select) {
  padding-right: 4.2rem;
}

.module-form .mf-field .form-control.has-icon:not(:first-child):not(select),
.module-form .mf-field .form-control.has-unit:not(:first-child):not(select) {
  padding-left: 4.2rem;
}

.module-form .mf-field_action {
  margin-top: 2rem;
}

.module-form .mf-field_action .mf-button {
  font-size: calc(1em + .1rem);
  font-weight: 700;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 4.2rem;
  margin-left: auto;
  padding: 0 1rem;
  color: #777;
  border: .1rem dotted #ccc;
  border-radius: .45rem;
  background: none;

  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.module-form .mf-field_action .mf-button [class*="icon"] {
  font-size: calc(1em + .9rem);
  margin-right: 1rem;
}

.module-form .mf-group {
  margin: 0 auto 4.8rem;
}

.module-form .mf-group .row {
  margin-right: -.8rem;
  margin-left: -.8rem;
}

.module-form .mf-group [class*="col-"] {
  padding-right: .8rem;
  padding-left: .8rem;
}

.module-form .mf-group_help {
  font-size: calc(1em + .3rem);
  line-height: 1.2;
  display: block;
  margin-top: .5rem;
}

.module-form .mf-group .is-active,
.module-form .mf-group .form-control:focus {
  border-bottom-color: #537b2b;
  background-color: #fff;
}

.module-form .mf-group.is-error {
  color: #d22323;
}

.module-form .mf-group.is-error .mf-field * {
  color: #d22323;
}

.module-form .mf-group.is-error .form-control {
  border-bottom-color: #d22323;
  background-color: #fff;
}

.module-form .mf-horizontal {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;

  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.module-form .mf-horizontal .form-control {
  font-weight: 700;
  color: inherit;
  border-bottom-color: #ddd;
  background: #fff;
}

.module-form .mf-horizontal .mf-label {
  margin-right: .5rem;
  margin-bottom: 0;

  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.module-form .mf-icon {
  font-size: calc(1em + 1rem);
  color: #666;
}

.module-form .mf-label {
  font-size: calc(1em + .2rem);
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 1.2rem;
}

.module-form .mf-label_icon {
  font-size: calc(1em - .1rem);
  font-weight: 400;
  margin-left: 1rem;
  padding: .2rem .6rem;
  border-radius: 0;
}

.module-form .mf-label .label-danger {
  background-color: #d22323;
}

.module-form .mf-radio {
  font-size: calc(1em + .2rem);
  margin-bottom: 1.2rem;
}

.module-form .mf-radio input:checked+.mf-radio_label::before {
  border-color: #477d17;
}

.module-form .mf-radio input:checked+.mf-radio_label::after {
  background-color: #477d17;
}

.module-form .mf-radio_label {
  font-weight: 400;
  position: relative;
  margin-bottom: 0;
  padding-left: 2.5rem;
}

.module-form .mf-radio_label::before,
.module-form .mf-radio_label::after {
  position: absolute;
  top: .2em;
  left: 0;
  content: "";
  transition: all .3s ease;
  border-radius: 50%;
}

.module-form .mf-radio_label::before {
  width: 1.5rem;
  height: 1.5rem;
  border: .1rem solid #b5b5b5;
}

.module-form .mf-radio_label::after {
  top: calc(.2em + .3rem);
  left: .3rem;
  width: .9rem;
  height: .9rem;
  background-color: #fff;
}

.module-form .mf-radiogroup {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.module-form .mf-radiogroup_item {
  font-size: calc(1em + .2rem);
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  width: 100%;
  height: 4rem;
  color: #ccc;
  border: .1rem solid #ddd;
  border-right: none;
}

.module-form .mf-radiogroup_item:last-child {
  border-right: .1rem solid #ddd;
}

.module-form .mf-radiogroup_label {
  font-weight: 400;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin: 0;
  transition: all .3s ease;

  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.module-form .mf-radiogroup input:checked+.mf-radiogroup_label {
  color: #fff;
  background-color: #366678;
}

.module-form .mf-select::after {
  position: absolute;
  top: 50%;
  right: 1.2rem;
  width: 0;
  height: 0;
  content: "";
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  pointer-events: none;
  border-width: .75rem .45rem 0 .45rem;
  border-style: solid;
  border-color: #999 transparent transparent transparent;
}

.module-form .mf-select select.form-control {
  padding-right: 3rem;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.module-form .mf-select select.form-control.has-unit {
  padding-right: 6rem;
}

.module-form .mf-select .mf-unit:last-child {
  right: 3.2rem;
}

.module-form .mf-subgroup {
  margin: 2rem auto;
}

.module-form .mf-subgroup .mf-label {
  color: #777;
}

.module-form .mf-table {
  font-size: calc(1em + .2rem);
  line-height: 1.4;
  margin-top: 2rem;
  color: #555;
}

.module-form .mf-table_data,
.module-form .mf-table_head {
  padding: .2rem 0;
  vertical-align: top;
}

.module-form .mf-table_head {
  font-weight: 400;
  width: 10%;
  text-align: right;
  white-space: nowrap;
}

.checkbox-with-label {

  label {
    display: block;
  }

  input {
    position: relative;
    top: -1px;
    margin: 0;
    padding: 0;
    vertical-align: bottom;
  }
}

.icon-css-remove {
  position: relative;
  width: 12px;
  height: 12px;
  left: -20px !important;
  vertical-align: top;

  &::before {
    position: absolute;
    top: 9px;
    left: 0;
    display: block;
    width: 10px;
    height: 1px;
    content: '';
    transform: rotate(45deg);
    background-color: #333;
  }

  &::after {
    position: absolute;
    top: 9px;
    left: 0;
    display: block;
    width: 10px;
    height: 1px;
    content: '';
    transform: rotate(-45deg);
    background-color: #333;
  }
}

/* 以下は使い捨ての設定 */
.sloppy-title {
  height: 48px;
  padding: 6px 12px 0 12px;

  font-size: 21px;
  font-weight: 700;
  color: black;
}

.sloppy-modal-panel {
  padding: 10px;
  background: white;
}

.sloppy-button {
  border: 1px solid #a9a9a9;
  border-radius: 10px;
  padding: 8px;
}

.sloppy-required {
  &::after {
    font-size: 11px;
    font-weight: 700;
    top: 1px;
    right: 10px;
    margin-left: 10px;
    width: 30px;
    height: 16px;
    content: "必須";
    color: #b70000;
    border: 1px solid #b70000;

    align-items: center;
    justify-content: center;
  }
}

.regulation-number-4 {
  font-weight: 700;
  margin: 0 0.5em !important;
}
