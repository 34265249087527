/**
 *  Toolbar component
 *  $1. Default styles
 *  $2. Custom styles
 */


/**
 *  $1. Default styles
 */

.toolbar {
  align-items: center;
  display: flex;
  height: 32px;
  justify-content: space-between;
  margin: 0 10px !important; // .barnのせい
  &--tool:not(:last-child) {
    margin-right: 10px !important; // .barnのせい
  } // alternative toolbar, display only when .display is appended
  &-alternative {
    background-color: #fff;
    justify-content: center;
    min-width: 0;
    max-width: 0;
    overflow: hidden;
    position: absolute;
    transition: $default-transition;
    z-index: 1;
    &.display {
      min-width: calc(100% - 20px);
      max-width: calc(100% - 20px);
    }
  }
}

.tools-sort {
  margin-right: auto !important; // .barnのせい
}

.tools--sortLabel {
  display: inline-block;
  &:not(:first-of-type) {
    margin-left: 10px!important;
  }
}


/**
 *  $2. Custom styles
 */

.toolbar {
  // カスタムリスト
  &-customList {
    margin: 0 !important; // .barnのせい
  } // 一括イベント
  &-events {
    margin: 0 0 10px !important; // .barnのせい
  }
}

.barn .toolbar-alert-history .tools-sort {
  vertical-align: middle;
  input[type=text] {
    width: 8em;
  }
}