.help-modal {
  width: 0;
  height: 0;
  overflow: visible !important;
  .modal-dialog {
    margin: 0;
  }
  .modal-content {
    background: none;
    border: none;
    overflow: visible;
    box-shadow: none;
  }
}
.help-content {
  cursor: move;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  box-shadow: 0 5px 15px rgba(0,0,0,.5);
  overflow: auto;
  position: fixed;
  width: 300px;

  .help--header {
    margin: 0;
    padding: 10px;
    background-color: $grey;
    box-shadow: 0 2px 5px rgba(0,0,0,.5);
    color: white;
    font-size: 2rem;
  }

  .help--text {
    padding: 10px;
    &.larger {
      font-size: 1.4rem;
    }
  }

  .help--listItem {
    margin: 10px 20px;
    &:before {
      content: '';
      display: inline-block;
      background-color: #000;
      width: 5px;
      height: 5px;
      border-radius: 50%;
      margin-right: 5px;
      margin-bottom: 2px;
    }
  }

  .btn-cancel {
    float: right;
    line-height: 1;

    .icon-css-cancel {
      position: relative;
      display: block;
      width: 20px;
      height: 20px;

      &::before {
        position: absolute;
        top: 8px;
        left: 3px;
        display: block;
        width: 14px;
        height: 2px;
        content: '';
        transform: rotate(45deg);
        background-color: #fff;
      }

      &::after {
        position: absolute;
        top: 8px;
        left: 3px;
        display: block;
        width: 14px;
        height: 2px;
        content: '';
        transform: rotate(-45deg);
        background-color: #fff;
      }
    }
  }
}
