@media print {
  .barn .accordion-table .summary div.table-container {
    width: 80mm;
  }
  .accordion-table h4.kindColor {
    color: #000 !important;
    font-size: 6pt;
    margin-bottom: 0;
    padding: 0px 10px;
  }
  .barn .accordion-table .summary table tbody td {
    text-align: right !important;
    border-bottom: 1px solid #333;
  }
  .barn .accordion-table .summary td:first-child {
    border-left: none !important;
  }
  .barn .accordion-table .summary table thead tr th {
    padding: 7px 10px;
    text-align: center;
    min-width: 150px !important;
  }
  .barn .top--table-simple td span {
    font-size: 7pt;
  }
  .barn .accordion-table .note {
    font-size: 7pt;
  }
  .barn {
    padding: none;
  }
  .barn .accordion-table.non-print {
    display: none;
  }
  .barn .accordion-table.print {
    display: block;
  }

  .barn .accordion-table {
    .result {
      display: block;
      padding: 5px 0px 0px;
      table {
        border-collapse: collapse;
        
        border-bottom: 1px solid black !important;
        border-right: none !important;
        border-left: none !important;

        thead {
          border: none !important;
          th {
            border: none !important;
            font-size: 11px !important;
            padding: 2px 0px 4px 0px !important;
            background-color: white !important;
            vertical-align: bottom;
          }
          th.category-header {
            width: 160px; 
          } 
          tr {
            border: none !important;
          }
        }

        tbody {
          border-right: 1px solid black;

          tr:nth-child(2n+1) {
            background-color: #f7f7f7 !important;
          }
          tr {
            td {
              font-size: 11px !important;                         
              padding: 0px !important; 
              div.all-data, div.yoy-data {
                font-size: 11px !important;
                min-height: 15px;  
                margin: 0px !important;
                padding: 0px 0px !important; 
              }
              div.yoy-data {
                span {
                  font-style: normal !important;
                  font-size: 11px !important;
                }
              }
            }
            td.data-cell {
              border-left: 0.5px solid #cacaca;
              text-align: right !important;
              padding: 0px 5px !important; 

              div.monthly-data.yoy-data {
                padding: 0px 0px !important;
                span {
                  font-size: 10px !important;                     
                  color: #aaa !important;
                }
              }
              div.all-data {
                padding: 0px 0px !important;
                font-size: 10px !important;                       
              }
              div.yoy-data {
                text-align: right !important;
              }
            }

            td:last-child {
              border-right: 1px solid black;
            }

            td.category {
              padding: 0px !important; 
              background-color: white !important;
              border-right: none !important;              
              .yoy-data {
                margin-left: 1em !important;
                color: #aaa !important;
              }
            }
            td.sub-category-yoy {
              padding: 0px 2px !important;
              background-color: white !important;
              border-right: none !important;
            }
            td.sub-category {
              border-right: none !important;
              border-top: 0.5px solid #cacaca !important;
              background-color: white !important;
              .all-data {
                margin-left: 10px !important;
              }
              .yoy-data {
                margin-left: 2em !important;
                color: #aaa !important;
              }
            }
            td.data-cell.sub {
              border-top: 0.5px solid #cacaca !important;
            }
            td.data {
              border-left: 0.5px solid #cacaca;
              text-align: right;
              padding: 0px !important;
            }
          }
        }
      }
    }
  }
}