/*---------------------------------
　サブ見出し
---------------------------------*/

/*
　@extend用のプレースホルダーセレクタ
---------------------------------*/
%uSubHeading__stopShipmentButton {
  font-weight: 700;
  position: relative;
  display: flex;
  margin: 0 8px 0 0 !important;
  padding: 4px 28px 4px 12px;
  border-radius: 10vw;
  box-shadow: 0 1px 2px rgba(0, 0, 0, .16);

  [class^="icon-l-arrow"] {
    position: absolute;
    top: 50%;
    right: 4px;
    transform: translateY(-50%);
  }
}

/*
　サブ見出し
---------------------------------*/
.uSubHeading {
  padding: 4px 16px 0 24px;
  color: white(primary);

  &__row {
    display: flex;

    justify-content: space-between;

    &--hasCowLabel {
      display: flex;

      flex-wrap: wrap;
    }
  }

  /*
  　サブ見出し - 出荷停止
  ---------------------------------*/
  &__stopShipment {
    display: flex;
    margin: 0 0 8px !important;

    align-items: center;

    &Button {
      &--primary {
        border: 1px solid rgba(128, 1, 113, 1);
        background: linear-gradient(to right, rgba(121, 5, 93, 1), rgba(100, 6, 108, 1));

        @extend %uSubHeading__stopShipmentButton;

        &:hover {
          background: linear-gradient(to right, rgba(178, 41, 145, 1), rgba(137, 2, 149, 1));
        }
      }

      &--secondary {
        border: 1px solid rgba(81, 4, 170, 1);
        background: linear-gradient(to right, rgba(80, 0, 160, 1), rgba(47, 2, 119, 1));

        @extend %uSubHeading__stopShipmentButton;

        &:hover {
          background: linear-gradient(to right, rgba(107, 0, 214, 1), rgba(71, 15, 160, 1));
        }
      }

      &Body,
      &Heading {
        line-height: 1.2;
      }

      &Body {
        font-size: 13px !important;

        &:last-child {
          padding: 0 8px 0 0;
        }
      }

      &Heading {
        font-size: 13px !important;
        padding: 0 8px 0 0;
      }
    }

    &Heading {
      font-size: 13px !important;
      position: relative;
      bottom: 1px;
      padding-right: 12px;
      color: rgba(255, 255, 255, .67);
    }
  }

  /*
  　サブ見出し - 基本情報
  ---------------------------------*/
  &__info {
    display: flex;

    flex-wrap: wrap;

    &Item {
      display: flex;
      margin: 2px 0 !important;
      padding: 0 20px 0 0;

      align-items: center;
      flex: 0 0 auto;

      &Body {
        font-size: 14px !important;
        font-weight: 700;
      }

      &Heading {
        font-size: 13px !important;
        padding: 0 8px 0 0;
        color: rgba(255, 255, 255, .67);
      }
    }

    .uSubHeading__row--hasCowLabel & {
      margin: 0 auto 2px 0 !important;

      &Item {
        margin: 2px 0 10px !important;
      }
    }
  }

  /*
  　サブ見出し - 個体ラベル
  ---------------------------------*/
  &__cowLabel {
    display: flex;
    padding: 0 0 0 20px;
    border-left: 1px solid #575757;

    align-items: center;
    flex: 0 0 auto;

    &Button {
      display: inline-flex;
      min-width: 30px;
      max-height: 24px;
      padding: 0 8px;
      border-radius: 10vw;
      background: #3b3b3b;

      align-items: center;
      flex: 0 0 auto;
      justify-content: center;

      &Heading {
        font-size: 13px !important;
        line-height: 1.2;
        padding: 5px 12px 5px 0;
      }

      .icon-css-plus {
        position: relative;
        display: block;
        width: 14px;
        height: 14px;

        &::before,
        &::after {
          position: absolute;
          top: 50%;
          right: 50%;
          display: block;
          width: 14px;
          height: 2px;
          content: '';
          transform: translate(50%, -50%);
          background-color: #bbbbbc;
        }

        &::after {
          transform: translate(50%, -50%) rotate(90deg);
        }
      }

      &:hover {
        background: linear-gradient(to right, #666, #555);
        box-shadow: 0 3px 6px rgba(0, 0, 0, .16);

        .icon-css-plus {

          &::before,
          &::after {
            background-color: #fff;
          }
        }
      }
    }

    &Container {
      display: flex;

      flex-wrap: wrap;
    }

    &Icon {
      line-height: 1.2;
      display: flex;
      padding-right: 12px;

      align-items: center;
      flex: 0 0 auto;
    }

    &Item {
      font-size: 13px !important;
      line-height: 24px;
      display: inline-flex;
      height: 24px;
      margin: 0 6px 4px 0 !important;
      padding: 1px 10px;
      border: 1px solid #444;
      border-radius: 4px;

      align-items: center;
    }

    .uSubHeading__row--hasCowLabel & {
      padding-left: 0;
      border-left: none;

      align-items: flex-start;
      flex-shrink: 1;

      &Button {
        width: 34px;
        border: 1px solid rgba(141, 141, 141, .08);
        border-radius: 4px;
        background: linear-gradient(to right, #333, #303234);

        &Heading {
          display: none;
        }

        &:hover {
          background: linear-gradient(to right, #666, #555);
        }
      }

      &Icon {
        padding: 4px 16px 3px 0;
      }
    }
  }

  /*
  　個体詳細（新デザイン）　調整
  ---------------------------------*/
  &+.cow-detail {
    padding-top: 12px !important;

    &::before {
      display: none !important;
    }

    .tab-result,
    .contents {
      padding: 0 16px 10px 24px !important;

      @media (pointer: coarse), (max-width: 959px) {
        padding-left: 16px !important;
      }
    }

    .box-history {
      position: static;
      width: 33.33333333%;
      min-width: 380px;
      padding: 0 0 10px 0 !important;
    }
  }

  &+.adjust-content-width {
    width: 80%;
    min-width: 880px;
    max-width: 1200px;

    @media (pointer: coarse) {
      @media (orientation: landscape) {
        width: 100%;
        min-width: 880px;
        max-width: none;
      }

      @media (orientation: portrait) {
        width: 100%;
        min-width: auto;
        max-width: none;
      }
    }
  }
}
