.alert-calving {
  .alert-calving-title {
    .uHeading__title {
      font-size: 21px !important;
    }

    &-unconfirmed {
      color: #ffffff;
      background: #4d0000;
      border: 1px solid #9f0024;
      border-radius: 4px;
      display: block;
      font-size: 14px;
      margin-left: 33px;
      opacity: 1;
      padding: 3.5px 22px;
    }

    &-confirmed {
      color: #fff;
      background: #444444;
      border: 1px solid #444444;
      border-radius: 4px;
      display: block;
      font-size: 14px;
      margin-left: 33px;
      opacity: 1;
      padding: 3.5px 22px;
    }

    &-count {
      font-size: 21px;
      margin-left: 25px;
    }
  }

  .alert-calving-content {
    padding: 10px;
  }

  .alert-calving-list-item {
    background: #ffffff;
    border-radius: 4px;
    display: flex;
    margin-bottom: 10px;
    overflow: auto;
    padding: 8px 24px 16px 16px;

    &-content {
      flex: 1 0 500px;

      .content-primary {
        display: flex;
        margin-bottom: 16px;
        flex-wrap: wrap;

        .content-item {
          margin: 0 32px 0 0;
          padding: 8px 0 0 0;

          .content-item-label {
            color: #2121219e;
            font-size: 13px;
            font-weight: 400;
            margin-right: 12px;
          }

          .content-item-value {
            color: #2121219e;
            font-size: 15px;
            font-weight: 700;

            &.cow-no {
              color: #2a66b7;
              font-size: 17px;
            }

            &.alert-level-high {
              color: #bc0822;
            }

            &.alert-level-low {
              color: #ff9e08;
            }

            &.confirmed-status-sign {
              color: #bc0822;
            }

            &.confirmed-status-no-sign {
              color: #bbbbbb;
            }
          }
        }
      }

      .content-secondary {
        background: #f0f0f0;
        border-radius: 4px;
        padding: 24px 20px;

        .content-item {
          margin: 0 24px 0 0;

          &-label {
            color: #2121219e;
            font-size: 13px;
            font-weight: 400;
            margin-right: 8px;
          }

          &-value {
            color: #2121219e;
            font-size: 13px;
            font-weight: 700;
          }
        }
      }
    }

    &-operation {
      &-regist {
        align-items: center;
        display: flex;
        height: 100%;
        justify-content: flex-end;
        width: 200px;

        &-button {
          align-items: center;
          background: linear-gradient(90deg,#387ac7,#0d3898);
          border: 1px solid rgba(0,0,0,.12);
          border-radius: 4px;
          box-shadow: 0 2px 3px rgba(0,0,0,.36);
          color: #fff;
          cursor: pointer;
          display: flex;
          font-size: 14px;
          font-weight: 700;
          height: 40px;
          justify-content: space-between;
          margin: 0 0 0 24px;
          opacity: 1;
          padding: 0 5px 0 19px;
          transition: all .1s ease;
          width: 156px;

          &:hover {
            border: 1px solid rgba(0, 0, 0, 0.08);
            background: linear-gradient(to right, #3e8eeb, #377adc, #1f34a8);
            box-shadow: 0 3px 6px rgba(0, 0, 0, 0.36);
          }
        }
      }

      &-edit {
        align-items: center;
        display: flex;
        height: 100%;
        justify-content: flex-end;
        width: 77px;

        &-button {
          align-items: center;
          background: #f2f2f2;
          border: 1px solid #8d8d8d15;
          border-radius: 44px;
          display: flex;
          font-size: 14px;
          justify-content: center;
          height: 40px;
          opacity: 1;
          width: 40px;

          &:hover {
            background: #eaeaea;
          }
        }
      }
    }

    .content-row {
      display: flex;
      flex-wrap: wrap;
      margin: 0 0 16px 0;

      &:last-child {
        margin: 0;
      }
    }
  }

  @media print {
    .alert-calving-list-item {
      border-bottom: 1px solid #000000;
      padding-top: 0;
    }

    .alert-calving-list-item-content {
      flex: 1;
    }

    .alert-calving-list-item-operation {
      display: none;
    }

    .content-primary {
      margin-bottom: 10px !important;
    }

    .content-secondary {
      padding: 0 !important;
    }
  }
}
