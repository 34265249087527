.top-notifications {
  overflow: auto;
  width: 100%;
  max-height: 164px;

  .top-notification-list-item {
    display: flex;
    overflow: hidden;
    height: 48px;
    margin-bottom: 5px;
    padding: 5px;
    background-color: white;

    align-items: center;

    &:last-child {
      margin-bottom: 10px;
    }

    &:hover {
      cursor: pointer;
    }

    .category-label {
      font-weight: 700;
      display: flex;
      width: 95px;
      margin-right: 10px;
      padding: 10px;

      flex: 0 0 auto;
      justify-content: center;
      align-items: center;
    }

    .title {
      margin-right: 10px;

      flex: 1 1 auto;
    }

    .date {
      display: flex;
      color: #999;

      align-items: center;
      flex: 0 0 auto;

      // 旧アイコンフォント調整
      .icon-l-arrow-A-r01 {
        color: $desamisGreen;
      }
    }

    &.maintenance {
      .category-label {
        color: notificationCategory(maintenance);
        background-color: notificationCategory(background);
      }
    }

    &.update {
      .category-label {
        color: notificationCategory(update);
        background-color: notificationCategory(background);
      }
    }

    &.incident {
      .category-label {
        color: white(primary);
        background-color: notificationCategory(incident);
      }

      .title {
        color: notificationCategory(incident);
      }
    }

    &.information {
      .category-label {
        color: notificationCategory(information);
        background-color: notificationCategory(background);
      }
    }
  }
}

.top-notification-dialog {
  padding: 30px;

  .cancel {
    position: absolute;
    top: -45px;
    right: 5px;
    color: white;

    &:hover {
      cursor: pointer;
    }
  }

  .icon-css-cancel {
    position: relative;
    display: block;
    width: 40px;
    height: 40px;

    &::before {
      position: absolute;
      top: 20px;
      left: 4px;
      display: block;
      width: 32px;
      height: 2px;
      content: '';
      transform: rotate(45deg);
      background-color: #fff;
    }

    &:after {
      position: absolute;
      top: 20px;
      left: 4px;
      display: block;
      width: 32px;
      height: 2px;
      content: '';
      transform: rotate(-45deg);
      background-color: #fff;
    }
  }

  .header {
    display: flex;
    border-bottom: 1px solid #ccc;

    align-items: center;

    .title {
      font-size: 16px;
      font-weight: 700;
      padding: 20px;

      flex: 1 1 auto;
    }

    .category-label {
      padding: 10px;

      flex: 0 0 auto;
    }
  }

  .body {
    overflow-y: auto;
    max-height: 50vh;
    padding: 20px;

    p {
      font-size: 14px;
    }
  }

  .footer {
    display: flex;
    padding: 20px 0px 20px 20px;

    justify-content: flex-end;

    .date {
      color: #999;
    }
  }

  &.maintenance {
    .header .category-label {
      color: notificationCategory(maintenance);
      background-color: notificationCategory(background);
    }
  }

  &.update {
    .header .category-label {
      color: notificationCategory(maintenance);
      background-color: notificationCategory(background);
    }
  }

  &.incident {
    .header .category-label {
      color: white(primary);
      background-color: notificationCategory(incident);
    }
  }

  &.information {
    .header .category-label {
      color: notificationCategory(information);
      background-color: notificationCategory(background);
    }
  }
}