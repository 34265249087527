.summary-milk-chart-message {
  text-align: center;
  width: 100%;
  padding-top: 90px;
  display: block;
}

#summary_activeMilkChart {
  .c3-tooltip-container {
    table.c3-tooltip {
      width: 200px;
      td.name {
        width: 70%;
      }
      td.value {
        width: 30%;
      }
    }
  }
}