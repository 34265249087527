/**
 *  Time Budget component
 *  $1. Default styles
 *  $2. TimeBudget details box (JS generated)
 *  $3. Single line TimeBudget detail (summary & activity)
 *  $4. TimeBudget error message (summary & activity)
 *  $5. TimeBudget chart controls (activity-activity, activity-heat)
 */

/**
 *  $1. Default styles
 */

.time-budget-contents-wrapper {
  height: 200px;
  position: relative;
  min-height: 1px;
}

/**
 *  $2. TimeBudget details box (JS generated)
 */

#activityTimeBudget-detail {
  >div {
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
  }
}

.activityTimeBudget-detail-child {
  margin: auto;
  display: inline-block;
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  border-bottom: solid 1px rgba(90, 90, 90, 0.3);
  .activityTimeBudget-detail-child-content {
    text-align: center;
    display: inline-block;
    padding: 3px 7px;
  }
}

/**
 *  $3. Single line TimeBudget detail (summary & activity)
 */

.timeBudget-detail--single {
  height: 24px;
}

/**
 *  $4. TimeBudget error message (summary & activity)
 */

.time-budget-message {
  text-align: center;
  width: 100%;
  display: block;
}

.activity-time-budget-message {
  padding-top: 70px;
}

.summary-time-budget-message {
  padding-top: 60px;
}

/**
 *  $5. TimeBudget chart controls
 *  // TODO: move to shared chart navi component
 */

.chart-nav {
  float: right;
  .edit-btn {
    min-width: 4rem;
    padding: 0 10px;
    text-align: center;
    cursor: pointer;
  }
}

.chart-pager {
  text-align: center;
  .btn-back,
  .btn-next {
    display: inline-block;
  }
  input {
    font-size: 1.5rem !important; // TODO: Remove !important flag
    font-weight: bold;
  }
}

.activityTimeBudgetFatteningDetail {
  text-align: right;
}

#activity-time-budget-detail-wrapper {
  height: 92px;
}